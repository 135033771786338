export interface DateCount {
  date: string;
  count: number;
}

export function countItemsByDate(
  items: { createdAt: unknown }[],
  daysBack = 30
): DateCount[] {
  const countsByDate: Record<string, number> = {};

  // Group items by date and count them
  items.forEach((item) => {
    const date = new Date(item.createdAt as string).toLocaleDateString();
    if (countsByDate[date]) {
      countsByDate[date]++;
    } else {
      countsByDate[date] = 1;
    }
  });

  // Generate the past daysBack days
  const result: DateCount[] = [];
  const today = new Date();

  for (let i = 0; i < daysBack; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    const dateString = date.toISOString().split("T")[0]; // 'YYYY-MM-DD' format

    result.push({
      date: dateString,
      count: countsByDate[dateString] || 0,
    });
  }

  return result.sort((a, b) => (a.date > b.date ? 1 : -1));
}
