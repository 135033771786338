import { Modal } from "@mantine/core";
import { useEffect, useState } from "react";
import type { Network } from "@pilplay/connectrpc";
import useBluetooth from "../../../../../hooks/useBluetooth/useBluetooth";
import { useIsMobile } from "../../../../../hooks/layout/useDevice";
import classes from "./ConfigureBoardModal.module.css";
import { BluetoothUnavailableScreen } from "./screens/BluetoothUnavailableScreen";
import { BluetoothDevicesScreen } from "./screens/BluetoothDevicesScreen";
import { BluetoothWifiListScreen } from "./screens/BluetoothWifiListScreen";
import { BluetoothWifiPasswordScreen } from "./screens/BluetoothWifiPasswordScreen";
import { BluetoothMenuScreen } from "./screens/BluetoothMenuScreen";

export interface ConfigureBoardModalProps {
  open: boolean;
  onClose: () => void;
}

const enum ConfigureState {
  Unavailable = "unavailable",
  Idle = "idle",
  Menu = "menu",
  ShowDevices = "show-devices",
  ShowWifiList = "show-wifi-list",
  EnterWifiPassword = "enter-wifi-password",
}

const ConfigStateComponent = () => {
  const [state, setState] = useState<ConfigureState>(
    ConfigureState.ShowDevices
  );
  const [connectedDevice, setConnectedDevice] =
    useState<BluetoothDevice | null>(null);
  const [selectedNetwork, setSelectedNetwork] = useState<Network | null>(null);
  const { available } = useBluetooth();

  useEffect(() => {
    if (!available) {
      setState(ConfigureState.Unavailable);
    }
  }, [available]);

  useEffect(() => {
    if (connectedDevice) {
      setState(ConfigureState.Menu);
    }
  }, [connectedDevice]);

  useEffect(() => {
    if (selectedNetwork) {
      setState(ConfigureState.EnterWifiPassword);
    }
  }, [selectedNetwork]);

  return (
    <>
      {state === ConfigureState.Menu && connectedDevice !== null ? (
        <BluetoothMenuScreen
          device={connectedDevice}
          onWifiConfigure={() => {
            setState(ConfigureState.ShowWifiList);
          }}
        />
      ) : null}
      {state === ConfigureState.Unavailable ? (
        <BluetoothUnavailableScreen />
      ) : null}
      {state === ConfigureState.Idle ? <div>Idle</div> : null}
      {state === ConfigureState.ShowDevices ? (
        <BluetoothDevicesScreen onConnect={setConnectedDevice} />
      ) : null}
      {state === ConfigureState.ShowWifiList && connectedDevice !== null ? (
        <BluetoothWifiListScreen
          device={connectedDevice}
          onBack={() => {
            setState(ConfigureState.Menu);
          }}
          onNetworkSelected={setSelectedNetwork}
        />
      ) : null}
      {state === ConfigureState.EnterWifiPassword &&
      selectedNetwork !== null &&
      connectedDevice !== null ? (
        <BluetoothWifiPasswordScreen
          device={connectedDevice}
          network={selectedNetwork}
          onConnected={() => {
            setState(ConfigureState.Menu);
          }}
          onBack={() => {
            setSelectedNetwork(null);
            setState(ConfigureState.ShowWifiList);
          }}
        />
      ) : null}
    </>
  );
};

export const ConfigureBoardModal: React.FC<ConfigureBoardModalProps> = ({
  open,
  onClose,
}) => {
  const isMobile = useIsMobile();

  return (
    <Modal
      keepMounted={false}
      size="xl"
      classNames={{
        body: classes.modalBody,
      }}
      fullScreen={isMobile}
      opened={open}
      onClose={onClose}
      pos="relative"
    >
      <ConfigStateComponent />
    </Modal>
  );
};
