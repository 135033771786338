import type { AuthUtilities } from "@urql/exchange-auth";
import { authExchange } from "@urql/exchange-auth";
import type { AuthToken } from "@pilplay/graphql";
import { jwtDecode } from "jwt-decode";
import { subSeconds } from "date-fns";
import type { Operation } from "urql";
import { Kind } from "graphql";
import {
  TOKEN_KEY,
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../../utils/storage";

// Seconds before the token expires to refresh it
const VERIFY_EXPIRE_TIME = 60;

export interface RefreshAuthExchangeParams {
  logout: () => void;
  verifyExpireTime?: number;
}

async function refreshAuth(
  refreshToken: string,
  mutate: AuthUtilities["mutate"]
) {
  return mutate(
    `
        mutation refreshToken($refreshToken: String!) {
          refreshToken(refreshToken: $refreshToken) {
            accessToken  
            refreshToken
            __typename
          }
        }
      
      `,
    { refreshToken }
  );
}

const SKIPPABLE_MUTATIONS = ["signIn", "signUp"];

/**
 * Some mutations should be skipped, like signIn and signUp since we don't have a token yet
 */
const isSkipMutation = (operation: Operation) => {
  return (
    operation.kind === "mutation" &&
    // Here we find any mutation definition with the "login" field
    operation.query.definitions.some((definition) => {
      return (
        definition.kind === Kind.OPERATION_DEFINITION &&
        definition.selectionSet.selections.some((node) => {
          // The field name is just an example, since signup may also be an exception
          return (
            node.kind === Kind.FIELD &&
            SKIPPABLE_MUTATIONS.includes(node.name.value)
          );
        })
      );
    })
  );
};

export const refreshAuthExchange = ({
  logout,
  verifyExpireTime = VERIFY_EXPIRE_TIME,
}: RefreshAuthExchangeParams) => {
  // eslint-disable-next-line @typescript-eslint/require-await -- We need to return a promise
  return authExchange(async (utils) => {
    return {
      willAuthError(operation) {
        const auth = getLocalStorage<AuthToken | null>(TOKEN_KEY, null);
        if (auth) {
          const decodedJwt = jwtDecode(auth.accessToken);
          const decodedRefreshJwt = jwtDecode(auth.refreshToken);
          const expireDate = new Date((decodedJwt.exp || 0) * 1000);
          const expiresSoon =
            expireDate < subSeconds(new Date(), verifyExpireTime);
          const expireRefreshDate = new Date(
            (decodedRefreshJwt.exp || 0) * 1000
          );
          const refreshExpired = expireRefreshDate < new Date();

          if (isSkipMutation(operation)) {
            return false;
          }

          if (refreshExpired) {
            removeLocalStorage(TOKEN_KEY);
            logout();
            return true;
          }
          if (expiresSoon) {
            return true;
          }
          return false;
        }
        return false;
      },
      didAuthError(error, _operation) {
        console.error("didAuthError", error);
        const errored = error.graphQLErrors.some(
          (e) => e.extensions.code === "UNAUTHORIZED"
        );
        return errored;
      },
      addAuthToOperation(operation) {
        const auth = getLocalStorage<AuthToken | null>(TOKEN_KEY, null); // Make sure we have the latest token
        if (!auth) return operation;
        return utils.appendHeaders(operation, {
          Authorization: `Bearer ${auth.accessToken}`,
        });
      },
      async refreshAuth() {
        let auth = getLocalStorage<AuthToken | null>(TOKEN_KEY, null);
        if (!auth) return;
        // eslint-disable-next-line @typescript-eslint/unbound-method -- fine to bound like this here
        const result = await refreshAuth(auth.refreshToken, utils.mutate);
        if (result.data?.refreshToken) {
          // Update our local variables and write to our storage
          auth = result.data.refreshToken;
          setLocalStorage(TOKEN_KEY, auth);
        } else {
          removeLocalStorage(TOKEN_KEY);
          logout();
        }
      },
    };
  });
};
