import type { ChipProps } from "@mantine/core";
import { Chip } from "@mantine/core";
import React from "react";

interface QuickAddChipProps extends Omit<ChipProps, "children"> {
  value: string;
  onClick: () => void;
  checked: boolean;
}

const QuickAddChip: React.FC<QuickAddChipProps> = ({
  value,
  onClick,
  checked,
  ...otherProps
}) => {
  return (
    <Chip checked={checked} onClick={onClick} {...otherProps}>
      {value}
    </Chip>
  );
};

export default QuickAddChip;
