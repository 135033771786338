import { Box, Overlay, Text } from "@mantine/core";
import cx from "clsx";
import React from "react";
import { PlayerAvatar } from "../../../PlayerAvatar";
import classes from "./FullScreenSwipe.module.css";

export interface FullScreenSwipeProps {
  direction?: "normal" | "reverse";
  title: string;
  description?: string;
  image?: string;
}

export const FullScreenSwipe: React.FC<FullScreenSwipeProps> = ({
  direction = "normal",
  title,
  description,
  image,
}) => {
  return (
    <Overlay center className={classes.base} data-direction={direction}>
      <Box className={classes.overlay} />
      <Box className={classes.overlayRight} />
      <Box className={cx(classes.children, classes.fadeInOut)}>
        <div>
          <Text fw="bold" fz={48} pb={120}>
            {title}
          </Text>
        </div>
        <PlayerAvatar size={320} url={image} />
        <div>
          <Text fw="bold" fz={48} mt={120}>
            {description}
          </Text>
        </div>
      </Box>
    </Overlay>
  );
};
