import React, { useEffect, useState } from "react";
import { Affix, Flex } from "@mantine/core";
import { Headline, Logo } from "@pilplay/ui";
import { format } from "date-fns";
import classes from "./IdleScreen.module.css";

export interface IdleScreenProps {
  show: boolean;
}

export const IdleScreen: React.FC<IdleScreenProps> = ({ show }) => {
  const [dateState, setDateState] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setDateState(new Date());
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!show) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Affix
        position={{
          bottom: "64px",
          right: "64px",
        }}
      >
        <Headline c="dimmed" size="xl" fw="bold" ta="center">
          {format(dateState, "HH:mm")}
        </Headline>
      </Affix>
      <Flex direction="column" align="flex-start">
        <Logo
          className={classes.floating}
          width={200}
          text="right"
          fontSize="64px"
        />

        <Headline c="dimmed" size="sm" fw="semiBold">
          Press anywhere to start a lobby
        </Headline>
      </Flex>
    </div>
  );
};
