import type { HitElement } from "@pilplay/ui";
import { DartboardDefaultHit } from "@pilplay/ui";
import React from "react";

interface MiddlingHitElementProps extends Omit<HitElement, "Cell"> {
  player: {
    id: string;
    avatarUrl?: string | undefined;
  };
  originalX: number;
  originalY: number;
  iconSize?: number;
  hitRadius?: number;
  arrow?: {
    width: number;
    height: number;
    yOffset: number;
  };
}

const DARTBOARD_DIAMETER = 451;
const DARTBOARD_RADIUS = DARTBOARD_DIAMETER / 2;

const MiddlingHitElement: React.FC<MiddlingHitElementProps> = ({
  x,
  y,
  originalX,
  originalY,
  fill,
  iconSize = 48,
  player,
  index,
  hitRadius = 12,
  arrow = {
    width: 30,
    height: 30,
    yOffset: 10,
  },
}) => {
  const imageY = y - arrow.height - arrow.yOffset / 2 - iconSize;
  const imageX = x - iconSize / 2;

  return (
    <g>
      <defs>
        <clipPath id="circleView">
          <circle cx={imageX * 2} cy={imageY * 2} r={iconSize / 2} />
        </clipPath>
      </defs>
      <circle cx={x} cy={imageY + iconSize / 2} fill="white" r={iconSize / 2} />

      <polygon
        fill="white"
        points={`${x + arrow.width / 2},${y - arrow.height - 8} ${x},${
          y - arrow.yOffset
        } ${x - arrow.width / 2},${y - arrow.height - 8}`}
      />

      <image
        height={iconSize}
        style={{
          outline: "4px solid white",
          borderRadius: "50%",
          clipPath: `circle(${iconSize / 2 + 4}px at 50% 50%)`,
        }}
        width={iconSize}
        x={imageX}
        y={imageY}
        xlinkHref={player.avatarUrl}
        // clip-path="url(#circleView)"
      />
      <DartboardDefaultHit fill={fill} index={index} x={x} y={y} />
    </g>
  );
};

export default MiddlingHitElement;
