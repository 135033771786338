import { LoadingOverlay } from "@mantine/core";
import type { AuthToken } from "@pilplay/graphql";
import { devtoolsExchange } from "@urql/devtools";
import { cacheExchange } from "@urql/exchange-graphcache";
import { relayPagination } from "@urql/exchange-graphcache/extras";
import type { SubscribePayload } from "graphql-ws";
import React, { useMemo } from "react";
import { Client, Provider, fetchExchange, subscriptionExchange } from "urql";
import introspection from "../../../server/graphql/introspection.json";
import { TOKEN_KEY, getLocalStorage } from "../../utils/storage";
import { useAuthContext } from "../AuthContext";
import { WsClientProvider } from "./WsClientProvider";
import { createClientWithOnReconnected } from "./WsClientProvider/WsClientProvider";
import { refreshAuthExchange } from "./refreshAuthExchange";
import {
  addLobbyPlayer,
  boardEvents,
  cancelLobby,
  removeLobbyPlayer,
} from "./updates";

interface GraphQLProviderProps {
  children: React.ReactNode;
}

const wsUrl = (path: string) => {
  const protocol = window.location.protocol === "https:" ? "wss" : "ws";
  return `${protocol}://${window.location.host}${path}`;
};

const wsClient = createClientWithOnReconnected({
  url: wsUrl("/graphql"),
});

const createClient = (opts: { logout: () => void }) => {
  let exchanges = [
    cacheExchange({
      schema: introspection,
      keys: {
        GameSnapshot: () => null,
        X01GameSnapshot: () => null,
        CricketGameSnapshot: () => null,
        X01GameConfig: () => null,
        CricketGameConfig: () => null,
        X01Round: () => null,
        DartPosition: () => null,
        MiddlingThrow: () => null,
      },
      updates: {
        Mutation: {
          removeLobbyPlayer,
          addLobbyPlayer,
          cancelLobby,
        },
        Subscription: {
          boardEvents,
        },
      },
      resolvers: {
        Query: {
          games: relayPagination(),
        },
      },
    }),
    refreshAuthExchange({
      logout: opts.logout,
    }),
    fetchExchange,
    subscriptionExchange({
      forwardSubscription(operation) {
        return {
          subscribe: (sink) => {
            const dispose = wsClient.subscribe(
              operation as SubscribePayload,
              sink
            );
            return {
              unsubscribe: dispose,
            };
          },
        };
      },
    }),
  ];
  if (process.env.NODE_ENV !== "production") {
    exchanges = [devtoolsExchange, ...exchanges];
  }
  const client = new Client({
    url: "/graphql",
    exchanges,
    fetchOptions: () => {
      const auth = getLocalStorage<AuthToken | null>(TOKEN_KEY, null);
      return {
        headers: { authorization: auth ? `Bearer ${auth.accessToken}` : "" },
      };
    },
  });
  return client;
};

const GraphQLProvider: React.FC<GraphQLProviderProps> = ({ children }) => {
  const { isSignedIn, isLoading, logout } = useAuthContext();
  const client = useMemo(() => {
    return createClient({ logout });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to create the client when the user signs in or out
  }, [isSignedIn]);

  if (isLoading) {
    return (
      <LoadingOverlay
        style={{
          backgroundColor: "#252525",
        }}
        visible
      />
    );
  }
  return (
    <WsClientProvider client={wsClient}>
      <Provider value={client}>{children}</Provider>{" "}
    </WsClientProvider>
  );
};

export default GraphQLProvider;
