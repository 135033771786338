import type { SegmentedControlProps } from "@mantine/core";
import { Box, Group, Popover, SegmentedControl, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconInfoCircle } from "@tabler/icons-react";
import React from "react";
import classes from "./SegmentConfig.module.css";

interface SegmentConfigProps extends SegmentedControlProps {
  title: string;
  description: string;
  value: string;
  onChange: (value: string) => void;
  tooltip?: string | React.ReactNode;
}

const SegmentConfig: React.FC<SegmentConfigProps> = ({
  title,
  description,
  tooltip,
  ...segmentControlProps
}) => {
  const [opened, { close, open }] = useDisclosure(false);
  return (
    <Box className={classes.item}>
      <Box className={classes.container}>
        <Group gap={4} wrap="nowrap">
          <Text className={classes.container_text}>{title}</Text>
          {tooltip ? (
            <Popover opened={opened} shadow="md" width={220} withArrow>
              <Popover.Target>
                <IconInfoCircle
                  onMouseEnter={open}
                  onMouseLeave={close}
                  size={18}
                />
              </Popover.Target>
              <Popover.Dropdown>{tooltip}</Popover.Dropdown>
            </Popover>
          ) : null}
        </Group>
        <Text c="dimmed" size="xs">
          {description}
        </Text>
      </Box>
      <SegmentedControl
        className={classes.segment}
        classNames={{
          indicator: classes.indicator,
        }}
        {...segmentControlProps}
      />
    </Box>
  );
};

export default SegmentConfig;
