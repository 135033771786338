import { Box, Center, Collapse, Divider, Flex, Text } from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import type { DartPoint } from "@pilplay/games";
import React, { useMemo } from "react";
import AnimatedNumber from "../../../../../../../../../components/AnimatedNumber";
import PlacementIcon from "../../../../../PlacementIcon";
import X01Checkout from "../../../X01Checkout";
import { hasCheckout } from "../../../X01Checkout/X01Checkout";
import { ActiveDart } from "../../../X01DartRound/X01DartRound";
import classes from "./mobile-x01-player.module.css";

interface MobileX01PlayerProps {
  player: {
    id: string;
    name: string;
    score: number;
    legs: number;
  };
  active: boolean;
  middlingPoint?: DartPoint;
  onMiddlingClick?: () => void;
  middlingPhase?: boolean;
  placementIndex?: number;
  finished?: boolean;
  legFinished?: boolean;
}

const MobileX01Player: React.FC<MobileX01PlayerProps> = ({
  player: { score, legs, name },
  active,
  middlingPoint,
  onMiddlingClick,
  middlingPhase,
  placementIndex,
  finished,
  legFinished,
}) => {
  const isCheckout = useMemo(() => hasCheckout(score), [score]);
  const { ref, width } = useElementSize();

  return (
    <>
      <Box ref={ref} p={"sm"} h={"100%"}>
        <Center h={"100%"} className={classes.fadeIn}>
          <Box style={{ flex: 3 }}>
            {middlingPhase ? (
              <Center>
                <ActiveDart
                  label="Middling"
                  onClick={onMiddlingClick}
                  point={middlingPoint}
                />
              </Center>
            ) : (
              <>
                <Text fw="bold" fz={24} ta="center">
                  <AnimatedNumber value={score} />
                </Text>
                <Text c="dimmed" size="sm" ta="center">
                  Legs: <AnimatedNumber value={legs} />
                </Text>
              </>
            )}
            <Flex justify="center" mt="xs" ta="center">
              {(finished || legFinished) && placementIndex !== undefined ? (
                <PlacementIcon
                  legWinnerOnly={!finished}
                  placementIndex={placementIndex}
                  size={32}
                  style={{ marginRight: 8, marginTop: -2 }}
                />
              ) : null}
              <Text size="lg" ta="center" fw={"bolder"}>
                {name}
              </Text>
            </Flex>
          </Box>
        </Center>

        <Divider
          color={active ? "orange" : "gray"}
          size="xl"
          style={{
            borderRadius: "5px",
          }}
        />
      </Box>
      <Collapse
        mx={"sm"}
        in={active ? isCheckout : false}
        style={{ position: "absolute", width }}
      >
        <X01Checkout score={score || 999} />
      </Collapse>
    </>
  );
};

export default MobileX01Player;
