import React from "react";
import { DartPoint } from "@pilplay/games";

export const dardboardNumbers = [
  20, 1, 18, 4, 13, 6, 10, 15, 2, 17, 3, 19, 7, 16, 8, 11, 14, 9, 12, 5,
];

export const SEGMENT_ANGLE = 360 / dardboardNumbers.length;
export const BOARD_SIZE = 1000;
export const BOARD_TEXT_SIZE = 20;
export const BOARD_NORMALIZE = 170;

const BoardConsts = {
  BULL_INNER: 7 / BOARD_NORMALIZE,
  BULL_OUTER: 17 / BOARD_NORMALIZE,
  TREBLE_INNER: 97 / BOARD_NORMALIZE,
  TREBLE_OUTER: 107 / BOARD_NORMALIZE,
  DOUBLE_INNER: 160 / BOARD_NORMALIZE,
  DOUBLE_OUTER: 170 / BOARD_NORMALIZE,
};

export interface Segment {
  name: string;
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
  color: string;
  point: DartPoint;
}

export interface DartboardColors {
  hit: string;
  lastHit: string;
  background: string;
  bull: string;
  outerBull: string;
  circleColor1: string;
  circleColor2: string;
  segmentColor1: string;
  segmentColor2: string;
  text: string;
}

export const defaultColors: DartboardColors = {
  hit: "#101010",
  lastHit: "#e8590c",
  background: "#141414",
  bull: "#ED3737",
  outerBull: "#309F6A",
  circleColor1: "#ED3737",
  circleColor2: "#4F9962",
  segmentColor1: "#141414",
  segmentColor2: "#F9DFBC",
  text: "#f7f7f7",
};

export const calculateTextTransform = (
  segment: Segment,
  boardNormalize: number,
  boardRadius: number
) => {
  const angle = (segment.startAngle + segment.endAngle) / 2 - 0.5 * Math.PI;
  let rotation = 90 + (angle * 180) / Math.PI;
  let radius = (197 / boardNormalize) * boardRadius;
  const a = (angle * 180) / Math.PI;
  if (a > 0 && a < 180) {
    radius +=
      (Math.round((BOARD_TEXT_SIZE / boardNormalize) * boardRadius) * 15) / 21;
    rotation += 180;
  }
  const { x, y } = pointOnCircle(radius, angle);
  const transform = `translate(${x}, ${y}) rotate(${rotation})`;
  return transform;
};

function pointOnCircle(radius: number, angle: number) {
  return {
    x: radius * Math.cos(angle),
    y: radius * Math.sin(angle),
  };
}

export const createSegments = (opts: {
  radius: number;
  normalize: number;
  boardOuterRadius: number;
  colors: DartboardColors;
}) => {
  const segments: { [key: string]: Segment } = {
    Bull: {
      name: "Bull",
      innerRadius: 0,
      outerRadius: BoardConsts.BULL_INNER * opts.radius,
      startAngle: 0,
      endAngle: Math.PI * 2,
      color: opts.colors.bull,
      point: DartPoint.DOUBLE_BULL,
    },
    "25": {
      name: "25",
      innerRadius: BoardConsts.BULL_INNER * opts.radius,
      outerRadius: BoardConsts.BULL_OUTER * opts.radius,
      startAngle: 0,
      endAngle: Math.PI * 2,
      color: opts.colors.outerBull,
      point: DartPoint.BULL,
    },
    Miss: {
      name: "Miss",
      innerRadius: BoardConsts.DOUBLE_OUTER * opts.radius,
      outerRadius: opts.boardOuterRadius,
      startAngle: 0,
      endAngle: Math.PI * 2,
      color: opts.colors.background,
      point: DartPoint.MISS,
    },
  };
  let angle = -9;
  for (let i = 0; i < dardboardNumbers.length; i++) {
    const num = dardboardNumbers[i];
    const color =
      i % 2 === 0 ? opts.colors.segmentColor1 : opts.colors.segmentColor2;
    const circleColor =
      i % 2 === 0 ? opts.colors.circleColor1 : opts.colors.circleColor2;

    const startAngle = angle * (Math.PI / 180);
    const endAngle = (angle + 18) * (Math.PI / 180);
    const name = num.toString();
    segments["SO" + name] = {
      name: "SO" + name,
      innerRadius: segments["Bull"].outerRadius,
      outerRadius: BoardConsts.TREBLE_INNER * opts.radius,
      startAngle,
      endAngle,
      color,
      point: name as DartPoint,
    };
    segments["T" + name] = {
      name: "T" + name,
      innerRadius: BoardConsts.TREBLE_INNER * opts.radius,
      outerRadius: BoardConsts.TREBLE_OUTER * opts.radius,
      startAngle,
      endAngle,
      color: circleColor,
      point: ("T" + name) as DartPoint,
    };
    segments["SI" + name] = {
      name: "SI" + name,
      innerRadius: BoardConsts.TREBLE_OUTER * opts.radius,
      outerRadius: BoardConsts.DOUBLE_INNER * opts.radius,
      startAngle,
      endAngle,
      color: color,
      point: name as DartPoint,
    };
    segments["D" + name] = {
      name: "D" + name,
      innerRadius: BoardConsts.DOUBLE_INNER * opts.radius,
      outerRadius: BoardConsts.DOUBLE_OUTER * opts.radius,
      startAngle,
      endAngle,
      color: circleColor,
      point: ("D" + name) as DartPoint,
    };
    segments["MISS" + name] = {
      name: "MISS" + name,
      innerRadius: BoardConsts.DOUBLE_OUTER * opts.radius,
      outerRadius: opts.boardOuterRadius,
      startAngle,
      endAngle,
      color: opts.colors.background,
      point: DartPoint.MISS,
    };
    angle += SEGMENT_ANGLE;
  }

  return segments;
};

export function boardDrawOrder(a: Segment, b: Segment) {
  if (a.name === "Bull") {
    return 1;
  }
  if (b.name === "Bull") {
    return -1;
  }
  if (a.name === "25") {
    return 1;
  }
  if (b.name === "25") {
    return -1;
  }

  return 0;
}

function normalizeDartboard({
  x,
  y,
  boardSize,
  boardRadius,
}: {
  x: number;
  y: number;
  boardSize: number;
  boardRadius: number;
}) {
  return {
    x: (x * boardSize - boardSize / 2) / boardRadius,
    y: -(y * boardSize - boardSize / 2) / boardRadius,
  };
}

export function getSegCoords(
  e: React.MouseEvent | React.TouchEvent,
  segments: { [key: string]: Segment },
  clientP: {
    x: number;
    y: number;
  },
  boardSize: number,
  boardRadius: number
) {
  const target = e.currentTarget.getBoundingClientRect();
  const { x: clientX, y: clientY } = clientP;
  const x = (clientX - target.left) / target.width;
  const y = (clientY - target.top) / target.height;
  const p = normalizeDartboard({
    x,
    y,
    boardSize,
    boardRadius,
  });
  return {
    coords: p,
    segment: segmentFromCoordinates(segments, p),
  };
}

export function getSegCoordsFromMouse(
  e: React.MouseEvent,
  segments: { [key: string]: Segment },
  boardSize: number,
  boardRadius: number
) {
  return getSegCoords(
    e,
    segments,
    {
      x: e.clientX,
      y: e.clientY,
    },
    boardSize,
    boardRadius
  );
}

export function getSegCoordsFromTouch(
  e: React.TouchEvent,
  segments: { [key: string]: Segment },
  boardSize: number,
  boardRadius: number
) {
  const clientX = e.touches[0].clientX || e.changedTouches[0].clientX;
  const clientY = e.touches[0].clientY || e.changedTouches[0].clientY;

  return getSegCoords(
    e,
    segments,
    {
      x: clientX,
      y: clientY,
    },
    boardSize,
    boardRadius
  );
}

export function segmentFromCoordinates(
  segments: { [key: string]: Segment },
  p: { x: number; y: number }
) {
  const { x, y } = p;
  const r = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
  let a = -(Math.atan2(y, x) * 180) / Math.PI;
  a = (a + 360) % 360;
  if (r <= BoardConsts.BULL_INNER) {
    return segments["Bull"];
  }
  if (r <= BoardConsts.BULL_OUTER) {
    return segments["25"];
  }
  let t = -SEGMENT_ANGLE / 2;
  for (const s of [
    6, 10, 15, 2, 17, 3, 19, 7, 16, 8, 11, 14, 9, 12, 5, 20, 1, 18, 4, 13, 6,
  ]) {
    if (t <= a && a < t + SEGMENT_ANGLE) {
      if (BoardConsts.TREBLE_INNER < r && r <= BoardConsts.TREBLE_OUTER) {
        return segments["T" + s];
      }
      if (BoardConsts.DOUBLE_INNER < r && r <= BoardConsts.DOUBLE_OUTER) {
        return segments["D" + s];
      }
      if (BoardConsts.DOUBLE_OUTER < r) {
        return segments["M" + s];
      }
      if (BoardConsts.BULL_OUTER < r && r <= BoardConsts.TREBLE_INNER) {
        return segments["SI" + s];
      }
      if (BoardConsts.TREBLE_OUTER < r && r <= BoardConsts.DOUBLE_INNER) {
        return segments["SO" + s];
      }
    }
    t += SEGMENT_ANGLE;
  }
  return segments["Miss"];
}
