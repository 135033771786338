import React from "react";
import { Modal, Text } from "@mantine/core";
import { Button, Headline } from "@pilplay/ui";
import useBoardMutations from "../../../../../hooks/useBoardMutations/useBoardMutations";
import classes from "./TakeoutInProgress.module.css";

export interface TakeoutInProgressProps {
  show: boolean;
  boardId?: string;
  onClose: () => void;
}

export const TakeoutInProgress: React.FC<TakeoutInProgressProps> = ({
  boardId,
  show,
  onClose,
}) => {
  const { restartDetection } = useBoardMutations();

  const onForceClose = () => {
    if (boardId) {
      void restartDetection({
        id: boardId,
      });
    }
    onClose();
  };

  return (
    <Modal
      centered
      withCloseButton={false}
      opened={show}
      onClose={onClose}
      radius="xl"
      classNames={{
        body: classes.body,
      }}
    >
      <Headline size="sm" fw="semiBold" ta="center">
        Takeout in progress
      </Headline>
      <Text mt="xl" ta="center" fz="sm" c="dimmed">
        We are waiting for all darts to be removed from the board before the
        next player can throw.
      </Text>
      <Button onClick={onForceClose} mt="xl" fullWidth variant="subtle">
        All darts are removed, continue
      </Button>
    </Modal>
  );
};
