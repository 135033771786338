// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file board/v1/board.proto (package board.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message board.v1.WebsocketRequest
 */
export class WebsocketRequest extends Message<WebsocketRequest> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from oneof board.v1.WebsocketRequest.request
   */
  request: {
    /**
     * @generated from field: board.v1.ProxyRequest proxy_request = 2;
     */
    value: ProxyRequest;
    case: "proxyRequest";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<WebsocketRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "board.v1.WebsocketRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "proxy_request", kind: "message", T: ProxyRequest, oneof: "request" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WebsocketRequest {
    return new WebsocketRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WebsocketRequest {
    return new WebsocketRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WebsocketRequest {
    return new WebsocketRequest().fromJsonString(jsonString, options);
  }

  static equals(a: WebsocketRequest | PlainMessage<WebsocketRequest> | undefined, b: WebsocketRequest | PlainMessage<WebsocketRequest> | undefined): boolean {
    return proto3.util.equals(WebsocketRequest, a, b);
  }
}

/**
 * @generated from message board.v1.ProxyRequest
 */
export class ProxyRequest extends Message<ProxyRequest> {
  /**
   * @generated from field: bytes data = 1;
   */
  data = new Uint8Array(0);

  constructor(data?: PartialMessage<ProxyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "board.v1.ProxyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProxyRequest {
    return new ProxyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProxyRequest {
    return new ProxyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProxyRequest {
    return new ProxyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ProxyRequest | PlainMessage<ProxyRequest> | undefined, b: ProxyRequest | PlainMessage<ProxyRequest> | undefined): boolean {
    return proto3.util.equals(ProxyRequest, a, b);
  }
}

/**
 * @generated from message board.v1.ProxyResponse
 */
export class ProxyResponse extends Message<ProxyResponse> {
  /**
   * @generated from field: bytes data = 1;
   */
  data = new Uint8Array(0);

  /**
   * @generated from field: optional string error = 2;
   */
  error?: string;

  constructor(data?: PartialMessage<ProxyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "board.v1.ProxyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProxyResponse {
    return new ProxyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProxyResponse {
    return new ProxyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProxyResponse {
    return new ProxyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ProxyResponse | PlainMessage<ProxyResponse> | undefined, b: ProxyResponse | PlainMessage<ProxyResponse> | undefined): boolean {
    return proto3.util.equals(ProxyResponse, a, b);
  }
}

/**
 * @generated from message board.v1.WebsocketResponse
 */
export class WebsocketResponse extends Message<WebsocketResponse> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from oneof board.v1.WebsocketResponse.response
   */
  response: {
    /**
     * @generated from field: board.v1.ProxyResponse proxy_response = 2;
     */
    value: ProxyResponse;
    case: "proxyResponse";
  } | {
    /**
     * @generated from field: board.v1.DetectorStateUpdate detector_state_update = 3;
     */
    value: DetectorStateUpdate;
    case: "detectorStateUpdate";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<WebsocketResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "board.v1.WebsocketResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "proxy_response", kind: "message", T: ProxyResponse, oneof: "response" },
    { no: 3, name: "detector_state_update", kind: "message", T: DetectorStateUpdate, oneof: "response" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WebsocketResponse {
    return new WebsocketResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WebsocketResponse {
    return new WebsocketResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WebsocketResponse {
    return new WebsocketResponse().fromJsonString(jsonString, options);
  }

  static equals(a: WebsocketResponse | PlainMessage<WebsocketResponse> | undefined, b: WebsocketResponse | PlainMessage<WebsocketResponse> | undefined): boolean {
    return proto3.util.equals(WebsocketResponse, a, b);
  }
}

/**
 * @generated from message board.v1.DetectorStateUpdate
 */
export class DetectorStateUpdate extends Message<DetectorStateUpdate> {
  /**
   * @generated from field: board.v1.DetectorStateUpdate.State state = 1;
   */
  state = DetectorStateUpdate_State.UNSPECIFIED;

  constructor(data?: PartialMessage<DetectorStateUpdate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "board.v1.DetectorStateUpdate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(DetectorStateUpdate_State) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DetectorStateUpdate {
    return new DetectorStateUpdate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DetectorStateUpdate {
    return new DetectorStateUpdate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DetectorStateUpdate {
    return new DetectorStateUpdate().fromJsonString(jsonString, options);
  }

  static equals(a: DetectorStateUpdate | PlainMessage<DetectorStateUpdate> | undefined, b: DetectorStateUpdate | PlainMessage<DetectorStateUpdate> | undefined): boolean {
    return proto3.util.equals(DetectorStateUpdate, a, b);
  }
}

/**
 * @generated from enum board.v1.DetectorStateUpdate.State
 */
export enum DetectorStateUpdate_State {
  /**
   * @generated from enum value: STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: STATE_STABLE = 1;
   */
  STABLE = 1,

  /**
   * @generated from enum value: STATE_PARTIAL_TAKEOUT = 2;
   */
  PARTIAL_TAKEOUT = 2,

  /**
   * @generated from enum value: STATE_EMPTY = 3;
   */
  EMPTY = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(DetectorStateUpdate_State)
proto3.util.setEnumType(DetectorStateUpdate_State, "board.v1.DetectorStateUpdate.State", [
  { no: 0, name: "STATE_UNSPECIFIED" },
  { no: 1, name: "STATE_STABLE" },
  { no: 2, name: "STATE_PARTIAL_TAKEOUT" },
  { no: 3, name: "STATE_EMPTY" },
]);

