import {
  Affix,
  Box,
  Center,
  Flex,
  Grid,
  SimpleGrid,
  Stack,
} from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import type { DartHit } from "@pilplay/games";
import React from "react";
import { ConfettiOverlay } from "@pilplay/ui";
import InteractiveDartboard from "../../../../../../../components/InteractiveDartboard/InteractiveDartboard";
import { useIsTabletStanding } from "../../../../../../../hooks/layout/useDevice";
import { useGameContext } from "../../../../context/useGameContext";
import { useX01Game } from "../../context/useX01Game";
import X01DartRound from "../X01DartRound";
import X01FloatingActions from "../X01FloatingActions/X01FloatingActions";
import { X01Menu } from "../X01Header/X01Header";
import MobilePlayerList from "./components/MobilePlayerList/MobilePlayerList";
import MobileX01Player from "./components/MobileX01Player/MobileX01Player";

interface MobileX01GameProps {
  inputDisabled?: boolean;
  actions: {
    onHistoryClick: () => void;
    onDartHitClick: (hit?: DartHit | null) => void;
  };
}

const MobileX01Game: React.FC<MobileX01GameProps> = ({
  actions,
  inputDisabled,
}) => {
  const isTabletStanding = useIsTabletStanding();

  const {
    players,
    activePlayer,
    activeRoundHits,
    finished,
    middlingThrows,
    middlingPhase,
    legFinished,
    scoreOrder,
  } = useX01Game();
  const { throwDart } = useGameContext();

  const { ref: boardColRef, width: boardColWidth } = useElementSize();
  const { ref, height } = useElementSize();
  const { ref: headerRef, height: headerHight } = useElementSize();

  const roundHitPositions = [
    activeRoundHits?.hit1?.position,
    activeRoundHits?.hit2?.position,
    activeRoundHits?.hit3?.position,
  ].filter((p) => Boolean(p)) as { x: number; y: number }[];

  const middlingPositions = middlingThrows
    .map((t) => t.throw.position)
    .filter((p) => Boolean(p)) as { x: number; y: number }[];

  const activeMiddleThrow = middlingThrows.find(
    (t) => t.playerId === activePlayer?.id
  )?.throw;

  return (
    <Stack
      gap={0}
      justify="space-between"
      ref={ref}
      style={{
        height: "100dvh",
        overflow: "hidden",
      }}
    >
      {finished || legFinished ? <ConfettiOverlay /> : null}

      <Box ref={headerRef}>
        <Affix
          top={5}
          left={5}
          style={{
            width: "fit-content",
            height: "fit-content",
          }}
        >
          <X01Menu />
        </Affix>
        <Flex direction="column">
          {isTabletStanding && players.length > 2 ? (
            <Grid justify="flex-start" align="stretch">
              {activePlayer ? (
                <Grid.Col span={6}>
                  <MobileX01Player
                    active
                    finished={finished}
                    key={activePlayer.id}
                    legFinished={legFinished}
                    middlingPhase={middlingPhase}
                    middlingPoint={activeMiddleThrow?.point}
                    onMiddlingClick={() => {
                      activeMiddleThrow?.id &&
                        actions.onDartHitClick(activeMiddleThrow);
                    }}
                    placementIndex={scoreOrder.findIndex(
                      (p) => p.id === activePlayer.id
                    )}
                    player={activePlayer}
                  />
                </Grid.Col>
              ) : null}

              <Grid.Col span={6}>
                <MobilePlayerList playerLimit={6} />
              </Grid.Col>
            </Grid>
          ) : null}
          {isTabletStanding && players.length <= 2 ? (
            <SimpleGrid cols={players.length} mt={4} spacing={4}>
              {players.map((player) => {
                const middlingThrow = middlingPhase
                  ? middlingThrows.find((t) => t.playerId === player.id)?.throw
                  : undefined;
                return (
                  <Box key={player.id}>
                    <MobileX01Player
                      active={activePlayer?.id === player.id}
                      finished={finished}
                      legFinished={legFinished}
                      middlingPhase={middlingPhase}
                      middlingPoint={middlingThrow?.point}
                      onMiddlingClick={() => {
                        middlingThrow?.id &&
                          actions.onDartHitClick(middlingThrow);
                      }}
                      placementIndex={scoreOrder.findIndex(
                        (p) => p.id === player.id
                      )}
                      player={player}
                    />
                  </Box>
                );
              })}
            </SimpleGrid>
          ) : null}
          <Flex
            align="center"
            direction="column"
            justify="center"
            mt="sm"
            style={{ flex: 1 }}
          >
            {!middlingPhase && (
              <X01DartRound
                onHitClick={actions.onDartHitClick}
                round={activeRoundHits}
              />
            )}
          </Flex>
        </Flex>
      </Box>
      <Box
        ref={boardColRef}
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Box
          style={{
            height: Math.min(height - headerHight, boardColWidth),
            position: "relative",
            overflow: "visible", // To show overflow of magnifier
          }}
        >
          <X01FloatingActions onHistoryClick={actions.onHistoryClick} />
          <Center>
            <InteractiveDartboard
              disabled={inputDisabled}
              hits={middlingPhase ? middlingPositions : roundHitPositions}
              magnifierOffset={{
                y: 320, // Offset to not cover finger
                x: 0,
              }}
              magnifierSize={175}
              maxSize={Math.min(height - headerHight, boardColWidth)}
              onHit={throwDart}
            />
          </Center>
        </Box>
      </Box>
    </Stack>
  );
};

export default MobileX01Game;
