import {
  ActionIcon,
  Badge,
  Box,
  Center,
  Flex,
  Loader,
  Stack,
  Text,
} from "@mantine/core";
import {
  IconChevronLeft,
  IconChevronRight,
  IconWifi,
  IconWifi1,
  IconWifi2,
} from "@tabler/icons-react";
import type { Network } from "@pilplay/connectrpc";
import { Button } from "@pilplay/ui";
import { PILPLAY_WIFI_LIST_UUID } from "../../../../../../hooks/useBluetooth/useBluetooth";
import useBluetoothCharacteristic, {
  parseGetAvailableNetworksResponse,
} from "../../../../../../hooks/useBluetooth/useBluetoothCharacteristic";
import classes from "./style.module.css";

export interface BluetoothWifiListScreenProps {
  device: BluetoothDevice;
  onNetworkSelected: (network: Network) => void;
  onBack: () => void;
}

const WIFISignalIcon: React.FC<{ signal: number; size?: number }> = ({
  signal,
  size,
}) => {
  if (signal > 80) {
    return <IconWifi size={size} />;
  } else if (signal > 60) {
    return <IconWifi size={size} />;
  } else if (signal > 40) {
    return <IconWifi2 size={size} />;
  }
  return <IconWifi1 size={size} />;
};

const NetworkListItem: React.FC<{
  network: Network;
  onClick: (n: Network) => void;
}> = ({ network, onClick }) => {
  return (
    <Flex
      onClick={() => {
        onClick(network);
      }}
      className={classes.hoverList}
      w="100%"
      justify="space-between"
      p="xs"
      style={{
        borderBottom: "1px solid var(--mantine-color-dark-6)",
      }}
    >
      <Box
        h={48}
        w={48}
        bg="dark.6"
        style={{
          borderRadius: "var(--mantine-radius-md)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <WIFISignalIcon size={32} signal={Number(network.signal)} />
      </Box>
      <Flex direction="column" justify="center" flex="auto" px="md">
        <Text>{network.ssid}</Text>
        <Flex mt={4} align="center">
          <Badge variant="dot" size="xs">
            Security: {network.security}
          </Badge>
          <Badge ml="xs" variant="dot" size="xs">
            Channel {network.channel}
          </Badge>
        </Flex>
      </Flex>
      <Flex align="center">
        <IconChevronRight />
      </Flex>
    </Flex>
  );
};

export const BluetoothWifiListScreen: React.FC<
  BluetoothWifiListScreenProps
> = ({ device, onNetworkSelected, onBack }) => {
  const { val: wifiList, loading: wifiLoading } = useBluetoothCharacteristic(
    device,
    PILPLAY_WIFI_LIST_UUID,
    parseGetAvailableNetworksResponse
  );

  if (wifiLoading) {
    return (
      <Flex h="100%" direction="column">
        <Stack>
          <Center>
            <Loader size="xl" />
          </Center>
          <Text ta="center" mt="md">
            Connecting to your board...
          </Text>
        </Stack>
      </Flex>
    );
  }

  return (
    <Flex h="100%" direction="column">
      <ActionIcon
        onClick={onBack}
        variant="transparent"
        c="dimmed"
        style={{
          position: "absolute",
          top: 12,
          left: 12,
        }}
      >
        <IconChevronLeft />
      </ActionIcon>
      <Stack>
        <Center>
          <IconWifi size="64" />
        </Center>
        <Text ta="center" mt="md">
          Choose WI-FI List to connect to
        </Text>
        <Box mt="md" px="lg">
          {(wifiList?.networks.length || 0) <= 0 && (
            <Center>
              <Flex align="center" direction="column">
                <Loader size="xl" />
                <Text py="md">Searching for networks...</Text>
              </Flex>
            </Center>
          )}
          {wifiList?.networks.map((network) => (
            <NetworkListItem
              key={`${network.ssid}-${network.channel}`}
              network={network}
              onClick={onNetworkSelected}
            />
          ))}
        </Box>
      </Stack>
      <Flex direction="column" flex="auto" px="xl" mt="xl" justify="flex-end">
        <Button variant="secondary" fullWidth mt="md" onClick={onBack}>
          Back
        </Button>
      </Flex>
    </Flex>
  );
};
