import { CricketScoringRule, CricketWinCondition } from "@pilplay/graphql";

// Configuration for Scoring Rules
export const SCORING_RULE_CONFIG = [
  { label: "Standard", value: CricketScoringRule.Standard },
  { label: "Cut Throat", value: CricketScoringRule.Cutthroat },
];

// Configuration for Hits to Open/Close
export const HITS_TO_OPEN_CLOSE_CONFIG = [
  { label: "1 Hit", value: "1" },
  { label: "2 Hits", value: "2" },
  { label: "3 Hits", value: "3" },
  { label: "4 Hits", value: "4" },
  { label: "5 Hits", value: "5" },
  // You can add more options here if needed
];

// Configuration for Start Number
export const START_NUMBER_CONFIG = [
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  // Add other numbers as needed
];

// Configuration for Win Condition
export const WIN_CONDITION_CONFIG = [
  { label: "All Numbers Closed", value: CricketWinCondition.Allclosed },
  {
    label: "All Closed with Highest Score",
    value: CricketWinCondition.Allclosedhighestscore,
  },
];

// Exporting all configurations
export default {
  SCORING_RULE_CONFIG,
  HITS_TO_OPEN_CLOSE_CONFIG,
  START_NUMBER_CONFIG,
  WIN_CONDITION_CONFIG,
};
