import type { CSSProperties } from "react";
import React from "react";
import { Overlay, Text } from "@mantine/core";
import classes from "./CircleFade.module.css";

export interface CircleFadeProps {
  text: string;
}

/**
 *   1: (70,  $pi2*240, -30, 15, rgba($minor, .5)),
  2: (120, $pi2*240, -60, 20, rgba($minor, .3)),
  3: (180, $pi2*180, -70, 40, rgba($minor, .8)),
  4: (240, $pi2*240, -90, 20, rgba($minor, .6)),
  5: (300, $pi2*300, -20, 30, rgba($minor, .5)),
  6: (380, $pi2*380, -80, 45, rgba($minor, .2)),
  7: (450, $pi2*450, -10, 75, rgba($minor,  1)),
  8: (540, $pi2*540, -70, 250, rgba($minor, .5))
 */
const CIRCLE_LAYERS = [
  {
    "--color": "rgba(var(--mantine-color-orange-8), 0.5)",
    "--width": 15,
    "--angle": -30,
    "--c": 120,
    "--c-end": 150,
    "--speed": "2s",
  },
  {
    "--color": "rgba(var(--mantine-color-orange-8), 0.3)",
    "--width": 20,
    "--angle": -60,
    "--c": 120,
    "--c-end": 100,
    "--speed": "1500ms",
  },
  {
    "--color": "rgba(var(--mantine-color-orange-8), 0.8)",
    "--width": 40,
    "--angle": -70,
    "--c": 240,
    "--c-end": 280,
  },
  {
    "--color": "rgba(var(--mantine-color-orange-8), 0.6)",
    "--width": 20,
    "--angle": -90,
    "--c": 240,
    "--c-end": 290,
  },
  {
    "--color": "rgba(var(--mantine-color-orange-8), 0.5)",
    "--width": 30,
    "--angle": -20,
    "--c": 300,
    "--c-end": 330,
  },
  {
    "--color": "rgba(var(--mantine-color-orange-8), 0.2)",
    "--width": 45,
    "--angle": -80,
    "--c": Math.PI * 2 * 380,
  },
] as unknown as CSSProperties[];

export const CircleFade: React.FC<CircleFadeProps> = ({ text }) => {
  return (
    <Overlay center>
      <svg className={classes.base} viewBox="0 0 100 100" overflow="visible">
        <g className="core">
          <circle className="path" cx="50" cy="50" r="1" fill="none" />
        </g>
        <g className="spinner">
          <circle className="path" cx="50" cy="50" r="20" fill="none" />
        </g>
        <g>
          <circle
            style={CIRCLE_LAYERS[0]}
            className={classes.layer}
            cx="50"
            cy="50"
            r="70"
            fill="none"
          />
        </g>
        <g>
          <circle
            style={CIRCLE_LAYERS[1]}
            className={classes.layer}
            cx="50"
            cy="50"
            r="120"
            fill="none"
          />
        </g>
        <g className="layer-3">
          <circle
            style={CIRCLE_LAYERS[2]}
            className={classes.layer}
            cx="50"
            cy="50"
            r="180"
            fill="none"
          />
        </g>
        <g className="layer-4">
          <circle
            style={CIRCLE_LAYERS[3]}
            className={classes.layer}
            cx="50"
            cy="50"
            r="240"
            fill="none"
          />
        </g>
        <g className="layer-5">
          <circle
            style={CIRCLE_LAYERS[4]}
            className={classes.layer}
            cx="50"
            cy="50"
            r="300"
            fill="none"
          />
        </g>
      </svg>
      <Text className={classes.text} fw="bold" fz={32}>
        {text}
      </Text>
    </Overlay>
  );
};
