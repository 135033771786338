import type { CSSVariablesResolver } from "@mantine/core";
import { createTheme } from "@mantine/core";

export const theme = createTheme({
  primaryColor: "orange",
  fontFamily: "Inter, sans-serif",

  colors: {
    dark: [
      "#F8F9FA",
      "#A1A4AC",
      "#979AA0",
      "#454D56",
      "#3F454D",
      "#383D43",
      "#323539",
      "#2B2D2F",
      "#252525",
      "#181818",
    ],
  },
});

export const cssVariablesResolver: CSSVariablesResolver = (_) => ({
  variables: {
    "--mantine-color-body": "#252525",
  },
  dark: {},
  light: {},
});
