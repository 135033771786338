import type { DefaultMantineColor, StyleProp } from "@mantine/core";
import {
  Box,
  UnstyledButton,
  Text,
  Flex,
  Skeleton,
  Stack,
} from "@mantine/core";
import classes from "./BigButton.module.css";

export interface BigButtonProps {
  title: string;
  subtext?: string | React.ReactNode;
  description?: string | React.ReactNode;
  icon?: React.ReactNode;
  loading?: boolean;
  bg?: StyleProp<DefaultMantineColor>;
  disabled?: boolean;
  onClick?: () => void;
}

export const BigButton: React.FC<BigButtonProps> = ({
  title,
  subtext,
  icon,
  description,
  onClick,
  disabled = false,
  loading = false,
  bg = "orange.8",
}) => {
  return (
    <UnstyledButton
      onClick={onClick}
      bg={loading ? "dark.6" : bg}
      className={classes.root}
      data-disabled={disabled}
    >
      <Flex justify="space-between" w="100%" align="center">
        <Stack gap={2}>
          <Skeleton visible={loading}>
            <Text fw="bold" fz="lg">
              {title}
            </Text>
          </Skeleton>
          <Skeleton visible={loading}>
            <Text size="xs">{subtext}</Text>
          </Skeleton>
        </Stack>
        {icon && (
          <Skeleton circle w={64} h={64} visible={loading}>
            <Box bg="dark.8" className={classes.icon}>
              {icon}
            </Box>
          </Skeleton>
        )}
      </Flex>
      <Flex mt="xl" justify="space-between" w="100%" align="center">
        {loading ? (
          <Skeleton visible h={22} w={256} />
        ) : (
          <Text fz="sm">{description}</Text>
        )}
      </Flex>
    </UnstyledButton>
  );
};
