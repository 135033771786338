import type { AppliedScore, DartPoint, ScoreMatrix } from "@pilplay/games";
import { CricketGame, scoreToPointsWithMultiplier } from "@pilplay/games";
import type {
  CricketDartHit,
  CricketGameConfig,
  CricketGameSnapshot,
  CricketRound,
  HitCount,
} from "@pilplay/graphql";
import { CricketScoringRule, CricketWinCondition } from "@pilplay/graphql";
import type { PlayerRounds } from "@pilplay/ui";
import type { Immutable } from "immer";
import React, { createContext, useMemo, useRef, useState } from "react";
import GameContextProvider from "../../../context/GameContextProvider";

interface CricketGamePlayer {
  id: string;
  name: string;
  avatarUrl?: string;
  score: number;
  hits: Immutable<HitCount[]>;
}

interface PlayerMatrix {
  hits: HitCount[];
  totalScore: number;
  scores: AppliedScore[];
  position: number;
}

export interface CricketContextValue {
  gameId: string;
  finished: boolean;
  config?: CricketGameConfig;
  round: number;
  activeHits: Immutable<CricketDartHit[]>;
  gamePlayers: CricketGamePlayer[];
  getGameWinner: () => CricketGamePlayer | undefined;
  hitNumbers: { label: string; number: number }[];
  activePlayerId?: string;
  activePlayer?: CricketGamePlayer;
  scoringMatrix: ScoreMatrix;
  currentRound: number;
  pointInHitNumbers: (point: DartPoint) => boolean;
  pointIsClosed: (playerId: string, point: DartPoint) => boolean;
  getPlayerMatrix: (playerId: string) => PlayerMatrix | undefined;
  throwRoundIndex: number;
  playerRounds: PlayerRounds<CricketRound>[];
}

export const CricketContext = createContext<CricketContextValue>({
  gameId: "",
  finished: false,
  round: 0,
  activeHits: [],
  gamePlayers: [],
  getGameWinner: () => undefined,
  hitNumbers: [],
  scoringMatrix: {},
  currentRound: 1,
  pointInHitNumbers: (_: DartPoint) => false,
  pointIsClosed: (_: string, __: DartPoint) => false,
  getPlayerMatrix: (_: string) => undefined,
  throwRoundIndex: 0,
  playerRounds: [],
});

interface CricketContextProviderProps {
  gameId: string;
  lobbyId?: string;
  players: {
    id: string;
    index: number;
    playerType: string;
    name: string;
    userId?: string;
    avatarUrl?: string;
  }[];
  showControls?: boolean;
  children: React.ReactNode;
}

const CricketContextProvider: React.FC<CricketContextProviderProps> = ({
  gameId,
  lobbyId,
  players,
  children,
  showControls = true,
}) => {
  const gameRef = useRef<CricketGame>(
    new CricketGame(
      "",
      {
        hitsToOpenClose: 3,
        numRounds: 10,
        scoringRule: CricketScoringRule.Cutthroat,
        startNumber: 15,
        includeBull: true,
        winCondition: CricketWinCondition.Allclosed,
      },
      []
    )
  );
  const [gameState, setGameState] = useState<Immutable<CricketGameSnapshot>>(
    gameRef.current.getGameState()
  );

  const getHitByEventId = (eventId: string): Immutable<CricketDartHit> => {
    const hit = gameState.roundThrows.reduce<CricketDartHit | undefined>(
      (acc: CricketDartHit | undefined, round) => {
        if (acc) {
          return acc;
        }
        return round.hits.find((h) => h.id === eventId);
      },
      undefined
    );
    if (!hit) {
      throw new Error("Hit not found");
    }
    return hit;
  };

  const activeHits = gameState.roundThrows.find(
    (round) =>
      round.playerId === gameState.activePlayerId &&
      round.roundNumber === gameState.activeRound
  )?.hits;

  const gamePlayers = [...gameState.players].map((player) => {
    const playerData = players.find((p) => p.id === player.id);
    if (!playerData) {
      throw new Error("Player not found");
    }
    return {
      id: player.id,
      score: player.score,
      hits: player.hits,
      name: playerData.name,
      avatarUrl: playerData.avatarUrl,
    };
  });

  const activePlayer = gamePlayers.find(
    (p) => p.id === gameState.activePlayerId
  );

  const scoringMatrix = useMemo(() => {
    return gameRef.current.getScoringMatrix().scoreMatrix;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We need to run the update when we have new throws
  }, [gameState.roundThrows]);

  const getPlayerMatrix = (playerId: string) => {
    return scoringMatrix[playerId];
  };

  const getGameWinner = () => {
    // Find the player with 1 position in the scoring matrix
    const winnerId = Object.keys(scoringMatrix).find((playerMatrix) => {
      const matrix = scoringMatrix[playerMatrix];
      return matrix.position === 1;
    });

    if (!winnerId) {
      return undefined;
    }
    return gamePlayers.find((p) => p.id === winnerId);
  };
  const hitNumbers = useMemo(() => {
    return gameRef.current
      .getHitNumbers(
        gameRef.current.config.startNumber,
        gameRef.current.config.includeBull
      )
      .map((n) => {
        return {
          label: n.number === 25 ? "Bull" : n.number.toString(),
          number: n.number,
        };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We need to update based on the real game config
  }, [gameRef.current.config]);

  const pointInHitNumbers = (point: DartPoint) => {
    const { points } = scoreToPointsWithMultiplier(point);
    return hitNumbers.some((n) => n.number === points);
  };

  const pointIsClosed = (playerId: string, point: DartPoint) => {
    const { points } = scoreToPointsWithMultiplier(point);
    const playerMatrix = scoringMatrix[playerId];
    const hitNumber = playerMatrix.hits.find((hn) => hn.number === points);
    if (!hitNumber) {
      return false;
    }
    return hitNumber.count >= gameRef.current.config.hitsToOpenClose;
  };

  const throwRoundIndex = useMemo(() => {
    const index = gameState.roundThrows.findIndex(
      (round) =>
        round.playerId === gameState.activePlayerId &&
        round.roundNumber === gameState.activeRound
    );
    if (index === -1) {
      // No throws exists, return 0
      return 0;
    }
    return index;
  }, [gameState.roundThrows, gameState.activePlayerId, gameState.activeRound]);

  const playerRounds = useMemo(() => {
    const playerRoundsData = gamePlayers.map((player) => {
      const pr = gameState.roundThrows.filter(
        (round) => round.playerId === player.id
      );
      return {
        id: player.id,
        player: player.name,
        rounds: pr.map((round) => ({
          ...round,
          hits: [...round.hits],
        })),
      };
    });

    return playerRoundsData;
  }, [gamePlayers, gameState.roundThrows]);

  return (
    <GameContextProvider<CricketGame, CricketGameSnapshot>
      fromSnapshot={(data) => {
        if (data.game.__typename === "CricketGame" && data.game.snapshot) {
          return CricketGame.fromSnapshot(
            data.game.id,
            data.game.config,
            data.game.players,
            data.game.snapshot
          );
        }
        throw new Error("Invalid game type");
      }}
      gameId={gameId}
      lobbyId={lobbyId}
      gameRef={gameRef}
      getHitByEventId={getHitByEventId}
      players={players}
      setGameState={(state) => {
        setGameState(state);
      }}
      showControls={showControls}
    >
      <CricketContext.Provider
        value={{
          getPlayerMatrix,
          gameId,
          finished: gameState.finished,
          config: gameRef.current.config,
          round: gameState.activeRound,
          activeHits: activeHits ?? [],
          gamePlayers: gamePlayers ?? ([] as CricketGamePlayer[]),
          getGameWinner,
          hitNumbers,
          activePlayerId: gameState.activePlayerId,
          activePlayer,
          scoringMatrix,
          currentRound: gameState.activeRound,
          pointInHitNumbers,
          pointIsClosed,
          throwRoundIndex,
          playerRounds,
        }}
      >
        {children}
      </CricketContext.Provider>
    </GameContextProvider>
  );
};

export default CricketContextProvider;
