import { LegOrderType, X01ExitMode } from "@pilplay/graphql";

export const SCORE_CONFIG = [
  {
    label: "101",
    value: "101",
  },
  {
    label: "301",
    value: "301",
  },
  {
    label: "501",
    value: "501",
  },
  {
    label: "701",
    value: "701",
  },
  {
    label: "901",
    value: "901",
  },
];

export const LEG_ORDER_CONFIG = [
  { label: "Middle", value: LegOrderType.Middle },
  { label: "Random", value: LegOrderType.Random },
  { label: "Static", value: LegOrderType.Static },
];

export const EXIT_MODE_CONFIG = [
  { label: "Single", value: X01ExitMode.Single },
  { label: "Double", value: X01ExitMode.Double },
  // { label: "DömD", value: X01ExitMode.Domd },
];

export const MAX_ROUNDS_CONFIG = [
  { label: "15", value: "15" },
  { label: "20", value: "20" },
  { label: "50", value: "50" },
  { label: "80", value: "80" },
];

export const MAX_LEGS_CONFIG = [
  { label: "1", value: "1" },
  { label: "3", value: "3" },
  { label: "5", value: "5" },
  { label: "7", value: "7" },
];
