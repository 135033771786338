import { Box, Collapse, Space } from "@mantine/core";
import { ActionButton, RoundCounter } from "@pilplay/ui";
import {
  IconArrowBack,
  IconChevronsRight,
  IconDoorExit,
  IconHistory,
  IconPlus,
} from "@tabler/icons-react";
import React, { useMemo } from "react";
import {
  useIsMobile,
  useIsTabletStanding,
} from "../../../../../../../hooks/layout/useDevice";
import { clientRoutes } from "../../../../../../../routes";
import { useGameContext } from "../../../../context/useGameContext";
import { useX01Game } from "../../context/useX01Game";
import classes from "./X01FloatingActions.module.css";

interface X01FloatingActionsProps {
  onHistoryClick?: () => void;
}

const X01FloatingActions: React.FC<X01FloatingActionsProps> = ({
  onHistoryClick,
}) => {
  const {
    finished,
    leg,
    middlingThrows,
    latestRoundIndex,
    legFinished,
    middlingPhase,
    activeRoundHits,
    middlingCompleted,
    players,
  } = useX01Game();
  const { nextPlayer, undo, lobbyId } = useGameContext();
  const { round, config } = useX01Game();

  const isMobile = useIsMobile();
  const isTabletStanding = useIsTabletStanding();

  const nextPlayerDisabled = useMemo(() => {
    // If middling phase is active, and active player has not thrown
    if (
      middlingPhase &&
      !middlingThrows.some((t) => t.playerId === activeRoundHits?.playerId)
    ) {
      return true;
    }
    // If not all hits are done and no hit is a bust
    if (
      !middlingPhase &&
      ![
        activeRoundHits?.hit1,
        activeRoundHits?.hit2,
        activeRoundHits?.hit3,
      ].every((h) => Boolean(h)) &&
      ![
        activeRoundHits?.hit1,
        activeRoundHits?.hit2,
        activeRoundHits?.hit3,
      ].some((h) => h?.bust)
    ) {
      return true;
    }

    return false;
  }, [activeRoundHits, middlingPhase, middlingThrows]);

  const nextLabel = useMemo(() => {
    if (middlingPhase && middlingCompleted) {
      return "Start Next Leg";
    }
    if (legFinished) {
      return "Next Leg";
    }
    if (players.length > 1) {
      return "Next Player";
    }
    return "Next Round";
  }, [legFinished, middlingCompleted, middlingPhase, players.length]);

  return (
    <Box>
      <Box className={classes.topLeft} p={28}>
        {onHistoryClick ? (
          <ActionButton
            disabled={legFinished || middlingPhase}
            icon={<IconHistory />}
            isMobile={isMobile}
            label="History"
            onClick={onHistoryClick}
            variant="outline"
          />
        ) : null}
      </Box>
      {isTabletStanding ? (
        <Box className={classes.topRight} p={28}>
          <RoundCounter
            round={round}
            totalRounds={config?.numRounds}
            hideLabel={isMobile}
            size={isMobile ? "lg" : "xl"}
          />
        </Box>
      ) : null}
      <Box className={classes.bottomLeft} p={28}>
        <ActionButton
          disabled={
            leg === 1 && middlingThrows.length === 0 && latestRoundIndex < 1
          }
          icon={<IconArrowBack />}
          isMobile={isMobile}
          label="Undo"
          onClick={undo}
          variant="outline"
        />
      </Box>
      <Box className={classes.bottomRight} p={28}>
        <Collapse in={finished}>
          <>
            <ActionButton
              buttonIconPosition="right"
              disabled={!finished}
              expanding={isMobile}
              icon={<IconPlus />}
              isMobile={isMobile}
              label="New Game"
              /* TODO create rematch mutation */
              link={
                lobbyId
                  ? clientRoutes.lobbySelectPage(lobbyId)
                  : clientRoutes.gameX01()
              }
            />
            <Space h="sm" />
          </>
        </Collapse>
        <Collapse in={finished}>
          <ActionButton
            buttonIconPosition="right"
            disabled={!finished}
            expanding={isMobile}
            icon={<IconDoorExit />}
            isMobile={isMobile}
            label="Exit"
            link={
              lobbyId ? clientRoutes.boardLobbyPage(lobbyId) : clientRoutes.root
            }
            variant="outline"
          />
        </Collapse>
        <Collapse in={!finished}>
          <ActionButton
            buttonIconPosition="right"
            disabled={nextPlayerDisabled}
            expanding={isMobile}
            icon={<IconChevronsRight />}
            isMobile={isMobile}
            label={nextLabel}
            onClick={nextPlayer}
          />
        </Collapse>
      </Box>
    </Box>
  );
};

export default X01FloatingActions;
