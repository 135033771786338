import { Box, Flex, Group } from "@mantine/core";
import { GameModeBadge, RoundCounter } from "@pilplay/ui";
import React from "react";
import { useIsMobile } from "../../../../../hooks/layout/useDevice";
import { useGameContext } from "../../context/useGameContext";
import GameMenu from "../GameMenu";
import classes from "./GameHeader.module.css";

interface GameHeaderProps {
  config: { label: string; value: string | number | undefined }[];
  round?: number;
  totalRounds?: number;
  headerLabel?: string;
  headerSubLabel?: string;
}

const GameHeader: React.FC<GameHeaderProps> = ({
  round,
  totalRounds,
  config = [],
  headerLabel,
  headerSubLabel,
}) => {
  const { showControls } = useGameContext();
  const isMobile = useIsMobile();

  return (
    <Box className={classes.root}>
      <Flex align="center" justify="space-between">
        <Box style={{ flex: 1 }}>
          <Group wrap="nowrap">
            {showControls ? <GameMenu config={config} /> : null}
            <GameModeBadge
              label={headerLabel}
              subLabel={headerSubLabel}
              hideSubLabel={isMobile}
              size={isMobile ? "lg" : "xl"}
            />
          </Group>
        </Box>
        <RoundCounter
          round={round || 1}
          totalRounds={totalRounds}
          hideLabel={isMobile}
          size={isMobile ? "lg" : "xl"}
        />
      </Flex>
    </Box>
  );
};

export default GameHeader;
