import React from "react";
import cx from "clsx";
import { Box, Flex, Text } from "@mantine/core";
import PlacementIcon from "../../../../../PlacementIcon";
import classes from "./ScoreboardPlayer.module.css";

export interface ScoreboardPlayerProps {
  player: {
    name: string;
    score: number;
    avatarUrl?: string;
  };
  placement: number;
  selected?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
  className?: string;
}

export const ScoreboardPlayer: React.FC<ScoreboardPlayerProps> = ({
  player,
  className,
  placement,
  selected = false,
  ...otherProps
}) => {
  return (
    <div {...otherProps} className={cx(classes.player, className)}>
      <div
        className={cx(classes.inner, {
          [classes.selected]: selected,
        })}
      >
        <Flex h="100%">
          <Box className={classes.left}>
            <Text className={classes.playerText} fw="bold" py="xs">
              {player.name}
            </Text>
            <div className={classes.avatarWrapper}>
              <div
                className={classes.avatar}
                style={{ backgroundImage: `url(${player.avatarUrl})` }}
              />
            </div>
          </Box>
          <Flex className={classes.middle}>
            <PlacementIcon size="60%" placementIndex={placement} />
          </Flex>
          <Flex className={classes.right}>
            <Text className={classes.labelText} c="dimmed">
              Points
            </Text>
            <Text className={classes.scoreText} fw="bold" mt={4}>
              {player.score}
            </Text>
          </Flex>
        </Flex>
      </div>
    </div>
  );
};
