import React, { useEffect } from "react";
import { Stack, TextInput, PasswordInput, Group } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Button } from "@pilplay/ui";
import { useAuthContext } from "../../../../../contexts/AuthContext";
import { useLogin } from "../../../../../hooks/useLogin";
import { TOKEN_KEY, setLocalStorage } from "../../../../../utils/storage";
import classes from "./SignInEmail.module.css";

interface SignInEmailProps {
  fetching?: boolean;
}

export const SignInEmail: React.FC<SignInEmailProps> = ({
  fetching = false,
}) => {
  const { setLogin } = useAuthContext();

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
  });

  const [{ data, fetching: fetchingLogin, error }, login] = useLogin();

  const handleSubmit = (): void => {
    void login({
      input: {
        email: form.values.email,
        password: form.values.password,
      },
    });
  };

  useEffect(() => {
    if (data?.signIn.accessToken) {
      setLocalStorage(TOKEN_KEY, data.signIn);
      setLogin(data.signIn);
    }
  }, [data, setLogin]);

  const errorMessage =
    error?.graphQLErrors.map((e) => e.message).join(",") ||
    form.errors.password;

  return (
    <div>
      <form
        onSubmit={form.onSubmit(() => {
          handleSubmit();
        })}
      >
        <Stack>
          <TextInput
            classNames={{ input: classes.input }}
            error={form.errors.email ? "Invalid email" : null}
            label="Email"
            onChange={(event) => {
              form.setFieldValue("email", event.currentTarget.value);
            }}
            placeholder="Enter your email address..."
            required
            variant="filled"
            value={form.values.email}
          />

          <PasswordInput
            classNames={{ input: classes.input }}
            error={errorMessage}
            label="Password"
            onChange={(event) => {
              form.setFieldValue("password", event.currentTarget.value);
            }}
            variant="filled"
            placeholder="Your password"
            required
            value={form.values.password}
          />
        </Stack>

        <Group justify="apart" mt="xl">
          <Button
            fullWidth
            disabled={fetchingLogin || fetching}
            loading={fetchingLogin || fetching}
            type="submit"
          >
            Continue with email
          </Button>
        </Group>
      </form>
    </div>
  );
};
