import {
  Box,
  Container,
  Group,
  Modal,
  Space,
  Stack,
  Text,
} from "@mantine/core";
import { PlayerAvatar } from "@pilplay/ui";
import React from "react";
import InteractiveDartboard from "../../../../../../../components/InteractiveDartboard/InteractiveDartboard";
import { useGameContext } from "../../../../context/useGameContext";
import Controls from "../../../Controls";
import { useCricketGame } from "../../context/useCricketGame";
import CricketPlayerHits from "../CricketScoreTable/componets/CricketPlayerHits";
import classes from "./CricketControlsModal.module.css";

interface CricketControlsModalProps {
  opened: boolean;
  onClose: () => void;
}

const CricketControlsModal: React.FC<CricketControlsModalProps> = ({
  opened,
  onClose,
}) => {
  const { activeHits, gamePlayers, finished, activePlayer } = useCricketGame();
  const { throwDart } = useGameContext();
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      centered
      size={"lg"}
      radius={"lg"}
    >
      <Stack>
        <Box className={classes.dartboard} mx={"auto"}>
          <InteractiveDartboard
            disabled={finished || activeHits.length >= 3}
            hits={
              activeHits
                .filter((h) => Boolean(h.position))
                .map((h) => h.position) as { x: number; y: number }[]
            }
            onHit={throwDart}
          />
        </Box>
        {activePlayer ? (
          <Container>
            <Group>
              <PlayerAvatar src={activePlayer.avatarUrl} />
              <Text fw="bold" size="xl">
                {activePlayer.name}
              </Text>
              <Box w={200}>
                <CricketPlayerHits
                  playerId={activePlayer.id}
                  onHitClick={onClose}
                />
              </Box>
            </Group>
          </Container>
        ) : null}

        <Space h={"sm"} />
        <Controls
          finished={finished}
          nextLabel={gamePlayers.length > 1 ? "Next Player" : "Next Round"}
          onNextPlayerClick={onClose}
          undoDisabled={false}
        />
      </Stack>
    </Modal>
  );
};

export default CricketControlsModal;
