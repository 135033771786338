import {
  ActionIcon,
  Alert,
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Grid,
  Kbd,
  LoadingOverlay,
  Paper,
  SimpleGrid,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import React, { useEffect } from "react";
import QRCode from "react-qr-code";
import { useNavigate, useParams } from "react-router-dom";
import { GameState } from "@pilplay/graphql";
import { useIsMobile } from "../../../hooks/layout/useDevice";
import { useGame } from "../../../hooks/useGame";
import { useStartGame } from "../../../hooks/useGameStart";
import { clientRoutes } from "../../../routes";
import classes from "./LobbyPage.module.css";
import AddGuestButton from "./components/AddGuestButton/AddGuestButton";
import GameConfig from "./components/GameConfig";

interface LobbyPageProps {
  // id to use and override the id from the url
  id?: string;
  redirect?: boolean;
  showControls?: boolean;
}

const OldLobbyPage: React.FC<LobbyPageProps> = ({
  id: passedId,
  redirect = true,
  showControls = true,
}) => {
  const theme = useMantineTheme();
  const { id: paramId } = useParams<{ id: string }>();
  const id = passedId || paramId;
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [{ data, error, fetching }, refetch] = useGame(id);
  const [{ error: startError, fetching: startGameFetching }, startGame] =
    useStartGame();

  useEffect(() => {
    if (data && data.game.state === GameState.Started && redirect) {
      navigate(clientRoutes.game(data.game.id));
    }
  }, [data, navigate, redirect]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [id, refetch]);

  if (error) {
    <Container>
      {startError ? (
        <Alert c="red" my="xl" title="Something went wrong" variant="filled">
          {(error || startError).graphQLErrors.map((e) => e.message).join(", ")}
        </Alert>
      ) : null}
    </Container>;
  }

  return (
    <Container className={classes.root}>
      <Grid mb={isMobile ? 120 : 0} pos="relative">
        <LoadingOverlay
          style={{
            borderRadius: theme.radius.md,
          }}
          visible={fetching}
        />
        <Grid.Col order={isMobile ? 1 : 2} span={{ base: 12, xs: 6 }}>
          <Flex justify="space-between" mt="md" ta="center">
            <Flex justify="center" ta="center">
              <Text>Players</Text>
            </Flex>
            {showControls ? (
              <AddGuestButton gameCode={data?.game.gameCode} />
            ) : null}
          </Flex>
          <Divider my="md" />
          <Stack gap="md">
            {data?.game.players.map((player) => (
              <Flex
                align="center"
                justify="space-between"
                key={player.id}
                ta="center"
              >
                <Avatar className={classes.avatar} src={player.avatarUrl} />
                <Text className={classes.playerName}>{player.name}</Text>
                <ActionIcon c="red" disabled ml="md" mt={8} variant="subtle">
                  <IconTrash color={theme.colors.red[7]} size={20} />
                </ActionIcon>
              </Flex>
            ))}
            <Text c="dimmed" fw="bold" size="xs" ta="right">
              {data?.game.players.length}/4 players
            </Text>
          </Stack>
        </Grid.Col>
        <Grid.Col mt="md" order={isMobile ? 2 : 1} span={{ base: 12, xs: 6 }}>
          <Paper p="md" radius="md" shadow="md" style={{}}>
            <Flex direction="column" justify="flex-end">
              <Stack>
                <Box>
                  <Center>
                    <Stack>
                      <Box mt="xl">
                        <QRCode
                          bgColor={theme.colors.dark[7]}
                          fgColor={theme.colors.dark[3]}
                          level="L"
                          className={classes.qrCode}
                          value={`${
                            import.meta.env.VITE_BASE_URL
                          }${clientRoutes.joinLobby(data?.game?.gameCode)}`}
                        />
                      </Box>
                    </Stack>
                  </Center>
                  <Divider
                    label="Join with QR code or with code below"
                    labelPosition="center"
                    my="md"
                  />
                  <Center>
                    <Kbd className={classes.code} size="xl">
                      {data?.game.gameCode
                        .toString()
                        .match(/.{1,3}/g)
                        ?.join("-")}
                    </Kbd>
                  </Center>
                </Box>
                <GameConfig config={data?.game.config} />
                {data?.game.board?.id ? (
                  <SimpleGrid cols={2}>
                    <Box mb="md">
                      <Text c="dimmed" size="xs">
                        Pilplay Auto board
                      </Text>
                      <Text fw="bold" size="md">
                        {data.game.board.name || "No board connected"}
                      </Text>
                    </Box>
                  </SimpleGrid>
                ) : null}
              </Stack>
              <Flex direction="column" justify="flex-end">
                {showControls ? (
                  <Button
                    disabled={startGameFetching}
                    fullWidth
                    mt="md"
                    onClick={async () => {
                      if (id) {
                        const res = await startGame({
                          gameID: id,
                        });
                        if (res.data?.startGame.id) {
                          navigate(clientRoutes.game(res.data.startGame.id));
                        }
                      }
                    }}
                    size="lg"
                  >
                    Start game
                  </Button>
                ) : null}
              </Flex>
            </Flex>
          </Paper>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default OldLobbyPage;
