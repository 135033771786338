import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";
import { clientRoutes } from "../../routes";

interface AuthWrapperProps {
  children: React.ReactNode;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isSignedIn, isLoading, hasAttemptedVerification } = useAuthContext();

  useEffect(() => {
    if (!isSignedIn && hasAttemptedVerification) {
      navigate(clientRoutes.login(location.pathname));
    }
  }, [isSignedIn, hasAttemptedVerification, navigate, location.pathname]);
  if (!hasAttemptedVerification && isLoading) return <div>Loading...</div>;

  if (!isSignedIn) {
    return <Navigate to={clientRoutes.login(location.pathname)} />;
  }
  return <>{children}</>;
};

export default AuthWrapper;
