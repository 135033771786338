import type { MantineBreakpoint, MantineSize } from "@mantine/core";
import { Box, Text } from "@mantine/core";
import cx from "clsx";
import type { ReactNode } from "react";
import React from "react";
import classes from "./DartHit.module.css";
import { DartHitList } from "./components/DartHitList";

export interface DartHitProps {
  size?: MantineSize | number;
  hit?: string | ReactNode;
  bust?: boolean;
  variant?: "positive" | "negative" | "natural";
  responsive?: boolean;
  grow?: boolean;
  onClick?: () => void;
}

const getSize = (size: MantineBreakpoint | number): number => {
  // If number is passed, return it
  if (typeof size === "number") return size;
  switch (size) {
    case "xs":
      return 52;
    case "sm":
      return 64;
    case "md":
      return 72;
    case "lg":
      return 78;
    case "xl":
      return 96;
    default:
      return 72;
  }
};

const Hit: React.FC<DartHitProps> = ({
  hit,
  size = "md",
  responsive = false,
  grow = false,
  bust = false,
  variant = "neutral",
  onClick,
}) => {
  const w = responsive || grow ? undefined : getSize(size);
  return (
    <Box
      onClick={onClick}
      w={w}
      h={w}
      className={cx(classes.dartbox, {
        [classes.click]: onClick !== undefined,
        [classes.negative]: bust || variant === "negative",
        [classes.positive]: variant === "positive",
        [classes.responsive]: responsive,
        [classes.animation]: hit,
        [classes.grow]: grow,
      })}
    >
      <Text component="div" fz="30cqi" fw={900}>
        {hit}
      </Text>
    </Box>
  );
};

export const DartHit = {
  List: DartHitList,
  Hit,
};
