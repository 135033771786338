import { Anchor, Text, Stack, Flex } from "@mantine/core";
import { NavLink } from "react-router-dom";
import { IconChevronRight } from "@tabler/icons-react";
import classes from "./CallToActionButton.module.css";

export interface CallToActionButtonProps {
  to?: string;
  onClick?: () => void;
  icon: React.ReactNode;
  title: string;
  disabled?: boolean;
  description: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
}

export const CallToActionButton: React.FC<CallToActionButtonProps> = ({
  to,
  icon,
  title,
  onClick,
  description,
  disabled = false,
  target = "_self",
}) => {
  return (
    <Anchor
      p="md"
      c="orange.7"
      w="100%"
      component={NavLink}
      to={to || ""}
      onClick={onClick}
      target={target}
      underline="never"
      className={classes.root}
      data-disabled={disabled}
    >
      <Flex justify="space-between" align="center">
        <Flex
          justify="center"
          align="center"
          w={52}
          h={52}
          className={classes.icon}
        >
          {icon}
        </Flex>
        <Stack ml="md" flex="auto" gap={4}>
          <Text c="white" fw="600">
            {title}
          </Text>
          <Text size="xs" c="dimmed">
            {description}
          </Text>
        </Stack>
        <IconChevronRight
          style={{
            justifySelf: "flex-end",
          }}
          size={20}
          color="white"
          stroke={2}
        />
      </Flex>
    </Anchor>
  );
};
