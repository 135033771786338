import type { FlexProps, MantineSize } from "@mantine/core";
import { Box, Flex, Stack, Text } from "@mantine/core";
import React from "react";
import logoNoText from "./logo-no-text.svg";

export interface LogoProps extends FlexProps {
  width?: number;
  text?: "below" | "right";
  fontSize?: MantineSize | string;
  offsetLeft?: number;
  subtitle?: string;
  subtitleFontSize?: MantineSize | string;
  color?: string;
}

export const Logo: React.FC<LogoProps> = ({
  width = 48,
  text,
  color,
  fontSize = "xl",
  offsetLeft = 0,
  subtitle,
  subtitleFontSize = "xs",
  ...otherProps
}) => {
  return (
    <Flex
      direction={text === "below" ? "column" : "row"}
      align="center"
      justify="center"
      {...otherProps}
    >
      <Box>
        <img
          style={{ display: "block", marginLeft: offsetLeft }}
          src={logoNoText}
          alt="Dart Sense"
          width={width}
        />
      </Box>
      {text && (
        <Stack gap={0}>
          <Box>
            <Text
              ta={text === "below" ? "center" : undefined}
              fz={fontSize}
              style={{
                fontFamily: "Inter",
              }}
              fw={800}
            >
              Pil
              <Text
                component="span"
                fz={fontSize}
                c={color || "orange.7"}
                style={{
                  fontFamily: "Inter",
                }}
                fw={800}
              >
                play
              </Text>
            </Text>
          </Box>
          {subtitle && (
            <Text
              ta={text === "below" ? "center" : undefined}
              fz={subtitleFontSize}
              c="dimmed"
              fw="bold"
            >
              {subtitle}
            </Text>
          )}
        </Stack>
      )}
    </Flex>
  );
};
