import { TextInput, Text, Modal, Flex, Button, Group } from "@mantine/core";
import React, { useState } from "react";

interface AddPlayerModalProps {
  open: boolean;
  onAdd: (name: string) => void;
  onClose: () => void;
}

const AddPlayerModal: React.FC<AddPlayerModalProps> = ({
  onAdd,
  onClose,
  open,
}) => {
  const [guestName, setGuestName] = useState<string>("");

  return (
    <Modal
      centered
      onClose={onClose}
      opened={open}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      title="Add player"
    >
      <Text>Enter your name</Text>
      <TextInput
        data-autofocus
        maxLength={12}
        onChange={(e) => {
          setGuestName(e.currentTarget.value);
        }}
        placeholder="Guest name"
        value={guestName}
      />
      <Flex justify="flex-end" mt="md">
        <Group>
          <Button onClick={onClose} variant="transparent">
            Cancel
          </Button>
          <Button
            disabled={guestName.length <= 0}
            onClick={() => {
              onAdd(guestName);
              setGuestName("");
              onClose();
            }}
          >
            Add
          </Button>
        </Group>
      </Flex>
    </Modal>
  );
};

export default AddPlayerModal;
