import {
  useUpdatePasswordMutation,
  useUpdateMeMutation,
} from "@pilplay/graphql";

export function useMeMutations() {
  const [{ fetching, error }, updatePassword] = useUpdatePasswordMutation();
  const [{ fetching: updateMeFetching, error: updateMeError }, updateMe] =
    useUpdateMeMutation();
  return {
    fetching: fetching || updateMeFetching,
    error: error || updateMeError,
    updatePassword,
    updateMe,
  };
}
