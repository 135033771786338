import { useCallback, useEffect, useRef, useState } from "react";
import {
  GetAvailableNetworksResponse,
  AdapterState,
  ErrorState,
  NetworkStatus,
  BoardInfo,
} from "@pilplay/connectrpc";
import { PILPLAY_SERVICE_ID } from "./useBluetooth";

export function parseGetAvailableNetworksResponse(
  data: DataView
): GetAvailableNetworksResponse {
  const arr = new Uint8Array(data.buffer);
  return GetAvailableNetworksResponse.fromBinary(arr);
}

export function parseAdapterState(data: DataView): AdapterState {
  const arr = new Uint8Array(data.buffer);
  return AdapterState.fromBinary(arr);
}

export function parseErrorState(data: DataView): ErrorState {
  const arr = new Uint8Array(data.buffer);
  return ErrorState.fromBinary(arr);
}

export function parseNetworkStatus(data: DataView): NetworkStatus {
  const arr = new Uint8Array(data.buffer);
  return NetworkStatus.fromBinary(arr);
}

export function parseBoardInfo(data: DataView): BoardInfo {
  const arr = new Uint8Array(data.buffer);
  return BoardInfo.fromBinary(arr);
}

export default function useBluetoothCharacteristic<T>(
  device: BluetoothDevice,
  charUUID: BluetoothCharacteristicUUID,
  parseMethod: (data: DataView) => T
) {
  const [val, setVal] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const char = useRef<BluetoothRemoteGATTCharacteristic | undefined | null>(
    null
  );

  const writeValue = useCallback(async (data: ArrayBuffer) => {
    if (!char.current) {
      // eslint-disable-next-line no-console -- for easier debugging
      console.warn("Characteristic is not available");
      return;
    }
    return char.current.writeValueWithResponse(data);
  }, []);

  const getCharacteristic = async () => {
    if (!device.gatt) {
      // eslint-disable-next-line no-console -- for easier debugging
      console.warn("Device does not have GATT server");
      return;
    }

    const server = await device.gatt.connect();
    if (!server) {
      // eslint-disable-next-line no-console -- for easier debugging
      console.warn("Failed to connect to device");
      return;
    }
    const service = await server.getPrimaryService(PILPLAY_SERVICE_ID);
    const characteristic = await service.getCharacteristic(charUUID);
    return characteristic;
  };

  const getValue = useCallback(async () => {
    if (!char.current) {
      // eslint-disable-next-line no-console -- for easier debugging
      console.warn("Characteristic is not available");
      return val;
    }
    try {
      const value = await char.current.readValue();
      const v = parseMethod(value);
      setVal(v);
      return v;
    } catch (err) {
      if (!String(err).includes("GATT operation already in progress.")) {
        // eslint-disable-next-line no-console -- for easier debugging
        console.error("Failed to read value", err);
      }
      return val;
    }
  }, [parseMethod, val]);

  const onCharacteristicValueChanged = (e: Event) => {
    const c = e.target as BluetoothRemoteGATTCharacteristic;
    // @ts-expect-error -- DataView is not a valid type
    const v = parseMethod(c.value);
    setVal(v);
  };

  useEffect(() => {
    if (!device) return;
    if (char.current) return;
    setLoading(true);
    getCharacteristic()
      .then((c) => {
        if (!c) return;
        char.current = c;
        void getValue();
        char.current
          .startNotifications()
          .then(() => {
            char.current?.addEventListener(
              "characteristicvaluechanged",
              onCharacteristicValueChanged
            );
            setLoading(false);
          })
          .catch((err) => {
            // eslint-disable-next-line no-console -- for easier debugging
            console.error("Failed to start notifications", err);
            setLoading(false);
          });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console -- for easier debugging
        console.error("Failed to get characteristic", err);
      });
    return () => {
      if (char.current) {
        char.current
          .stopNotifications()
          .then(
            () =>
              char.current?.removeEventListener(
                "oncharacteristicvaluechanged",
                onCharacteristicValueChanged
              )
          )
          .catch((err) => {
            // eslint-disable-next-line no-console -- for easier debugging
            console.error("Failed to stop notifications", err);
          });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount and unmount
  }, [device]);

  return {
    val,
    loading,
    writeValue,
    getValue,
  };
}
