import { Box, Collapse, Grid, Group, Paper, Stack, Text } from "@mantine/core";
import { PlayerAvatar } from "@pilplay/ui";
import cx from "clsx";
import React, { useMemo } from "react";
import AnimatedNumber from "../../../../../../../../../components/AnimatedNumber/AnimatedNumber";
import PlacementIcon from "../../../../../PlacementIcon";
import { calculateRoundSum } from "../../../../context/X01ContextProvider";
import { getPlayerRound, useX01Game } from "../../../../context/useX01Game";
import X01Checkout, { hasCheckout } from "../../../X01Checkout/X01Checkout";
import classes from "./X01PlayerScore.module.css";
import ActiveRoundPlayerHits from "./components/ActiveRoundPlayerHits";

interface X01PlayerScoreProps {
  player: {
    id: string;
    score: number;
    legs: number;
    name: string;
    avatarUrl?: string | undefined;
  };
  height?: number;
  minHeight?: number;
  width?: number;
}

const X01PlayerScore: React.FC<X01PlayerScoreProps> = ({
  player: { id, score, legs, name, avatarUrl },
  height = 100,
  minHeight = 100,
  width = 600,
}) => {
  const {
    config,
    playerRounds,
    activePlayer,
    round,
    finished,
    legFinished,
    scoreOrder,
  } = useX01Game();

  const isCheckout = useMemo(() => hasCheckout(score), [score]);
  const activeRound = useMemo(
    () => getPlayerRound(playerRounds, id, round),
    [playerRounds, id, round]
  );
  const activeRoundSum = useMemo(
    () => calculateRoundSum(activeRound),
    [activeRound]
  );

  const h = useMemo(
    () => Math.max(Math.min(width / 4.1, height), minHeight),
    [width, height, minHeight]
  );

  const {
    imageSize,
    hitSize,
    scoreSize,
    checkoutSize,
    nameSize,
    placementIcon,
  } = useMemo(
    () => ({
      imageSize: h / 3,
      nameSize: h / 6.4,
      checkoutSize: h / 10,
      scoreSize: h / 6,
      hitSize: h / 2.1,
      placementIcon: h / 3,
    }),
    [h]
  );

  const isActive = useMemo(() => id === activePlayer?.id, [id, activePlayer]);

  const isFinished = useMemo(
    () => finished || legFinished,
    [finished, legFinished]
  );

  return (
    <Box className={classes.box} style={{ height: h, minHeight: checkoutSize }}>
      <Paper
        radius={"lg"}
        p={"md"}
        shadow="md"
        className={cx({
          [classes.root]: true,
          [classes.active]: isActive,
        })}
      >
        <Grid justify="center" align="center">
          <Grid.Col span={isFinished ? 3 : 4}>
            <Group wrap="nowrap">
              <PlayerAvatar url={avatarUrl} size={imageSize} />
              <div className={classes.playerName}>
                <Text fz={nameSize} fw={900}>
                  {name}
                </Text>
                {config?.legs && config?.legs > 1 ? (
                  <Text fz={nameSize / 2} fw={700}>
                    Legs: {legs} / {config?.legs}
                  </Text>
                ) : null}
              </div>
            </Group>
          </Grid.Col>
          <Grid.Col span={isFinished ? 9 : 8}>
            <Group wrap="nowrap">
              <ActiveRoundPlayerHits playerId={id} hitSize={hitSize} />
              {isFinished ? (
                <Box>
                  <PlacementIcon
                    legWinnerOnly={!finished}
                    placementIndex={scoreOrder.findIndex((p) => p.id === id)}
                    size={placementIcon}
                  />
                </Box>
              ) : null}
              <Stack
                w={scoreSize * 4}
                gap={0}
                justify={"flex-end"}
                align={"flex-end"}
              >
                <Text fz={scoreSize} fw={900}>
                  <AnimatedNumber value={score} />
                </Text>
                <Text c="dimmed" fz={scoreSize / 1.5} fw={900}>
                  <AnimatedNumber value={activeRoundSum} />
                </Text>
              </Stack>
            </Group>
          </Grid.Col>
        </Grid>
      </Paper>
      <Collapse in={Boolean(isActive && isCheckout)}>
        <X01Checkout
          style={{
            paddingTop: checkoutSize * 2,
            marginTop: -checkoutSize * 2,
          }}
          score={score || 999}
          fontSize={checkoutSize}
        />
      </Collapse>
    </Box>
  );
};

export default X01PlayerScore;
