import { X01Game, getActiveRoundHits, scoreToPoints } from "@pilplay/games";
import type {
  MiddlingThrow,
  X01DartHit,
  X01GameConfig,
  X01GameSnapshot,
  X01Round,
} from "@pilplay/graphql";
import { X01ExitMode } from "@pilplay/graphql";
import type { Immutable } from "immer";
import React, { createContext, useMemo, useRef, useState } from "react";
import GameContextProvider from "../../../context/GameContextProvider";

interface X01Player {
  id: string;
  score: number;
  name: string;
  legs: number;
  avatarUrl?: string;
}

export interface X01ContextValue {
  gameId: string;
  finished: boolean;
  legFinished: boolean;
  middlingPhase: boolean;
  middlingThrows: Omit<MiddlingThrow, "__typename">[];
  middlingCompleted: boolean;
  config?: X01GameConfig;
  round: number;
  leg: number;
  playerRounds: Record<string, ExtendedX01Round[]>;
  latestRoundIndex: number;
  activeRoundHits?: Omit<X01Round, "__typename">;
  activePlayer?: X01Player;
  players: X01Player[];
  scoreOrder: { id: string; score: number }[];
}

export const X01Context = createContext<X01ContextValue>({
  gameId: "",
  finished: false,
  legFinished: false,
  middlingPhase: false,
  middlingThrows: [],
  middlingCompleted: false,
  playerRounds: {},
  latestRoundIndex: 0,
  leg: 0,
  round: 0,
  players: [],
  scoreOrder: [],
});

interface X01ContextProviderProps {
  gameId: string;
  lobbyId?: string;
  players: {
    id: string;
    index: number;
    playerType: string;
    name: string;
    userId?: string;
    avatarUrl?: string;
  }[];
  showControls?: boolean;
  children: React.ReactNode;
}

export type ExtendedX01Round = X01Round & { sum: number; scoreAtRound: number };

const X01ContextProvider: React.FC<X01ContextProviderProps> = ({
  gameId,
  lobbyId,
  players,
  children,
  showControls = true,
}) => {
  const gameRef = useRef<X01Game>(
    new X01Game(
      "",
      {
        legs: 1,
        numRounds: 10,
        startScore: 501,
        exitMode: X01ExitMode.Double,
      },
      []
    )
  );
  const [gameState, setGameState] = useState<Immutable<X01GameSnapshot>>(
    gameRef.current.getGameState()
  );

  const legOrder = gameState.legOrder[gameState.leg - 1];

  const gamePlayers = [...gameState.players]
    .map((player) => {
      const playerData = players.find((p) => p.id === player.id);
      if (!playerData) {
        throw new Error("Player not found");
      }
      return {
        id: player.id,
        legs: player.legs,
        name: playerData.name,
        avatarUrl: playerData.avatarUrl,
        score: player.score,
      };
    })
    .sort((a, b) => {
      // Sort based on leg order
      if (!legOrder) {
        return 0;
      }
      const aIndex = legOrder.findIndex((id) => id === a.id);
      const bIndex = legOrder.findIndex((id) => id === b.id);
      return aIndex - bIndex;
    });

  const activePlayer = gamePlayers.find(
    (p) => p.id === gameState.activePlayerId
  );

  const legFinished = activePlayer?.score === 0;

  const activeRoundHits = getActiveRoundHits(gameState, activePlayer?.id);

  const middlingThrows = [...gameState.middlingThrows].filter(
    (t) => t.leg === gameState.leg
  );

  const middlingCompleted = middlingThrows.length === players.length;

  const latestRoundIndex = gameState.rounds.findIndex(
    (r) =>
      r.playerId === gameState.activePlayerId &&
      r.roundNumber === gameState.activeRound &&
      r.leg === gameState.leg
  );

  const playerRounds = [...gameState.rounds]
    .filter((round) => round.leg === gameState.leg)
    .sort((a, b) => a.roundNumber - b.roundNumber)
    .reduce<Record<string, ExtendedX01Round[]>>((acc, round) => {
      if (!acc[round.playerId]) {
        acc[round.playerId] = [];
      }
      const lastRoundScore =
        acc[round.playerId].length > 0
          ? acc[round.playerId][acc[round.playerId].length - 1].scoreAtRound
          : gameRef.current.config.startScore || 0;
      const roundSum = calculateRoundSum(round);
      acc[round.playerId] = [
        ...acc[round.playerId].sort((a, b) => a.roundNumber - b.roundNumber),
        {
          ...{
            ...round,
            sum: roundSum,
            scoreAtRound: lastRoundScore - roundSum,
          },
        },
      ];
      return acc;
    }, {});

  const scoreOrder = useMemo(() => {
    return gamePlayers
      .map((player) => {
        return {
          id: player.id,
          score: player.score,
        };
      })
      .sort((a, b) => {
        return a.score - b.score;
      });
  }, [gamePlayers]);

  const getHitByEventId = (eventId: string): Immutable<X01DartHit> => {
    const middlingHit = gameState.middlingThrows.find(
      (t) => t.throw.id === eventId
    );
    if (middlingHit) {
      return middlingHit.throw;
    }
    const hit = gameState.rounds.reduce<X01DartHit | undefined>(
      (acc: X01DartHit | undefined, round) => {
        if (acc) {
          return acc;
        }
        return [round.hit1, round.hit2, round.hit3].find(
          (h) => h?.id === eventId
        ) as X01DartHit | undefined;
      },
      undefined
    );
    if (!hit) {
      throw new Error("Hit not found");
    }
    return hit;
  };

  return (
    <GameContextProvider<X01Game, X01GameSnapshot>
      fromSnapshot={(data) => {
        if (data.game.__typename === "X01Game" && data.game.snapshot) {
          return X01Game.fromSnapshot(
            data.game.id,
            data.game.config,
            data.game.players,
            data.game.snapshot
          );
        }
        throw new Error("Invalid game type");
      }}
      gameId={gameId}
      lobbyId={lobbyId}
      gameRef={gameRef}
      getHitByEventId={getHitByEventId}
      players={players}
      setGameState={(state) => {
        setGameState(state as Immutable<X01GameSnapshot>);
      }}
      showControls={showControls}
    >
      <X01Context.Provider
        value={{
          gameId,
          finished: gameState.finished,
          middlingPhase: gameState.middlingPhase,
          middlingThrows,
          middlingCompleted,
          legFinished,
          config: gameRef.current.config,
          leg: gameState.leg,
          round: gameState.activeRound,
          playerRounds,
          latestRoundIndex,
          activeRoundHits,
          activePlayer,
          players: gamePlayers,
          scoreOrder,
        }}
      >
        {children}
      </X01Context.Provider>
    </GameContextProvider>
  );
};

export function calculateRoundSum(round: X01Round | undefined) {
  if (!round) {
    return 0;
  }
  const hits = [round.hit1, round.hit2, round.hit3].filter((hit) => hit?.point);
  if (hits.some((hit) => hit?.bust)) {
    return 0;
  }
  return hits.reduce((sum, hit) => {
    return sum + scoreToPoints(hit!.point);
  }, 0);
}

export default X01ContextProvider;
