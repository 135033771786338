/* eslint-disable camelcase -- auto generated */
import {
  Box,
  Center,
  Flex,
  Loader,
  LoadingOverlay,
  PasswordInput,
  Stack,
  Text,
} from "@mantine/core";
import { Button } from "@pilplay/ui";
import { useEffect, useState } from "react";
import { IconCircleCheckFilled, IconWifi } from "@tabler/icons-react";
import {
  AdapterState_State,
  ConnectWifiRequest,
  ErrorState_Error,
  type Network,
} from "@pilplay/connectrpc";
import { useInputState } from "@mantine/hooks";
import {
  PILPLAY_ADAPTER_STATE_UUID,
  PILPLAY_CONNECT_WIFI_UUID,
  PILPLAY_ERROR_STATE_UUID,
  PILPLAY_NETWORK_STATUS_UUID,
} from "../../../../../../hooks/useBluetooth/useBluetooth";
import ErrorAlert from "../../../../../../components/ErrorAlert";
import useBluetoothCharacteristic, {
  parseAdapterState,
  parseErrorState,
  parseNetworkStatus,
} from "../../../../../../hooks/useBluetooth/useBluetoothCharacteristic";
import classes from "./style.module.css";

export interface BluetoothWifiPasswordScreenProps {
  device: BluetoothDevice;
  network: Network;
  onBack: () => void;
  onConnected: () => void;
}

const humanAdapterState = (state: AdapterState_State) => {
  switch (state) {
    case AdapterState_State.AWAITING_AUTHORIZATION:
      return "Waiting for device";
    case AdapterState_State.AUTHORIZED:
      return "Ready to connect";
    case AdapterState_State.PROVISIONING:
      return "Connecting to Wi-Fi";
    case AdapterState_State.PROVISIONED:
      return "Connected to Wi-Fi";
    case AdapterState_State.UNSPECIFIED:
    default:
      return "Unspecified";
  }
};

const humanErrorCode = (error: ErrorState_Error) => {
  switch (error) {
    case ErrorState_Error.NONE:
      return "None";
    case ErrorState_Error.UNKNOWN_RPC:
      return "Unknown RPC";
    case ErrorState_Error.UNABLE_TO_CONNECT:
      return "Unable to connect to Wifi, please check your password";
    case ErrorState_Error.NOT_AUTHORIZED:
      return "Unauthorized";
    case ErrorState_Error.UNKNOWN:
    default:
      return "Unknown";
  }
};

export const BluetoothWifiPasswordScreen: React.FC<
  BluetoothWifiPasswordScreenProps
> = ({ device, network, onBack, onConnected }) => {
  const [password, setPassword] = useInputState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { val: errorState } = useBluetoothCharacteristic(
    device,
    PILPLAY_ERROR_STATE_UUID,
    parseErrorState
  );

  const { val: networkStatus } = useBluetoothCharacteristic(
    device,
    PILPLAY_NETWORK_STATUS_UUID,
    parseNetworkStatus
  );

  const { val: adapterState } = useBluetoothCharacteristic(
    device,
    PILPLAY_ADAPTER_STATE_UUID,
    parseAdapterState
  );

  const { writeValue } = useBluetoothCharacteristic(
    device,
    PILPLAY_CONNECT_WIFI_UUID,
    (v) => {
      return v.buffer;
    }
  );

  const isSameNetwork = networkStatus?.wiFiSsid === network.ssid;

  const connectToWifi = async () => {
    setError(null);
    setLoading(true);
    try {
      const msg = new ConnectWifiRequest({
        ssid: network.ssid,
        password,
      }).toBinary();
      await writeValue(msg);
      setLoading(false);
    } catch (err) {
      // eslint-disable-next-line no-console -- for easier debugging
      console.error("Failed to connect to wifi", err);
      setError(err as Error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errorState && errorState.error !== ErrorState_Error.NONE) {
      const code = humanErrorCode(errorState.error);

      setError(new Error(`Error Code: ${code}, ${errorState.message}`));
    }
  }, [errorState]);

  useEffect(() => {
    if (
      Boolean(adapterState) &&
      adapterState?.state === AdapterState_State.PROVISIONED &&
      isSameNetwork
    ) {
      setError(null);
      setTimeout(() => {
        onConnected();
      }, 2000);
    }
  }, [adapterState, isSameNetwork, onConnected]);

  const isProvisinedToSameNetwork =
    adapterState?.state === AdapterState_State.PROVISIONED && isSameNetwork;

  const showLoading =
    adapterState?.state === AdapterState_State.PROVISIONING ||
    isProvisinedToSameNetwork;

  return (
    <Flex h="100%" direction="column">
      {showLoading ? (
        <LoadingOverlay
          visible
          opacity={1}
          bg="dark.9"
          overlayProps={{
            bg: "dark.9",
          }}
          loaderProps={{
            children: (
              <Center>
                <Flex justify="center" align="center" direction="column">
                  {adapterState?.state === AdapterState_State.PROVISIONED ? (
                    <IconCircleCheckFilled size="64" color="green" />
                  ) : (
                    <Loader size="xl" />
                  )}
                  <Text mt="md">
                    {humanAdapterState(adapterState?.state)} ...
                  </Text>
                </Flex>
              </Center>
            ),
          }}
        />
      ) : null}
      <Stack>
        <Box px="xl">
          <Center>
            <IconWifi size="64" />
          </Center>
          <Text ta="left" fz="xl" mt="md">
            Enter Wi-Fi Password
          </Text>
          <Text ta="left" fz="md" mt="sm" c="dimmed">
            {network.ssid}
          </Text>
          <PasswordInput
            value={password}
            classNames={{
              input: classes.input,
            }}
            onChange={setPassword}
            mt="xl"
            size="lg"
            required
            variant="filled"
            placeholder="Enter password"
            label="Password"
          />
        </Box>
      </Stack>
      <Flex direction="column" flex="auto" px="xl" mt="xl" justify="flex-end">
        <ErrorAlert error={error} />
        <Button variant="secondary" fullWidth mt="md" onClick={onBack}>
          Back
        </Button>
        <Button loading={loading} fullWidth mt="md" onClick={connectToWifi}>
          Connect
        </Button>
      </Flex>
    </Flex>
  );
};
