import { ActionIcon, Badge, Text, Menu, Group, Stack } from "@mantine/core";
import { IconChevronLeft, IconDots } from "@tabler/icons-react";
import React, { useEffect } from "react";
import { showNotification } from "@mantine/notifications";
import { formatDistanceToNow } from "date-fns";
import { useActivateLobbyCodeInputMutation } from "@pilplay/graphql";
import { useBoards } from "../../../../../../hooks/useBoards/useBoards";

const MenuItem = Menu.Item.withProps({
  mod: { hovered: false },
  onMouseOver: (e) => {
    e.currentTarget.setAttribute("data-hovered", "true");
  },
});

export interface LobbyCodeActionsProps {
  code: string;
  slug: string;
  canActivate?: boolean;
}

export const LobbyCodeActions: React.FC<LobbyCodeActionsProps> = ({
  code,
  slug,
  canActivate,
}) => {
  const [{ data }] = useBoards();

  const [{ data: codeData, fetching, error }, activateLobbyCode] =
    useActivateLobbyCodeInputMutation();

  useEffect(() => {
    if (error) {
      showNotification({
        title: "Failed to activate lobby code",
        message: error.message,
        color: "red",
      });
    }
  }, [error]);

  useEffect(() => {
    if (codeData) {
      showNotification({
        title: "Lobby code activated",
        message: `Lobby code has been activated`,
        color: "green",
      });
    }
  }, [codeData]);

  return (
    <Menu width={200} shadow="md" position="left" closeOnItemClick={false}>
      <Menu.Target>
        <ActionIcon aria-label="Actions" title="Actions">
          <IconDots size={16} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Actions</Menu.Label>

        <Menu width={200} shadow="md" position="left" closeOnItemClick={false}>
          <Menu.Target>
            <MenuItem
              disabled={!canActivate}
              leftSection={<IconChevronLeft size={18} />}
            >
              Activate on board
            </MenuItem>
          </Menu.Target>

          <Menu.Dropdown>
            {data?.boards.map((board) => (
              <MenuItem
                key={board.id}
                disabled={fetching}
                onClick={async () => {
                  await activateLobbyCode({
                    input: {
                      code,
                      orgSlug: slug,
                      boardId: board.id,
                    },
                  });
                }}
              >
                <Stack gap="xs">
                  <Text fz="xs">{board.name}</Text>
                  {board.activeLobby?.expiresAt ? (
                    <Group>
                      <Text size="xs" c="dimmed">
                        Expires:
                      </Text>
                      <Badge size="xs" color="red" variant="filled">
                        {formatDistanceToNow(
                          new Date(board.activeLobby.expiresAt)
                        )}
                      </Badge>
                    </Group>
                  ) : null}
                </Stack>
              </MenuItem>
            ))}
          </Menu.Dropdown>
        </Menu>
      </Menu.Dropdown>
    </Menu>
  );
};
