import { GameType } from "@pilplay/games";
import type { GameConfigInput } from "@pilplay/graphql";
import React from "react";
import { Divider, Text } from "@mantine/core";
import X01GameConfig from "./components/X01GameConfig";
import classes from "./GameConfig.module.css";
import CricketGameConfig from "./components/CricketGameConfig";

interface GameConfigProps {
  gameType: GameType;
  onConfigChange: (config: GameConfigInput) => void;
}

const GameConfig: React.FC<GameConfigProps> = ({
  gameType,
  onConfigChange,
}) => {
  const component = () => {
    switch (gameType) {
      case GameType.X01:
        return <X01GameConfig onConfigChange={onConfigChange} />;
      case GameType.Cricket:
        return <CricketGameConfig onConfigChange={onConfigChange} />;
    }
    return null;
  };

  return (
    <div className={classes.root}>
      <Text fw="bold" fz="xl">
        Game settings
      </Text>
      <Divider my="md" />

      {component()}
    </div>
  );
};

export default GameConfig;
