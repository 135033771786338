import React from "react";
import { useElementSize } from "@mantine/hooks";
import { Box, Grid } from "@mantine/core";
import classes from "./DesktopLayout.module.css";

export interface DesktopLayoutProps {
  header?: React.ReactNode;
  cols: {
    span: number | "auto" | "content";
    render: (props: {
      height: number;
      width: number;
      colWidth?: number;
    }) => React.ReactNode;
  }[];
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export const DesktopLayout: React.FC<DesktopLayoutProps> = ({
  header,
  children,
  cols,
  style,
  className,
}) => {
  const { ref: headerRef, height: headerHeight } = useElementSize();

  const { ref, height, width } = useElementSize();

  const maxHeight = height - headerHeight;

  return (
    <Box
      style={{
        height: "100dvh",
        ...style,
      }}
      className={className}
      ref={ref}
    >
      <Box ref={headerRef}>{header}</Box>
      {children}
      <Grid gutter={0} style={{ height: maxHeight }} align="stretch">
        {cols.map((col, i) => {
          const colWidth =
            typeof col.span === "number" ? width / (12 / col.span) : 12;
          return (
            <Grid.Col
              className={classes.gridCol}
              span={col.span}
              key={`grid-col-${i}`}
              style={{
                overflow: "hidden",
                ...(col.span === 0
                  ? {
                      "--col-flex-basis": "0%",
                      "--col-max-width": "0%",
                    }
                  : {}),
              }}
            >
              {col.render({ height: maxHeight, width, colWidth })}
            </Grid.Col>
          );
        })}
      </Grid>
    </Box>
  );
};
