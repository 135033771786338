import React, { useState } from "react";
import { Container } from "@mantine/core";
import { SectionHeader } from "@pilplay/ui";
import { useParams } from "react-router-dom";
import { OrganizationTabs } from "../OrganizationTabs";
import { LobbyCodesTable } from "./components/LobbyCodesTable";
import { CreateCode } from "./components/CreateCode";

export const CalendarPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [showCreateCode, setShowCreateCode] = useState(false);
  return (
    <Container size="xl">
      <SectionHeader
        breadcrumbs={[
          { label: "Home", link: "/" },
          { label: "Organization", link: `/organization/${slug}` },
          {
            label: "Calendar",
            active: true,
          },
        ]}
        mt="xl"
        title="Calendar"
        subTitle="Manage and create lobby sessions for your boards"
        primaryAction={{
          label: "New Code",
          onClick: () => {
            setShowCreateCode(!showCreateCode);
          },
        }}
      />
      <OrganizationTabs value="calendar" />
      {showCreateCode ? (
        <CreateCode
          orgSlug={slug!}
          onCreate={() => {
            setShowCreateCode(false);
          }}
        />
      ) : null}
      <LobbyCodesTable slug={slug!} />
    </Container>
  );
};
