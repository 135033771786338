import React from "react";
import type { TextProps } from "@mantine/core";
import { Text } from "@mantine/core";
import "./Headline.module.css";
import { useMediaQuery } from "@mantine/hooks";

export interface HeadlineProps {
  size?: "xss" | "xs" | "sm" | "md" | "lg" | "xl";
  fw?: "light" | "regular" | "medium" | "semiBold" | "bold";
  forceMobile?: boolean;
  children: React.ReactNode;
  ta?: "left" | "center" | "right";
  c?: TextProps["c"];
}

const sizeToPt = (size: HeadlineProps["size"], isMobile: boolean): string => {
  switch (size) {
    case "xl":
      return isMobile ? "36pt" : "40pt";
    case "lg":
      return isMobile ? "32pt" : "36pt";
    case "md":
      return isMobile ? "28pt" : "32pt";
    case "sm":
      return isMobile ? "24pt" : "28pt";
    case "xs":
      return isMobile ? "20" : "22pt";
    case "xss":
      return isMobile ? "18pt" : "20pt";
    default:
      return isMobile ? "28pt" : "32pt";
  }
};

const weightToFontWeight = (weight: HeadlineProps["fw"]): number => {
  switch (weight) {
    case "light":
      return 300;
    case "regular":
      return 400;
    case "medium":
      return 500;
    case "semiBold":
      return 600;
    case "bold":
      return 700;
    default:
      return 400;
  }
};

function calculateLetterSpacing(
  baseFontSize: string,
  percentage: string
): string {
  if (percentage.endsWith("px")) {
    return percentage;
  }
  const pct = Number(percentage.replace("%", ""));
  const fontSize = Number(baseFontSize.replace("pt", ""));
  // Calculate letter-spacing in pixels
  const pixelValue = fontSize * (pct / 100);

  // Calculate letter-spacing in ems
  // const emValue = pixelValue / fontSize;

  return `${pixelValue}px`;
}

const sizeToLetterSpacing = (
  size: HeadlineProps["size"],
  isMobile: boolean
): string => {
  switch (size) {
    case "xl":
      return isMobile ? "-2%" : "-2%";
    case "lg":
      return isMobile ? "-2%" : "-2%";
    case "md":
      return isMobile ? "-2%" : "-1%";
    case "sm":
      return isMobile ? "-2%" : "-1%";
    case "xs":
      return isMobile ? "-2%" : "-1%";
    case "xss":
      return isMobile ? "-0.1px" : "-1%";
    default:
      return isMobile ? "-2%" : "-1%";
  }
};

const MOBILE_BREAKPOINT = 768;

export const Headline: React.FC<HeadlineProps> = ({
  size,
  fw,
  children,
  forceMobile = false,
  ta = "left",
  c,
}) => {
  const isMobileQuery = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`);
  const isMobile = forceMobile || isMobileQuery || false;

  return (
    <Text
      ta={ta}
      fz={sizeToPt(size, isMobile)}
      fw={weightToFontWeight(fw)}
      lts={calculateLetterSpacing(
        sizeToPt(size, isMobile),
        sizeToLetterSpacing(size, isMobile)
      )}
      c={c}
    >
      {children}
    </Text>
  );
};
