import { Anchor, Center, Container, Image, Stack, Text } from "@mantine/core";
import React from "react";
import { NavLink } from "react-router-dom";
import { Logo } from "@pilplay/ui";
import type { CombinedError } from "urql";
import ErrorAlert from "../../components/ErrorAlert";
import Image404 from "./404.png";
import classes from "./InternalErrorPage.module.css";

interface InternalErrorPage {
  error?: CombinedError | null;
}

const InternalErrorPage: React.FC<InternalErrorPage> = ({ error }) => {
  return (
    <Container mt={120}>
      <Center>
        <Stack>
          <Logo fontSize="48" text="right" width={128} />
          <Image
            alt="404 not found image"
            className={classes.image}
            src={Image404}
          />
          <Text
            classNames={{
              root: classes.title,
            }}
          >
            Internal server error
          </Text>
          <Text className={classes.subTitle}>
            We aimed for the dartboard, but hit the server instead. We&apos;re
            sorry for the inconvenience. Please try again later.
            <br />
            <Anchor component={NavLink} to="/">
              Click here to go back to the main page.
            </Anchor>
          </Text>
          <ErrorAlert error={error} />
        </Stack>
      </Center>
    </Container>
  );
};

export default InternalErrorPage;
