import { merge } from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { arc as d3Arc } from "d3-shape";
import type { DartboardColors, Segment } from "./utils";
import {
  BOARD_SIZE,
  BOARD_TEXT_SIZE,
  boardDrawOrder,
  calculateTextTransform,
  createSegments,
  defaultColors,
  getSegCoordsFromMouse,
  getSegCoordsFromTouch,
  segmentFromCoordinates,
} from "./utils";
import classes from "./Dartboard.module.css";
import AnimatePath from "./components/AnimatePath";

const arc = d3Arc();
type TouchOrMouseEvent =
  | React.MouseEvent<SVGRectElement>
  | React.TouchEvent<SVGRectElement>;

export interface DartboardEvent {
  coords: {
    x: number;
    y: number;
  };
  segment?: Segment;
}

export interface HitElement {
  x: number;
  y: number;
  fill?: string;
  index?: number;
  opacity?: number;
  Cell?: React.FC<HitElement>;
}

export interface DartboardProps {
  boardSize?: number;
  disabled?: boolean;
  boardNormalize?: number;
  colors?: Partial<DartboardColors>;
  onUp?: (event: TouchOrMouseEvent, data: DartboardEvent) => void;
  onDown?: (event: TouchOrMouseEvent, data: DartboardEvent) => void;
  onMove?: (event: TouchOrMouseEvent, data: DartboardEvent) => void;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  afterChildren?: React.ReactNode;
  clipPath?: string;
  offsetY?: number;
  offsetX?: number;
  hits?: HitElement[];
  scale?: number;
}

const noop = () => {};

function isTouchEvent(
  e: React.MouseEvent | React.TouchEvent
): e is React.TouchEvent {
  return "touches" in e || "changedTouches" in e;
}

export const DefaultHit: React.FC<HitElement> = ({ x, y, opacity, fill }) => {
  return (
    <circle
      style={{
        stroke: "white",
        strokeWidth: 2,
        opacity,
        fill,
      }}
      fill={fill}
      cx={x}
      cy={y}
      r="8"
    />
  );
};

export const Dartboard: React.FC<DartboardProps> = ({
  colors,
  boardSize = BOARD_SIZE,
  disabled = false,
  boardNormalize = 170,
  onDown = noop,
  onUp = noop,
  onMove = noop,
  style = {},
  children,
  afterChildren,
  offsetY = 0,
  offsetX = 0,
  clipPath = "",
  hits = [],
  scale = 1,
}) => {
  const [lastData, setLastData] = React.useState<DartboardEvent | null>(null);
  const boardColors = merge(defaultColors, colors);
  const ref = useRef<SVGRectElement>(null);
  const opacity = disabled ? 0.5 : 1;

  const boardRadius = boardSize / 3;
  const board = 225 / boardNormalize;
  const boardOuterRadius = board * boardRadius;

  const segments = createSegments({
    normalize: boardNormalize,
    radius: boardRadius,
    boardOuterRadius: boardOuterRadius,
    colors: boardColors,
  });

  const pointPosition = (point: number) => point * (boardSize / 3);

  const numbers = Object.values(segments).filter((d) => d.name.startsWith("D"));

  const getSegCoords = (event: React.MouseEvent | React.TouchEvent) => {
    if (isTouchEvent(event)) {
      return getSegCoordsFromTouch(event, segments, boardSize, boardRadius);
    }
    return getSegCoordsFromMouse(event, segments, boardSize, boardRadius);
  };

  const _onMove = (event: TouchOrMouseEvent) => {
    if (disabled) {
      return;
    }
    const data = getSegCoords(event);
    setLastData(data);
    onMove(event, data);
  };

  const _onUp = (event: TouchOrMouseEvent) => {
    if (disabled) {
      return;
    }
    onUp(event, lastData!);
  };

  const _onDown = (event: TouchOrMouseEvent) => {
    if (disabled) {
      return;
    }
    const data = getSegCoords(event);
    // Set data if the user does not move the mouse
    setLastData(data);
    onDown(event, data);
  };

  // Prevents scrolling on mobile when touching the dartboard
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener(
        "touchmove",
        (e) => {
          e.preventDefault();
        },
        { passive: false }
      );
    }
    return () => {
      if (ref && ref.current) {
        ref.current.removeEventListener("touchmove", (e: any) => {
          e.preventDefault();
        });
      }
    };
  }, []);

  const lastHit = hits.length >= 1 ? hits[hits.length - 1] : undefined;

  const lastHitSegment = useMemo(() => {
    if (!lastHit) {
      return undefined;
    }
    return segmentFromCoordinates(segments, {
      x: lastHit.x,
      y: -lastHit.y, // Invert y since it's inverted in the dartboard
    });
  }, [lastHit]);

  return (
    <svg
      className={classes.root}
      style={style}
      viewBox={`${offsetX} ${offsetY} ${boardSize} ${boardSize}`}
    >
      {children}
      <g clipPath={clipPath}>
        <g
          opacity={opacity}
          transform={`translate(${boardSize / 2}, ${
            boardSize / 2
          }) scale(${scale})`}
        >
          <rect
            ref={ref}
            className={classes.rect}
            opacity={0}
            x={-boardSize / 2}
            y={-boardSize / 2}
            width={boardSize}
            height={boardSize}
            onMouseDown={_onDown}
            onMouseUp={_onUp}
            onMouseMove={_onMove}
            onTouchStart={_onDown}
            onTouchEnd={(e) => {
              // Prevent default to not trigger mouse events
              e.preventDefault();
              _onUp(e);
            }}
            onTouchMove={_onMove}
          />
          <circle
            id="background"
            r={boardOuterRadius}
            fill={boardColors.background}
          />
          {Object.values(segments)
            .sort(boardDrawOrder)
            .map((segment) => (
              <path
                key={segment.name}
                id={segment.name}
                d={
                  arc({
                    innerRadius: segment.innerRadius,
                    outerRadius: segment.outerRadius,
                    startAngle: segment.startAngle,
                    endAngle: segment.endAngle,
                  }) || ""
                }
                fill={segment.color}
                fillOpacity={1}
              >
                <AnimatePath
                  animate={lastHitSegment?.point == segment.point}
                  baseColor={segment.color}
                />
              </path>
            ))}
          {numbers.map((segment) => {
            return (
              <text
                className={classes.number}
                style={{
                  fontSize: `${
                    (BOARD_TEXT_SIZE / boardNormalize) * boardRadius
                  }px`,
                  fill: boardColors.text,
                }}
                key={segment.name}
                transform={calculateTextTransform(
                  segment,
                  boardNormalize,
                  boardRadius
                )}
                id={`Number${segment.name}`}
              >
                {segment.name.substring(1)}
              </text>
            );
          })}
          {hits.map((hit, i) => {
            const isLastHit = i === hits.length - 1;
            if (!hit.Cell) {
              return (
                <DefaultHit
                  key={i}
                  index={i}
                  x={pointPosition(hit.x)}
                  y={pointPosition(hit.y)}
                  opacity={hits.length - 1 ? 1 : 0.5}
                  fill={isLastHit ? boardColors.lastHit : boardColors.hit}
                />
              );
            }
            return (
              <hit.Cell
                key={i}
                index={i}
                x={pointPosition(hit.x)}
                y={pointPosition(hit.y)}
                opacity={isLastHit ? 1 : 0.5}
                fill={isLastHit ? boardColors.lastHit : boardColors.hit}
              />
            );
          })}
        </g>
        {afterChildren}
      </g>
    </svg>
  );
};
