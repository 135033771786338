import { Box, Center } from "@mantine/core";
import type { DartHit} from "@pilplay/games";
import { DartPoint, scoreToPoints } from "@pilplay/games";
import { uniqueId } from "lodash";
import React, { useState } from "react";
import type { DartboardEvent } from "@pilplay/ui";
import { Dartboard } from "@pilplay/ui";
import { useElementSize } from "@mantine/hooks";
import classes from "./InteractiveDartboard.module.css";
import Magnifier from "./Magnifier";

export type TouchOrMouseEvent =
  | React.MouseEvent<SVGRectElement>
  | React.TouchEvent<SVGRectElement>;

interface InteractiveDartboardProps {
  boardSize?: number;
  disabled?: boolean;
  magnifierSize?: number;
  magnifierOffset?: { x: number; y: number };
  magnifierScale?: number;
  magnifierColor?: string;
  crosshairColor?: string;
  onHit?: (hit: DartHit) => void;
  maxSize?: number;
  hits?: { x: number; y: number }[];
  children?: React.ReactNode;
  afterChildren?: React.ReactNode;
}

const noop = () => {};

const InteractiveDartboard: React.FC<InteractiveDartboardProps> = ({
  boardSize = 1000, // TODO: This is always static
  magnifierSize,
  magnifierOffset,
  magnifierScale = 1.5,
  disabled = false,
  onHit = noop,
  maxSize,
  hits = [],
  magnifierColor,
  crosshairColor,
  children,
  afterChildren,
}) => {
  const [down, setDown] = useState(false);
  const [clipPosition, setClipPosition] = useState({ x: 400, y: 400 });
  const [hoverPoint, setHoverPoint] = useState<DartPoint | null>(null);

  const onDown = (_: TouchOrMouseEvent, data: DartboardEvent) => {
    // Set the position to prevent the magnifier from jumping on touch
    setClipPosition({
      x: data.coords.x * (boardSize / 3),
      y: -data.coords.y * (boardSize / 3),
    });
    setHoverPoint(data.segment?.point ?? null);
    setDown(true);
  };

  const onMove = (
    _: React.TouchEvent | React.MouseEvent,
    data: DartboardEvent
  ) => {
    // This function will trigger even when the mouse is not down on mousemove
    setClipPosition({
      x: data.coords.x * (boardSize / 3),
      y: -data.coords.y * (boardSize / 3),
    });
    setHoverPoint(data.segment?.point ?? null);
  };

  const onUp = (_event: TouchOrMouseEvent, data: DartboardEvent) => {
    setDown(false);
    if (data.segment) {
      onHit({
        id: uniqueId(),
        point: data.segment.point,
        position: {
          x: data.coords.x,
          y: -data.coords.y,
        },
      });
    } else {
      onHit({
        id: uniqueId(),
        point: DartPoint.MISS,
        position: {
          x: data.coords.x,
          y: -data.coords.y,
        },
      });
    }
  };

  return (
    <Box
      className={classes.root}
      style={{
        width: maxSize ? maxSize : "100%",
        height: maxSize ? maxSize : "100%",
      }}
    >
      {down ? <Magnifier
          boardSize={boardSize}
          color={magnifierColor}
          crosshairColor={crosshairColor}
          disabled={disabled}
          edgeLabel={`${scoreToPoints(hoverPoint ?? "Miss")}`}
          hits={hits}
          magnifierRadius={magnifierSize}
          offset={magnifierOffset}
          position={clipPosition}
          scale={magnifierScale}
        /> : null}
      <Dartboard
        afterChildren={afterChildren}
        disabled={disabled}
        hits={hits}
        onDown={onDown}
        onMove={onMove}
        onUp={onUp}
      >
        {children}
      </Dartboard>
    </Box>
  );
};

export const InteractiveDartboardAutoScale: React.FC<
  InteractiveDartboardProps & { height: number | string; padding?: number }
> = ({ height, padding = 0, children, ...props }) => {
  const { ref, width } = useElementSize();
  return (
    <Center
      ref={ref}
      style={{
        width: "100%",
        height,
      }}
    >
      <InteractiveDartboard
        {...props}
        maxSize={Math.min(width, Number(height) - padding, 1200)}
      >
        {children}
      </InteractiveDartboard>
    </Center>
  );
};

export default InteractiveDartboard;
