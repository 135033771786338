import React, { useEffect } from "react";
import { Divider, Text } from "@mantine/core";
import {
  EXIT_MODE_CONFIG,
  LEG_ORDER_CONFIG,
  MAX_LEGS_CONFIG,
  SCORE_CONFIG,
} from "@pilplay/games";
import { useForm } from "@mantine/form";
import type { X01GameConfigInput } from "@pilplay/graphql";
import { LegOrderType, X01ExitMode } from "@pilplay/graphql";
import SegmentConfig from "../../../SegmentConfig";
import classes from "./x-01-game-config.module.css";

interface X01GameConfigProps {
  onConfigChange: (config: { x01: X01GameConfigInput }) => void;
}

const X01GameConfig: React.FC<X01GameConfigProps> = ({ onConfigChange }) => {
  const form = useForm({
    initialValues: {
      startScore: 301,
      exitMode: X01ExitMode.Double,
      legOrderType: LegOrderType.Middle,
      numRounds: 50,
      legs: 1,
    },
  });

  useEffect(() => {
    onConfigChange({
      x01: {
        ...form.values,
        startScore: parseInt(form.values.startScore.toString()),
        numRounds: parseInt(form.values.numRounds.toString()),
        legs: parseInt(form.values.legs.toString()),
      },
    });
  }, [form.values, onConfigChange]);

  return (
    <div className={classes.root}>
      <SegmentConfig
        data={SCORE_CONFIG}
        description="The score to reach to win the game"
        title="Starting score"
        {...form.getInputProps("startScore")}
        value={form.values.startScore.toString()}
      />
      <SegmentConfig
        data={LEG_ORDER_CONFIG}
        description="How to determine the player order"
        title="Player order"
        {...form.getInputProps("legOrderType")}
        tooltip={
          <Text>
            <Text component="span" fw="bold">
              Middle
            </Text>{" "}
            The player closets to the middle of the board starts
            <br />
            <br />
            <Text component="span" fw="bold">
              Random
            </Text>{" "}
            The player order is randomized
            <br />
            <br />
            <Text component="span" fw="bold">
              Static
            </Text>{" "}
            The player order is static
          </Text>
        }
        value={form.values.legOrderType.toString()}
      />
      <SegmentConfig
        data={EXIT_MODE_CONFIG}
        description="The way to exit the game"
        title="Exit mode"
        {...form.getInputProps("exitMode")}
        tooltip={
          <Text>
            <Text component="span" fw="bold">
              Single
            </Text>{" "}
            The player must hit a single field to exit
            <br />
            <br />
            <Text component="span" fw="bold">
              Double
            </Text>{" "}
            The player must hit a double field to exit
            <br />
            <br />
            <Text component="span" fw="bold">
              DömD
            </Text>{" "}
            First 5 rounds below 50 points must be finished with a double, after
            you can finish with a single
          </Text>
        }
      />
      <SegmentConfig
        data={MAX_LEGS_CONFIG}
        description="Amount of legs to win"
        title="Legs"
        {...form.getInputProps("legs")}
        value={form.values.legs.toString()}
      />
      <Divider my="md" />
      <Text c="dimmed" fz="sm">
        X01 is a classic game of darts where you start with a score of 301 or
        higher and try to reach 0 by hitting the board. The game can be played
        in different ways, but the most common is to play a best of 3, 5 or 7
        legs. A leg is won by reaching 0 points before your opponent. The first
        player to reach the set amount of legs wins the match.
        <br />
        <br />
        If your playing with exit mode Double or you must hit a double field to
        exit the game.
      </Text>
    </div>
  );
};

export default X01GameConfig;
