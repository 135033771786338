import React from "react";
import { LoadingOverlay } from "@mantine/core";
import Confetti from "react-confetti-boom";

// eslint-disable-next-line @typescript-eslint/no-empty-interface -- empty interface is used to define props
export interface ConfettiOverlayProps {}

export const ConfettiOverlay: React.FC<ConfettiOverlayProps> = () => {
  return (
    <LoadingOverlay
      loaderProps={{
        style: {
          display: "none",
        },
      }}
      overlayProps={{
        backgroundOpacity: 0,

        children: (
          <Confetti
            mode="boom"
            particleCount={1000}
            spreadDeg={60}
            launchSpeed={2}
            y={1}
          />
        ),
      }}
      style={{
        pointerEvents: "none",
      }}
      visible
    />
  );
};
