import { useLobbyCodesQuery } from "@pilplay/graphql";
import { useCallback, useMemo, useState } from "react";
import { useAuthContext } from "../../contexts/AuthContext";

export function useLobbyCodes(slug: string, take = 10, orderBy = "id") {
  const { isSignedIn } = useAuthContext();

  const [state, setState] = useState<{
    first?: number | null;
    after?: string | null;
    last?: number | null;
    before?: string | null;
    orderBy?: string | null;
  }>({
    first: take,
    after: null,
    last: null,
    before: null,
    orderBy,
  });

  const [{ data, error, fetching }] = useLobbyCodesQuery({
    variables: {
      orgSlug: slug,
      pagination: state,
    },
    requestPolicy: "cache-and-network",
    pause: !slug || !isSignedIn,
  });

  const pageInfo = useMemo(() => {
    return data?.lobbyCodes.pageInfo;
  }, [data?.lobbyCodes.pageInfo]);

  const getNextPage = useCallback(() => {
    setState({
      after: pageInfo?.endCursor,
      first: take,
      orderBy,
    });
  }, [orderBy, pageInfo?.endCursor, take]);

  const getPreviousPage = useCallback(() => {
    setState({
      before: pageInfo?.startCursor,
      last: take,
      orderBy,
    });
  }, [orderBy, pageInfo?.startCursor, take]);

  return {
    codes: data?.lobbyCodes.edges,
    error,
    fetching,
    pageInfo,
    getNextPage,
    getPreviousPage,
    hasNextPage: pageInfo?.hasNextPage,
    hasPreviousPage: pageInfo?.hasPreviousPage,
  };
}
