import { useMutation, useQuery } from "@tanstack/react-query";

const AUTO_CALIBRATION_URL: string =
  import.meta.env.VITE_AUTO_CALIBRATION_URL || "http://localhost:8000/predict";

interface CalibrationPoint {
  x: number;
  y: number;
  point: string;
}

interface CalibrationPointResponse {
  points: CalibrationPoint[];
}

export function useCalibrationPoints(boardId: string, cameraId: string) {
  return useQuery({
    queryKey: ["calibrationPoints", boardId, cameraId],
    queryFn: async (): Promise<CalibrationPointResponse> => {
      const response = await fetch(
        `/proxy/${boardId}/api/cameras/${cameraId}/calibration`
      );
      if (response.status >= 400) {
        throw new Error(await response.text());
      }
      return response.json();
    },
  });
}

type AutoCalibrationKey = "8" | "13" | "20" | "17";

type AutoCalibrateResponse = Record<
  AutoCalibrationKey,
  {
    box: {
      x1: number;
      x2: number;
      y1: number;
      y2: number;
    };
    id: string;
    key_point: {
      x: number;
      y: number;
    };
    name: string;
    score: number;
  }
>;

export function useAutoCalibration() {
  return useMutation<AutoCalibrateResponse, Error, string>({
    mutationFn: async (src) => {
      const file = await fetch(src).then((res) => res.blob());
      const formData = new FormData();
      formData.append("file", file, "image.jpg");
      const response = await fetch(AUTO_CALIBRATION_URL, {
        method: "POST",
        body: formData,
      });
      if (response.status >= 400) {
        throw new Error(await response.text());
      }
      return response.json();
    },
  });
}

export interface CalibrationResponse {
  status: string;
}

export interface CalibrationResponseError extends Error {
  error: string;
}

export interface CalibrationVariables {
  points: CalibrationPoint[];
}

export default function useCameraCalibrationMutation(
  boardId: string,
  cameraID: string
) {
  const data = useMutation<
    CalibrationResponse,
    CalibrationResponseError,
    CalibrationVariables
  >({
    mutationKey: ["calibration", cameraID],
    mutationFn: async (calibration) => {
      const response = await fetch(
        `/proxy/${boardId}/api/cameras/${cameraID}/calibration`,
        {
          body: JSON.stringify({
            points: calibration.points.map((point) => ({
              point: Number(point.point),
              x: Math.round(point.x),
              y: Math.round(point.y),
            })),
          }),
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      );
      try {
        const d = await response.json();
        return d;
      } catch (e) {
        // eslint-disable-next-line no-console -- Log errors
        console.error(e);
        throw new Error("Failed to calibrate camera");
      }
    },
  });
  return data;
}
