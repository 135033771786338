import { Group, Stack } from "@mantine/core";
import { ActionButton } from "@pilplay/ui";
import {
  IconArrowBack,
  IconArrowRight,
  IconDoorExit,
} from "@tabler/icons-react";
import React from "react";
import { useGameContext } from "../../context/useGameContext";
import {} from "./controls.module.css";

interface ControlsProps {
  undoDisabled?: boolean;
  nextLabel?: string;
  finished?: boolean;
  onExitClick?: () => void;
  onNextPlayerClick?: () => void;
}

const Controls: React.FC<ControlsProps> = ({
  undoDisabled,
  nextLabel = "Next Player",
  finished,
  onExitClick,
  onNextPlayerClick,
}) => {
  const { nextPlayer, undo } = useGameContext();

  return !finished ? (
    <Group grow>
      <ActionButton
        disabled={undoDisabled}
        icon={<IconArrowBack />}
        onClick={undo}
        variant="outline"
        label="Undo"
      />
      <ActionButton
        onClick={() => {
          void nextPlayer();
          onNextPlayerClick && onNextPlayerClick();
        }}
        variant="filled"
        label={nextLabel}
        icon={<IconArrowRight />}
        buttonIconPosition="right"
      />
    </Group>
  ) : (
    <Stack>
      {onExitClick ? (
        <ActionButton
          onClick={onExitClick}
          icon={<IconDoorExit />}
          label="Exit game"
          buttonIconPosition="right"
        />
      ) : null}
      <ActionButton
        icon={<IconArrowBack />}
        onClick={undo}
        size="xl"
        variant="outline"
        label="Undo last throw"
      />
    </Stack>
  );
};

export default Controls;
