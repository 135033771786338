import { useMantineTheme } from "@mantine/core";

interface AnimatePathProps {
  baseColor: string;
  animate: boolean;
}

const AnimatePath: React.FC<AnimatePathProps> = ({ baseColor, animate }) => {
  const theme = useMantineTheme();
  if (!animate) {
    return (
      <animate
        attributeName="fill"
        values={baseColor}
        dur="2s"
        repeatCount="indefinite"
      />
    );
  }
  return (
    <animate
      attributeName="fill"
      values={baseColor + ";" + theme.colors.yellow[7] + ";" + baseColor}
      dur="2s"
      repeatCount="indefinite"
    ></animate>
  );
};

export default AnimatePath;
