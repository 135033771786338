import React, { useEffect, useState } from "react";
import { BoardStatus } from "@pilplay/graphql";
import { IconWifi, IconWifiOff } from "@tabler/icons-react";
import { Box, Loader, Text, Transition, useMantineTheme } from "@mantine/core";
import { useTimeout } from "@mantine/hooks";
import useBoard from "../../hooks/useBoard";
import classes from "./BoardConnectivity.module.css";

interface BoardConnectivityProps {
  id: string;
  size?: number;
}

const BoardConnectivity: React.FC<BoardConnectivityProps> = ({
  id,
  size = 32,
}) => {
  const [{ data, fetching, error }] = useBoard(id);
  const theme = useMantineTheme();
  const state = data?.board.status || BoardStatus.Disconnected;
  const [show, setShow] = useState(false);
  const [showConnectedText, setShowConnectedText] = useState(false);
  const { start: startConnectedText, clear: clearConnectedText } = useTimeout(
    () => {
      setShowConnectedText(false);
    },
    3000
  );
  const { start, clear } = useTimeout(() => {
    setShow(false);
  }, 6000);

  useEffect(() => {
    if (state === BoardStatus.Connected) {
      clearConnectedText;
      clear();
      setShowConnectedText(true);
      startConnectedText();
      start();
    } else if (state === BoardStatus.Disconnected) {
      clear();
      setShow(true);
    }
  }, [clear, clearConnectedText, start, startConnectedText, state]);

  const statusText = () => {
    if (error) {
      return "Error connecting to board";
    }
    if (fetching) {
      return "Connecting...";
    }
    switch (state) {
      case BoardStatus.Connected:
        return "Board Connected";
      case BoardStatus.Disconnected:
        return "Board Disconnected";
      default:
        return "Unknown board status";
    }
  };

  const statusColor = () => {
    if (error) {
      return theme.white;
    }
    if (fetching) {
      return theme.colors.gray[8];
    }
    switch (state) {
      case BoardStatus.Connected:
        return theme.white;
      case BoardStatus.Disconnected:
        return theme.white;
      default:
        return theme.colors.yellow[8];
    }
  };

  const statusIcon = () => {
    if (fetching) {
      return <Loader className={classes.spinner} p={0} size={size} />;
    }
    if (error) {
      return (
        <IconWifiOff
          className={classes.icon}
          color={statusColor()}
          size={size}
        />
      );
    }
    switch (state) {
      case BoardStatus.Connected:
        return (
          <IconWifi
            className={classes.icon}
            color={statusColor()}
            size={size}
          />
        );
      case BoardStatus.Disconnected:
        return (
          <IconWifiOff
            className={classes.icon}
            color={statusColor()}
            size={size}
          />
        );
      default:
        return (
          <IconWifi
            className={classes.icon}
            color={statusColor()}
            size={size}
          />
        );
    }
  };

  return (
    <Transition
      mounted={show}
      transition="fade"
      duration={400}
      timingFunction="ease"
    >
      {(styles) => (
        <Box className={classes.container} style={styles}>
          <div
            className={classes.root}
            data-connected={state === BoardStatus.Connected}
            style={{
              width:
                !showConnectedText && state === BoardStatus.Connected
                  ? `calc(${size}px + 2 * ${theme.spacing.lg})`
                  : "100%",
            }}
          >
            {statusIcon()}
            <Text className={classes.text}>{statusText()}</Text>
          </div>
        </Box>
      )}
    </Transition>
  );
};

export default BoardConnectivity;
