import { Center } from "@mantine/core";
import { DartHit } from "@pilplay/ui";
import { IconShieldFilled } from "@tabler/icons-react";
import React, { useCallback, useMemo } from "react";
import { useGameContext } from "../../../../../../context/useGameContext";
import { useCricketGame } from "../../../../context/useCricketGame";
import {} from "./CricketPlayerHits.module.css";

interface CricketPlayerHitsProps {
  playerId: string;
  onHitClick?: () => void;
  onEmptyClick?: () => void;
}

const CricketPlayerHits: React.FC<CricketPlayerHitsProps> = ({
  playerId,
  onHitClick,
  onEmptyClick,
}) => {
  const {
    activePlayerId,
    activeHits,
    currentRound,
    getPlayerMatrix,
    pointInHitNumbers,
  } = useCricketGame();
  const { setEditId } = useGameContext();
  const active = playerId === activePlayerId;

  const hits = useMemo(() => {
    return Array.from({ length: 3 }).map(
      (
        _,
        i
      ): {
        id?: string;
        text?: string | React.ReactNode;
        variant: "positive" | "negative" | "natural";
      } => {
        const hit = activeHits[i] || undefined;
        const playerMatrix = getPlayerMatrix(playerId);
        const appliedScore = playerMatrix?.scores.find(
          (score) =>
            score.hitIndex === i &&
            score.roundNumber === currentRound &&
            score.from === activePlayerId
        );
        const inNumberArray = hit && pointInHitNumbers(hit.point);

        if (active) {
          return {
            id: hit?.id,
            text: appliedScore?.score || hit?.point,
            variant: inNumberArray ? "positive" : "natural",
          };
        }
        if (!inNumberArray) {
          return {
            text: undefined,
            variant: "natural",
          };
        }

        if (appliedScore?.block) {
          return {
            text: (
              <Center
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <IconShieldFilled size={"80%"} />
              </Center>
            ),
            variant: "natural",
          };
        }

        return {
          text: appliedScore?.score,
          variant: appliedScore ? "negative" : "natural",
        };
      }
    );
  }, [
    activeHits,
    getPlayerMatrix,
    playerId,
    pointInHitNumbers,
    active,
    currentRound,
    activePlayerId,
  ]);

  const handleHitClick = useCallback(
    (hitId?: string) => {
      if (hitId) {
        onHitClick && onHitClick();
        setEditId(hitId);
      } else {
        onEmptyClick && onEmptyClick();
      }
    },
    [onEmptyClick, onHitClick, setEditId]
  );

  return (
    <DartHit.List grow>
      <DartHit.Hit
        hit={hits[0]?.text}
        onClick={
          active
            ? () => {
                handleHitClick(hits[0]?.id);
              }
            : undefined
        }
        variant={hits[0]?.variant}
      />
      <DartHit.Hit
        hit={hits[1]?.text}
        onClick={
          active
            ? () => {
                handleHitClick(hits[1]?.id);
              }
            : undefined
        }
        variant={hits[1]?.variant}
      />
      <DartHit.Hit
        hit={hits[2]?.text}
        onClick={
          active
            ? () => {
                handleHitClick(hits[2]?.id);
              }
            : undefined
        }
        variant={hits[2]?.variant}
      />
    </DartHit.List>
  );
};

export default CricketPlayerHits;
