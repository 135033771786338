import { Box, Divider, SimpleGrid, Text } from "@mantine/core";
import type { CricketGameConfig, X01GameConfig } from "@pilplay/graphql";
import React from "react";
import {
  scoringRuleToText,
  winConditionToText,
} from "../../../GamePage/components/CricketGamePage/components/CricketHeader/CricketHeader";
import { exitModeToText } from "../../../GamePage/components/X01GamePage/components/X01Header/X01Header";
import {} from "./GameConfig.module.css";

const ConfigRow: React.FC<{
  cols: { label: string; value: string | number }[];
}> = ({ cols }) => {
  return (
    <SimpleGrid cols={cols.length}>
      {cols.map((col) => (
        <Box key={col.label} mb="md">
          <Text c="dimmed" size="xs">
            {col.label}
          </Text>
          <Text fw="bold" size="md">
            {col.value}
          </Text>
        </Box>
      ))}
    </SimpleGrid>
  );
};

const X01GameConfigContainer: React.FC<{ config: X01GameConfig }> = ({
  config,
}) => {
  return (
    <>
      <ConfigRow
        cols={[
          { label: "Exit mode", value: exitModeToText(config.exitMode) },
          { label: "Max rounds", value: config.numRounds },
        ]}
      />
      <ConfigRow
        cols={[
          { label: "Start score", value: config.startScore },
          { label: "Best of", value: config.legs },
        ]}
      />
    </>
  );
};

const CricketGameConfigContainer: React.FC<{ config: CricketGameConfig }> = ({
  config,
}) => {
  return (
    <>
      <ConfigRow
        cols={[
          {
            label: "Scoring rule",
            value: scoringRuleToText(config.scoringRule),
          },
          { label: "Hits to open/close", value: config.hitsToOpenClose },
        ]}
      />
      <ConfigRow
        cols={[
          { label: "Start number", value: config.startNumber },
          { label: "Max rounds", value: config.numRounds },
        ]}
      />
      <ConfigRow
        cols={[
          {
            label: "Game ends",
            value: winConditionToText(config.winCondition),
          },
        ]}
      />
    </>
  );
};

interface GameConfigProps {
  config: X01GameConfig | CricketGameConfig | undefined;
}

const GameConfig: React.FC<GameConfigProps> = ({ config }) => {
  if (!config) {
    return null;
  }
  return (
    <Box>
      <Text fw="bold" mb="md" size="lg">
        Game settings
      </Text>
      <Divider my="md" />
      {config.__typename === "X01GameConfig" && (
        <X01GameConfigContainer config={config} />
      )}
      {config.__typename === "CricketGameConfig" && (
        <CricketGameConfigContainer config={config} />
      )}
    </Box>
  );
};

export default GameConfig;
