import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Text,
  Grid,
  Paper,
  SegmentedControl,
  Image,
  LoadingOverlay,
  Group,
  Card,
  ActionIcon,
  Collapse,
} from "@mantine/core";
import { Button, ImageMagnifier, SectionHeader } from "@pilplay/ui";
import { useParams } from "react-router-dom";
import {
  IconDeviceFloppy,
  IconScanEye,
  IconArrowDownRight,
  IconArrowUpLeft,
} from "@tabler/icons-react";
import { useForceUpdate } from "@mantine/hooks";
import useBoard from "../../../hooks/useBoard";
import ErrorAlert from "../../../components/ErrorAlert";
import { clientRoutes } from "../../../routes";
import classes from "./CalibrateBoardPage.module.css";
import useCameraCalibrationMutation, {
  useAutoCalibration,
  useCalibrationPoints,
} from "./apis";

type CameraID = "camera1" | "camera2" | "camera3";

enum MarkerID {
  ThirtheenSix = 0,
  TwentyFive = 15,
  ThreeSeventeen = 5,
  // SIX_TEN = 1,
  EightEleven = 10,
  // FOURTEEN_ELEVEN = 11,
}

const autoCalibrationIdToMarkerId = (id: string | number) => {
  switch (id.toString()) {
    case "8":
      return MarkerID.EightEleven;
    case "13":
      return MarkerID.ThirtheenSix;
    case "20":
      return MarkerID.TwentyFive;
    case "17":
      return MarkerID.ThreeSeventeen;
    default:
      throw new Error("Invalid id");
  }
};

const DefaultMarkers = {
  [MarkerID.TwentyFive]: { x: "20%", y: "50%" },
  [MarkerID.ThreeSeventeen]: { x: "50%", y: "80%" },
  [MarkerID.ThirtheenSix]: { x: "80%", y: "50%" },
  [MarkerID.EightEleven]: { x: "50%", y: "20%" },
};

const markerToLabel = (marker: MarkerID) => {
  switch (Number(marker)) {
    case MarkerID.TwentyFive:
      return "20/5";
    case MarkerID.ThreeSeventeen:
      return "3/17";
    /*case Marker.SIX_TEN:
      return "6/10";
    case Marker.FOURTEEN_ELEVEN:
      return "14/11";*/
    case MarkerID.ThirtheenSix:
      return "13/6";
    case MarkerID.EightEleven:
      return "8/11";
    default:
      return "?";
  }
};

const Marker: React.FC<{
  label: string;
  position?: { x: number | string; y: number | string };
  selected?: boolean;
  onClick?: () => void;
}> = ({ position, selected, label, onClick }) => {
  return (
    <Box
      onClick={onClick}
      className={classes.marker}
      style={{
        top: position?.y,
        left: position?.x,
        pointerEvents: selected ? "none" : "all",
      }}
      pos={position ? "absolute" : "relative"}
      data-selected={selected}
    >
      <div className={classes.markerDot} />
      <div className={classes.markerLabel}>{label}</div>
    </Box>
  );
};

export const CalibrateBoardPage: React.FC = () => {
  const { slug, id } = useParams<{ slug: string; id: string }>();
  const [multiplier, setMultiplier] = useState<{
    x: number;
    y: number;
  }>({
    x: 1,
    y: 1,
  });
  const [{ data, fetching, error }] = useBoard(id);
  const forceUpdate = useForceUpdate();
  const [selectedCamera, setSelectedCamera] = useState<CameraID>("camera1");
  const [selectedMarker, setSelectedMarker] = useState<MarkerID | null>(null);
  const [showPreview, setShowPreview] = useState(false);
  const [isAutoCalibrating, setIsAutoCalibrating] = useState(false);

  const {
    data: calibrationPoints,
    error: cpError,
    isFetching: cpIsFetching,
    refetch: refetchCalibrationPoints,
  } = useCalibrationPoints(id!, selectedCamera);
  const [timestamp, setTimestamp] = useState<number>(new Date().getTime());

  const {
    mutateAsync: saveCalibration,
    error: calibrationError,
    isPending,
  } = useCameraCalibrationMutation(id!, selectedCamera);

  const [markers, setMarkers] =
    useState<Record<MarkerID, { x: number | string; y: number | string }>>(
      DefaultMarkers
    );

  const changeId = (calibrationPoints?.points ?? []).reduce((acc, point) => {
    return `${acc}${point.point}${point.x}${point.y}`;
  }, "");

  useEffect(() => {
    const newMarkers = (calibrationPoints?.points || []).reduce<
      Record<MarkerID, { x: number | string; y: number | string }>
    >((acc, point) => {
      acc[point.point as unknown as MarkerID] = {
        x: point.x,
        y: point.y,
      };
      return acc;
    }, DefaultMarkers);
    setMarkers(newMarkers);
    setTimeout(() => {
      forceUpdate();
    }, 1);
  }, [calibrationPoints?.points, changeId, forceUpdate]);

  const disabled =
    Object.keys(markers).some(
      (k) =>
        typeof markers[k as unknown as MarkerID].x === "string" ||
        typeof markers[k as unknown as MarkerID].y === "string"
    ) || isPending;

  const url = `/proxy/${id}/api/cameras/${selectedCamera}/image/${timestamp}`;
  const transformedUrl = `/proxy/${id}/api/cameras/${selectedCamera}/image/transformed/${timestamp}`;

  const { mutateAsync: autoCalibrate, error: acError } = useAutoCalibration();

  return (
    <Container size="xl">
      <SectionHeader
        breadcrumbs={[
          { label: "Home" },
          { label: "Organization", link: clientRoutes.organizationHome(slug) },
          { label: "Boards", link: clientRoutes.organizationBoards(slug) },
          {
            label: data?.board.name || "",
            link: clientRoutes.organizationBoard(slug, id),
          },
          { label: "Calibrate", active: true },
        ]}
        title="Calibrate Board"
        subTitle="Calibrate your board to ensure the best possible experience for your users."
      />
      <ErrorAlert error={error || cpError || acError || calibrationError} />
      <Paper mt="34" bg="dark.9" radius="lg" p={0}>
        <Grid pt="lg" px="xl" pb="xl">
          <Grid.Col span={{ base: 12 }} pos="relative">
            <SegmentedControl
              fullWidth
              value={selectedCamera}
              onChange={(v) => {
                setSelectedCamera(v as CameraID);
                setTimestamp(new Date().getTime());
              }}
              size="lg"
              data={[
                { value: "camera1", label: "Camera 1" },
                { value: "camera2", label: "Camera 2" },
                { value: "camera3", label: "Camera 3" },
              ]}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12 }} pos="relative">
            <LoadingOverlay
              visible={fetching || cpIsFetching || isAutoCalibrating}
            />
            <ImageMagnifier
              style={{
                minHeight: 100,
              }}
              showMagnifier={Boolean(selectedMarker)}
              onImageResize={({
                width,
                height,
                naturalHeight,
                naturalWidth,
              }) => {
                const x = naturalWidth / width;
                const y = naturalHeight / height;
                if (isNaN(x) || isNaN(y)) {
                  return;
                }
                setMultiplier({
                  x,
                  y,
                });
              }}
              src={url}
              onClick={(_, position) => {
                if (!selectedMarker) return;
                setMarkers({
                  ...markers,
                  [selectedMarker]: {
                    x: position.x * multiplier.x,
                    y: position.y * multiplier.y,
                  },
                });
                setSelectedMarker(null);
              }}
              zoom={2}
            >
              {Object.entries(markers).map(([key, value]) => (
                <Marker
                  key={key}
                  label={markerToLabel(key as unknown as MarkerID)}
                  position={{
                    x:
                      typeof value.x === "number"
                        ? value.x / multiplier.x
                        : value.x,
                    y:
                      typeof value.y === "number"
                        ? value.y / multiplier.y
                        : value.y,
                  }}
                  onClick={() => {
                    setSelectedMarker(key as unknown as MarkerID);
                  }}
                  selected={(key as unknown as MarkerID) === selectedMarker}
                />
              ))}
            </ImageMagnifier>

            <Card
              w={showPreview ? "25%" : "inherit"}
              pos="absolute"
              style={{
                right: "var(--mantine-spacing-md)",
                bottom: "var(--mantine-spacing-md)",
              }}
            >
              <Card.Section
                onClick={() => {
                  setShowPreview(!showPreview);
                }}
                style={{
                  cursor: "pointer",
                }}
                inheritPadding
                py="md"
              >
                <Group>
                  <ActionIcon variant="subtle" c="white">
                    {showPreview ? <IconArrowDownRight /> : <IconArrowUpLeft />}
                  </ActionIcon>
                  <Text>Preview</Text>
                </Group>
              </Card.Section>
              <Collapse transitionDuration={0} in={showPreview}>
                <Card.Section pt="md" pb="md" inheritPadding>
                  <Image
                    src={transformedUrl}
                    fallbackSrc="https://placehold.co/1080x720?text=Save to load preview"
                  />
                </Card.Section>
              </Collapse>
            </Card>
          </Grid.Col>

          <Grid.Col span={12}>
            <Group grow>
              <Button
                fullWidth
                fw="bold"
                color="teal"
                leftSection={<IconScanEye size={24} />}
                onClick={async () => {
                  try {
                    setIsAutoCalibrating(true);
                    const res = await autoCalibrate(url);
                    const updatedMarkers = Object.entries(res).reduce<
                      Record<
                        MarkerID,
                        { x: number | string; y: number | string }
                      >
                    >(
                      (acc, [key, value]) => {
                        const markerId = autoCalibrationIdToMarkerId(key);
                        const centerOfBoxX = (value.box.x1 + value.box.x2) / 2;
                        const centerOfBoxY = (value.box.y1 + value.box.y2) / 2;

                        return {
                          ...acc,
                          [markerId]: {
                            x: centerOfBoxX,
                            y: centerOfBoxY,
                          },
                        };
                      },
                      { ...markers }
                    );
                    setMarkers(updatedMarkers);
                  } finally {
                    setIsAutoCalibrating(false);
                  }
                }}
              >
                Auto Calibrate
              </Button>
              <Button
                fullWidth
                fw="bold"
                loading={isPending}
                disabled={disabled}
                onClick={async () => {
                  const d = Object.entries(markers).map(([index, point]) => ({
                    point: index,
                    x: Number(point.x),
                    y: Number(point.y),
                  }));
                  const res = await saveCalibration({
                    points: d,
                  });
                  if (res.status) {
                    await refetchCalibrationPoints();
                    setTimestamp(new Date().getTime());
                  }
                }}
                leftSection={<IconDeviceFloppy size={24} />}
              >
                Save
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </Paper>
    </Container>
  );
};
