import React, { useMemo } from "react";
import { Text, Flex, Group } from "@mantine/core";
import { PlayerAvatar } from "@pilplay/ui";
import type { DartPosition, Maybe, X01DartHit } from "@pilplay/graphql";
import { DartPoint, parseMultiplier, scoreToPoints } from "@pilplay/games";
import { useElementSize } from "@mantine/hooks";
import { useSpring, a } from "@react-spring/web";
import { useX01Game } from "../../../../context/useX01Game";
import type { ExtendedX01Round } from "../../../../context/X01ContextProvider";
import PlacementIcon from "../../../../../PlacementIcon";
import { InteractiveDartboardAutoScale } from "../../../../../../../../../components/InteractiveDartboard/InteractiveDartboard";
import X01FloatingActions from "../../../X01FloatingActions";
import classes from "./PlayerScoreboard.module.css";
import { ListStat } from "./ListStat";

export interface PlayerScoreboardProps {
  selectedPlayerIndex: number;
}

const placementText = (placement: number) => {
  if (placement === 0) {
    return "1st";
  }
  if (placement === 1) {
    return "2nd";
  }
  if (placement === 2) {
    return "3rd";
  }
  return `${placement + 1}th`;
};

export const PlayerScoreboard: React.FC<PlayerScoreboardProps> = ({
  selectedPlayerIndex,
}) => {
  const { players, playerRounds } = useX01Game();

  const sortedPlayers = players.sort((i, b) => i.score - b.score);
  const player = sortedPlayers[selectedPlayerIndex];

  const hits: X01DartHit[] = useMemo(() => {
    const allHits: (Maybe<X01DartHit> | undefined)[] = [];
    playerRounds[player.id].forEach((round) => {
      allHits.push(round.hit1);
      allHits.push(round.hit2);
      allHits.push(round.hit3);
    });
    return allHits.filter((hit) => Boolean(hit)) as X01DartHit[];
  }, [playerRounds, player]);

  const positions = useMemo(() => {
    return hits
      .map((hit) => hit.position)
      .filter((position) => Boolean(position)) as DartPosition[];
  }, [hits]);

  const triples = useMemo(() => {
    return hits.filter((hit) => parseMultiplier(hit.point) === 3);
  }, [hits]);

  const doubles = useMemo(() => {
    return hits.filter((hit) => parseMultiplier(hit.point) === 2);
  }, [hits]);

  const singles = useMemo(() => {
    return hits.filter((hit) => parseMultiplier(hit.point) === 1);
  }, [hits]);

  const busts = useMemo(() => {
    return hits.filter((hit) => hit.bust);
  }, [hits]);

  const placement = sortedPlayers
    .sort((i, b) => i.score - b.score)
    .indexOf(player);

  const { ref, height } = useElementSize();
  const { ref: rootRef, height: rootHeight } = useElementSize();

  const animateIn = useSpring({
    config: { mass: 5, tension: 1000, friction: 200 },
    opacity: 1,
    x: 0,
    delay: 800,
    from: { opacity: 0, x: 400 },
  });

  return (
    <a.div style={animateIn} className={classes.root} ref={rootRef}>
      <Flex direction="column" pos="relative">
        <div ref={ref}>
          <Flex align="center" justify="space-between">
            <Group>
              <PlayerAvatar url={player.avatarUrl} size="xl" />
              <Text size="xl" fw="bold">
                {player.name}
              </Text>
            </Group>
            <Group>
              <Text size="xl">{placementText(placement)}</Text>
              <PlacementIcon placementIndex={placement} />
            </Group>
          </Flex>
          <ListStat title="Score" value={player.score} />
          <ListStat title="Darts thrown" value={hits.length} />
          <ListStat
            title="Average points per round"
            value={averagePointPerRound(playerRounds[player.id])}
          />
          <ListStat title="Singles" value={singles.length} />
          <ListStat title="Doubles" value={doubles.length} />
          <ListStat title="Triples" value={triples.length} />
          <ListStat title="Busts" value={busts.length} />
          <X01FloatingActions />
        </div>
        <div>
          <InteractiveDartboardAutoScale
            hits={positions}
            height={rootHeight - height}
          />
        </div>
      </Flex>
    </a.div>
  );
};

const averagePointPerRound = (playerRounds: ExtendedX01Round[]) => {
  let totalPoints = 0;
  playerRounds.forEach((round) => {
    totalPoints += scoreToPoints(round.hit1?.point || DartPoint.MISS);
    totalPoints += scoreToPoints(round.hit2?.point || DartPoint.MISS);
    totalPoints += scoreToPoints(round.hit3?.point || DartPoint.MISS);
  });
  return totalPoints / playerRounds.length;
};
