import type { BoxComponentProps } from "@mantine/core";
import { Box } from "@mantine/core";
import React from "react";
import classes from "./FloatingCorners.module.css";

interface FloatingCornersProps extends BoxComponentProps {
  children?: React.ReactNode;
  topLeft?: React.ReactNode;
  topRight?: React.ReactNode;
  bottomLeft?: React.ReactNode;
  bottomRight?: React.ReactNode;
  cornderPadding?: number;
}

export const FloatingCorners: React.FC<FloatingCornersProps> = ({
  children,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  cornderPadding = 28,
  ...boxProps
}) => {
  return (
    <Box className={children ? classes.container : undefined} {...boxProps}>
      {topLeft && (
        <Box className={classes.topLeft} p={cornderPadding}>
          {topLeft}
        </Box>
      )}
      {topRight && (
        <Box className={classes.topRight} p={cornderPadding}>
          {topRight}
        </Box>
      )}
      {children}
      {bottomLeft && (
        <Box className={classes.bottomLeft} p={cornderPadding}>
          {bottomLeft}
        </Box>
      )}
      {bottomRight && (
        <Box className={classes.bottomRight} p={cornderPadding}>
          {bottomRight}
        </Box>
      )}
    </Box>
  );
};
