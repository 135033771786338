import React from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { ColumnDef, RowData, TableOptions } from "@tanstack/react-table";
import {
  Text,
  Card,
  Table as MantineTable,
  ScrollArea,
  Group,
  Box,
  Progress,
} from "@mantine/core";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import { Button } from "../../buttons/Button";
import classes from "./Table.module.css";

export interface TableProps<T>
  extends Omit<TableOptions<T>, "data" | "columns" | "getCoreRowModel"> {
  title: string;
  data: T[];
  columns: ColumnDef<T>[];
  fetching?: boolean;
  pagination?: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    nextPage: () => void;
    previousPage: () => void;
    totalCount: number;
  };
}

export const Table = <T extends RowData>({
  title,
  data,
  columns,
  fetching,
  pagination,
  ...otherProps
}: TableProps<T>): React.ReactNode => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    ...otherProps,
  });

  return (
    <Card bg="dark.9" withBorder px="0" radius="lg" pb="0">
      <Card.Section inheritPadding py="md" px="xl">
        <Text fw={600}>{title}</Text>
      </Card.Section>
      {fetching && <Progress value={100} animated radius={0} size="sm" />}
      <ScrollArea>
        <MantineTable className={classes.root}>
          <MantineTable.Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <MantineTable.Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <MantineTable.Th key={header.id}>
                    <Text fz="10pt">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Text>
                  </MantineTable.Th>
                ))}
              </MantineTable.Tr>
            ))}
          </MantineTable.Thead>
          <MantineTable.Tbody>
            {table.getRowModel().rows.map((row) => (
              <MantineTable.Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </MantineTable.Tr>
            ))}
          </MantineTable.Tbody>
        </MantineTable>
        {data.length === 0 && (
          <Box bg="dark.8" p="lg">
            <Text c="dimmed" ta="center">
              No data to display
            </Text>
          </Box>
        )}
        {pagination && (
          <Group bg="dark.8" justify="space-between" py="md">
            <Button
              px="sm"
              ml="sm"
              size="sm"
              color="dark.8"
              onClick={() => {
                pagination.previousPage();
              }}
              disabled={!pagination.hasPreviousPage}
            >
              <IconArrowLeft size={16} />
            </Button>
            <Box>
              <Text c="dimmed" size="sm">
                {pagination.totalCount} total
              </Text>
            </Box>
            <Button
              px="sm"
              mr="sm"
              color="dark.8"
              size="sm"
              onClick={() => {
                pagination.nextPage();
              }}
              disabled={!pagination.hasNextPage}
            >
              <IconArrowRight size={16} />
            </Button>
          </Group>
        )}
      </ScrollArea>
    </Card>
  );
};
