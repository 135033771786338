import { ActionIcon, Code, Group } from "@mantine/core";
import { LobbyCodeStatus } from "@pilplay/graphql";
import { IconEye } from "@tabler/icons-react";
import React, { useState } from "react";

export interface LobbyCodeDisplayProps {
  code: string;
  status: LobbyCodeStatus;
}

export const LobbyCodeDisplay: React.FC<LobbyCodeDisplayProps> = ({
  code,
  status,
}) => {
  const [show, setShow] = useState(false);

  if (!show) {
    return (
      <Group gap={4}>
        <Code
          onClick={() => {
            setShow(true);
          }}
        >
          ******
        </Code>
        <ActionIcon
          onClick={() => {
            setShow(true);
          }}
          aria-label="Show code"
          title="Show code"
          size="sm"
          variant="transparent"
        >
          <IconEye size={14} />
        </ActionIcon>
      </Group>
    );
  }

  return (
    <Code
      style={{
        textDecoration:
          status === LobbyCodeStatus.Created ? "none" : "line-through",
      }}
    >
      {code}
    </Code>
  );
};
