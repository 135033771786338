import React from "react";
import { useNavigate } from "react-router-dom";
import { Affix, AffixBaseProps, Button } from "@mantine/core";
import classes from "./BackToAffix.module.css";

export interface BackToAffixProps {
  to?: string;
  onClick?: () => void;
  text: string;
  position?: AffixBaseProps["position"];
}

export const BackToAffix: React.FC<BackToAffixProps> = ({
  to,
  text,
  onClick,
  position,
}) => {
  const navigate = useNavigate();
  return (
    <Affix
      onClick={() => {
        if (to) {
          if (to.startsWith("http") || to.startsWith("https")) {
            window.location.href = to;
          } else {
            navigate(to);
          }
        } else if (onClick) {
          onClick();
        }
      }}
      position={position || { top: 0, left: 0 }}
      classNames={{
        root: classes.affix,
      }}
    >
      <Button color="dark" variant="subtle">
        ‹ {text}
      </Button>
    </Affix>
  );
};
