import {} from "./placement-icon.module.css";

import {
  IconMedal,
  IconRosetteNumber4,
  IconRosetteNumber5,
  IconRosetteNumber6,
  IconTrophyFilled,
  IconTrophy,
} from "@tabler/icons-react";

interface PlacementIconProps {
  placementIndex: number;
  size?: number | string;
  style?: React.CSSProperties;
  legWinnerOnly?: boolean;
}

const PlacementIcon: React.FC<PlacementIconProps> = ({
  placementIndex,
  size,
  style,
  legWinnerOnly,
}) => {
  if (legWinnerOnly) {
    return placementIndex === 0 ? (
      <IconMedal color="#D4AF37" size={size ? size : 64} style={style} />
    ) : null;
  }
  switch (placementIndex) {
    case 0:
      return (
        <IconTrophyFilled
          color="#D4AF37"
          size={size ? size : 64}
          style={style}
        />
      );

    case 1:
      return (
        <IconTrophy color="#C0C0C0" size={size ? size : 42} style={style} />
      );

    case 2:
      return (
        <IconTrophy color="#CD7F32" size={size ? size : 42} style={style} />
      );

    case 3:
      return (
        <IconRosetteNumber4
          color="#545454"
          size={size ? size : 42}
          style={style}
        />
      );

    case 4:
      return (
        <IconRosetteNumber5
          color="#545454"
          size={size ? size : 42}
          style={style}
        />
      );

    case 5:
      return (
        <IconRosetteNumber6
          color="#545454"
          size={size ? size : 42}
          style={style}
        />
      );

    default:
      return null;
  }
};

export default PlacementIcon;
