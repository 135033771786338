import type { MantineSize } from "@mantine/core";
import { Badge, Text } from "@mantine/core";
import React from "react";
import {} from "./RoundCounter.module.css";

interface RoundCounterProps {
  round: number;
  totalRounds?: number;
  hideLabel?: boolean;
  size?: MantineSize;
}

export const RoundCounter: React.FC<RoundCounterProps> = ({
  hideLabel,
  round,
  totalRounds,
  size = "lg",
}) => {
  return (
    <Badge size={size} p={"lg"} color={"#1a1b1e"}>
      {!hideLabel && <Text component="span">{"Round "}</Text>}
      <Text component="span" fw={900}>
        {round}
        {totalRounds ? ` / ${totalRounds}` : null}
      </Text>
    </Badge>
  );
};
