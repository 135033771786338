import { createContext } from "react";

export interface AuthAttributes {
  accessToken: string;
  refreshToken: string;
}

export interface AuthContextData {
  isSignedIn: boolean;
  isLoading: boolean;
  authAttributes?: AuthAttributes;
  hasAttemptedVerification: boolean;
  setLogin: (authAttributes: AuthAttributes) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextData>({
  isSignedIn: false,
  isLoading: true,
  hasAttemptedVerification: false,
  setLogin: () => { console.warn("No AuthAttributes provided"); },
  logout: () => { console.warn("No logout function provided"); },
});
export default AuthContext;
