import React, { useState } from "react";
import { Group, Tooltip, ActionIcon, Menu, Text, Anchor } from "@mantine/core";
import { IconDots, IconKey, IconExternalLink } from "@tabler/icons-react";
import { clientRoutes } from "../../../../../routes";
import { GenerateSignInCodeModal } from "../GenerateSignInCodeModal";

interface BoardActionsProps {
  boardId: string;
}

const BoardActions: React.FC<BoardActionsProps> = ({ boardId }) => {
  const [showSignInCodeModal, setShowSignInCodeModal] = useState(false);

  return (
    <Group>
      <GenerateSignInCodeModal
        boardId={boardId}
        open={showSignInCodeModal}
        onClose={() => {
          setShowSignInCodeModal(false);
        }}
      />
      <Tooltip
        label="Generate sign in code"
        onClick={() => {
          setShowSignInCodeModal(true);
        }}
      >
        <ActionIcon variant="transparent" bg="dark.8" size="lg" c="white">
          <IconKey size={16} />
        </ActionIcon>
      </Tooltip>

      <Menu position="left" shadow="sm" withinPortal>
        <Menu.Target>
          <ActionIcon variant="transparent" bg="dark.8" size="lg" c="white">
            <IconDots size={16} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label>Debug</Menu.Label>
          <Menu.Divider />
          <Anchor target="_blank" href={clientRoutes.boardStartPage(boardId)}>
            <Menu.Item rightSection={<IconExternalLink size={16} />}>
              <Text fz="xs">Open controller</Text>
            </Menu.Item>
          </Anchor>
          <Anchor target="_blank" href={clientRoutes.boardFollowPage(boardId)}>
            <Menu.Item rightSection={<IconExternalLink size={16} />}>
              <Text fz="xs">Open screen display</Text>
            </Menu.Item>
          </Anchor>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
};

export default BoardActions;
