import type { ButtonProps } from "@mantine/core";
import { Link } from "react-router-dom";
import { Button } from "../Button";

interface ButtonLinkProps extends ButtonProps {
  link?: string;
  target?: string;
  onClick?: () => void;
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({ link, ...props }) => {
  if (link) {
    return <Button {...props} component={Link} to={link} />;
  }
  return <Button {...props} />;
};
