import { Flex, Group, Skeleton, Text } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import cx from "clsx";
import React from "react";
import { PlayerAvatar } from "../PlayerAvatar";
import classes from "./lobby-slot.module.css";

export interface LobbySlotProps {
  player?: {
    displayName?: string;
    avatarUrl?: string;
  };
  loading?: boolean;
  onClick?: () => void;
  leftSection?: React.ReactNode;
}

export const LobbySlot: React.FC<LobbySlotProps> = ({
  player,
  loading = false,
  onClick,
  leftSection,
}) => {
  return (
    <div
      onClick={onClick}
      className={cx(classes.root, {
        [classes.empty]: !player,
        [classes.loading]: loading,
      })}
    >
      <Group>
        <Flex miw={32} justify="center" align="center">
          {leftSection}
        </Flex>
        <Skeleton radius={100} width={38} height={38} visible={loading}>
          <PlayerAvatar size="md" src={player?.avatarUrl} useOutlineIcon />
        </Skeleton>
        {player ? (
          <Text fw="bold">{player.displayName || "No name"}</Text>
        ) : (
          <Skeleton height={24} width={220} visible={loading}>
            <Group className={classes.emptyState} gap={4}>
              <IconPlus size={20} />
              <Text className={classes.addPlayerText}>Add player</Text>
            </Group>
          </Skeleton>
        )}
      </Group>
    </div>
  );
};
