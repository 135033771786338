import React, { useCallback, useEffect, useState } from "react";
import { Text, PinInput, Stack, Flex, Collapse } from "@mantine/core";
import { Button } from "@pilplay/ui";
import { useSignInWithBoard } from "../../../../../hooks/useSignInWithBoard/useSignInWithBoard";
import { TOKEN_KEY, setLocalStorage } from "../../../../../utils/storage";
import { useAuthContext } from "../../../../../contexts/AuthContext";
import classes from "./SignInBoardCode.module.css";

const CODE_LENGTH = 6;

interface SignInBoardCodeProps {
  fetching?: boolean;
}

export const SignInBoardCode: React.FC<SignInBoardCodeProps> = ({
  fetching = false,
}) => {
  const [code, setCode] = useState("");

  const [{ data, error, fetching: fetchingBoard }, signIn] =
    useSignInWithBoard();
  const { setLogin } = useAuthContext();

  const handleSubmit = useCallback((): void => {
    void signIn({
      code,
    });
    setCode("");
  }, [code, signIn]);

  useEffect(() => {
    if (code.length === CODE_LENGTH) {
      handleSubmit();
    }
  }, [code, handleSubmit]);

  useEffect(() => {
    if (data?.signInWithBoard.accessToken) {
      setLocalStorage(TOKEN_KEY, data.signInWithBoard);
      setLogin(data.signInWithBoard);
    }
  }, [data, setLogin]);

  const errorMessage =
    error?.graphQLErrors.map((e) => e.message).join(",") ||
    error?.message ||
    (error && "Internal error, please try again later.");

  return (
    <div className={classes.root}>
      <Flex maw={320}>
        <Stack>
          <PinInput
            value={code}
            type="number"
            onChange={(v) => {
              setCode(v);
            }}
            size="md"
            length={CODE_LENGTH}
          />
          <Collapse in={Boolean(errorMessage)}>
            <Text c="red.7" fz="xs">
              {errorMessage}
            </Text>
          </Collapse>
          <Text fz="xs" c="dimmed">
            Enter the 6-digit code from the board to sign in. You can generate a
            new code from the board settings. Ask your administator for help.
          </Text>
          <Button
            variant="secondary"
            fullWidth
            disabled={fetchingBoard || fetching}
            loading={fetchingBoard || fetching}
            onClick={() => {
              handleSubmit();
            }}
          >
            Continue with board code
          </Button>
        </Stack>
      </Flex>
    </div>
  );
};
