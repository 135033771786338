import {
  Anchor,
  Center,
  Collapse,
  Divider,
  Group,
  Stack,
  Text,
} from "@mantine/core";
import { Button, Logo } from "@pilplay/ui";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../../contexts/AuthContext";
import { clientRoutes } from "../../../routes";
import { BackToAffix } from "../../../components/BackToAffix";
import { useMe, isBoardUser } from "../../../hooks/useMe";
import { SignInEmail } from "./components/SignInEmail";
import { SignInBoardCode } from "./components/SignInBoardCode";

enum LoginState {
  Initial = "Initial",
  Email = "Email",
  BoardCode = "BoardCode",
}

const getTitle = (loginState: LoginState) => {
  switch (loginState) {
    case LoginState.Email:
      return "Enter your email and password";
    case LoginState.BoardCode:
      return "Enter your board code";
    default:
      return "Log in to Pilplay";
  }
};

const LoginPage: React.FC = () => {
  const [loginState, setLoginState] = useState(LoginState.Initial);
  const { redirect } = useParams<{ redirect: string | undefined }>();
  const [{ data, fetching }] = useMe();
  const navigate = useNavigate();
  const { isSignedIn } = useAuthContext();

  // If user is already signed in, redirect to home page
  useEffect(() => {
    if (isSignedIn && data) {
      if (redirect && redirect.length > 1) {
        navigate(redirect);
      } else if (isBoardUser(data)) {
        navigate(clientRoutes.lobbyIdlePage(data.me.board.id));
      } else {
        navigate(clientRoutes.root);
      }
    }
  }, [isSignedIn, navigate, redirect, data]);

  const title = getTitle(loginState);

  return (
    <>
      <BackToAffix to="https://pilplay.com" text="Back to Pilplay" />
      <Center h="100vh">
        <Stack miw={320}>
          <Stack mb="md">
            <Logo width={96} />
            <Text ta="center" fz="lg">
              {title}
            </Text>
          </Stack>
          <Stack>
            <Collapse in={loginState === LoginState.Email}>
              <SignInEmail fetching={fetching} />
            </Collapse>
            {loginState !== LoginState.Email && (
              <Button
                onClick={() => {
                  setLoginState(LoginState.Email);
                }}
                fullWidth
              >
                Continue with email
              </Button>
            )}
            <Collapse in={loginState === LoginState.BoardCode}>
              <Divider my="md" />
              <SignInBoardCode fetching={fetching} />
            </Collapse>
            {loginState !== LoginState.BoardCode && (
              <Button
                fullWidth
                variant="secondary"
                onClick={() => {
                  setLoginState(LoginState.BoardCode);
                }}
              >
                Continue with board code
              </Button>
            )}
            <Group justify="center" mt="sm">
              <Anchor
                component={Link}
                size="xs"
                to={clientRoutes.signUp}
                type="a"
              >
                Don&apos;t have an account? Register
              </Anchor>
            </Group>
          </Stack>
        </Stack>
      </Center>
    </>
  );
};

export default LoginPage;
