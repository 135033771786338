import { ActionIcon } from "@mantine/core";
import React from "react";
import { ButtonLink } from "../ButtonLink";
import { ExpandingButton } from "../ExpandingButton";
import {} from "./ActionButton.module.css";

interface ActionButtonProps {
  isMobile?: boolean;
  icon: React.ReactNode;
  buttonIconPosition?: "left" | "right";
  label: string;
  onClick?: () => void;
  link?: string;
  disabled?: boolean;
  variant?: "filled" | "outline";
  expanding?: boolean;
  size?: "sm" | "md" | "lg" | "xl";
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  isMobile,
  icon,
  label,
  onClick,
  link,
  disabled,
  buttonIconPosition = "left",
  variant = "filled",
  expanding,
}) => {
  const isRightIcon = buttonIconPosition === "right";
  if (expanding) {
    return (
      <ExpandingButton
        buttonIconPosition={buttonIconPosition}
        disabled={disabled}
        expanding={!disabled}
        icon={icon}
        label={label}
        link={link}
        onClick={onClick}
        variant={variant}
      />
    );
  }
  if (!isMobile) {
    return (
      <ButtonLink
        disabled={disabled}
        fullWidth
        leftSection={!isRightIcon && icon}
        link={link}
        onClick={onClick}
        radius="xl"
        rightSection={isRightIcon ? icon : null}
        size="xl"
        fw="bold"
        variant={variant}
      >
        {label}
      </ButtonLink>
    );
  }
  return (
    <ActionIcon
      color="orange"
      disabled={disabled}
      onClick={onClick}
      radius="xl"
      size="xl"
      variant={variant}
    >
      {icon}
    </ActionIcon>
  );
};
