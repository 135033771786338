import {
  Alert,
  Button,
  Collapse,
  Divider,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  EXIT_MODE_CONFIG,
  LEG_ORDER_CONFIG,
  MAX_LEGS_CONFIG,
  MAX_ROUNDS_CONFIG,
  SCORE_CONFIG,
} from "@pilplay/games";
import type { CreateGameInput, X01GameConfigInput } from "@pilplay/graphql";
import { LegOrderType, X01ExitMode } from "@pilplay/graphql";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SegmentConfig from "../../../../components/SegmentConfig/SegmentConfig";
import SwitchConfig from "../../../../components/SwitchConfig/SwitchConfig";
import { useBoards } from "../../../../hooks/useBoards/useBoards";
import { useMe } from "../../../../hooks/useMe";
import { useNewGame } from "../../../../hooks/useNewGame/useNewGame";
import { clientRoutes } from "../../../../routes";
import ConfigPageContainer from "../ConfigPageContainer";

const Three01Defaults = {
  startScore: 301,
  exitMode: X01ExitMode.Double,
  legOrderType: LegOrderType.Middle,
  numRounds: 50,
  legs: 1,
};

const Five01Defaults = {
  startScore: 501,
  exitMode: X01ExitMode.Double,
  legOrderType: LegOrderType.Middle,
  numRounds: 50,
  legs: 1,
};

const Three01DomDDefaults = {
  startScore: 301,
  exitMode: X01ExitMode.Domd,
  legOrderType: LegOrderType.Middle,
  numRounds: 50,
  legs: 1,
};

const getDefaultConfig = (key: string) => {
  switch (key) {
    case "301":
      return Three01Defaults;
    case "501":
      return Five01Defaults;
    case "domd":
      return Three01DomDDefaults;
    default:
      return Three01Defaults;
  }
};

const X01ConfigPage: React.FC = () => {
  const { boardId, config } = useParams<{
    boardId: string | undefined;
    config: string | undefined;
  }>();
  const [{ data: user }] = useMe();
  const navigate = useNavigate();
  const [{ fetching, error }, createGame] = useNewGame();

  const [{ data: boards, fetching: boardsFetching, error: errorBoards }] =
    useBoards();

  const form = useForm<
    CreateGameInput & {
      config: { x01: X01GameConfigInput };
    }
  >({
    validateInputOnChange: true,
    initialValues: {
      name: user?.me.displayName
        ? `${user.me.displayName}'s game` // Maybe add name from config?
        : `Untitled game`,
      config: {
        x01: {
          ...getDefaultConfig(config || "301"),
        },
      },
      boardId,
      private: false,
    },
    validate: {
      name: (value) => value.trim().length <= 2 && "Game name is too short",
    },
    transformValues: (values) => ({
      ...values,
      config: {
        ...values.config,
        x01: {
          ...values.config.x01,
          startScore: parseInt(values.config.x01.startScore.toString()),
          numRounds: parseInt(values.config.x01.numRounds.toString()),
          legs: parseInt(values.config.x01.legs.toString()),
        },
      },
    }),
  });

  useEffect(() => {
    if (user?.me.displayName) {
      form.setFieldValue("name", `${user.me.displayName}'s game`);
    }
  }, [user?.me]);

  const handleSubmit = async (values: CreateGameInput) => {
    const res = await createGame({
      input: values,
    });
    if (res.data) {
      navigate(clientRoutes.gameLobby(res.data.createGame.id));
    }
  };

  return (
    <ConfigPageContainer
      gameModeDescription={
        <>
          X01 is a popular dart game where players aim to reach zero points from
          a starting score, typically 301 or 501. Players take turns throwing
          darts at a dartboard, subtracting their scores from the starting
          number.
          <br />
          <br />
          The objective is to reach exactly zero by strategically aiming for
          high-scoring areas, such as the triple or double rings. The game can
          be played with various rules.
          <br />
          <br />
          If a player exceeds zero points by going below the required score,
          their score for that round is "busted," and they return to their
          previous score. For example, if a player has 32 points remaining and
          scores a 35, they would revert back to 32 instead of busting the
          round.
          <br />
          <br />
          X01 is both competitive and a great way to improve dart skills.
        </>
      }
      gameModeLabel="X01 Game mode"
    >
      <form
        onSubmit={form.onSubmit((values) => {
          handleSubmit(values);
        })}
      >
        <TextInput
          size="lg"
          {...form.getInputProps("name")}
          label="Game name"
        />
        <Divider label="Game settings" labelPosition="center" my="md" />
        {/* Starting score */}
        <SegmentConfig
          data={SCORE_CONFIG}
          description="The score to reach to win the game"
          title="Starting score"
          {...form.getInputProps("config.x01.startScore")}
          value={form.values.config.x01.startScore.toString()}
        />
        <SegmentConfig
          data={LEG_ORDER_CONFIG}
          description="How to determine the player order"
          title="Player order"
          {...form.getInputProps("config.x01.legOrderType")}
          tooltip={
            <Text>
              <Text component="span" fw="bold">
                Middle
              </Text>{" "}
              The player closets to the middle of the board starts
              <br />
              <br />
              <Text component="span" fw="bold">
                Random
              </Text>{" "}
              The player order is randomized
              <br />
              <br />
              <Text component="span" fw="bold">
                Static
              </Text>{" "}
              The player order is static
            </Text>
          }
          value={form.values.config.x01.legOrderType.toString()}
        />
        <SegmentConfig
          data={EXIT_MODE_CONFIG}
          description="The way to exit the game"
          title="Exit mode"
          {...form.getInputProps("config.x01.exitMode")}
          tooltip={
            <Text>
              <Text component="span" fw="bold">
                Single
              </Text>{" "}
              The player must hit a single field to exit
              <br />
              <br />
              <Text component="span" fw="bold">
                Double
              </Text>{" "}
              The player must hit a double field to exit
              <br />
              <br />
              <Text component="span" fw="bold">
                DömD
              </Text>{" "}
              First 5 rounds below 50 points must be finished with a double,
              after you can finish with a single
            </Text>
          }
        />
        <SegmentConfig
          data={MAX_ROUNDS_CONFIG}
          description="The maximum number of rounds to play"
          title="Max rounds"
          {...form.getInputProps("config.x01.numRounds")}
          value={form.values.config.x01.numRounds.toString()}
        />
        <Divider my="md" />
        <SegmentConfig
          data={MAX_LEGS_CONFIG}
          description="Amount of legs to win"
          title="Legs"
          {...form.getInputProps("config.x01.legs")}
          value={form.values.config.x01.legs.toString()}
        />
        <Divider my="md" />

        <SwitchConfig
          checked={form.values.private}
          description="Whether the game is public or private"
          mt="md"
          offLabel="Public"
          onChange={(value) => {
            form.setFieldValue("private", value.currentTarget.checked);
          }}
          onLabel="Private"
          size="xl"
          title="Lobby mode"
        />
        <Divider my="md" />
        <Collapse in={(boards?.boards ?? []).length > 0}>
          <Select
            clearable
            data={(boards?.boards ?? []).map((board) => ({
              value: board.id,
              label: board.name,
            }))}
            description="Connected Pilplay Auto Board to use for this game"
            disabled={boardsFetching}
            error={errorBoards?.graphQLErrors[0]?.message}
            label="Pilplay Auto Board"
            {...form.getInputProps("boardId")}
          />
        </Collapse>
        <Collapse in={Boolean(error)}>
          {error ? (
            <Alert color="red" mt="md">
              {error.graphQLErrors.map((error) => error.message).join(", ")}
            </Alert>
          ) : null}
        </Collapse>
        <Button
          disabled={!form.isValid() || fetching}
          fullWidth
          loading={fetching}
          mt="md"
          size="lg"
          type="submit"
        >
          Create lobby
        </Button>
      </form>
    </ConfigPageContainer>
  );
};

export default X01ConfigPage;
