import type { DartHit } from "@pilplay/games";
import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@mantine/core";
import { useIsTabletStanding } from "../../../../../../../hooks/layout/useDevice";
import { EditHitModal } from "../../../EditHit/EditHit";
import { useX01Game } from "../../context/useX01Game";
import DesktopX01Game from "../DesktopX01Game";
import MobileX01Game from "../MobileX01Game";
import X01History from "../X01History";
import { useGameContext } from "../../../../context/useGameContext";

const X01Game: React.FC = () => {
  const {
    finished,
    activeRoundHits,
    activePlayer,
    middlingPhase,
    middlingThrows,
  } = useX01Game();
  const { editId, showControls, setEditId } = useGameContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showHistory, setShowHistory] = useState(false);
  const isTabletStanding = useIsTabletStanding();

  const onDartHitClick = (hit?: DartHit | null) => {
    if (hit) {
      setEditId(hit.id);
    }
  };

  const onHistoryClick = () => {
    setShowHistory(true);
  };

  const inputDisabled = useMemo(() => {
    // We need a active player to play
    if (!activePlayer) return true;
    // If game is finished, disable input
    if (finished) return true;
    // If any hit is bust, disable input
    if (
      [
        activeRoundHits?.hit1,
        activeRoundHits?.hit2,
        activeRoundHits?.hit3,
      ].some((h) => Boolean(h) && h?.bust)
    )
      return true;
    // If all hits are done, disable input
    if (activeRoundHits?.hit1 && activeRoundHits.hit2 && activeRoundHits.hit3)
      return true;
    // If middling phase is active, and active player has thrown, disable input
    if (
      middlingPhase &&
      middlingThrows.some((t) => t.playerId === activePlayer.id)
    )
      return true;

    return false;
  }, [
    activePlayer,
    finished,
    activeRoundHits?.hit1,
    activeRoundHits?.hit2,
    activeRoundHits?.hit3,
    middlingPhase,
    middlingThrows,
  ]);

  return (
    <Box
      style={{
        backgroundColor: "var(--mantine-color-dark-9)",
      }}
    >
      {isTabletStanding ? (
        <MobileX01Game
          actions={{
            onHistoryClick,
            onDartHitClick,
          }}
          inputDisabled={inputDisabled}
        />
      ) : null}
      {!isTabletStanding && (
        <DesktopX01Game
          actions={{
            onHistoryClick,
          }}
          inputDisabled={inputDisabled}
        />
      )}
      <X01History
        isMobile={isTabletStanding || false}
        onClose={() => {
          setShowHistory(false);
        }}
        onHitClick={(hit) => {
          if (hit) {
            setShowHistory(false);
            setEditId(hit.id);
          }
        }}
        open={showHistory}
      />
      <EditHitModal
        editId={editId}
        onClose={() => {
          setEditId(undefined);
        }}
        show={showControls}
      />
    </Box>
  );
};
export default X01Game;
