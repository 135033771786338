import { ActionIcon, Box, Text, useMantineTheme } from "@mantine/core";
import { LobbyStatus } from "@pilplay/graphql";
import { PlayerAvatarGroup } from "@pilplay/ui";
import { IconArrowBack, IconSettings } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLobby } from "../../hooks/useLobby/useLobby";
import { clientRoutes } from "../../routes";
import GameLobbyOptionsModal from "../GameLobbyOptionsModal";
import { CountdownTimer } from "../CountdownTimer";
import classes from "./game-lobby-header.module.css";

interface GameLobbyHeaderProps {
  title: string;
  lobbyId: string;
  backLink?: string;
  backText?: string;
  players: {
    displayName: string;
    avatarUrl?: string | null;
  }[];
}

const GameLobbyHeader: React.FC<GameLobbyHeaderProps> = ({
  title,
  players,
  backLink,
  backText,
  lobbyId,
}) => {
  const [{ data }, refetch] = useLobby(lobbyId);
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    if (data?.lobby?.status === LobbyStatus.Closed) {
      if (data?.lobby?.board?.id) {
        navigate(clientRoutes.lobbyIdlePage(data?.lobby.board?.id), {
          replace: true,
        });
      } else {
        navigate(clientRoutes.root, { replace: true });
      }
    }
  }, [data, navigate]);

  // If the lobby expires, refetch the lobby data and it will redirect if the lobby is closed
  const onExpire = () => {
    refetch();
  };

  return (
    <div className={classes.root}>
      <GameLobbyOptionsModal
        onClose={() => {
          setShowOptions(false);
        }}
        lobbyId={lobbyId}
        opened={showOptions}
      />

      <div className={classes.backButton}>
        {backLink ? (
          <Link className={classes.link} to={backLink}>
            <IconArrowBack className={classes.icon} />
            <Text mr="md" component="span">
              {backText}
            </Text>
          </Link>
        ) : null}
        {data?.lobby?.expiresAt !== null &&
          data?.lobby?.expiresAt !== undefined && (
            <Box>
              <CountdownTimer
                expiresAt={data.lobby.expiresAt}
                onExpire={onExpire}
              />
            </Box>
          )}
      </div>

      <div className={classes.title}>{title}</div>
      <div className={classes.lobbyList}>
        <PlayerAvatarGroup
          players={players.map((p) => ({
            key: p.displayName,
            url: p.avatarUrl,
            tooltip: p.displayName,
          }))}
          mx="md"
        />
        <ActionIcon
          onClick={() => {
            setShowOptions(true);
          }}
          variant="subtle"
          color="gray"
        >
          <IconSettings size={32} color={theme.colors.dark[4]} />
        </ActionIcon>
      </div>
    </div>
  );
};

export default GameLobbyHeader;
