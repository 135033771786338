import { Box, Center, Flex, Stack, Text } from "@mantine/core";
import { Button } from "@pilplay/ui";
import { useState } from "react";
import { IconPlugConnected } from "@tabler/icons-react";
import useBluetooth from "../../../../../../hooks/useBluetooth/useBluetooth";
import ErrorAlert from "../../../../../../components/ErrorAlert";

export interface BluetoothDeviceScreenProps {
  onConnect: (device: BluetoothDevice) => void;
}

export const BluetoothDevicesScreen: React.FC<BluetoothDeviceScreenProps> = ({
  onConnect,
}) => {
  const { requestDevice } = useBluetooth();
  const [error, setError] = useState<Error | null>(null);

  const refreshDevices = () => {
    setError(null);
    requestDevice()
      .then((data) => {
        if (data) {
          onConnect(data);
        }
      })
      .catch((err: Error) => {
        // eslint-disable-next-line no-console -- for easier debugging
        console.error("Failed to get devices", err);
        setError(err);
      });
  };
  return (
    <Flex h="100%" direction="column">
      <Stack>
        <Center>
          <IconPlugConnected size="64" />
        </Center>
        <Text ta="center" mt="md">
          Connect to your board
        </Text>
        <Box px="xl">
          <Text ta="center" fz="sm" mt="sm" c="dimmed">
            Let us connect to your board. Click on the button below and select
            your board from the list of bluetooth devices.
          </Text>
        </Box>
      </Stack>
      <Flex direction="column" flex="auto" px="xl" mt="xl" justify="flex-end">
        <ErrorAlert error={error} />
        <Button fullWidth mt="md" onClick={refreshDevices}>
          Connect
        </Button>
      </Flex>
    </Flex>
  );
};
