import React, { useMemo, useState } from "react";
import type { BoardsQuery } from "@pilplay/graphql";
import { Container, Text, Flex, Code, Box, Anchor } from "@mantine/core";
import { ConnectionStatusBadge, SectionHeader, Table } from "@pilplay/ui";
import type { ColumnDef } from "@tanstack/react-table";
import { createColumnHelper } from "@tanstack/react-table";
import { Link, useParams } from "react-router-dom";
import { useBoards } from "../../../hooks/useBoards/useBoards";
import ErrorAlert from "../../../components/ErrorAlert";
import { OrganizationTabs } from "../OrganizationTabs";
import { clientRoutes } from "../../../routes";
import BoardActions from "./components/BoardActions";
import { ConfigureBoardModal } from "./components/ConfigureBoardModal";
import AddBoardModal from "./components/AddBoardModal";

type Board = BoardsQuery["boards"][0];
const columnHelper = createColumnHelper<Board>();

const BoardsPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [{ data, error }] = useBoards();

  const columns: ColumnDef<Board>[] = useMemo(() => {
    return [
      columnHelper.accessor("name", {
        header: "Name",
        // eslint-disable-next-line react/no-unstable-nested-components -- used in useMemo
        cell: (info) => (
          <Anchor
            component={Link}
            to={clientRoutes.organizationBoard(slug, info.row.original.id)}
          >
            <Text pl="md" fw="600" fz="sm">
              {info.renderValue()}
            </Text>
          </Anchor>
        ),
      }),

      columnHelper.accessor("version", {
        header: "Version",
        // eslint-disable-next-line react/no-unstable-nested-components -- used in useMemo
        cell: (cell) => <Code>{cell.renderValue() || "unknown"}</Code>,
      }),
      columnHelper.accessor("status", {
        header: "Board connection",
        // eslint-disable-next-line react/no-unstable-nested-components -- used in useMemo
        cell: (cell) => (
          <ConnectionStatusBadge my="md" status={cell.row.original.status} />
        ),
      }),
      columnHelper.accessor("id", {
        // eslint-disable-next-line react/no-unstable-nested-components -- used in useMemo
        header: () => <Box ta="right">Actions</Box>,
        // eslint-disable-next-line react/no-unstable-nested-components -- used in useMemo
        cell: (info) => (
          <Flex align="center" justify="flex-end">
            <BoardActions boardId={info.row.original.id} />
          </Flex>
        ),
      }),
    ] as ColumnDef<Board>[];
  }, [slug]);

  const [open, setOpen] = useState(false);
  return (
    <Container size="xl">
      <SectionHeader
        breadcrumbs={[
          { label: "Home", link: "/" },
          { label: "Organization", link: `/organization/${slug}` },
          {
            label: "Boards",
            active: true,
          },
        ]}
        mt="xl"
        title="Boards"
        subTitle="Manage and configure your boards"
        primaryAction={{
          label: "Add board",
          onClick: () => {
            setOpen(true);
          },
        }}
      />
      <OrganizationTabs value="boards" />
      <ErrorAlert error={error} />
      <Box mt="xl">
        <Table columns={columns} data={data?.boards ?? []} title={"Boards"} />
      </Box>
      <AddBoardModal />
      <ConfigureBoardModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </Container>
  );
};

export default BoardsPage;
