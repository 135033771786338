import type { BoardQueryVariables } from "@pilplay/graphql";
import { useBoardQuery, useBoardUpdatesSubscription } from "@pilplay/graphql";
import type { UseQueryArgs } from "urql";
import { useAuthContext } from "../../contexts/AuthContext";

export default function useBoard(
  id?: string | null,
  opts?: Omit<UseQueryArgs<BoardQueryVariables>, "query" | "variables">
) {
  const { isSignedIn } = useAuthContext();
  const res = useBoardQuery({
    requestPolicy: "cache-and-network",
    variables: {
      id: id || "",
    },
    pause: !id || !isSignedIn,
    ...opts,
  });

  const [_subRes] = useBoardUpdatesSubscription({
    variables: {
      id: id || "",
    },
    pause: !id || !isSignedIn,
  });

  return res;
}
