import { useDidUpdate, useForceUpdate } from "@mantine/hooks";
import { useEffect, useRef } from "react";
import type { MutableRefObject } from "react";
import type { AnimationsStore } from "./Animations.store";
import { animationsStore, useAnimations } from "./Animations.store";

export interface AnimationsProps {
  store?: AnimationsStore;
}

const queueNextAnimation = (
  store: AnimationsStore,
  ref: MutableRefObject<NodeJS.Timeout | null>,
  forceUpdate: () => void
): void => {
  let state = store.getState();
  if (state.queue.length === 0) {
    setTimeout(() => {
      forceUpdate();
    }, 0);
    return;
  }
  const [nextAnimation, ...queue] = state.queue;
  store.updateState({ ...state, currentAnimation: nextAnimation, queue });
  setTimeout(() => {
    forceUpdate();
  }, 0);
  ref.current = setTimeout(() => {
    ref.current = null;
    state = store.getState();
    store.updateState({ ...state, currentAnimation: undefined });
    setTimeout(() => {
      forceUpdate();
    }, 0);
    queueNextAnimation(store, ref, forceUpdate);
  }, nextAnimation.duration);
};

export const Animations: React.FC<AnimationsProps> = ({
  store = animationsStore,
}) => {
  const data = useAnimations(store);
  const forceUpdate = useForceUpdate();
  const animationTimerRef = useRef<NodeJS.Timeout | null>(null);

  // Cleanup the timer when the component is unmounted
  useEffect(() => {
    return () => {
      if (animationTimerRef.current) {
        clearTimeout(animationTimerRef.current);
      }
    };
  }, []);

  useDidUpdate(() => {
    if (!data.currentAnimation) {
      queueNextAnimation(store, animationTimerRef, forceUpdate);
    } else {
      setTimeout(() => {
        forceUpdate();
      }, 0);
    }
  }, [data.queue]);

  if (!data.currentAnimation) {
    return null;
  }
  return (
    <>
      <div key={data.currentAnimation.id}>
        {data.currentAnimation.component}
      </div>
    </>
  );
};
