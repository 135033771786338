import { Drawer } from "@mantine/core";
import type { DartHit } from "@pilplay/games";
import type { PlayerRounds, TableColumn } from "@pilplay/ui";
import { GameHistory } from "@pilplay/ui";
import { useMemo } from "react";
import { useGameContext } from "../../../../context/useGameContext";
import type { ExtendedX01Round } from "../../context/X01ContextProvider";
import { useX01Game } from "../../context/useX01Game";

const tableColumns = (
  onClick: (hit?: DartHit | null) => void
): TableColumn<ExtendedX01Round>[] => {
  return [
    {
      key: "roundNumber",
      title: "Round",
    },
    {
      key: "hit1",
      title: "Dart 1",
      valueFn: (round: ExtendedX01Round) => round.hit1?.point,
      onClick: (round: ExtendedX01Round) => {
        onClick(round.hit1);
      },
    },
    {
      key: "hit2",
      title: "Dart 2",
      valueFn: (round: ExtendedX01Round) => round.hit2?.point,
      onClick: (round: ExtendedX01Round) => {
        onClick(round.hit2);
      },
    },
    {
      key: "hit3",
      title: "Dart 3",
      valueFn: (round: ExtendedX01Round) => round.hit3?.point,
      onClick: (round: ExtendedX01Round) => {
        onClick(round.hit3);
      },
    },
    {
      key: "sum",
      title: "Sum",
    },
    {
      key: "scoreAtRound",
      title: "Score",
    },
  ];
};

interface X01HistoryProps {
  isMobile: boolean;
  open: boolean;
  onClose: () => void;
  onHitClick: (hit?: DartHit | null) => void;
}

const X01History: React.FC<X01HistoryProps> = ({
  isMobile = false,
  open,
  onClose,
  onHitClick,
}) => {
  const { players, activePlayer, playerRounds } = useX01Game();
  const { fetching } = useGameContext();

  const rounds: PlayerRounds<ExtendedX01Round>[] = useMemo(() => {
    // From players create playerRoundsData
    /* {
    id: "1",
    player: "Player 1",
    rounds: rounds,
   } */
    const playerRoundsData = players.map((player) => {
      return {
        id: player.id,
        player: player.name,
        rounds: playerRounds[player.id] || [],
      };
    });

    return playerRoundsData;
  }, [players, playerRounds]);

  const columns = useMemo(() => tableColumns(onHitClick), [onHitClick]);

  return (
    <Drawer
      onClose={onClose}
      opened={open}
      position={isMobile ? "bottom" : "left"}
      size={isMobile ? "70%" : "md"}
      title="Rounds"
    >
      <GameHistory
        playerRoundsData={rounds}
        columns={columns}
        loading={fetching}
        initialPlayerId={activePlayer?.id}
      />
    </Drawer>
  );
};

export default X01History;
