import { Tabs } from "@mantine/core";
import "./OrganizationTabs.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { clientRoutes } from "../../../routes";

export interface OrganizationTabsProps {
  value: "dashboard" | "boards" | "calendar";
}

export const OrganizationTabs: React.FC<OrganizationTabsProps> = ({
  value,
}) => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  return (
    <Tabs radius={0} py="xl" defaultValue={value}>
      <Tabs.List fw="600">
        <Tabs.Tab
          fz="md"
          p="md"
          value="dashboard"
          onClick={() => {
            navigate(clientRoutes.organizationHome(slug));
          }}
        >
          Dashboard
        </Tabs.Tab>
        <Tabs.Tab
          fz="md"
          p="md"
          value="calendar"
          onClick={() => {
            navigate(clientRoutes.organizationCalendar(slug));
          }}
        >
          Calendar
        </Tabs.Tab>
        <Tabs.Tab
          fz="md"
          p="md"
          value="boards"
          onClick={() => {
            navigate(clientRoutes.organizationBoards(slug));
          }}
        >
          Boards
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
};
