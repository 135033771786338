import { LegOrderType, X01ExitMode } from "@pilplay/graphql";
import React from "react";
import GameHeader from "../../../GameHeader";
import { useX01Game } from "../../context/useX01Game";
import GameMenu from "../../../GameMenu";

export const playerOrderToText = (
  playerOrder: LegOrderType | undefined | null
) => {
  switch (playerOrder) {
    case LegOrderType.Middle:
      return "Middle";
    case LegOrderType.Random:
      return "Random";
    default:
      return "Static";
  }
};

export const exitModeToText = (exitMode: X01ExitMode | undefined) => {
  switch (exitMode) {
    case X01ExitMode.Double:
      return "Double Out";
    case X01ExitMode.Single:
      return "Single Out";
    case X01ExitMode.Domd:
      return "DömD";
    default:
      return "-";
  }
};

export const X01Menu: React.FC = () => {
  const { config } = useX01Game();
  return (
    <GameMenu
      config={[
        {
          label: "Rounds per leg",
          value: config?.numRounds,
        },
        {
          label: "Number of legs",
          value: config?.legs,
        },
        {
          label: "Starting score",
          value: config?.startScore,
        },
        {
          label: "Player Order",
          value: playerOrderToText(config?.legOrderType),
        },
        {
          label: "Exit Mode",
          value: exitModeToText(config?.exitMode),
        },
      ]}
    />
  );
};

const X01Header: React.FC = () => {
  const { config, round } = useX01Game();
  return (
    <GameHeader
      config={[
        {
          label: "Rounds per leg",
          value: config?.numRounds,
        },
        {
          label: "Number of legs",
          value: config?.legs,
        },
        {
          label: "Starting score",
          value: config?.startScore,
        },
        {
          label: "Player Order",
          value: playerOrderToText(config?.legOrderType),
        },
        {
          label: "Exit Mode",
          value: exitModeToText(config?.exitMode),
        },
      ]}
      headerLabel={config?.startScore.toString()}
      headerSubLabel={exitModeToText(config?.exitMode)}
      round={round}
      totalRounds={config?.numRounds}
    />
  );
};

export default X01Header;
