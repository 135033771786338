import type { MantineSize } from "@mantine/core";
import { Badge, Text } from "@mantine/core";
import React from "react";
import {} from "./GameModeBadge.module.css";

interface GameModeBadgeProps {
  label?: string;
  subLabel?: string;
  size?: MantineSize;
  hideSubLabel?: boolean;
}

export const GameModeBadge: React.FC<GameModeBadgeProps> = ({
  label,
  subLabel,
  hideSubLabel,
  size = "lg",
}) => {
  return (
    <Badge size={size} p={"lg"} color={"#1a1b1e"}>
      <Text fw={900} component="span">
        {label}
      </Text>

      {!hideSubLabel && <Text component="span"> {subLabel}</Text>}
    </Badge>
  );
};
