import { skip } from "node:test";
import React from "react";

interface useFetchOptions {
  url: string;
  skip?: boolean;
  parseJson?: boolean;
}

interface useFetchResponse<Data> {
  data: Data | null;
  error: any | null;
  loading: boolean;
  statusCode: number | null;
}

const useFetch = <Data extends any>({
  url,
  skip = false,
  parseJson = true,
}: useFetchOptions): useFetchResponse<Data> => {
  const [statusCode, setStatusCode] = React.useState<number | null>(null);
  const [data, setData] = React.useState<Data | null>(null);
  const [error, setError] = React.useState<any | null>(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (skip) {
      return;
    }
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await fetch(url);
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        setStatusCode(res.status);
        if (parseJson) {
          const json = await res.json();
          setData(json as Data);
        } else {
          const text = await res.text();
          setData(text as Data);
        }
        setLoading(false);
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
  }, [url, skip]);

  return { data, error, loading, statusCode };
};

export default useFetch;
