import { useLobbyQuery } from "@pilplay/graphql";

export function useLobby(lobbyId?: string) {
  const data = useLobbyQuery({
    variables: {
      id: lobbyId || "",
    },
    requestPolicy: "cache-and-network",
    pause: !lobbyId,
  });

  return data;
}
