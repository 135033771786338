import type { BoxProps } from "@mantine/core";
import { Anchor, Box, Breadcrumbs, Flex, Text } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { Headline } from "../../typography/Headline";
import "./SectionHeader.module.css";
import { Button } from "../../buttons/Button";

export interface SectionHeaderProps extends BoxProps {
  title: string;
  subTitle?: string;
  primaryAction?: {
    label: string;
    onClick: () => void;
  };
  secondaryAction?: {
    label: string;
    onClick: () => void;
  };
  breadcrumbs?: {
    label: string;
    link?: string;
    active?: boolean;
  }[];
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  subTitle,
  secondaryAction,
  primaryAction,
  breadcrumbs = [],
  ...otherProps
}) => {
  return (
    <Box {...otherProps}>
      <Breadcrumbs
        py="md"
        separator={<IconChevronRight stroke={2} size={20} />}
      >
        {breadcrumbs.map(({ label, link, active }) => (
          <Anchor
            c={active ? "orange.6" : "dimmed"}
            key={label}
            to={link || "#"}
            fw={600}
            underline={link ? "hover" : "never"}
            component={Link}
          >
            {label}
          </Anchor>
        ))}
      </Breadcrumbs>
      <Flex justify="space-between" align="center">
        <Box>
          <Headline size="sm" fw="semiBold">
            {title}
          </Headline>
          <Text c="dimmed" fw={400} fz={"14pt"} lh={"14pt"} lts={"0.15px"}>
            {subTitle}
          </Text>
        </Box>
        <Box>
          {secondaryAction && (
            <Button
              size="sm"
              c="white"
              variant="outline"
              style={{ borderColor: "var(--mantine-color-dark-7)" }}
              onClick={secondaryAction.onClick}
            >
              {secondaryAction.label}
            </Button>
          )}
          {primaryAction && (
            <Button
              size="sm"
              onClick={primaryAction.onClick}
              ml={secondaryAction ? "sm" : 0}
            >
              {primaryAction.label}
            </Button>
          )}
        </Box>
      </Flex>
    </Box>
  );
};
