import { DartPosition } from "@pilplay/graphql";
import { DartPoint } from "../dart";

export enum GameEventType {
  START = "START",
  THROW = "THROW",
  EDIT = "EDIT",
  MANUAL_NEXT = "MANUAL_NEXT",
  EMPTY_BOARD = "EMPTY_BOARD",
  UNDO = "UNDO",
  FINISH = "FINISH",
}

export interface GameEventStart {
  id: string;
  timestamp: number;
  type: GameEventType.START;
  payload: {
    gameId: string;
  };
}

export interface GameEventThrow {
  id: string;
  timestamp: number;
  type: GameEventType.THROW;
  payload: {
    gameId: string;
    point: DartPoint;
    position?: DartPosition;
  };
}

export interface GameEventEdit {
  id: string;
  timestamp: number;
  type: GameEventType.EDIT;
  payload: {
    gameId: string;
    eventId: string;
    point: DartPoint;
    position?: DartPosition;
  };
}

export interface GameEventManualNext {
  id: string;
  timestamp: number;
  type: GameEventType.MANUAL_NEXT;
  payload: {
    gameId: string;
  };
}

export interface GameEventEmptyBoard {
  id: string;
  timestamp: number;
  type: GameEventType.EMPTY_BOARD;
  payload: {
    gameId: string;
  };
}

export interface GameEventFinish {
  id: string;
  timestamp: number;
  type: GameEventType.FINISH;
  payload: {
    gameId: string;
  };
}

export interface GameEventUndo {
  id: string;
  timestamp: number;
  type: GameEventType.UNDO;
  payload: {
    gameId: string;
  };
}

export type GameEvent =
  | GameEventStart
  | GameEventThrow
  | GameEventEdit
  | GameEventManualNext
  | GameEventEmptyBoard
  | GameEventFinish
  | GameEventUndo;
