import { DartHit } from "@pilplay/ui";
import React from "react";
import type { MantineSize } from "@mantine/core";
import { useGameContext } from "../../../../../../../../context/useGameContext";
import {
  getPlayerRound,
  useX01Game,
} from "../../../../../../context/useX01Game";

interface ActiveRoundPlayerHitsProps {
  playerId: string;
  hitSize?: number | MantineSize;
}

const ActiveRoundPlayerHits: React.FC<ActiveRoundPlayerHitsProps> = ({
  playerId,
  hitSize,
}) => {
  const { round, playerRounds } = useX01Game();
  const { setEditId } = useGameContext();

  const activeRound = getPlayerRound(playerRounds, playerId, round);

  return (
    <DartHit.List size={hitSize}>
      <DartHit.Hit
        bust={activeRound?.hit1?.bust}
        hit={activeRound?.hit1?.point}
        onClick={() => {
          setEditId(activeRound?.hit1?.id);
        }}
      />
      <DartHit.Hit
        bust={activeRound?.hit2?.bust}
        hit={activeRound?.hit2?.point}
        onClick={() => {
          setEditId(activeRound?.hit2?.id);
        }}
      />
      <DartHit.Hit
        bust={activeRound?.hit3?.bust}
        hit={activeRound?.hit3?.point}
        onClick={() => {
          setEditId(activeRound?.hit3?.id);
        }}
      />
    </DartHit.List>
  );
};
export default ActiveRoundPlayerHits;
