import {
  Anchor,
  Center,
  Group,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Logo } from "@pilplay/ui";
import { useForm } from "@mantine/form";
import { useAuthContext } from "../../../contexts/AuthContext";
import { useSignup } from "../../../hooks/useSignup";
import { clientRoutes } from "../../../routes";
import { BackToAffix } from "../../../components/BackToAffix";
import classes from "./Signup.module.css";

interface SignUpFormValues {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const Signup: React.FC = () => {
  const navigate = useNavigate();
  const { isSignedIn, setLogin } = useAuthContext();
  const [{ error, fetching }, signup] = useSignup();

  const form = useForm<SignUpFormValues>({
    initialValues: {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validateInputOnChange: true,

    validate: (values) => ({
      username: !values.username ? "Username is required" : null,
      email: !values.email ? "Email is required" : null,
      password: !values.password ? "Password is required" : null,
      confirmPassword:
        values.password !== values.confirmPassword
          ? "Passwords do not match"
          : null,
    }),
  });

  const handleSubmit = async (values: SignUpFormValues) => {
    const res = await signup({
      input: {
        username: values.username,
        email: values.email,
        password: values.password,
      },
    });
    if (res.data?.signUp.accessToken) {
      setLogin(res.data.signUp);
    }
  };

  // If user is already signed in, redirect to home page
  useEffect(() => {
    if (isSignedIn) {
      navigate(clientRoutes.root);
    }
  }, [isSignedIn, navigate]);

  const errorMessage = error?.graphQLErrors.map((e) => e.message).join(",");

  return (
    <Center h="100vh">
      <BackToAffix to="https://pilplay.com" text="Back to Pilplay" />
      <Stack miw={320}>
        <Stack mb="md">
          <Logo width={96} />
          <Text ta="center" fz="lg">
            Sign up to Pilplay
          </Text>
        </Stack>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="Username"
            mt="sm"
            placeholder="Username"
            required
            variant="filled"
            classNames={{
              input: classes.input,
            }}
            {...form.getInputProps("username")}
          />
          <TextInput
            label="Email"
            mt="sm"
            placeholder="Email"
            required
            variant="filled"
            {...form.getInputProps("email")}
            classNames={{
              input: classes.input,
            }}
          />
          <PasswordInput
            label="Password"
            mt="sm"
            placeholder="Password"
            required
            variant="filled"
            classNames={{
              input: classes.input,
            }}
            {...form.getInputProps("password")}
          />
          <PasswordInput
            label="Confirm Password"
            mt="sm"
            placeholder="Confirm Password"
            required
            variant="filled"
            classNames={{
              input: classes.input,
            }}
            {...form.getInputProps("confirmPassword")}
          />
          {errorMessage ? (
            <Text mt="md" fz="sm" c="red.7">
              {errorMessage}
            </Text>
          ) : null}
          <Button mt="md" loading={fetching} type="submit" fullWidth>
            Sign Up
          </Button>
        </form>
        <Group justify="center" mt="sm">
          <Anchor
            component={Link}
            size="xs"
            to={clientRoutes.login("")}
            type="a"
          >
            Already have an account? Click here to sign in
          </Anchor>
        </Group>
      </Stack>
    </Center>
  );
};
export default Signup;
