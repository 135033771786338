import {
  Text,
  Grid,
  PasswordInput,
  Group,
  Button,
  useMantineTheme,
  Alert,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import React from "react";
import type { UpdatePasswordInput } from "@pilplay/graphql";
import { useForm } from "@mantine/form";
import { useMeMutations } from "../../../../../hooks/useMeMutations";

interface AccountInformationProps {}

type AccountInformationForm = UpdatePasswordInput & { confirmPassword: string };

const AccountInformation: React.FC<AccountInformationProps> = ({}) => {
  const theme = useMantineTheme();
  const { updatePassword, error, fetching } = useMeMutations();
  const [saved, setSaved] = React.useState(false);

  const form = useForm<AccountInformationForm>({
    initialValues: {
      newPassword: "",
      oldPassword: "",
      confirmPassword: "",
    },
    validateInputOnChange: ["confirmPassword"],
    validate: {
      confirmPassword: (v, values) => {
        if (v !== values.newPassword) {
          return "Passwords do not match";
        }
        return undefined;
      },
    },
  });

  const handleSubmit = async (values: UpdatePasswordInput) => {
    setSaved(false);
    const res = await updatePassword({
      input: {
        newPassword: values.newPassword,
        oldPassword: values.oldPassword,
      },
    });
    if (res.data?.updatePassword) {
      setSaved(true);
    }
  };

  return (
    <Grid mt="xl">
      <Grid.Col span={{ base: 12, xs: 5 }}>
        <Text fw="bold">Account Information</Text>
        <Text c="dimmed" size="xs">
          Configure your account information and how you sign to pilplay.com
        </Text>
      </Grid.Col>
      <Grid.Col span={{ base: 12, sm: 7, xs: 7 }}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <PasswordInput
            error={error?.message}
            label="Current password"
            {...form.getInputProps("oldPassword")}
          />
          <PasswordInput
            error={error?.graphQLErrors.map((m) => m.message).join()}
            label="Password"
            mt="md"
            {...form.getInputProps("newPassword")}
          />
          <PasswordInput
            error={form.errors.confirmPassword}
            label="Confirm Password"
            mt="md"
            {...form.getInputProps("confirmPassword")}
          />
          {error ? <Alert c="red" mb="lg" mt="md">
              <Text size="xs">
                {error.graphQLErrors.map((m) => m.message).join()}
              </Text>
            </Alert> : null}
          <Group grow mt="md">
            <Button loading={fetching} type="submit" variant="outline">
              Update account
            </Button>
            <Group>
              {saved ? <>
                  <IconCheck color={theme.colors.green[8]} size={18} />
                  <Text c="dimmed" size="xs">
                    Account updated
                  </Text>
                </> : null}
            </Group>
          </Group>
        </form>
      </Grid.Col>
    </Grid>
  );
};

export default AccountInformation;
