import { BoardDocument, LobbyDocument, LobbyStatus } from "@pilplay/graphql";
import type {
  AddLobbyPlayerMutation,
  AddLobbyPlayerMutationVariables,
  BoardQuery,
  BoardUpdatesSubscription,
  BoardUpdatesSubscriptionVariables,
  CancelLobbyMutation,
  CancelLobbyMutationVariables,
  LobbyQuery,
  RemoveLobbyPlayerMutation,
  RemoveLobbyPlayerMutationVariables,
} from "@pilplay/graphql";
import type { UpdateResolver } from "@urql/exchange-graphcache";

export const boardEvents: UpdateResolver<
  BoardUpdatesSubscription,
  BoardUpdatesSubscriptionVariables
> = (result, args, cache) => {
  cache.updateQuery<BoardQuery>(
    {
      query: BoardDocument,
      variables: {
        id: args.id,
      },
    },
    (data) => {
      if (data?.board?.id === args.id) {
        switch (result.boardEvents.__typename) {
          case "BoardEventConnected":
            return {
              board: {
                ...data.board,
                ...result.boardEvents.board,
              },
            };
          case "BoardEventDisconnected":
            return {
              board: {
                ...data.board,
                ...result.boardEvents.board,
              },
            };
          default:
            return data;
        }
      }
      return data;
    }
  );
};

export const cancelLobby: UpdateResolver<
  CancelLobbyMutation,
  CancelLobbyMutationVariables
> = (_, args, cache) => {
  cache.updateQuery<LobbyQuery>(
    {
      query: LobbyDocument,
      variables: {
        id: args.input.lobbyId,
      },
    },
    (data) => {
      if (data?.lobby?.id === args.input.lobbyId) {
        return {
          lobby: {
            ...data.lobby,
            status: LobbyStatus.Closed,
          },
        };
      }
      return data;
    }
  );
};

export const addLobbyPlayer: UpdateResolver<
  AddLobbyPlayerMutation,
  AddLobbyPlayerMutationVariables
> = (result, args, cache) => {
  cache.updateQuery<LobbyQuery>(
    {
      query: LobbyDocument,
      variables: {
        id: args.input.lobbyId,
      },
    },
    (data) => {
      if (data?.lobby?.id === args.input.lobbyId) {
        return {
          lobby: {
            ...data.lobby,
            players: [...data.lobby.players, result.addLobbyPlayer],
          },
        };
      }
      return data;
    }
  );
};

export const removeLobbyPlayer: UpdateResolver<
  RemoveLobbyPlayerMutation,
  RemoveLobbyPlayerMutationVariables
> = (result, args, cache) => {
  cache.updateQuery<LobbyQuery>(
    {
      query: LobbyDocument,
      variables: {
        id: args.input.lobbyId,
      },
    },
    (data) => {
      if (data?.lobby?.id === args.input.lobbyId) {
        return {
          lobby: {
            ...data.lobby,
            players: [
              ...data.lobby.players.filter(
                (player) => player.id !== args.input.playerId
              ),
            ],
          },
        };
      }
      return data;
    }
  );
};
