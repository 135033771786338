import { LoadingOverlay } from "@mantine/core";
import React from "react";
import { useParams } from "react-router-dom";
import { useGame } from "../../../hooks/useGame";
import CricketGamePage from "./components/CricketGamePage";
import X01GamePage from "./components/X01GamePage";

interface GamePageProps {
  // id to use and override the id from the url
  id?: string;
  showControls?: boolean;
}

const GamePage: React.FC<GamePageProps> = ({ id: passedId, showControls }) => {
  const { id } = useParams<{ id: string }>();

  const [{ data, fetching, error }] = useGame(passedId || id);

  if (error) {
    return <div>{error.graphQLErrors.map((g) => g.message).join(",")}</div>;
  }

  if (fetching || !data) {
    return <LoadingOverlay variant="dots" visible />;
  }

  switch (data.game.__typename) {
    case "X01Game":
      return (
        <X01GamePage
          config={data.game.config}
          gameId={data.game.id}
          lobbyId={data.game.lobby?.id}
          players={data.game.players}
          showControls={showControls}
        />
      );
    case "CricketGame":
      return (
        <CricketGamePage
          config={data.game.config}
          gameId={data.game.id}
          lobbyId={data.game.lobby?.id}
          players={data.game.players}
          showControls={showControls}
        />
      );
  }

  return <div>Unknown game type</div>;
};

export default GamePage;
