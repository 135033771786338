import {
  Alert,
  Button,
  Collapse,
  Divider,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  HITS_TO_OPEN_CLOSE_CONFIG,
  MAX_ROUNDS_CONFIG,
  SCORING_RULE_CONFIG,
  START_NUMBER_CONFIG,
  WIN_CONDITION_CONFIG,
} from "@pilplay/games";
import type { CreateGameInput, CricketGameConfigInput } from "@pilplay/graphql";
import { CricketScoringRule, CricketWinCondition } from "@pilplay/graphql";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SegmentConfig from "../../../../components/SegmentConfig/SegmentConfig";
import SwitchConfig from "../../../../components/SwitchConfig/SwitchConfig";
import { useBoards } from "../../../../hooks/useBoards/useBoards";
import { useMe } from "../../../../hooks/useMe";
import { useNewGame } from "../../../../hooks/useNewGame/useNewGame";
import { clientRoutes } from "../../../../routes";
import ConfigPageContainer from "../ConfigPageContainer";

const CutthroathDefaults = {
  scoringRule: CricketScoringRule.Cutthroat,
  hitsToOpenClose: 3,
  startNumber: 15,
  winCondition: CricketWinCondition.Allclosed,
  numRounds: 30,
};

const StandardDefaults = {
  scoringRule: CricketScoringRule.Standard,
  hitsToOpenClose: 3,
  startNumber: 15,
  winCondition: CricketWinCondition.Allclosed,
  numRounds: 30,
};

const getDefaultConfig = (key: string) => {
  switch (key) {
    case "Cutthroat":
      return CutthroathDefaults;
    default:
      return StandardDefaults;
  }
};

const CricketConfigPage: React.FC = () => {
  const { boardId, config } = useParams<{
    boardId: string | undefined;
    config: string | undefined;
  }>();
  const [{ data: user }] = useMe();
  const navigate = useNavigate();
  const [{ fetching, error }, createGame] = useNewGame();

  const [{ data: boards, fetching: boardsFetching, error: errorBoards }] =
    useBoards();

  const form = useForm<
    CreateGameInput & {
      config: { cricket: CricketGameConfigInput };
    }
  >({
    validateInputOnChange: true,
    initialValues: {
      name: user?.me.displayName
        ? `${user.me.displayName}'s game` // Maybe add name from config?
        : `Cricket game`,
      config: {
        cricket: {
          ...getDefaultConfig(config || "Cutthroat"),
        },
      },
      boardId,
      private: false,
    },
    validate: {
      name: (value) => value.trim().length <= 2 && "Game name is too short",
    },
    transformValues: (values) => ({
      ...values,
      config: {
        ...values.config,
        cricket: {
          ...values.config.cricket,
          hitsToOpenClose: parseInt(
            values.config.cricket.hitsToOpenClose.toString()
          ),
          startNumber: parseInt(values.config.cricket.startNumber.toString()),
          numRounds: parseInt(values.config.cricket.numRounds.toString()),
        },
      },
    }),
  });

  useEffect(() => {
    if (user?.me.displayName) {
      form.setFieldValue("name", `${user.me.displayName}'s game`);
    }
  }, [user?.me]);

  const handleSubmit = async (values: CreateGameInput) => {
    const res = await createGame({
      input: values,
    });
    if (res.data) {
      navigate(clientRoutes.gameLobby(res.data.createGame.id));
    }
  };

  return (
    <ConfigPageContainer
      gameModeDescription={<>Cricket</>}
      gameModeLabel="Cricket Game mode"
    >
      <form
        onSubmit={form.onSubmit((values) => {
          handleSubmit(values);
        })}
      >
        <TextInput
          size="lg"
          {...form.getInputProps("name")}
          label="Game name"
        />
        <Divider label="Game settings" labelPosition="center" my="md" />
        {/* Scoring rule */}
        <SegmentConfig
          data={SCORING_RULE_CONFIG}
          description="How the score is applied"
          title="Scoring rule"
          {...form.getInputProps("config.cricket.scoringRule")}
          tooltip={
            <Text>
              <Text component="span" fw="bold">
                Standard
              </Text>{" "}
              The score is applied to the player who hit an opened number
              <br />
              <br />
              <Text component="span" fw="bold">
                Cut Throath
              </Text>{" "}
              All players that have not closed a number gets the score
            </Text>
          }
          value={form.values.config.cricket.scoringRule.toString()}
        />
        <SegmentConfig
          data={HITS_TO_OPEN_CLOSE_CONFIG}
          description="How many hits to open/close a number"
          title="Hits to open/close"
          {...form.getInputProps("config.cricket.legOrderType")}
          value={form.values.config.cricket.hitsToOpenClose.toString()}
        />
        <SegmentConfig
          data={START_NUMBER_CONFIG}
          description="From start number to 20 and bull"
          title="Start number"
          {...form.getInputProps("config.cricket.startNumber")}
        />
        <SegmentConfig
          data={MAX_ROUNDS_CONFIG}
          description="The maximum number of rounds to play"
          title="Max rounds"
          {...form.getInputProps("config.cricket.numRounds")}
          value={form.values.config.cricket.numRounds.toString()}
        />
        <Divider my="md" />
        <SegmentConfig
          data={WIN_CONDITION_CONFIG}
          description="How the game is finished"
          title="Win condition"
          {...form.getInputProps("config.cricket.winCondition")}
          tooltip={
            <Text>
              <Text component="span" fw="bold">
                All Numbers Closed
              </Text>{" "}
              When any player closes all numbers and bull the game ends
              <br />
              <br />
              <Text component="span" fw="bold">
                All Closed with Highest Score
              </Text>{" "}
              When the player with the highest/lowest score closes all numbers
              and bull the game ends.
            </Text>
          }
          value={form.values.config.cricket.winCondition.toString()}
        />
        <Divider my="md" />

        <SwitchConfig
          checked={form.values.private}
          description="Whether the game is public or private"
          mt="md"
          offLabel="Public"
          onChange={(value) => {
            form.setFieldValue("private", value.currentTarget.checked);
          }}
          onLabel="Private"
          size="xl"
          title="Lobby mode"
        />
        <Divider my="md" />
        <Collapse in={(boards?.boards ?? []).length > 0}>
          <Select
            clearable
            data={(boards?.boards ?? []).map((board) => ({
              value: board.id,
              label: board.name,
            }))}
            description="Connected Pilplay Auto Board to use for this game"
            disabled={boardsFetching}
            error={errorBoards?.graphQLErrors[0]?.message}
            label="Pilplay Auto Board"
            {...form.getInputProps("boardId")}
          />
        </Collapse>
        <Collapse in={Boolean(error)}>
          {error ? (
            <Alert color="red" mt="md">
              {error.graphQLErrors.map((error) => error.message).join(", ")}
            </Alert>
          ) : null}
        </Collapse>
        <Button
          disabled={!form.isValid() || fetching}
          fullWidth
          loading={fetching}
          mt="md"
          size="lg"
          type="submit"
        >
          Create lobby
        </Button>
      </form>
    </ConfigPageContainer>
  );
};

export default CricketConfigPage;
