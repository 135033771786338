/// <reference types="web-bluetooth" />

export const PILPLAY_SERVICE_ID: BluetoothServiceUUID =
  "39663131-3630-3034-2d65-6538322d3462";

export const PILPLAY_CONNECT_WIFI_UUID: BluetoothCharacteristicUUID =
  "61633034-6463-6362-2d38-6534632d3432";
export const PILPLAY_ERROR_STATE_UUID: BluetoothCharacteristicUUID =
  "65653234-3966-3535-2d66-6630322d3439";
export const PILPLAY_ADAPTER_STATE_UUID: BluetoothCharacteristicUUID =
  "36396261-3663-3562-2d31-3165302d3466";
export const PILPLAY_WIFI_LIST_UUID: BluetoothCharacteristicUUID =
  "37396639-3633-3038-2d34-3366372d3466";

export const PILPLAY_BOARD_ID_UUID: BluetoothCharacteristicUUID =
  "39643461-3932-6532-2d37-3165332d3436";

export const PILPLAY_NETWORK_STATUS_UUID: BluetoothCharacteristicUUID =
  "30666564-3936-3234-2d33-3935652d3433";

export default function useBluetooth() {
  const available = Boolean(navigator.bluetooth);

  const requestDevice = async () => {
    if (!available) {
      // eslint-disable-next-line no-console -- for easier debugging
      console.warn("Bluetooth is not available, unable to request device");
      return;
    }
    const device = await navigator.bluetooth.requestDevice({
      filters: [
        {
          namePrefix: "Pilplay",
        },
        {
          services: [PILPLAY_SERVICE_ID],
        },
      ],
    });
    return device;
  };

  return {
    available,
    requestDevice,
  };
}
