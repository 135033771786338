import { Button as MantineButton } from "@mantine/core";
import type {
  ButtonProps as MantineButtonProps,
  PolymorphicComponentProps,
} from "@mantine/core";
import classes from "./Button.module.css";

export interface ButtonProps extends MantineButtonProps {
  variant?: "secondary" | MantineButtonProps["variant"];
}

export const Button = <C = "button",>({
  children,
  size = "lg",
  fz = "sm",
  fw = "normal",
  variant,
  ...otherProps // eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- no need to specify return type
}: PolymorphicComponentProps<C, ButtonProps>) => {
  const mergedProps = { size, fz, fw, variant, ...otherProps };
  if (variant === "secondary") {
    mergedProps.variant = "filled";
    // @ts-expect-error -- This is a wrong type check
    mergedProps.color = "dark.7";
  }
  return (
    // @ts-ignore -- This is a wrong type check
    <MantineButton className={classes.root} {...mergedProps}>
      {children}
    </MantineButton>
  );
};
