import type { GroupProps, MantineSize } from "@mantine/core";
import { Group, Stack, Text } from "@mantine/core";
import React, { Children, cloneElement } from "react";

export interface DartHitListProps {
  size?: MantineSize | number;
  responsive?: boolean;
  grow?: boolean;
  withLabel?: boolean;
  children: React.ReactNode;
  justify?: GroupProps["justify"];
  align?: GroupProps["align"];
}

export const DartHitList: React.FC<DartHitListProps> = ({
  withLabel = false,
  responsive = false,
  grow = false,
  size = "md",
  children,
  justify,
  align = "center",
}) => {
  return (
    <Group justify={justify} align={align} wrap="nowrap" w="100%" grow={grow}>
      {Children.map(children, (child, i) => {
        return (
          <Stack gap={4} key={`dart-hit-${i}`} align="center">
            {cloneElement(child as React.ReactElement, {
              size,
              responsive,
              grow,
            })}
            {withLabel && (
              <Text fz={size} fw="bold" ta="center">
                Dart {i + 1}
              </Text>
            )}
          </Stack>
        );
      })}
    </Group>
  );
};
