// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file bluetooth/v1/bluetooth.proto (package bluetooth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message bluetooth.v1.ConnectWifiRequest
 */
export class ConnectWifiRequest extends Message<ConnectWifiRequest> {
  /**
   * @generated from field: string ssid = 1;
   */
  ssid = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  constructor(data?: PartialMessage<ConnectWifiRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bluetooth.v1.ConnectWifiRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ssid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectWifiRequest {
    return new ConnectWifiRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectWifiRequest {
    return new ConnectWifiRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectWifiRequest {
    return new ConnectWifiRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectWifiRequest | PlainMessage<ConnectWifiRequest> | undefined, b: ConnectWifiRequest | PlainMessage<ConnectWifiRequest> | undefined): boolean {
    return proto3.util.equals(ConnectWifiRequest, a, b);
  }
}

/**
 * @generated from message bluetooth.v1.AdapterState
 */
export class AdapterState extends Message<AdapterState> {
  /**
   * @generated from field: bluetooth.v1.AdapterState.State state = 1;
   */
  state = AdapterState_State.UNSPECIFIED;

  constructor(data?: PartialMessage<AdapterState>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bluetooth.v1.AdapterState";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(AdapterState_State) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdapterState {
    return new AdapterState().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdapterState {
    return new AdapterState().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdapterState {
    return new AdapterState().fromJsonString(jsonString, options);
  }

  static equals(a: AdapterState | PlainMessage<AdapterState> | undefined, b: AdapterState | PlainMessage<AdapterState> | undefined): boolean {
    return proto3.util.equals(AdapterState, a, b);
  }
}

/**
 * @generated from enum bluetooth.v1.AdapterState.State
 */
export enum AdapterState_State {
  /**
   * @generated from enum value: STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * Adapter is authorized and ready to use.
   *
   * @generated from enum value: STATE_AUTHORIZED = 1;
   */
  AUTHORIZED = 1,

  /**
   * Adapter is awaiting authorization from physical device.
   *
   * @generated from enum value: STATE_AWAITING_AUTHORIZATION = 2;
   */
  AWAITING_AUTHORIZATION = 2,

  /**
   * Adapter is provisioning a new wifi network.
   *
   * @generated from enum value: STATE_PROVISIONING = 3;
   */
  PROVISIONING = 3,

  /**
   * Is connected to a wifi network.
   *
   * @generated from enum value: STATE_PROVISIONED = 4;
   */
  PROVISIONED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(AdapterState_State)
proto3.util.setEnumType(AdapterState_State, "bluetooth.v1.AdapterState.State", [
  { no: 0, name: "STATE_UNSPECIFIED" },
  { no: 1, name: "STATE_AUTHORIZED" },
  { no: 2, name: "STATE_AWAITING_AUTHORIZATION" },
  { no: 3, name: "STATE_PROVISIONING" },
  { no: 4, name: "STATE_PROVISIONED" },
]);

/**
 * @generated from message bluetooth.v1.ErrorState
 */
export class ErrorState extends Message<ErrorState> {
  /**
   * @generated from field: bluetooth.v1.ErrorState.Error error = 1;
   */
  error = ErrorState_Error.UNSPECIFIED;

  /**
   * @generated from field: optional string message = 2;
   */
  message?: string;

  constructor(data?: PartialMessage<ErrorState>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bluetooth.v1.ErrorState";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error", kind: "enum", T: proto3.getEnumType(ErrorState_Error) },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ErrorState {
    return new ErrorState().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ErrorState {
    return new ErrorState().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ErrorState {
    return new ErrorState().fromJsonString(jsonString, options);
  }

  static equals(a: ErrorState | PlainMessage<ErrorState> | undefined, b: ErrorState | PlainMessage<ErrorState> | undefined): boolean {
    return proto3.util.equals(ErrorState, a, b);
  }
}

/**
 * @generated from enum bluetooth.v1.ErrorState.Error
 */
export enum ErrorState_Error {
  /**
   * @generated from enum value: ERROR_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * No error.
   *
   * @generated from enum value: ERROR_NONE = 1;
   */
  NONE = 1,

  /**
   * Unknown RPC error.
   *
   * @generated from enum value: ERROR_UNKNOWN_RPC = 2;
   */
  UNKNOWN_RPC = 2,

  /**
   * Unable to connect to the wifi network.
   *
   * @generated from enum value: ERROR_UNABLE_TO_CONNECT = 3;
   */
  UNABLE_TO_CONNECT = 3,

  /**
   * Adapter is not authorized.
   *
   * @generated from enum value: ERROR_NOT_AUTHORIZED = 4;
   */
  NOT_AUTHORIZED = 4,

  /**
   * Unknown error.
   *
   * @generated from enum value: ERROR_UNKNOWN = 5;
   */
  UNKNOWN = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(ErrorState_Error)
proto3.util.setEnumType(ErrorState_Error, "bluetooth.v1.ErrorState.Error", [
  { no: 0, name: "ERROR_UNSPECIFIED" },
  { no: 1, name: "ERROR_NONE" },
  { no: 2, name: "ERROR_UNKNOWN_RPC" },
  { no: 3, name: "ERROR_UNABLE_TO_CONNECT" },
  { no: 4, name: "ERROR_NOT_AUTHORIZED" },
  { no: 5, name: "ERROR_UNKNOWN" },
]);

/**
 * @generated from message bluetooth.v1.GetAvailableNetworksResponse
 */
export class GetAvailableNetworksResponse extends Message<GetAvailableNetworksResponse> {
  /**
   * @generated from field: repeated bluetooth.v1.Network networks = 1;
   */
  networks: Network[] = [];

  constructor(data?: PartialMessage<GetAvailableNetworksResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bluetooth.v1.GetAvailableNetworksResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "networks", kind: "message", T: Network, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAvailableNetworksResponse {
    return new GetAvailableNetworksResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAvailableNetworksResponse {
    return new GetAvailableNetworksResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAvailableNetworksResponse {
    return new GetAvailableNetworksResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAvailableNetworksResponse | PlainMessage<GetAvailableNetworksResponse> | undefined, b: GetAvailableNetworksResponse | PlainMessage<GetAvailableNetworksResponse> | undefined): boolean {
    return proto3.util.equals(GetAvailableNetworksResponse, a, b);
  }
}

/**
 * @generated from message bluetooth.v1.Network
 */
export class Network extends Message<Network> {
  /**
   * @generated from field: string ssid = 1;
   */
  ssid = "";

  /**
   * @generated from field: string mode = 2;
   */
  mode = "";

  /**
   * @generated from field: string channel = 3;
   */
  channel = "";

  /**
   * @generated from field: string rate = 4;
   */
  rate = "";

  /**
   * @generated from field: string signal = 5;
   */
  signal = "";

  /**
   * @generated from field: string security = 6;
   */
  security = "";

  constructor(data?: PartialMessage<Network>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bluetooth.v1.Network";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ssid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "mode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "channel", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "signal", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "security", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Network {
    return new Network().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Network {
    return new Network().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Network {
    return new Network().fromJsonString(jsonString, options);
  }

  static equals(a: Network | PlainMessage<Network> | undefined, b: Network | PlainMessage<Network> | undefined): boolean {
    return proto3.util.equals(Network, a, b);
  }
}

/**
 * @generated from message bluetooth.v1.NetworkStatus
 */
export class NetworkStatus extends Message<NetworkStatus> {
  /**
   * @generated from field: bool wi_fi_connected = 1;
   */
  wiFiConnected = false;

  /**
   * @generated from field: string wi_fi_ssid = 2;
   */
  wiFiSsid = "";

  /**
   * @generated from field: bool ethernet_connected = 3;
   */
  ethernetConnected = false;

  /**
   * @generated from field: string ethernet_ssid = 4;
   */
  ethernetSsid = "";

  constructor(data?: PartialMessage<NetworkStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bluetooth.v1.NetworkStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wi_fi_connected", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "wi_fi_ssid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ethernet_connected", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "ethernet_ssid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NetworkStatus {
    return new NetworkStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NetworkStatus {
    return new NetworkStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NetworkStatus {
    return new NetworkStatus().fromJsonString(jsonString, options);
  }

  static equals(a: NetworkStatus | PlainMessage<NetworkStatus> | undefined, b: NetworkStatus | PlainMessage<NetworkStatus> | undefined): boolean {
    return proto3.util.equals(NetworkStatus, a, b);
  }
}

/**
 * @generated from message bluetooth.v1.BoardInfo
 */
export class BoardInfo extends Message<BoardInfo> {
  /**
   * @generated from field: string board_id = 1;
   */
  boardId = "";

  /**
   * @generated from field: string board_version = 2;
   */
  boardVersion = "";

  /**
   * @generated from field: string registration_code = 3;
   */
  registrationCode = "";

  constructor(data?: PartialMessage<BoardInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bluetooth.v1.BoardInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "board_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "board_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "registration_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BoardInfo {
    return new BoardInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BoardInfo {
    return new BoardInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BoardInfo {
    return new BoardInfo().fromJsonString(jsonString, options);
  }

  static equals(a: BoardInfo | PlainMessage<BoardInfo> | undefined, b: BoardInfo | PlainMessage<BoardInfo> | undefined): boolean {
    return proto3.util.equals(BoardInfo, a, b);
  }
}

