import { Box, Center } from "@mantine/core";
import type { HitElement } from "@pilplay/ui";
import { Animations, DesktopLayout } from "@pilplay/ui";
import React, { useEffect, useMemo, useState } from "react";
import { useInterval, usePrevious } from "@mantine/hooks";
import { InteractiveDartboardAutoScale } from "../../../../../../../components/InteractiveDartboard/InteractiveDartboard";
import { useGameContext } from "../../../../context/useGameContext";
import { useX01Game } from "../../context/useX01Game";
import BullOffActivePlayer from "../BullOffActivePlayer";
import MiddlingHitElement from "../MiddlingHitElement";
import X01FloatingActions from "../X01FloatingActions";
import X01Header from "../X01Header/X01Header";
import PlayerStack from "./components/PlayerStack";
import { ScoreboardList } from "./components/ScoreboardList";
import { PlayerScoreboard } from "./components/PlayerScoreboard";

interface DesktopX01GameProps {
  inputDisabled: boolean;
  actions: {
    onHistoryClick: () => void;
  };
}

const DARTBOARD_MARGIN = 50;

const DesktopX01Game: React.FC<DesktopX01GameProps> = ({
  inputDisabled,
  actions,
}) => {
  const { activeRoundHits, finished, middlingThrows, middlingPhase, players } =
    useX01Game();
  const { showControls, throwDart } = useGameContext();
  const roundHitPositions = [
    activeRoundHits?.hit1?.position,
    activeRoundHits?.hit2?.position,
    activeRoundHits?.hit3?.position,
  ].filter((p) => Boolean(p)) as { x: number; y: number }[];
  const [showFinishedScreen, setShowFinishedScreen] = useState(false);

  const previousValue = usePrevious(finished);

  const { start, stop } = useInterval(() => {
    setSelectedPlayerIndex((prev) => (prev + 1) % players.length);
  }, 8000);

  useEffect(() => {
    if (finished && previousValue === undefined) {
      setSelectedPlayerIndex(0);
      start();
      setShowFinishedScreen(true);
    } else if (finished && previousValue === false) {
      // Wait for animation to finish
      setTimeout(() => {
        setSelectedPlayerIndex(0);
        start();
        setShowFinishedScreen(true);
      }, 1600);
    }
  }, [finished, previousValue, start]);

  const [selectedPlayerIndex, setSelectedPlayerIndex] = useState(0);

  const middlingPositions = useMemo(
    () =>
      middlingThrows
        .map((t) => ({
          ...t.throw.position,
          Cell: ({ x, y, ...otherProps }: HitElement) => {
            const player = players.find((p) => p.id === t.playerId);
            if (!player) {
              return null;
            }
            return MiddlingHitElement({
              originalX: t.throw.position?.x || 0,
              originalY: t.throw.position?.y || 0,
              player,
              x,
              y,
              ...otherProps,
            });
          },
        }))
        .filter((p) => Boolean(p)) as HitElement[],
    [middlingThrows, players]
  );

  const hits = middlingPhase ? middlingPositions : roundHitPositions;

  return (
    <>
      <Animations />
      <DesktopLayout
        header={<X01Header />}
        cols={[
          {
            span: middlingPhase ? 0 : 5,
            render: ({ height, colWidth }) => {
              if (showFinishedScreen) {
                return (
                  <Box h={height} style={{ overflow: "auto" }} p="md">
                    <ScoreboardList
                      onClick={(index) => {
                        stop();
                        setSelectedPlayerIndex(index);
                      }}
                      selectedPlayerIndex={selectedPlayerIndex}
                    />
                  </Box>
                );
              }
              return (
                <Box h={height} style={{ overflow: "auto" }} p="md">
                  <PlayerStack height={height} width={colWidth} />
                </Box>
              );
            },
          },
          {
            span: middlingPhase ? 12 : 7,
            render: ({ height }) => {
              if (showFinishedScreen) {
                return (
                  <PlayerScoreboard selectedPlayerIndex={selectedPlayerIndex} />
                );
              }
              return (
                <Box
                  style={{
                    height,
                    position: "relative",
                  }}
                >
                  <BullOffActivePlayer />
                  {showControls ? (
                    <X01FloatingActions
                      onHistoryClick={actions.onHistoryClick}
                    />
                  ) : null}

                  <Center>
                    <InteractiveDartboardAutoScale
                      disabled={inputDisabled}
                      height={height}
                      hits={hits}
                      onHit={throwDart}
                      padding={DARTBOARD_MARGIN}
                    />
                  </Center>
                </Box>
              );
            },
          },
        ]}
        style={{
          background: "var(--mantine-color-dark-8)",
        }}
      />
    </>
  );
};

export default DesktopX01Game;
