import type {
  Client as WsClient,
  ClientOptions as WsClientOptions,
} from "graphql-ws";
import { createClient as createWSClient } from "graphql-ws";
import { createContext, useEffect, useState } from "react";

type WsConnectionStatus = "closed" | "connecting" | "connected";
export interface ClientWithOnReconnected extends WsClient {
  onReconnected: (cb: () => void) => () => void;
  onStatusChange: (cb: (status: WsConnectionStatus) => void) => () => void;
}
export const createClientWithOnReconnected = (
  options: WsClientOptions
): ClientWithOnReconnected => {
  let abruptlyClosed = false;
  const reconnectedCbs: (() => void)[] = [];
  let statusCbs: (status: WsConnectionStatus) => void;

  const client = createWSClient({
    ...options,
    shouldRetry: () => {
      // Should use the default retry logic 'Randomised exponential backoff'
      return true;
    },
    on: {
      ...options.on,
      closed: (event) => {
        statusCbs("closed");
        options.on?.closed?.(event);
        // non-1000 close codes are abrupt closes
        if ((event as CloseEvent).code !== 1000) {
          abruptlyClosed = true;
        }
      },
      connecting: (...args) => {
        statusCbs("connecting");
        options.on?.connecting?.(...args);
      },
      connected: (...args) => {
        statusCbs("connected");
        options.on?.connected?.(...args);
        // if the client abruptly closed, this is then a reconnect
        if (abruptlyClosed) {
          abruptlyClosed = false;
          reconnectedCbs.forEach((cb) => {
            cb();
          });
        }
      },
    },
  });

  return {
    ...client,
    onReconnected: (cb) => {
      reconnectedCbs.push(cb);
      return () => {
        reconnectedCbs.splice(reconnectedCbs.indexOf(cb), 1);
      };
    },
    onStatusChange: (cb) => {
      statusCbs = cb;
      return () => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function -- we want to assign an empty function to statusCbs
        statusCbs = () => {}; // Assign an empty function to statusCbs
      };
    },
  };
};

interface WsClientContextValue {
  status: WsConnectionStatus;
  client: ClientWithOnReconnected;
}
export const WsClientContext = createContext<WsClientContextValue | null>(null);

interface WsClientProviderProps {
  children: React.ReactNode;
  client: ClientWithOnReconnected;
}

export const WsClientProvider: React.FC<WsClientProviderProps> = ({
  children,
  client,
}) => {
  const [status, setStatus] = useState<WsConnectionStatus>("closed");

  useEffect(() => {
    const unlisten = client.onStatusChange(setStatus);
    return unlisten;
  }, [client]);

  return (
    <WsClientContext.Provider value={{ client, status }}>
      {children}
    </WsClientContext.Provider>
  );
};
