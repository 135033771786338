import {
  Divider,
  Flex,
  Grid,
  Group,
  Skeleton,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import type { UpdateMeInput } from "@pilplay/graphql";
import { Button, ButtonLink, PlayerAvatar } from "@pilplay/ui";
import { IconCheck } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useMe } from "../../../../../hooks/useMe";
import { useMeMutations } from "../../../../../hooks/useMeMutations";
import { isUser } from "../../../../../hooks/useMe/useMe";

const ProfileInformation: React.FC = () => {
  const theme = useMantineTheme();
  const [saved, setSaved] = useState(false);
  const [{ data, fetching }] = useMe();
  const { updateMe, error, fetching: mutationFetching } = useMeMutations();
  const form = useForm<UpdateMeInput>({
    initialValues: {
      displayName: "",
    },
  });

  // Load user data into form when loaded
  useEffect(() => {
    if (data && isUser(data)) {
      form.setValues({
        displayName: data.me.displayName,
        email: data.me.email,
      });
      form.resetDirty();
    }
  }, [data, form]);

  const handleSubmit = async (values: UpdateMeInput) => {
    setSaved(false);
    const res = await updateMe({
      input: values,
    });
    if (res.data?.updateMe) {
      setSaved(true);
    }
  };

  const user = isUser(data) ? data.me : null;

  return (
    <Grid>
      <Grid.Col span={12}>
        <Flex my="md" justify="space-between" align="center">
          <Group>
            <PlayerAvatar size={60} url={user?.avatarUrl} useOutlineIcon />
            <Stack gap={8}>
              <Skeleton height={20} visible={fetching} width={100}>
                <Text fw="600">Your avatar</Text>
              </Skeleton>
              <Text c="dimmed" size="sm">
                Avatars are managed by{" "}
                <a href="https://gravatar.com" rel="noopener" target="_blank">
                  Gravatar
                </a>
              </Text>
            </Stack>
          </Group>
          <ButtonLink
            target="_blank"
            link="https://gravatar.com/profile/avatars"
            size="sm"
          >
            Change avatar
          </ButtonLink>
        </Flex>
        <Divider />
      </Grid.Col>
      <Grid.Col span={{ base: 12 }}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Grid>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput
                {...form.getInputProps("displayName")}
                description="Your username is shown to other users"
                error={error?.message}
                label="Username"
                placeholder="Your username..."
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput
                {...form.getInputProps("email")}
                description="Your email address is used to log in to your account"
                error={error?.message}
                label="Email"
                placeholder="Your username..."
              />
            </Grid.Col>
            <Flex justify="flex-end" w="100%">
              <Group mx={8} mt="md">
                <Group>
                  {saved ? (
                    <>
                      <IconCheck color={theme.colors.green[8]} size={18} />
                      <Text c="dimmed" size="xs">
                        Profile updated
                      </Text>
                    </>
                  ) : null}
                </Group>
                <Button size="sm" loading={mutationFetching} type="submit">
                  Update profile
                </Button>
              </Group>
            </Flex>
          </Grid>
        </form>
      </Grid.Col>
    </Grid>
  );
};

export default ProfileInformation;
