import type { GamesFilterInput } from "@pilplay/graphql";
import { useGamesQuery } from "@pilplay/graphql";
import { useCallback, useMemo, useState } from "react";

interface UseGamesArgs {
  take?: number;
  filter?: GamesFilterInput;
}

export function useGames({ take = 10, filter }: UseGamesArgs) {
  const [state, setState] = useState<{
    first?: number | null;
    after?: string | null;
    last?: number | null;
    before?: string | null;
  }>({
    first: take,
    after: null,
    last: null,
    before: null,
  });
  const [{ data, error, fetching }] = useGamesQuery({
    requestPolicy: "cache-and-network",
    variables: {
      filter: filter || undefined,
      ...state,
    },
  });

  const pageInfo = useMemo(() => {
    return data?.games.pageInfo;
  }, [data?.games.pageInfo]);

  const games = useMemo(() => {
    return data?.games.edges.map((edge) => edge.node);
  }, [data?.games.edges]);

  const getNextPage = useCallback(() => {
    setState({
      after: pageInfo?.endCursor,
      first: take,
    });
  }, [pageInfo?.endCursor, take]);

  const getPreviousPage = useCallback(() => {
    setState({
      before: pageInfo?.startCursor,
      last: take,
    });
  }, [pageInfo?.startCursor, take]);

  return {
    games,
    error,
    fetching,
    pageInfo,
    getNextPage,
    getPreviousPage,
    hasNextPage: pageInfo?.hasNextPage,
    hasPreviousPage: pageInfo?.hasPreviousPage,
  };
}
