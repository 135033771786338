import {
  useAbortGameMutation,
  useAddGuestMutation,
  useJoinGameMutation,
} from "@pilplay/graphql";

export function useGameMutations() {
  const [{ error, fetching }, joinGame] = useJoinGameMutation();
  const [{ error: guestError, fetching: guestFetching }, addGuest] =
    useAddGuestMutation();
  const [{ error: abortError, fetching: abortFetching }, abortGame] =
    useAbortGameMutation();
  return {
    fetching: fetching || guestFetching || abortFetching,
    error: error || guestError || abortError,
    joinGame,
    abortGame,
    addGuest,
  };
}
