import { useContext } from "react";
import type { ExtendedX01Round} from "./X01ContextProvider";
import { X01Context } from "./X01ContextProvider";

export function useX01Game() {
  return useContext(X01Context);
}

export function getPlayerRound(
  playerRounds: Record<string, ExtendedX01Round[]>,
  playerId: string,
  round: number
) {
  const playerRound = playerRounds[playerId];

  const activeRound: ExtendedX01Round | undefined = (playerRound ?? []).find(
    (r) => r.roundNumber === round
  );
  return activeRound;
}
