import { useElementSize } from "@mantine/hooks";
import React, { useEffect, useState } from "react";

interface ScaleToFitProps {
  children: React.ReactNode;
  margin?: number;
  maxWidth?: number; // Max width for the scaled content
  maxHeight?: number; // Max height for the scaled content
  maxScale?: number; // Maximum scale factor
  width?: "fit-content" | "auto" | "max-content" | "min-content" | number;
}

const ScaleToFit: React.FC<ScaleToFitProps> = ({
  children,
  margin = 1, // Scales with scale
  maxWidth = Number.MAX_VALUE, // Default to a very large value
  maxHeight = Number.MAX_VALUE, // Default to a very large value
  maxScale = Number.MAX_VALUE, // Default to a very large value
  width = "fit-content",
}) => {
  const { ref, width: elementWidth, height } = useElementSize();
  const {
    ref: wrapperRef,
    width: wrapperWidth,
    height: wrapperHeight,
  } = useElementSize();
  const [scale, setScale] = useState(1);

  useEffect(() => {
    if (ref.current && wrapperRef.current) {
      const widthScale = (wrapperWidth - margin * 2 * scale) / elementWidth;
      const heightScale = (wrapperHeight - margin * 2 * scale) / height;
      // Apply maximum dimensions constraints
      const constrainedWidthScale = Math.min(
        maxWidth / elementWidth,
        widthScale
      );
      const constrainedHeightScale = Math.min(maxHeight / height, heightScale);

      // Apply the maximum scale constraint
      const newScale = Math.min(
        maxScale,
        Math.max(1, Math.min(constrainedWidthScale, constrainedHeightScale))
      );
      setScale(newScale);
      const scaledWidth = elementWidth * newScale;
      const scaledHeight = height * newScale;

      // Calculate the difference in width and height
      const widthDiff = Math.max(0, wrapperWidth - scaledWidth) / 2; // Ensure non-negative value
      const heightDiff = Math.max(0, wrapperHeight - scaledHeight) / 2; // Ensure non-negative value

      // Apply this difference as margin to center the content
      ref.current.style.marginLeft = `${widthDiff}px`; // Center horizontally
      ref.current.style.marginTop = `${heightDiff}px`; // Center vertically
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Including scale will cause infinite loop
  }, [
    width,
    height,
    wrapperWidth,
    wrapperHeight,
    margin,
    maxScale,
    maxHeight,
    maxWidth,
    ref,
    wrapperRef,
    elementWidth,
  ]);

  return (
    <div
      ref={wrapperRef}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        ref={ref}
        style={{
          transform: `scale(${scale})`,
          transformOrigin: "top left",
          width,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ScaleToFit;
