import React, { useMemo } from "react";
import { Table } from "@pilplay/ui";
import type { ColumnDef } from "@tanstack/react-table";
import { createColumnHelper } from "@tanstack/react-table";
import { Box, Text, Flex } from "@mantine/core";
import { LobbyCodeStatus, type LobbyCodesQuery } from "@pilplay/graphql";
import { useLobbyCodes } from "../../../../../hooks/useLobbyCodes";
import ErrorAlert from "../../../../../components/ErrorAlert";
import { LobbyCodeDisplay } from "./LobbyCodeDisplay";
import { LobbyCodeActions } from "./LobbyCodeActions";

type LobbyCode = LobbyCodesQuery["lobbyCodes"]["edges"]["0"];

export interface LobbyCodesTableProps {
  slug: string;
}

const columnHelper = createColumnHelper<LobbyCode>();
export const LobbyCodesTable: React.FC<LobbyCodesTableProps> = ({ slug }) => {
  const { codes, pageInfo, error, fetching, getNextPage, getPreviousPage } =
    useLobbyCodes(slug);

  const columns: ColumnDef<LobbyCode>[] = useMemo(() => {
    return [
      columnHelper.accessor("node.code", {
        header: "Code",
        // eslint-disable-next-line react/no-unstable-nested-components -- used in useMemo
        cell: (row) => (
          <LobbyCodeDisplay
            code={row.row.original.node.code}
            status={row.row.original.node.status}
          />
        ),
      }),
      columnHelper.accessor("node.duration", {
        header: "Duration",
        // eslint-disable-next-line react/no-unstable-nested-components -- used in useMemo
        cell: (row) => (
          <Text py="xs" size="sm" c="dimmed">
            {row.row.original.node.duration} minutes
          </Text>
        ),
      }),
      columnHelper.accessor("node.status", {
        header: "Status",
        // eslint-disable-next-line react/no-unstable-nested-components -- used in useMemo
        cell: (row) => (
          <Text size="sm" c="dimmed">
            {row.row.original.node.status}
          </Text>
        ),
      }),

      columnHelper.accessor("node.id", {
        // eslint-disable-next-line react/no-unstable-nested-components -- used in useMemo
        header: () => <Box ta="right">Actions</Box>,
        // eslint-disable-next-line react/no-unstable-nested-components -- used in useMemo
        cell: (r) => (
          <Flex justify="flex-end">
            <LobbyCodeActions
              code={r.row.original.node.code}
              slug={slug}
              canActivate={
                r.row.original.node.status === LobbyCodeStatus.Created
              }
            />
          </Flex>
        ),
      }),
    ] as ColumnDef<LobbyCode>[];
  }, [slug]);

  return (
    <>
      <ErrorAlert mb="md" error={error} />
      <Table
        title="Lobby Codes"
        columns={columns}
        fetching={fetching}
        data={codes ?? []}
        rowCount={pageInfo?.totalCount ?? 0}
        pagination={{
          hasNextPage: pageInfo?.hasNextPage ?? false,
          hasPreviousPage: pageInfo?.hasPreviousPage ?? false,
          nextPage: getNextPage,
          previousPage: getPreviousPage,
          totalCount: pageInfo?.totalCount ?? 0,
        }}
      />
    </>
  );
};
