import { SegmentedControl } from "@mantine/core";
import React from "react";

interface PlayerSelectProps {
  players: {
    value: string;
    label: string;
  }[];
  selectedPlayer: string;
  onPlayerSelect: (selectedPlayer: string) => void;
}

export const PlayerSelect: React.FC<PlayerSelectProps> = ({
  players,
  selectedPlayer,
  onPlayerSelect,
}) => {
  const handlePlayerChange = (selected: string): void => {
    onPlayerSelect(selected);
  };

  return (
    <div>
      <SegmentedControl
        data={players}
        value={selectedPlayer}
        onChange={handlePlayerChange}
      />
    </div>
  );
};
