import {
  useAddLobbyPlayerMutation,
  useCancelLobbyMutation,
  useCreateLobbyGameMutation,
  useCreateLobbyMutation,
  useRemoveLobbyPlayerMutation,
} from "@pilplay/graphql";

export function useLobbyMutations() {
  const [createData, createLobby] = useCreateLobbyMutation();
  const [createGameData, createGame] = useCreateLobbyGameMutation();
  const [addPlayerData, addPlayer] = useAddLobbyPlayerMutation();
  const [removePlayerData, removePlayer] = useRemoveLobbyPlayerMutation();
  const [cancelLobbyData, cancelLobby] = useCancelLobbyMutation();
  return {
    create: createData,
    createLobby,
    createGameData,
    createGame,
    addPlayerData,
    addPlayer,
    removePlayerData,
    removePlayer,
    cancelLobbyData,
    cancelLobby,
  };
}
