import React from "react";
import { Dartboard } from "@pilplay/ui";
import { useMantineTheme } from "@mantine/core";

interface MagnifierCrosshairProps {
  boardSize: number;
  position: { x: number; y: number };
  color: string;
  magnifierRadius: number;
}

const MagnifierCrosshair: React.FC<MagnifierCrosshairProps> = ({
  boardSize,
  position,
  color: sightColor,
  magnifierRadius,
}) => {
  return (
    <>
      <line
        stroke={sightColor}
        strokeWidth={magnifierRadius * 0.05}
        transform={`translate(${boardSize / 2} ${boardSize / 2})`}
        x1={position.x - magnifierRadius / 5}
        x2={position.x + magnifierRadius / 5}
        y1={position.y - magnifierRadius / 5}
        y2={position.y + magnifierRadius / 5}
      />
      <line
        stroke={sightColor}
        strokeWidth={magnifierRadius * 0.05}
        transform={`translate(${boardSize / 2} ${boardSize / 2})`}
        x1={position.x + magnifierRadius / 5}
        x2={position.x - magnifierRadius / 5}
        y1={position.y - magnifierRadius / 5}
        y2={position.y + magnifierRadius / 5}
      />
    </>
  );
};

interface MagnifierEdgeLabelProps {
  boardSize: number;
  position: { x: number; y: number };
  label?: string;

  bgColor?: string;
  color?: string;
  magnifierRadius: number;
}

const MagnifierEdgeLabel: React.FC<MagnifierEdgeLabelProps> = ({
  boardSize,
  position,
  label,
  bgColor = "#000000",
  color = "#ffffff",
  magnifierRadius,
}) => {
  const fontSize = 0.23 * magnifierRadius;
  return (
    <>
      <rect
        fill={bgColor}
        height={magnifierRadius}
        id="background"
        transform={`translate(${boardSize / 2} ${boardSize / 2})`}
        width={magnifierRadius}
        x={position.x}
        y={position.y - magnifierRadius}
       />

      <text
        fill={color}
        fontSize={fontSize}
        fontWeight="bold"
        textAnchor="end"
        transform={`translate(${boardSize / 2} ${boardSize / 2})`}
        x={position.x + magnifierRadius - fontSize / 4}
        y={position.y - magnifierRadius + fontSize}
      >
        {label}
      </text>
    </>
  );
};

interface MagnifierBorderProps {
  boardSize: number;
  position: { x: number; y: number };
  color: string;
  magnifierRadius: number;
}

const MagnifierBorder: React.FC<MagnifierBorderProps> = ({
  boardSize,
  position,
  color: strokeColor,
  magnifierRadius,
}) => {
  return (
    <circle
      cx={position.x}
      cy={position.y}
      id="circle-shadow"
      r={magnifierRadius}
      style={{
        stroke: strokeColor,
        fill: strokeColor,
      }}
      transform={`translate(${boardSize / 2} ${boardSize / 2})`}
    />
  );
};

interface MagnifierProps {
  boardSize?: number;
  disabled?: boolean;
  edgeLabel?: string;
  position: { x: number; y: number };
  magnifierRadius?: number;
  scale?: number;
  offset?: { x: number; y: number };
  color?: string;
  crosshairColor?: string;
  hits?: { x: number; y: number }[];
}

const Magnifier: React.FC<MagnifierProps> = ({
  boardSize = 1000,
  disabled,
  edgeLabel,
  position,
  magnifierRadius = 75,
  offset = { x: 0, y: 100 },
  scale = 1.5,
  color = "#000000",
  crosshairColor,
  hits,
}) => {
  const theme = useMantineTheme();
  const normalizedPosition = {
    x: position.x * scale,
    y: position.y * scale,
  };

  return (
    <Dartboard
      afterChildren={
        <MagnifierCrosshair
          boardSize={boardSize}
          color={crosshairColor || theme.colors.red[9]}
          magnifierRadius={magnifierRadius}
          position={normalizedPosition}
        />
      }
      boardSize={boardSize}
      clipPath="url(#mask)"
      disabled={disabled}
      hits={hits}
      offsetX={offset.x + position.x * (scale - 1)}
      offsetY={offset.y + position.y * (scale - 1)}
      scale={scale}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        overflow: "visible",
        bottom: 0,
        zIndex: 100,
      }}
    >
      <defs>
        <clipPath id="mask">
          <circle
            cx={normalizedPosition.x}
            cy={normalizedPosition.y}
            id="mask-circle"
            r={magnifierRadius}
            transform={`translate(${boardSize / 2} ${boardSize / 2})`}
          />
        </clipPath>
      </defs>
      <g>
        <MagnifierEdgeLabel
          bgColor={color}
          boardSize={boardSize}
          label={edgeLabel}
          magnifierRadius={magnifierRadius * 1.1}
          position={normalizedPosition}
        />
        <MagnifierBorder
          boardSize={boardSize}
          color={color}
          magnifierRadius={magnifierRadius * 1.1}
          position={normalizedPosition}
        />
      </g>
    </Dartboard>
  );
};

export default Magnifier;
