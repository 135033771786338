import React from "react";
import {
  Divider,
  Group,
  Paper,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import QRCode from "react-qr-code";
import { Logo } from "@pilplay/ui";
import { clientRoutes } from "../../../../../routes";
import classes from "./IdleBoard.module.css";

interface IdleBoardProps {
  boardId: string;
}

const IdleBoard: React.FC<IdleBoardProps> = ({ boardId }) => {
  const theme = useMantineTheme();

   
  const baseUrl = import.meta.env.VITE_BASE_URL || "https://app.pilplay.com";
  return (
    <div className={classes.root}>
      <div className={classes.gradient} />
      <Logo fontSize="68" mt={-64} text="right" width={128} />
      <Paper mt={48} p="xl" radius="md" shadow="md" style={{ zIndex: 1 }}>
        <Group>
          <QRCode
            bgColor={theme.colors.dark[7]}
            fgColor={theme.colors.dark[3]}
            level="L"
            style={{ height: "auto" }}
            value={`${baseUrl}${clientRoutes.gameX01(boardId)}`}
          />
          <Divider orientation="vertical" />
          <Stack>
            <Text fw="bold" fz={24} ta="center">
              Lets play some darts!
            </Text>
            <Text c="dimmed" fz={18} ta="center">
              Scan the QR code with your <br /> phone to setup a game.
            </Text>
          </Stack>
        </Group>
      </Paper>
    </div>
  );
};

export default IdleBoard;
