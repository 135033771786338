import React, { useState } from "react";
import {
  Box,
  Card,
  Center,
  Container,
  Flex,
  Stack,
  Text,
  Stepper,
  TextInput,
  TagsInput,
  Image,
  Anchor,
} from "@mantine/core";
import { Button, Headline, SectionHeader } from "@pilplay/ui";
import classes from "./NewOrganizationPage.module.css";
import { useForm } from "@mantine/form";
import { Link } from "react-router-dom";
import {
  CreateOrganizationInput,
  useCreateOrganizationMutation,
} from "@pilplay/graphql";
import CompletedImage from "./completed.svg";
import { clientRoutes } from "../../../routes";
import ErrorAlert from "../../../components/ErrorAlert";

const MAX_STEPS = 2;

export const NewOrganizationPage: React.FC = () => {
  const [active, setActive] = useState(0);
  const nextStep = () => {
    setActive((current) => (current < MAX_STEPS ? current + 1 : current));
  };
  const prevStep = () => {
    setActive((current) => (current > 0 ? current - 1 : current));
  };

  const [{ data, error, fetching }, createOrg] =
    useCreateOrganizationMutation();

  const form = useForm<CreateOrganizationInput>({
    initialValues: {
      name: "",
      invites: [],
    },
    validate: (values) => {
      const errors: Record<string, string> = {};
      if (!values.name) {
        errors.name = "Organization name is required";
      }
      return errors;
    },
  });

  const handleSubmit = async () => {
    const res = await createOrg({
      input: form.values,
    });
    if (res.data?.createOrganization) {
      nextStep();
    }
  };

  return (
    <Container size="sm" className={classes.root}>
      <Center>
        <Stack w="100%">
          <Center py="xl">
            <SectionHeader
              ta="center"
              title="Team"
              subTitle="Create a new team"
            />
          </Center>
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Card bg="dark.9" withBorder radius="lg">
              <Card.Section bg="dark.9" p="lg" withBorder>
                <ErrorAlert mb="md" error={error} />
                <Stepper size="sm" active={active}>
                  <Stepper.Step label="General">
                    <Box py="xl">
                      <TextInput
                        {...form.getInputProps("name")}
                        label="Team Name"
                        placeholder="Name"
                      />
                    </Box>
                  </Stepper.Step>
                  <Stepper.Step label="Invite users">
                    <TagsInput
                      label="Invite users"
                      description="Press email address and press Enter to add user to the list. Users will receive an email with invitation link."
                      placeholder="Enter email"
                      splitChars={[",", " ", "|"]}
                      value={form.values.invites as string[]}
                      onChange={(emails) => {
                        form.setFieldValue("invites", emails);
                      }}
                    />
                  </Stepper.Step>
                  <Stepper.Completed>
                    <Center py="xl">
                      <Image w="200px" src={CompletedImage} alt="Completed" />
                    </Center>

                    <Text ta="center" fw="600" fz="lg">
                      Your organization has been created successfully!{" "}
                    </Text>
                    <Text ta="center">
                      <Anchor
                        component={Link}
                        to={clientRoutes.organizationHome(
                          data?.createOrganization?.slug
                        )}
                      >
                        Go to organization
                      </Anchor>{" "}
                      to start using Pilplay.
                    </Text>
                  </Stepper.Completed>
                </Stepper>
              </Card.Section>

              <Card.Section p="lg" pt="md" bg="dark.7">
                <Flex justify="space-between">
                  <Box>
                    {active !== 0 && (
                      <Button variant="secondary" onClick={prevStep} size="sm">
                        Back
                      </Button>
                    )}
                  </Box>
                  <Box>
                    {active !== MAX_STEPS && (
                      <Button
                        loading={fetching}
                        onClick={
                          active !== MAX_STEPS - 1 ? nextStep : handleSubmit
                        }
                        size="sm"
                      >
                        {active === MAX_STEPS - 1 ? "Complete" : "Next"}
                      </Button>
                    )}
                  </Box>
                </Flex>
              </Card.Section>
            </Card>
          </form>
        </Stack>
      </Center>
    </Container>
  );
};
