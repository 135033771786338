import React from "react";
import {
  Anchor,
  Avatar,
  Box,
  Burger,
  Collapse,
  Flex,
  Group,
  Menu,
  Stack,
  Text,
  UnstyledButton,
  rem,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown, IconPlus } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { Logo } from "../../Logo";
import { ButtonLink } from "../../buttons/ButtonLink";
import classes from "./NavigationHeader.module.css";

export interface NavigationHeaderProps {
  links: {
    href: string;
    text: string;
    active?: boolean;
  }[];
  hiddenFrom?: "xs" | "sm" | "md" | "lg" | "xl";
  profile?: {
    name: string;
    avatarUrl?: string | null;
    organizationName?: string | null;
    organizations?: {
      id: string;
      name: string;
      link: string;
    }[];
  };
  newOrganizationLink?: string;
  onLogout?: () => void;
}

export const NavigationHeader: React.FC<NavigationHeaderProps> = ({
  links,
  profile,
  onLogout,
  newOrganizationLink,
  hiddenFrom = "sm",
}) => {
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);

  const linkComponents = links.map((link) => (
    <ButtonLink
      key={link.href}
      link={link.href}
      size="sm"
      fw={600}
      variant="transparent"
      data-active={link.active}
      className={classes.link}
    >
      {link.text}
    </ButtonLink>
  ));

  const profileComponent = profile && (
    <Menu>
      <Menu.Target>
        <UnstyledButton>
          <Group gap={7}>
            <Avatar
              src={profile.avatarUrl}
              alt={profile.name}
              radius="xl"
              size="md"
            />
            <Box>
              <Text fw={500} size="sm" lh={1} mr={3}>
                {profile.name}
              </Text>
              <Text fw={500} c="dimmed" size="sm" mt={4} lh={1} mr={3}>
                {profile.organizationName}
              </Text>
            </Box>
            <IconChevronDown
              style={{ width: rem(12), height: rem(12) }}
              stroke={1.5}
            />
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown miw={200}>
        <Menu.Label>Teams</Menu.Label>
        {profile.organizations?.map((org) => (
          <Anchor key={org.id} component={Link} to={org.link} underline="never">
            <Menu.Item>{org.name}</Menu.Item>
          </Anchor>
        ))}
        {newOrganizationLink && (
          <Menu.Item
            c="orange.7"
            leftSection={<IconPlus size={16} />}
            component={Link}
            to={newOrganizationLink}
          >
            New Team
          </Menu.Item>
        )}
        <Menu.Divider />
        <Anchor component={Link} to={"/profile"} underline="never">
          <Menu.Item>Profile</Menu.Item>
        </Anchor>
        <Menu.Item onClick={onLogout}>
          <Text c="red">Logout</Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );

  return (
    <header className={classes.root}>
      <Group px="xl" py="md" justify="space-between" h="100%">
        <Flex flex="auto" justify="flex-start" align="center">
          <Logo color="white" text="right" width={32} fontSize="lg" />
        </Flex>
        <Flex visibleFrom={hiddenFrom} justify="center" align="center" flex="1">
          {linkComponents}
        </Flex>
        <Flex
          flex="auto"
          justify="flex-end"
          align="center"
          visibleFrom={hiddenFrom}
        >
          {profile ? (
            profileComponent
          ) : (
            <>
              <ButtonLink
                link="/login"
                variant="subtle"
                fw={600}
                c="orange.8"
                size="sm"
                mr="sm"
                visibleFrom={hiddenFrom}
              >
                Log in
              </ButtonLink>
              <ButtonLink
                link="/register"
                visibleFrom={hiddenFrom}
                fw={600}
                size="sm"
              >
                Sign Up
              </ButtonLink>
            </>
          )}
        </Flex>
        <Burger
          opened={linksOpened}
          onClick={toggleLinks}
          size="sm"
          hiddenFrom={hiddenFrom}
        />
      </Group>
      <Collapse
        className={classes.mobileCollapse}
        hiddenFrom={hiddenFrom}
        in={linksOpened}
        bg="dark.8"
      >
        <Stack px="md" className={classes.mobileStack} py="md">
          {linkComponents}
        </Stack>
        <Flex px="xl" py="md" justify="center">
          {profile ? (
            profileComponent
          ) : (
            <>
              <ButtonLink
                fullWidth
                link="/login"
                variant="subtle"
                fw={600}
                c="orange.8"
                size="sm"
                mr="sm"
              >
                Log in
              </ButtonLink>
              <ButtonLink fullWidth link="/register" fw={600} size="sm">
                Sign Up
              </ButtonLink>
            </>
          )}
        </Flex>
      </Collapse>
    </header>
  );
};
