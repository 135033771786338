import { Box, Group, Modal, Stack, Text } from "@mantine/core";
import type { DartHit } from "@pilplay/games";
import { ActionButton } from "@pilplay/ui";
import { IconArrowRight, IconEditCircle, IconX } from "@tabler/icons-react";
import React, { useMemo, useState } from "react";
import InteractiveDartboard from "../../../../../components/InteractiveDartboard";
import { useGameContext } from "../../context/useGameContext";
import classes from "./EditHit.module.css";

interface EditHitProps {
  show: boolean;
  editId?: string;
  isMobile?: boolean;
  onClose: () => void;
  magnifierSize?: number;
  magnifierOffset?: { x: number; y: number };
}

export const EditHit: React.FC<EditHitProps> = ({
  editId,
  onClose,
  magnifierSize = 150,
  magnifierOffset = { x: 0, y: 250 },
}) => {
  const [newHit, setNewHit] = useState<DartHit | undefined>(undefined);
  const { getHitByEventId, editDart } = useGameContext();

  const originalHit = useMemo(() => {
    if (!editId) return undefined;
    return getHitByEventId(editId);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- getHitByEventId not needed
  }, [editId]);

  const onHit = (hit: DartHit) => {
    setNewHit(hit);
  };

  const onEdit = () => {
    if (newHit && editId) {
      void editDart(editId, newHit);
      onClose();
    }
  };

  const roundHitPositions = [originalHit?.position, newHit?.position].filter(
    (p) => Boolean(p)
  ) as { x: number; y: number }[];

  return (
    <Stack>
      <Group align="center" grow justify="center">
        <Box>
          <Text fw="bold" fz="lg" ta="center">
            From
          </Text>
          <Text fw="bold" fz="lg" ta="center">
            {originalHit?.point}
          </Text>
        </Box>
        <IconArrowRight />
        <Box>
          <Text fw="bold" fz="lg" ta="center">
            To
          </Text>
          <Text fw="bold" fz="lg" ta="center">
            {newHit?.point || "-"}
          </Text>
        </Box>
      </Group>
      <Box className={classes.dartboard}>
        <InteractiveDartboard
          hits={roundHitPositions}
          magnifierOffset={magnifierOffset}
          magnifierSize={magnifierSize}
          onHit={onHit}
        />
      </Box>

      <Group grow>
        <ActionButton
          onClick={onClose}
          variant="outline"
          label="Cancel"
          icon={<IconX />}
        />

        <ActionButton
          disabled={!newHit}
          onClick={onEdit}
          label="Save"
          buttonIconPosition="right"
          icon={<IconEditCircle />}
        />
      </Group>
    </Stack>
  );
};

export const EditHitModal: React.FC<EditHitProps> = ({
  editId,
  onClose,
  show,
  ...rest
}) => {
  return (
    <Modal
      centered
      onClose={onClose}
      opened={show ? Boolean(editId) : false}
      size={"lg"}
      radius={"lg"}
      withCloseButton={false}
      withinPortal
    >
      <EditHit editId={editId} onClose={onClose} show={show} {...rest} />
    </Modal>
  );
};

export default EditHit;
