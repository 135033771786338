import type { AlertProps } from "@mantine/core";
import { Alert, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import React from "react";
import type { CombinedError } from "urql";

interface ErrorAlertProps extends AlertProps {
  error?: CombinedError | Error | null;
}

const isCombinedError = (
  error: CombinedError | Error | null
): error is CombinedError => {
  return Boolean(error && "graphQLErrors" in error);
};

const ErrorAlert: React.FC<ErrorAlertProps> = ({ error, ...otherProps }) => {
  if (!error) return null;

  const message = isCombinedError(error)
    ? error.graphQLErrors.map((m) => m.message).join(",")
    : error.message || "Something went wrong";

  return (
    <Alert
      color="red"
      icon={<IconAlertCircle />}
      mt="md"
      title="An error accoured"
      {...otherProps}
    >
      <Text size="xs">{message}</Text>
    </Alert>
  );
};

export default ErrorAlert;
