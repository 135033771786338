import { ButtonLink } from "../ButtonLink";
import classes from "./ExpandingButton.module.css";

interface ExpandingButtonProps {
  label: string;
  onClick?: () => void;
  link?: string;
  disabled?: boolean;
  variant?: "filled" | "outline";
  icon: React.ReactNode;
  buttonIconPosition?: "left" | "right";
  expanding?: boolean;
  size?: "sm" | "md" | "lg" | "xl";
}

export const ExpandingButton: React.FC<ExpandingButtonProps> = ({
  label,
  onClick,
  link,
  disabled,
  variant,
  icon,
  buttonIconPosition,
  size = "lg",
  expanding = false,
}) => {
  const isRightIcon = buttonIconPosition === "right";
  return (
    <div
      style={{
        display: "flex",
        justifyContent: isRightIcon ? "flex-end" : "flex-start",
      }}
    >
      <ButtonLink
        classNames={classes}
        data-expanding={expanding}
        disabled={disabled}
        fullWidth
        leftSection={!isRightIcon && icon}
        link={link}
        onClick={onClick}
        radius="xl"
        rightSection={isRightIcon ? icon : null}
        size={size}
        variant={variant}
      >
        {label}
      </ButtonLink>
    </div>
  );
};
