import { useBoardFollowSubscription } from "@pilplay/graphql";

const useBoardFollow = (id: string) => {
  return useBoardFollowSubscription({
    variables: {
      id,
    },
  });
};

export default useBoardFollow;
