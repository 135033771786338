import {
  ActionIcon,
  Anchor,
  Box,
  Burger,
  Center,
  Flex,
  Modal,
  Stack,
  Text,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconRefreshAlert } from "@tabler/icons-react";
import { clientRoutes } from "../../../../../routes";
import { useGameContext } from "../../context/useGameContext";
import useBoardMutations from "../../../../../hooks/useBoardMutations/useBoardMutations";
import ErrorAlert from "../../../../../components/ErrorAlert";
import { useGame } from "../../../../../hooks/useGame";
import classes from "./GameMenu.module.css";

interface GameMenuProps {
  config: { label: string; value: string | number | undefined }[];
}

const GameMenu: React.FC<GameMenuProps> = () => {
  const [open, setOpen] = useState(false);

  const { abortGame, lobbyId, gameId } = useGameContext();

  const [{ data }] = useGame(gameId);

  const { restartDetection, fetching, error } = useBoardMutations();

  const navigate = useNavigate();

  const abortGameConfirm = () => {
    modals.openConfirmModal({
      title: "Abort game",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to abort the game? You will lose all progress.
        </Text>
      ),
      labels: { confirm: "Abort", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        await abortGame();
        if (lobbyId) {
          navigate(clientRoutes.lobbySelectPage(lobbyId));
        } else {
          navigate(clientRoutes.root);
        }
      },
    });
  };

  const onClose = () => {
    setOpen(false);
  };

  const hasBoard = data?.game.board;

  const onRestartDetection = async () => {
    if (!data?.game.board) {
      return;
    }
    await restartDetection({
      id: data.game.board.id,
    });
  };

  return (
    <>
      <Modal
        opened={open}
        onClose={onClose}
        overlayProps={{
          backgroundOpacity: 0.8,
          blur: 8,
        }}
        transitionProps={{ transition: "scale", duration: 200 }}
        classNames={{
          body: classes.modalBody,
          header: classes.modalHeader,
          content: classes.modalContent,
        }}
        closeButtonProps={{
          size: "xl",
        }}
        centered
        fullScreen
      >
        <Center>
          <Box py="xl" maw={320}>
            <Stack gap="xl">
              <Anchor onClick={onClose} c="white">
                <Text fw="bold" fz={32} ta="center">
                  Close
                </Text>
              </Anchor>
              <Anchor onClick={abortGameConfirm} c="red.8">
                <Text fw="bold" fz={32} ta="center">
                  Abort Game
                </Text>
              </Anchor>
              <ErrorAlert error={error} />
              {hasBoard ? (
                <Flex
                  mt={120}
                  justify="center"
                  direction="column"
                  align="center"
                >
                  <ActionIcon
                    onClick={onRestartDetection}
                    loading={fetching}
                    size={96}
                  >
                    <IconRefreshAlert stroke={1.2} size={64} />
                  </ActionIcon>
                  <Text mt="md" ta="center" fw="600" fz="14pt">
                    Reboot
                  </Text>
                </Flex>
              ) : null}
            </Stack>
          </Box>
        </Center>
      </Modal>

      <Burger
        onClick={() => {
          setOpen(true);
        }}
        size="sm"
      />
    </>
  );
};

export default GameMenu;
