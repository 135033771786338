import { Group, Skeleton, Table } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { IconEdit } from "@tabler/icons-react";
import type { ReactNode } from "react";
// import classes from "./RoundTable.module.css";

interface TableCellRendererProps<Round> {
  item: Round;
  column: {
    key: string;
    render?: (item: Round) => ReactNode;
    valueFn?: (item: Round) => string | number | undefined | null;
    onClick?: (item: Round) => void;
  };
}

const TableCellRenderer = <Round,>({
  item,
  column,
}: TableCellRendererProps<Round>): React.ReactElement | null => {
  const { hovered, ref } = useHover();
  if (column.render) {
    return <>{column.render(item)}</>;
  }

  let cellValue;
  if (column.valueFn) {
    cellValue = column.valueFn(item);
  } else if (typeof item === "object" && item && column.key in item) {
    cellValue = item[column.key as keyof Round];
  } else {
    return null;
  }
  if (typeof cellValue !== "string" && typeof cellValue !== "number") {
    return null;
  }

  return (
    <Group
      gap={4}
      onClick={() => {
        column.onClick && column.onClick(item);
      }}
      ref={ref}
      style={{
        cursor: cellValue && column.onClick ? "pointer" : "default",
      }}
    >
      {cellValue}
      {column.onClick && (
        <IconEdit size={14} style={{ opacity: hovered ? 1 : 0 }} />
      )}
    </Group>
  );
};

export interface TableColumn<Round> {
  key: string;
  title: string;
  render?: (item: Round) => ReactNode;
  valueFn?: (item: Round) => string | number | undefined | null;
  onClick?: (item: Round) => void;
}

export interface RoundTableProps<Round> {
  data: Round[];
  columns: TableColumn<Round>[];
  loading?: boolean;
  skeletonRows?: number;
}

export const RoundTable = <Round,>({
  data,
  columns,
  loading,
  skeletonRows = 5,
}: RoundTableProps<Round>): React.ReactElement => (
  <Table striped>
    <Table.Thead>
      <Table.Tr>
        {columns.map((column) => (
          <Table.Th key={String(column.key)}>{column.title}</Table.Th>
        ))}
      </Table.Tr>
    </Table.Thead>
    <Table.Tbody>
      {!loading
        ? data.map((item, index) => (
            <Table.Tr key={index}>
              {columns.map((column) => (
                <Table.Td key={String(column.key)}>
                  <TableCellRenderer item={item} column={column} />
                </Table.Td>
              ))}
            </Table.Tr>
          ))
        : Array.from({ length: skeletonRows }).map((_, i) => (
            <Table.Tr key={`history-row-skeleton-${i}`}>
              {columns.map((column) => (
                <Table.Td key={String(column.key)}>
                  <Skeleton animate h={20} />
                </Table.Td>
              ))}
            </Table.Tr>
          ))}
    </Table.Tbody>
  </Table>
);
