import { CricketScoringRule, CricketWinCondition } from "@pilplay/graphql";
import React from "react";
import GameHeader from "../../../GameHeader";
import { useCricketGame } from "../../context/useCricketGame";

export const scoringRuleToText = (
  rule: CricketScoringRule | undefined | null
) => {
  switch (rule) {
    case CricketScoringRule.Cutthroat:
      return "Cut Throath";
    case CricketScoringRule.Standard:
      return "Standard";
    default:
      return "-";
  }
};

export const winConditionToText = (
  rule: CricketWinCondition | undefined | null
) => {
  switch (rule) {
    case CricketWinCondition.Allclosed:
      return "All Closed";
    case CricketWinCondition.Allclosedhighestscore:
      return "All closed & best score";
    default:
      return "-";
  }
};

const CricketHeader: React.FC = () => {
  const { config, round } = useCricketGame();

  return (
    <GameHeader
      config={[
        {
          label: "Scoring rule",
          value: scoringRuleToText(config?.scoringRule),
        },
        { label: "Hits to open/close", value: config?.hitsToOpenClose },
        { label: "Start number", value: config?.startNumber },
        { label: "Include Bull", value: config?.includeBull ? "Yes" : "No" },
        { label: "Max rounds", value: config?.numRounds },
        {
          label: "Game ends",
          value: winConditionToText(config?.winCondition),
        },
      ]}
      headerLabel={"Cricket"}
      headerSubLabel={scoringRuleToText(config?.scoringRule)}
      round={round}
      totalRounds={config?.numRounds}
    />
  );
};

export default CricketHeader;
