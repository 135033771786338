import { Drawer } from "@mantine/core";
import type { DartHit } from "@pilplay/games";
import type { CricketRound } from "@pilplay/graphql";
import type { TableColumn } from "@pilplay/ui";
import { GameHistory } from "@pilplay/ui";
import React, { useMemo } from "react";
import { useGameContext } from "../../../../context/useGameContext";
import { useCricketGame } from "../../context/useCricketGame";

const tableColumns = (
  onClick: (hit?: DartHit | null) => void
): TableColumn<CricketRound>[] => {
  return [
    {
      key: "roundNumber",
      title: "Round",
    },
    {
      key: "hit1",
      title: "Dart 1",
      valueFn: (round: CricketRound) => round.hits[0]?.point,
      onClick: (round: CricketRound) => {
        onClick(round.hits[0]);
      },
    },
    {
      key: "hit2",
      title: "Dart 2",
      valueFn: (round: CricketRound) => round.hits[0]?.point,
      onClick: (round: CricketRound) => {
        onClick(round.hits[1]);
      },
    },
    {
      key: "hit3",
      title: "Dart 3",
      valueFn: (round: CricketRound) => round.hits[0]?.point,
      onClick: (round: CricketRound) => {
        onClick(round.hits[2]);
      },
    },
  ];
};

interface CricketHistoryProps {
  isMobile: boolean;
  open: boolean;
  onClose: () => void;
  onHitClick: (hit?: DartHit | null) => void;
}

const CricketHistory: React.FC<CricketHistoryProps> = ({
  isMobile,
  open,
  onClose,
  onHitClick,
}) => {
  const { fetching } = useGameContext();
  const { activePlayer, playerRounds } = useCricketGame();

  const columns = useMemo(() => tableColumns(onHitClick), [onHitClick]);

  return (
    <Drawer
      onClose={onClose}
      opened={open}
      position={isMobile ? "bottom" : "left"}
      size={isMobile ? "70%" : "md"}
      title="Rounds"
    >
      <GameHistory
        playerRoundsData={playerRounds}
        columns={columns}
        loading={fetching}
        initialPlayerId={activePlayer?.id}
      />
    </Drawer>
  );
};

export default CricketHistory;
