import type { SwitchProps} from "@mantine/core";
import { Group, Text, Switch } from "@mantine/core";
import React from "react";
import classes from "./SwitchConfig.module.css";

interface SwitchConfigProps extends SwitchProps {
  title: string;
  description: string;
}

const SwitchConfig: React.FC<SwitchConfigProps> = ({
  title,
  description,
  ...switchProps
}) => {
  return (
    <Group className={classes.item} gap="xl" justify="apart" wrap="nowrap">
      <div>
        <Text>{title}</Text>
        <Text c="dimmed" size="xs">
          {description}
        </Text>
      </div>
      <Switch {...switchProps} />
    </Group>
  );
};

export default SwitchConfig;
