import type { AddGuestInput } from "@pilplay/graphql";
import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { IconPlus } from "@tabler/icons-react";
import React, { useEffect } from "react";
import { useGameMutations } from "../../../../../hooks/useGameMutations/useGameMutations";

interface AddGuestButtonProps {
  gameCode?: number;
}

const GuestForm: React.FC<{ gameCode?: number; onAddGuest: () => void }> = ({
  gameCode,
  onAddGuest,
}) => {
  const { addGuest, error } = useGameMutations();

  const form = useForm<AddGuestInput>({
    initialValues: {
      name: "",
      gameCode: gameCode || -1,
    },
  });

  useEffect(() => {
    form.setValues({
      ...form.values,
      gameCode,
    });
  }, [gameCode]);

  const handleSubmit = async (values: AddGuestInput) => {
    const res = await addGuest({ input: values });
    if (res.data?.addGuest) {
      form.reset();
      onAddGuest();
    }
  };
  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <TextInput
        data-autofocus
        error={error?.graphQLErrors.map((e) => e.message).join(", ")}
        label="Name"
        placeholder="Guest name"
        {...form.getInputProps("name")}
      />
      <Button fullWidth mt="md" type="submit">
        Add
      </Button>
    </form>
  );
};

const AddGuestButton: React.FC<AddGuestButtonProps> = ({ gameCode }) => {
  return (
    <Button
      ml="sm"
      onClick={() => {
        modals.open({
          title: "Add guest",
          children: (
            <GuestForm
                gameCode={gameCode}
                onAddGuest={() => { modals.closeAll(); }}
              />
          ),
        });
      }}
      rightSection={<IconPlus size={14} />}
      size="xs"
      variant="subtle"
    >
      Add guest
    </Button>
  );
};

export default AddGuestButton;
