import { Anchor, Box, Center, Stack, Text } from "@mantine/core";
import { IconBluetoothOff } from "@tabler/icons-react";
import classes from "./style.module.css";

export const BluetoothUnavailableScreen: React.FC = () => {
  return (
    <div>
      <Stack>
        <div>
          <Center>
            <div className={classes.iconCircle}>
              <IconBluetoothOff color="var(--mantine-color-dark-2)" size={64} />
            </div>
          </Center>
          <Text ta="center" mt="md">
            Bluetooth is not available
          </Text>
          <Box px="xl">
            <Text ta="center" fz="sm" mt="sm" c="dimmed">
              Please you a bluetooth enabled browser to configure your board.
            </Text>

            <Text ta="center" fz="sm" mt="sm" c="dimmed">
              We recommend using{" "}
              <Anchor
                component="a"
                target="_blank"
                href="https://www.google.com/chrome/"
                fz="sm"
              >
                Google Chrome
              </Anchor>{" "}
              or{" "}
              <Anchor
                component="a"
                target="_blank"
                href="https://www.microsoft.com/en-us/edge"
                fz="sm"
              >
                Microsoft Edge
              </Anchor>
            </Text>
          </Box>
        </div>
      </Stack>
    </div>
  );
};
