import React, { useEffect, useRef } from "react";
import { useSpring, animated } from "@react-spring/web";

interface AnimatedNumberProps {
  value: number;
}

const AnimatedNumber: React.FC<AnimatedNumberProps> = ({ value }) => {
  const prevValueRef = useRef(value);
  useEffect(() => {
    prevValueRef.current = value;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only update on first render
  }, []);

  const spring = useSpring({
    value,
    from: { value: prevValueRef.current },
    config: { duration: 500 },
  });

  return (
    <animated.span>{spring.value.to((val) => Math.floor(val))}</animated.span>
  );
};

export default AnimatedNumber;
