import React from "react";
import { Text } from "@mantine/core";
import cx from "clsx";
import { GameType } from "@pilplay/games";
import classes from "./game-card.module.css";
import X01Image from "./x01.png";
import CricketImage from "./cricket.png";

interface GameCardProps {
  onClick?: () => void;
  type: GameType;
  active?: boolean;
}

const getImage = (type: GameType) => {
  switch (type) {
    case GameType.X01:
      return X01Image;
    case GameType.Cricket:
      return CricketImage;
  }
};

const getTitle = (type: GameType) => {
  switch (type) {
    case GameType.X01:
      return "X01";
    case GameType.Cricket:
      return "Cricket";
  }
};

const getDescription = (type: GameType) => {
  switch (type) {
    case GameType.X01:
      return "The classic game of 301,501 etc";
    case GameType.Cricket:
      return "Challenge your friends to a game of cricket";
  }
};

const GameCard: React.FC<GameCardProps> = ({
  type,
  active = false,
  onClick,
}) => {
  const image = getImage(type);
  const title = getTitle(type);
  const description = getDescription(type);
  return (
    <button
      className={cx(classes.root, { [classes.active]: active })}
      onClick={onClick}
      type="button"
    >
      <div
        className={classes.image}
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <div>
        <Text className={classes.title}>{title}</Text>
        <Text c="dimmed" className={classes.description} fz="sm">
          {description}
        </Text>
      </div>
    </button>
  );
};

export default GameCard;
