import {
  Box,
  Collapse,
  Container,
  Flex,
  Grid,
  Paper,
  Text,
} from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import React from "react";
import { useIsMobile } from "../../../../hooks/layout/useDevice";
import classes from "./ConfigPageContainer.module.css";

interface ConfigPageContainerProps {
  children: React.ReactNode;
  gameModeLabel: string;
  gameModeDescription: React.ReactNode;
}

const ConfigPageContainer: React.FC<ConfigPageContainerProps> = ({
  children,
  gameModeLabel,
  gameModeDescription,
}) => {
  const isMobile = useIsMobile();
  const [showDescription, toggleDescription] = useToggle([true, false]);

  return (
    <Container className={classes.container} pt="md">
      <Grid>
        <Grid.Col order={isMobile ? 2 : 1} span={{ base: 12, sm: 6 }}>
          {children}
        </Grid.Col>
        <Grid.Col order={isMobile ? 1 : 2} span={{ base: 12, md: 6, sm: 6 }}>
          <Paper
            p="md"
            radius="md"
            shadow="md"
            style={{
              height: "fit-content",
              maxHeight: "initial",
            }}
          >
            <Flex direction="column" style={{ height: "100%" }}>
              <Flex
                align="center"
                justify="space-between"
                onClick={() => { toggleDescription(); }}
              >
                <Text fw="bold" size="xl">
                  {gameModeLabel}
                </Text>
                <Box
                  style={{
                    transform: !showDescription
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                >
                  <IconChevronDown />
                </Box>
              </Flex>
              <Collapse in={showDescription}>
                <Text mt="md" size="sm">
                  {gameModeDescription}
                </Text>
              </Collapse>
            </Flex>
          </Paper>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default ConfigPageContainer;
