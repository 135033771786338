import React, { useEffect, useState } from "react";
import { Text } from "@mantine/core";
import classes from "./CountdownTimer.module.css";

export interface CountdownTimerProps {
  expiresAt: string | Date | number;
  onExpire?: () => void;
}

export const CountdownTimer: React.FC<CountdownTimerProps> = ({
  expiresAt,
  onExpire,
}) => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    setTime(
      Math.max(
        0,
        Math.floor((new Date(expiresAt).getTime() - Date.now()) / 1000)
      )
    );
  }, [expiresAt]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((t) => {
        if (t <= 0) {
          clearInterval(timer);
          onExpire?.();
          return 0;
        }
        return t - 1;
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [onExpire]);

  return (
    <Text c="dimmed" fz="sm" className={classes.root}>
      Time left: {`${Math.floor(time / 60)}`.padStart(2, "0")}:
      {`${time % 60}`.padStart(2, "0")}
    </Text>
  );
};
