import React, { useState } from "react";
import {
  Box,
  Card,
  Container,
  Text,
  Grid,
  Paper,
  Stack,
  Code,
  Skeleton,
  LoadingOverlay,
  Tooltip,
  Flex,
} from "@mantine/core";
import { useParams } from "react-router-dom";
import {
  CallToActionButton,
  ConnectionStatusBadge,
  SectionHeader,
} from "@pilplay/ui";
import {
  IconExternalLink,
  IconLockAccess,
  IconScanEye,
} from "@tabler/icons-react";
import { AreaChart } from "@mantine/charts";
import { BoardStatus } from "@pilplay/graphql";
import useBoard from "../../../hooks/useBoard";
import ErrorAlert from "../../../components/ErrorAlert";
import { clientRoutes } from "../../../routes";
import { GenerateSignInCodeModal } from "../BoardsPage/components/GenerateSignInCodeModal";
import { useGames } from "../../../hooks/useGames";
import { countItemsByDate } from "../../../utils/charts";
import classes from "./BoardPage.module.css";

export const BoardPage: React.FC = () => {
  const { slug, id } = useParams<{ slug: string; id: string }>();
  const [showSignInCodeModal, setShowSignInCodeModal] = useState(false);

  const [{ data, fetching, error }] = useBoard(id);

  const {
    games,
    fetching: gamesFetching,
    error: gamesError,
  } = useGames({
    filter: {
      boardId: id,
    },
  });

  const gamesPlayedByDate = countItemsByDate(games ?? []);

  return (
    <Container size="xl" className={classes.root}>
      <SectionHeader
        mt="xl"
        breadcrumbs={[
          { label: "Home" },
          { label: "Organization", link: `/organization/${slug}` },
          { label: "Boards", link: `/organization/${slug}/boards` },
          { label: data?.board.name || "", active: true },
        ]}
        title="Board"
        subTitle="Manage your board"
      />

      <Grid>
        <Grid.Col span={{ base: 12, sm: 8 }}>
          <ErrorAlert error={error || gamesError} />
          <Grid>
            <Grid.Col span={{ base: 12, md: 12 }}>
              <Paper mt="34" bg="dark.9" pt="lg" radius="lg" px="xl" pb="xl">
                <Grid>
                  <Grid.Col span={{ base: 6, sm: 4 }}>
                    <Box>
                      <Text size="sm" fw="600" c="dimmed">
                        Name
                      </Text>
                      <Skeleton visible={fetching}>
                        <Text fw="bold">{data?.board.name}</Text>
                      </Skeleton>
                    </Box>
                  </Grid.Col>
                  <Grid.Col span={{ base: 6, sm: 4 }}>
                    <Box>
                      <Text size="sm" fw="600" c="dimmed">
                        Connection status
                      </Text>
                      <Skeleton visible={fetching}>
                        {data ? (
                          <ConnectionStatusBadge status={data.board.status} />
                        ) : null}
                      </Skeleton>
                    </Box>
                  </Grid.Col>

                  <Grid.Col span={{ base: 12, sm: 4 }}>
                    <Box>
                      <Text size="sm" fw="600" c="dimmed">
                        Version
                      </Text>
                      <Skeleton visible={fetching || !data}>
                        {data ? <Code>{data?.board.version}</Code> : null}
                      </Skeleton>
                    </Box>
                  </Grid.Col>
                </Grid>
              </Paper>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 12 }}>
              <Card bg="dark.9" shadow="sm" radius="md">
                <Card.Section fw="600" inheritPadding py="md">
                  Games played last 30 days
                </Card.Section>
                <Card.Section inheritPadding py="md" pos="relative">
                  <LoadingOverlay visible={gamesFetching} />
                  <AreaChart
                    h={230}
                    data={gamesPlayedByDate}
                    dataKey="date"
                    series={[
                      {
                        name: "count",
                        label: "Games played",
                        color: "indigo.6",
                      },
                    ]}
                    curveType="monotone"
                    withDots={false}
                  />
                </Card.Section>
              </Card>
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 4 }}>
          <Card bg="dark.8">
            <Card.Section mb="xs">Links</Card.Section>
            <Card.Section>
              <Stack>
                <CallToActionButton
                  to={clientRoutes.lobbyIdlePage(id)}
                  icon={<IconExternalLink size={32} />}
                  title="Open Lobby"
                  target="_blank"
                  description="Open the board lobby page"
                />
                <CallToActionButton
                  to={clientRoutes.boardFollowPage(id)}
                  icon={<IconExternalLink size={32} />}
                  title="Open Big Screen Mode"
                  target="_blank"
                  description="Open the big screen display"
                />
              </Stack>
            </Card.Section>
            <Card.Section my="md">Actions</Card.Section>
            <Card.Section>
              <Stack>
                <GenerateSignInCodeModal
                  boardId={id!}
                  open={showSignInCodeModal}
                  onClose={() => {
                    setShowSignInCodeModal(false);
                  }}
                />
                <CallToActionButton
                  onClick={() => {
                    setShowSignInCodeModal(true);
                  }}
                  icon={<IconLockAccess size={32} />}
                  title="Sign in code"
                  description="Create a temporary sign in code"
                />
                <Tooltip
                  disabled={data?.board.status === BoardStatus.Connected}
                  label="Board needs to be connected to be able to calibrate it"
                >
                  <Flex w="100%">
                    <CallToActionButton
                      to={clientRoutes.calibrateBoard(slug, id)}
                      icon={<IconScanEye size={32} />}
                      title="Calibrate"
                      disabled={data?.board.status !== BoardStatus.Connected}
                      description="Calibrate your board"
                    />
                  </Flex>
                </Tooltip>
              </Stack>
            </Card.Section>
          </Card>
        </Grid.Col>
      </Grid>
    </Container>
  );
};
