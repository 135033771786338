import React, { useEffect, useMemo } from "react";
import { Alert, Center, LoadingOverlay } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";
import { useSignInWithApiKey } from "../../hooks/useSignInWithApiToken/useSignInWithApiToken";
import { TOKEN_KEY, setLocalStorage } from "../../utils/storage";

export interface AuthWrapperApiKeyProps {
  children: React.ReactNode;
}

export const AuthWrapperApiKey: React.FC<AuthWrapperApiKeyProps> = ({
  children,
}) => {
  const { isSignedIn, setLogin } = useAuthContext();
  const [{ data, fetching, error }, signIn] = useSignInWithApiKey();
  const navigate = useNavigate();
  const key = useMemo(
    () => new URLSearchParams(window.location.search).get("key"),
    []
  );

  useEffect(() => {
    if (key && !isSignedIn) {
      void signIn({ apiToken: key });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to run this effect once
  }, [key]);

  useEffect(() => {
    if (!isSignedIn && data?.signInWithApiToken) {
      setLocalStorage(TOKEN_KEY, data.signInWithApiToken);
      setLogin(data.signInWithApiToken);
    }
  }, [data?.signInWithApiToken, isSignedIn, setLogin]);

  useEffect(() => {
    if (isSignedIn && key && key?.length > 2) {
      navigate(window.location.pathname, {
        replace: true,
      });
    }
  }, [isSignedIn, key, navigate]);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (isSignedIn && queryParams.has("key")) {
      queryParams.delete("key");
    }
  }, [isSignedIn]);

  if (fetching) {
    return <LoadingOverlay visible />;
  }
  if (error) {
    return (
      <Center h="100vh">
        <Alert title="Error signing in" color="red">
          {error.message}
        </Alert>
      </Center>
    );
  }
  if (!isSignedIn) {
    return (
      <Center h="100vh">
        <Alert title="Not Authenticated" color="red">
          You are not authenticated. Please sign in.
        </Alert>
      </Center>
    );
  }

  return <>{children}</>;
};
