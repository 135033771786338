import React from "react";
import { useParams } from "react-router-dom";
import {
  Text,
  Box,
  Center,
  Container,
  LoadingOverlay,
  Paper,
} from "@mantine/core";
import useBoard from "../../../hooks/useBoard";
import useFetch from "../../../hooks/useFetch";

interface BoardIFramePageProps {}

const BoardIFramePage: React.FC<BoardIFramePageProps> = ({}) => {
  const { id } = useParams<{ id: string }>();
  const [iframeLoaded, setIframeLoaded] = React.useState(false);
  const [{ data, fetching, error }] = useBoard(id!);

  const { statusCode, data: test } = useFetch({
    url: `http://${data?.board.boardInfo.localAddress}/healthz`,
    skip: !data?.board.boardInfo.localAddress,
    parseJson: false,
  });

  if (fetching) {
    return <LoadingOverlay visible />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (statusCode !== 200) {
    return (
      <Container mt={120}>
        <Center>
          <Paper maw={320}>
            <Box p="md">
              <Text ta="center">
                Can't connect to board, make sure your on the same network
              </Text>
            </Box>
          </Paper>
        </Center>
      </Container>
    );
  }

  const iframeUrl = `http://${data?.board.boardInfo.localAddress}/logs`;

  return (
    <Box h="100vh" pos="relative" w="100%">
      <LoadingOverlay visible={fetching || !iframeLoaded} />
      <iframe
        onLoad={() => { setIframeLoaded(true); }}
        src={iframeUrl}
        style={{
          width: "100%",
          height: "100vh",
          border: "none",
        }}
      />
    </Box>
  );
};

export default BoardIFramePage;
