import { Anchor, Box, Modal, Stack, Text } from "@mantine/core";
import React from "react";
import { modals } from "@mantine/modals";
import { useLobbyMutations } from "../../hooks/useLobbyMutations/useLobbyMutations";
import { useAuthContext } from "../../contexts/AuthContext";
import classes from "./GameLobbyOptionsModal.module.css";

interface GameLobbyOptionsModalProps {
  lobbyId: string;
  opened: boolean;
  onClose: () => void;
}

const GameLobbyOptionsModal: React.FC<GameLobbyOptionsModalProps> = ({
  lobbyId,
  opened,
  onClose,
}) => {
  const { cancelLobby } = useLobbyMutations();
  const { logout } = useAuthContext();

  const handleCancelLobby = () => {
    modals.openConfirmModal({
      title: "Cancel Lobby",

      labels: {
        confirm: "Cancel Lobby",
        cancel: "Keep playing",
      },
      centered: true,
      children: (
        <Text>
          Are you sure you want to cancel this lobby? You will not be able to
          rejoin and your game session will end.
        </Text>
      ),
      cancelProps: {
        variant: "filled",
        color: "orange",
      },
      confirmProps: {
        variant: "subtle",
        color: "red",
      },

      onConfirm: () => {
        void cancelLobby({
          input: {
            lobbyId,
          },
        });
      },
    });
  };

  const handleLogout = () => {
    modals.openConfirmModal({
      title: "Logout from account",

      labels: {
        confirm: "Logout",
        cancel: "Keep playing",
      },
      centered: true,
      children: <Text>Are you sure you want to logout from your account?</Text>,
      cancelProps: {
        variant: "filled",
        color: "orange",
      },
      confirmProps: {
        variant: "subtle",
        color: "red",
      },

      onConfirm: () => {
        logout();
      },
    });
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      overlayProps={{
        backgroundOpacity: 0.7,
        blur: 8,
      }}
      transitionProps={{ transition: "scale", duration: 200 }}
      classNames={{
        body: classes.modalBody,
        header: classes.modalHeader,
        content: classes.modalContent,
      }}
      closeButtonProps={{
        size: "xl",
      }}
      centered
      fullScreen
    >
      <Box py="xl">
        <Stack gap="xl">
          <Anchor onClick={handleCancelLobby} c="red">
            <Text fw="bold" fz={32} ta="center">
              Cancel Lobby
            </Text>
          </Anchor>
          <Anchor onClick={handleLogout} c="red">
            <Text fw="bold" fz={32} ta="center">
              Logout
            </Text>
          </Anchor>
          <Anchor onClick={onClose} c="white">
            <Text fw="bold" fz={32} ta="center">
              Close
            </Text>
          </Anchor>
        </Stack>

        <Stack mt="xl" gap="xs">
          <Text fz="xs" c="dimmed" ta="center">
            Lobby ID: {lobbyId}
          </Text>
        </Stack>
      </Box>
    </Modal>
  );
};

export default GameLobbyOptionsModal;
