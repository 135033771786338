import type { PaperProps} from "@mantine/core";
import { Box, Paper, Center } from "@mantine/core";
import React from "react";
import { Logo } from "@pilplay/ui";

interface CenterPaperProps extends PaperProps {
  children?: React.ReactNode;
}

const CenterPaper: React.FC<CenterPaperProps> = ({ children, ...props }) => {
  return (
    <Box
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Center>
        <Paper p="xl" radius="md" withBorder {...props}>
          <>
            <Logo text="below" />
            {children}
          </>
        </Paper>
      </Center>
    </Box>
  );
};

export default CenterPaper;
