import React from "react";
import { Affix, Center, Stack, Text } from "@mantine/core";
import { ButtonLink, Logo } from "@pilplay/ui";
import { useParams } from "react-router-dom";
import { clientRoutes } from "../../../routes";
import useBoard from "../../../hooks/useBoard";

export const BoardStartPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [{ data }] = useBoard(id);

  return (
    <Center h="100vh">
      <Affix bottom={16} right={16}>
        <Text fz="xs" c="dimmed" ta="right">
          Board: {data?.board.name}
        </Text>
        <Text fz="xs" c="dimmed" ta="right">
          Version: {data?.board.version}
        </Text>
      </Affix>
      <Stack maw="320">
        <Logo width={96} />
        <Text ta="center" fw="bold" size="lg">
          Let&apos;s get started!
        </Text>
        <Text c="dimmed" ta="center" size="sm">
          Click on the button below to start a new lobby
        </Text>
        <ButtonLink link={clientRoutes.boardLobbyPage(id)} size="lg">
          Start
        </ButtonLink>
      </Stack>
    </Center>
  );
};
