import { GameEventsSubscription, GameSnapshot } from "@pilplay/graphql";
import EventEmitter from "events";
import { Immutable } from "immer";

import { GameEvent, GameEventStart, GameEventType } from "../game/types";

export enum GameAnimationEvent {
  NextPlayer = "nextPlayer",
  PreviousPlayer = "previousPlayer",
  Bust = "bust",
  Winner = "winner",
}

abstract class Game<TSnapshot extends GameSnapshot> {
  gameId: string;
  events: GameEvent[] = [];
  emitter: EventEmitter;

  protected abstract snapshot: Immutable<TSnapshot>;

  constructor(gameId: string) {
    this.gameId = gameId;
    this.emitter = new EventEmitter();
  }

  onGameEvent(event: GameEventsSubscription["gameEvents"]) {
    switch (event.__typename) {
      case "GameEventThrow":
        this.onEvent({
          type: GameEventType.THROW,
          id: event.id,
          timestamp: new Date(event.timestamp).getTime(),
          payload: {
            gameId: event.game.id,
            point: event.point,
            position: event.position
              ? {
                  x: event.position.x,
                  y: event.position.y,
                }
              : undefined,
          },
        });
        break;
      case "GameEventStart":
        this.onEvent({
          type: GameEventType.START,
          timestamp: new Date(event.timestamp).getTime(),
          id: event.id,
          payload: {
            gameId: event.game.id,
          },
        });
        break;
      case "GameEventManualNext":
        this.onEvent({
          type: GameEventType.MANUAL_NEXT,
          timestamp: new Date(event.timestamp).getTime(),
          id: event.id,
          payload: {
            gameId: event.game.id,
          },
        });
        break;
      case "GameEventEdit":
        this.onEvent({
          type: GameEventType.EDIT,
          timestamp: new Date(event.timestamp).getTime(),
          id: event.id,
          payload: {
            gameId: event.game.id,
            eventId: event.eventId,
            point: event.point,
            position: event.position
              ? {
                  x: event.position.x,
                  y: event.position.y,
                }
              : undefined,
          },
        });
        break;
      case "GameEventFinish":
        this.onEvent({
          id: event.id,
          type: GameEventType.FINISH,
          timestamp: new Date(event.timestamp).getTime(),
          payload: {
            gameId: event.game.id,
          },
        });
        break;
      case "GameEventEmptyBoard":
        this.onEvent({
          id: event.id,
          type: GameEventType.EMPTY_BOARD,
          timestamp: new Date(event.timestamp).getTime(),
          payload: {
            gameId: event.game.id,
          },
        });
        break;
      case "GameEventUndo":
        this.onEvent({
          id: event.id,
          type: GameEventType.UNDO,
          timestamp: new Date(event.timestamp).getTime(),
          payload: {
            gameId: event.game.id,
          },
        });
        break;
    }
  }

  onEvent(event: GameEvent) {
    if (this.snapshot.finished) {
      if (event.type !== GameEventType.UNDO) {
        console.warn("Game is finished, no more events allowed");
        return;
      }
    }
    switch (event.type) {
      case GameEventType.START:
        this.onStartEvent(event);
        break;
      case GameEventType.THROW:
        this.onThrowEvent(event);
        break;
      case GameEventType.EDIT:
        this.onEditEvent(event);
        break;
      case GameEventType.MANUAL_NEXT:
        this.onManualNextEvent(event);
        break;
      case GameEventType.EMPTY_BOARD:
        this.onEmptyBoardEvent(event);
        break;
      case GameEventType.FINISH:
        console.log("Finish");
        break;
      case GameEventType.UNDO:
        this.onUndoEvent(event);
    }
    this.events.push(event);
    this.emitter.emit("gameStateChange", this.snapshot);
  }

  onGameStateChange(cb: (state: Immutable<TSnapshot>) => void) {
    this.emitter.on("gameStateChange", cb);
  }

  offGameStateChange(listener: (state: Immutable<TSnapshot>) => void) {
    this.emitter.removeListener("gameStateChange", listener);
  }

  abstract onStartEvent(event: GameEventStart): void;
  abstract onThrowEvent(event: GameEvent): void;
  abstract onEditEvent(event: GameEvent): void;
  abstract onManualNextEvent(event: GameEvent): void;
  abstract onEmptyBoardEvent(event: GameEvent): void;
  abstract onUndoEvent(event: GameEvent): void;

  public onNextPlayer(callback: (playerId: string) => void) {
    this.emitter.on(GameAnimationEvent.NextPlayer, callback);
  }
  public onPreviousPlayer(callback: (playerId: string) => void) {
    this.emitter.on(GameAnimationEvent.PreviousPlayer, callback);
  }
  public onBust(callback: (playerId: string) => void) {
    this.emitter.on(GameAnimationEvent.Bust, callback);
  }
  public onWinner(callback: (playerId: string) => void) {
    this.emitter.on(GameAnimationEvent.Winner, callback);
  }
  public offNextPlayer(callback: (playerId: string) => void) {
    this.emitter.off(GameAnimationEvent.NextPlayer, callback);
  }
  public offPreviousPlayer(callback: (playerId: string) => void) {
    this.emitter.off(GameAnimationEvent.PreviousPlayer, callback);
  }
  public offBust(callback: (playerId: string) => void) {
    this.emitter.off(GameAnimationEvent.Bust, callback);
  }
  public offWinner(callback: (playerId: string) => void) {
    this.emitter.off(GameAnimationEvent.Winner, callback);
  }

  public getGameState(): Immutable<TSnapshot> {
    return this.snapshot;
  }
}

export default Game;
