import React from "react";
import { AppShell, rem } from "@mantine/core";
import { useHeadroom } from "@mantine/hooks";
import { NavigationHeader } from "@pilplay/ui";
import { Outlet, useParams } from "react-router-dom";
import { clientRoutes } from "../../routes";
import { useMe } from "../../hooks/useMe";
import { isUser } from "../../hooks/useMe/useMe";

const LINKS = [
  {
    text: "Organization",
    href: clientRoutes.root,
  },
];

const AppLayout: React.FC = () => {
  const pinned = useHeadroom({ fixedAt: 120 });

  const [{ data }] = useMe();

  const { slug } = useParams<{ slug?: string }>();

  const activeOrganization = isUser(data)
    ? data.me.organizations.find((org) => org.slug === slug)
    : undefined;

  return (
    <AppShell
      header={{ height: 60, collapsed: !pinned, offset: false }}
      padding="md"
    >
      <AppShell.Header>
        <NavigationHeader
          profile={
            data
              ? {
                  name: data.me.displayName,
                  avatarUrl: isUser(data) ? data.me.avatarUrl : undefined,
                  organizationName: activeOrganization?.name,
                  organizations: isUser(data)
                    ? data.me.organizations.map((org) => ({
                        id: org.id,
                        name: org.name,
                        link: clientRoutes.organizationHome(org.slug),
                      }))
                    : undefined,
                }
              : undefined
          }
          newOrganizationLink={clientRoutes.newOrganization}
          links={LINKS}
        />
      </AppShell.Header>
      <AppShell.Main pt={`calc(${rem(60)} + var(--mantine-spacing-md))`}>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
};

export default AppLayout;
