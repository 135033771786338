import { Anchor, Center, Container, Image, Stack, Text } from "@mantine/core";
import React from "react";
import { NavLink } from "react-router-dom";
import { Logo } from "@pilplay/ui";
import Image404 from "./404.png";
import classes from "./NotFoundPage.module.css";

interface NotFoundPageProps {}

const NotFoundPage: React.FC<NotFoundPageProps> = ({}) => {
  return (
    <Container mt={120}>
      <Center>
        <Stack>
          <Logo fontSize="48" text="right" width={128} />
          <Image
            alt="404 not found image"
            className={classes.image}
            src={Image404}
          />
          <Text className={classes.title}>404: Dart Not Found!</Text>
          <Text className={classes.subTitle}>
            You aimed for the board, but hit cyberspace. Let's redirect your
            throw!
            <br />
            <Anchor component={NavLink} to="/">
              Click here to go back to the main page.
            </Anchor>
          </Text>
        </Stack>
      </Center>
    </Container>
  );
};

export default NotFoundPage;
