import type { AvatarGroupProps, AvatarProps } from "@mantine/core";
import { Avatar, Image, Tooltip } from "@mantine/core";
import React from "react";
import fallbackImage from "./fallbackImage.svg";

export interface PlayerAvatarProps extends AvatarProps {
  url?: string | null;
  alt?: string;
  useOutlineIcon?: boolean;
}

export const PlayerAvatar: React.FC<PlayerAvatarProps> = ({
  url,
  alt = "Player avatar",
  radius,
  size,
  useOutlineIcon,
  ...rest
}) => {
  return (
    <Avatar size={size} alt={alt} radius={radius} src={url} {...rest}>
      {useOutlineIcon ? null : <FallBackAvatar alt={alt} />}
    </Avatar>
  );
};

interface FallBackAvatarProps {
  alt?: string;
}
export const FallBackAvatar: React.FC<FallBackAvatarProps> = ({ alt }) => {
  return <Image h={"110%"} w={"110%"} src={fallbackImage} alt={alt} />;
};

export interface PlayerAvatarGroupProps extends AvatarGroupProps {
  players: {
    key: string;
    url?: string | null;
    tooltip?: string;
  }[];
  avatarProps?: AvatarProps;
}
export const PlayerAvatarGroup: React.FC<PlayerAvatarGroupProps> = ({
  players,
  avatarProps,
  ...props
}) => {
  return (
    <Avatar.Group {...props}>
      {players.map((player) => (
        <Tooltip key={player.key} label={player.tooltip}>
          <Avatar alt={"Player avatar"} src={player.url} {...avatarProps}>
            <FallBackAvatar alt={"Player avatar"} />
          </Avatar>
        </Tooltip>
      ))}
    </Avatar.Group>
  );
};
