import { DartPosition } from "@pilplay/graphql";

export interface DartHit {
  id: string;
  point: DartPoint;
  position?: DartPosition | null;
}

export enum DartPoint {
  ONE = "1",
  TWO = "2",
  THREE = "3",
  FOUR = "4",
  FIVE = "5",
  SIX = "6",
  SEVEN = "7",
  EIGHT = "8",
  NINE = "9",
  TEN = "10",
  ELEVEN = "11",
  TWELVE = "12",
  THIRTEEN = "13",
  FOURTEEN = "14",
  FIFTEEN = "15",
  SIXTEEN = "16",
  SEVENTEEN = "17",
  EIGHTEEN = "18",
  NINETEEN = "19",
  TWENTY = "20",
  BULL = "Bull",
  MISS = "Miss",
  DOUBLE_ONE = "D1",
  DOUBLE_TWO = "D2",
  DOUBLE_THREE = "D3",
  DOUBLE_FOUR = "D4",
  DOUBLE_FIVE = "D5",
  DOUBLE_SIX = "D6",
  DOUBLE_SEVEN = "D7",
  DOUBLE_EIGHT = "D8",
  DOUBLE_NINE = "D9",
  DOUBLE_TEN = "D10",
  DOUBLE_ELEVEN = "D11",
  DOUBLE_TWELVE = "D12",
  DOUBLE_THIRTEEN = "D13",
  DOUBLE_FOURTEEN = "D14",
  DOUBLE_FIFTEEN = "D15",
  DOUBLE_SIXTEEN = "D16",
  DOUBLE_SEVENTEEN = "D17",
  DOUBLE_EIGHTEEN = "D18",
  DOUBLE_NINETEEN = "D19",
  DOUBLE_TWENTY = "D20",
  DOUBLE_BULL = "DBull",
  TRIPLE_ONE = "T1",
  TRIPLE_TWO = "T2",
  TRIPLE_THREE = "T3",
  TRIPLE_FOUR = "T4",
  TRIPLE_FIVE = "T5",
  TRIPLE_SIX = "T6",
  TRIPLE_SEVEN = "T7",
  TRIPLE_EIGHT = "T8",
  TRIPLE_NINE = "T9",
  TRIPLE_TEN = "T10",
  TRIPLE_ELEVEN = "T11",
  TRIPLE_TWELVE = "T12",
  TRIPLE_THIRTEEN = "T13",
  TRIPLE_FOURTEEN = "T14",
  TRIPLE_FIFTEEN = "T15",
  TRIPLE_SIXTEEN = "T16",
  TRIPLE_SEVENTEEN = "T17",
  TRIPLE_EIGHTEEN = "T18",
  TRIPLE_NINETEEN = "T19",
  TRIPLE_TWENTY = "T20",
  BUST = "BUST",
}

export enum DartMultiplier {
  SINGLE = "S",
  DOUBLE = "D",
  TRIPLE = "T",
}

export const scoreToPoints = (score: string): number => {
  const multipler = parseMultiplier(score);
  const points = parsePoints(score);
  return multipler * points;
};

export const scoreToPointsWithMultiplier = (
  score: string
): { points: number; multiplier: number } => {
  const multiplier = parseMultiplier(score);
  const points = parsePoints(score);
  return { points, multiplier };
};

export const parsePoints = (score: string): number => {
  const points = score
    .replace(DartMultiplier.SINGLE, "")
    .replace(DartMultiplier.DOUBLE, "")
    .replace(DartMultiplier.TRIPLE, "");
  switch (points) {
    case DartPoint.ONE:
      return 1;
    case DartPoint.TWO:
      return 2;
    case DartPoint.THREE:
      return 3;
    case DartPoint.FOUR:
      return 4;
    case DartPoint.FIVE:
      return 5;
    case DartPoint.SIX:
      return 6;
    case DartPoint.SEVEN:
      return 7;
    case DartPoint.EIGHT:
      return 8;
    case DartPoint.NINE:
      return 9;
    case DartPoint.TEN:
      return 10;
    case DartPoint.ELEVEN:
      return 11;
    case DartPoint.TWELVE:
      return 12;
    case DartPoint.THIRTEEN:
      return 13;
    case DartPoint.FOURTEEN:
      return 14;
    case DartPoint.FIFTEEN:
      return 15;
    case DartPoint.SIXTEEN:
      return 16;
    case DartPoint.SEVENTEEN:
      return 17;
    case DartPoint.EIGHTEEN:
      return 18;
    case DartPoint.NINETEEN:
      return 19;
    case DartPoint.TWENTY:
      return 20;
    case DartPoint.BULL:
      return 25;
    default:
      return 0;
  }
};

export const parseMultiplier = (score: string): number => {
  if (score.startsWith(DartMultiplier.SINGLE)) {
    return 1;
  } else if (score.startsWith(DartMultiplier.DOUBLE)) {
    return 2;
  } else if (score.startsWith(DartMultiplier.TRIPLE)) {
    return 3;
  }
  return 1;
};

export const getMultipler = (score: DartPoint): DartMultiplier => {
  switch (score) {
    case DartPoint.DOUBLE_ONE:
    case DartPoint.DOUBLE_TWO:
    case DartPoint.DOUBLE_THREE:
    case DartPoint.DOUBLE_FOUR:
    case DartPoint.DOUBLE_FIVE:
    case DartPoint.DOUBLE_SIX:
    case DartPoint.DOUBLE_SEVEN:
    case DartPoint.DOUBLE_EIGHT:
    case DartPoint.DOUBLE_NINE:
    case DartPoint.DOUBLE_TEN:
    case DartPoint.DOUBLE_ELEVEN:
    case DartPoint.DOUBLE_TWELVE:
    case DartPoint.DOUBLE_THIRTEEN:
    case DartPoint.DOUBLE_FOURTEEN:
    case DartPoint.DOUBLE_FIFTEEN:
    case DartPoint.DOUBLE_SIXTEEN:
    case DartPoint.DOUBLE_SEVENTEEN:
    case DartPoint.DOUBLE_EIGHTEEN:
    case DartPoint.DOUBLE_NINETEEN:
    case DartPoint.DOUBLE_TWENTY:
    case DartPoint.DOUBLE_BULL:
      return DartMultiplier.DOUBLE;
    case DartPoint.TRIPLE_ONE:
    case DartPoint.TRIPLE_TWO:
    case DartPoint.TRIPLE_THREE:
    case DartPoint.TRIPLE_FOUR:
    case DartPoint.TRIPLE_FIVE:
    case DartPoint.TRIPLE_SIX:
    case DartPoint.TRIPLE_SEVEN:
    case DartPoint.TRIPLE_EIGHT:
    case DartPoint.TRIPLE_NINE:
    case DartPoint.TRIPLE_TEN:
    case DartPoint.TRIPLE_ELEVEN:
    case DartPoint.TRIPLE_TWELVE:
    case DartPoint.TRIPLE_THIRTEEN:
    case DartPoint.TRIPLE_FOURTEEN:
    case DartPoint.TRIPLE_FIFTEEN:
    case DartPoint.TRIPLE_SIXTEEN:
    case DartPoint.TRIPLE_SEVENTEEN:
    case DartPoint.TRIPLE_EIGHTEEN:
    case DartPoint.TRIPLE_NINETEEN:
    case DartPoint.TRIPLE_TWENTY:
      return DartMultiplier.TRIPLE;
    default:
      return DartMultiplier.SINGLE;
  }
};
