import { Box, Collapse, Group, Space } from "@mantine/core";
import { ActionButton, FloatingCorners } from "@pilplay/ui";
import {
  IconArrowBack,
  IconDoorExit,
  IconHistory,
  IconPlus,
  IconTarget,
} from "@tabler/icons-react";
import React from "react";
import { useIsMobile } from "../../../../../../../hooks/layout/useDevice";
import { clientRoutes } from "../../../../../../../routes";
import { useGameContext } from "../../../../context/useGameContext";
import { useCricketGame } from "../../context/useCricketGame";

interface CricketFloatingActionsProps {
  onHistoryClick?: () => void;
  onControlsClick?: () => void;
  children?: React.ReactNode;
  minWidth?: number;
}

const CricketFloatingActions: React.FC<CricketFloatingActionsProps> = ({
  onHistoryClick,
  onControlsClick,
  children,
  minWidth,
}) => {
  const { undo, lobbyId } = useGameContext();
  const { finished } = useCricketGame();
  const isMobile = useIsMobile();
  return (
    <FloatingCorners
      cornderPadding={0}
      style={{
        minWidth,
      }}
      bottomRight={
        <Group justify={"flex-end"}>
          <Collapse in={finished}>
            <ActionButton
              buttonIconPosition="left"
              expanding={false}
              icon={<IconArrowBack />}
              isMobile={isMobile}
              label="Undo last throw"
              onClick={undo}
            />
          </Collapse>
          <Box>
            {/*<Collapse in={finished}>
              <>
                <ActionButton
                  buttonIconPosition="right"
                  expanding={isMobile}
                  icon={<IconRefresh />}
                  isMobile={isMobile}
                  label="Rematch"
                  /* TODO create rematch mutation */
            /*
                  link={clientRoutes.gameCricket()}
                />

                <Space h="sm" />
              </>
      </Collapse>*/}
            <Collapse in={finished}>
              <>
                <ActionButton
                  buttonIconPosition="right"
                  disabled={!finished}
                  expanding={isMobile}
                  icon={<IconPlus />}
                  isMobile={isMobile}
                  label="New Game"
                  /* TODO create rematch mutation */
                  link={
                    lobbyId
                      ? clientRoutes.lobbySelectPage(lobbyId)
                      : clientRoutes.gameCricket()
                  }
                />
                <Space h="sm" />
              </>
            </Collapse>
            <Collapse in={finished}>
              <ActionButton
                buttonIconPosition="right"
                disabled={!finished}
                expanding={isMobile}
                icon={<IconDoorExit />}
                isMobile={isMobile}
                label="Exit"
                link={
                  lobbyId
                    ? clientRoutes.lobbySelectPage(lobbyId)
                    : clientRoutes.root
                }
                variant="outline"
              />
            </Collapse>
            <Collapse in={!finished}>
              <ActionButton
                buttonIconPosition="right"
                expanding={false}
                icon={<IconTarget />}
                isMobile={isMobile}
                label={"Controls"}
                onClick={onControlsClick}
                variant="outline"
              />
            </Collapse>
          </Box>
        </Group>
      }
      bottomLeft={
        <ActionButton
          expanding={false}
          icon={<IconHistory />}
          isMobile={isMobile}
          label={"History"}
          onClick={onHistoryClick}
          variant="outline"
        />
      }
    >
      {children}
    </FloatingCorners>
  );
};

export default CricketFloatingActions;
