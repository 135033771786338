import { randomId } from "@mantine/hooks";
import type { PilplayStore } from "./store";
import { createStore, useStore } from "./store";
import type { PlayableAnimation } from "./animationConfig";

export type AnimationData = PlayableAnimation;

export interface AnimationsState {
  queue: AnimationData[];
  currentAnimation?: AnimationData;
}

export type AnimationsStore = PilplayStore<AnimationsState>;

export const createAnimationsStore = (): AnimationsStore => {
  return createStore<AnimationsState>({
    queue: [],
  });
};

export const animationsStore = createAnimationsStore();

export const useAnimations = (
  store: AnimationsStore = animationsStore
): AnimationsState => {
  return useStore(store);
};

export function updateAnimationsState(
  store: AnimationsStore,
  update: (notifications: AnimationData[]) => AnimationData[]
): void {
  const state = store.getState();
  const queue = update([...state.queue]);

  store.setState({
    queue,
  });
}

export function showAnimation(
  notification: AnimationData,
  store: AnimationsStore = animationsStore
): string {
  const id = notification.id || randomId();

  updateAnimationsState(store, (animation) => {
    if (notification.id && animation.some((n) => n.id === notification.id)) {
      return animation;
    }

    return [...animation, { ...notification, id }];
  });

  return id;
}
