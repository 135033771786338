import React, { useEffect, useState } from "react";
import VerificationInput from "react-verification-input";
import classes from "./CodeInput.module.css";

interface CodeInputProps {
  codeLength?: number;
  onChange?: (value: string) => void;
  onComplete?: (value: string) => void;
  autoFocus?: boolean;
  toUppercase?: boolean;
}

const CodeInput: React.FC<CodeInputProps> = ({
  codeLength = 6,
  onChange = () => {},
  autoFocus = false,
  onComplete = () => {},
  toUppercase = false,
}) => {
  const [input, setInput] = useState("");

  useEffect(() => {
    onChange(input);
  }, [input]);

  return (
    <VerificationInput
      autoFocus={autoFocus}
      classNames={{
        container: classes.container,
        character: classes.character,
        characterInactive: classes.characterInactive,
        characterSelected: classes.characterSelected,
      }}
      length={codeLength}
      onChange={(v) => { setInput(toUppercase ? v.toUpperCase() : v); }}
      onComplete={(v) => { onComplete(v); }}
      value={input}
    />
  );
};

export default CodeInput;
