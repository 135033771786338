import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Flex, Grid } from "@mantine/core";
import { GameType } from "@pilplay/games";
import type { GameConfigInput } from "@pilplay/graphql";
import InternalErrorPage from "../../InternalErrorPage";
import GameLobbyHeader from "../../../components/GameLobbyHeader";
import { clientRoutes } from "../../../routes";
import GameConfig from "../../../components/GameConfig";
import { useLobbyMutations } from "../../../hooks/useLobbyMutations/useLobbyMutations";
import { useLobby } from "../../../hooks/useLobby/useLobby";
import classes from "./game-selection-page.module.css";
import GameCard from "./components/GameCard";

const GameSelectionPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [gameType, setGameType] = useState<GameType>(GameType.X01);
  const {
    createGameData: { fetching },
    createGame,
  } = useLobbyMutations();

  const [config, setConfig] = useState<GameConfigInput | undefined>(undefined);
  const navigate = useNavigate();
  const [{ data, fetching: fetchindLobby, error }] = useLobby(id);

  if (error || (!data && !fetchindLobby)) {
    return <InternalErrorPage error={error} />;
  }

  const onStartGame = () => {
    if (config === undefined) {
      return;
    }
    void createGame({
      input: {
        config,
        lobbyId: id!,
      },
    }).then((res) => {
      if (res.data?.createLobbyGame) {
        navigate(clientRoutes.game(res.data.createLobbyGame.id));
      }
    });
  };

  const disabled = config === undefined;

  const hasBoard = data?.lobby?.board?.id !== undefined;

  return (
    <div>
      <GameLobbyHeader
        lobbyId={id!}
        backLink={
          hasBoard
            ? clientRoutes.boardLobbyPage(data.lobby?.board?.id)
            : undefined
        }
        backText="Back to lobby"
        players={data?.lobby?.players ?? []}
        title="Select game"
      />
      <div className={classes.content}>
        <Grid
          classNames={{
            inner: classes.gridInner,
          }}
          gutter={0}
          h="100%"
        >
          <Grid.Col h="100%" span={{ base: 6 }}>
            <Grid
              classNames={{
                inner: classes.gridInner,
              }}
              gutter={0}
              h="100%"
            >
              <Grid.Col h="100%" span={{ base: 6 }}>
                <GameCard
                  active={gameType === GameType.X01}
                  onClick={() => {
                    setGameType(GameType.X01);
                  }}
                  type={GameType.X01}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 6 }}>
                <GameCard
                  active={gameType === GameType.Cricket}
                  onClick={() => {
                    setGameType(GameType.Cricket);
                  }}
                  type={GameType.Cricket}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col h="100%" span={{ base: 6 }}>
            <Flex direction="column" h="100%" justify="space-between">
              <div className={classes.settings}>
                <GameConfig gameType={gameType} onConfigChange={setConfig} />
              </div>
              <Button
                disabled={disabled}
                fullWidth
                loading={fetching}
                mx="md"
                my={4}
                onClick={onStartGame}
                size="lg"
              >
                Start game
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </div>
    </div>
  );
};

export default GameSelectionPage;
