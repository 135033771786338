import { Box, Flex, SimpleGrid, Text } from "@mantine/core";
import React from "react";
import classes from "./X01Checkout.module.css";
import checkoutTable from "./checkout.json";

const getCheckout = (score: number) => {
  const checkout = (checkoutTable as Record<string, string[]>)[
    score.toString()
  ] as string[] | undefined;
  return checkout;
};

export const hasCheckout = (score: number) => {
  const checkout = getCheckout(score);
  return Boolean(checkout);
};

interface X01CheckoutProps {
  score: number;
  style?: React.CSSProperties;
  fontSize?: number;
}

const X01Checkout: React.FC<X01CheckoutProps> = ({
  score,
  fontSize,
  ...otherProps
}) => {
  const checkout = getCheckout(score);
  if (!checkout) {
    return null;
  }

  return (
    <Flex
      align="center"
      justify="center"
      px="md"
      {...otherProps}
      className={classes.container}
    >
      <Box className={classes.checkoutText} style={{ flex: 1 }}>
        <Text fw="bold" pr="md" fz={fontSize}>
          Checkout
        </Text>
      </Box>
      <SimpleGrid cols={3}>
        {checkout.map((d, i) => (
          <Text
            className={classes.dartText}
            fw="bold"
            key={`${d}-${i}`}
            ta="center"
            fz={fontSize}
          >
            {d}
          </Text>
        ))}
      </SimpleGrid>
    </Flex>
  );
};

export default X01Checkout;
