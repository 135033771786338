import {
  Alert,
  Button,
  Divider,
  Group,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { PlayerAvatar } from "@pilplay/ui";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CenterPaper from "../../../components/CenterPaper/CenterPaper";
import { useAuthContext } from "../../../contexts/AuthContext";
import { useGameMutations } from "../../../hooks/useGameMutations/useGameMutations";
import { useMe } from "../../../hooks/useMe";
import { clientRoutes } from "../../../routes";

interface JoinGameFormValues {
  name: string;
  gameCode: string | number;
}

const JoinGamePage: React.FC = () => {
  const { gameCode } = useParams<{ gameCode: string | undefined }>();
  const form = useForm({
    initialValues: {
      name: "",
      gameCode: gameCode || "",
    },
    validate: {
      name: (value) => {
        if (isSignedIn) {
          return null;
        }
        if (value.length < 3) {
          return "Name must be at least 3 characters long";
        }
        return null;
      },
    },
    transformValues: (values: JoinGameFormValues) => ({
      ...values,
      gameCode: Number(values.gameCode.toString().replace("-", "")),
    }),
  });
  const navigate = useNavigate();
  const { joinGame, addGuest, error, fetching } = useGameMutations();
  const { isSignedIn } = useAuthContext();
  const [{ data }] = useMe();

  const handleSubmit = async (values: JoinGameFormValues) => {
    if (isSignedIn) {
      const res = await joinGame({
        input: {
          gameCode: Number(values.gameCode),
        },
      });
      if (res.data?.joinGame) {
        navigate(clientRoutes.gameLobby(res.data.joinGame.id));
      }
    } else {
      const res = await addGuest({
        input: {
          name: values.name,
          gameCode: Number(values.gameCode),
        },
      });
      if (res.data?.addGuest) {
        navigate(clientRoutes.gameLobby(res.data.addGuest.id));
      }
    }
  };

  useEffect(() => {
    if (form.values.gameCode.length >= 6) {
      form.validate();
      if (form.isValid()) {
        void handleSubmit(form.getTransformedValues());
      }
    }
  }, [form.values.gameCode]);

  return (
    <CenterPaper maw={420}>
      <Stack>
        {error ? (
          <Alert color="red">
            {error.graphQLErrors.map((e) => e.message).join(",")}
          </Alert>
        ) : null}

        {isSignedIn ? (
          <Group justify="center" mt="md">
            <Stack align="center">
              <Text fw="bold" size="xl" ta="center">
                Joining as
              </Text>
              <PlayerAvatar size="lg" url={data?.me.avatarUrl} />
              <Text size="xl" ta="center">
                {data?.me.displayName}
              </Text>
            </Stack>
          </Group>
        ) : null}
        <Text pt="md" size="md" ta="center">
          Join a game by entering the game code you received from a friend.
        </Text>
        {!isSignedIn && (
          <Text c="dimmed" size="sm" ta="center">
            Already have an account you want to join with? Login{" "}
            <Link to={clientRoutes.login("")}>here</Link>
          </Text>
        )}
        <form onSubmit={form.onSubmit(handleSubmit)}>
          {!isSignedIn && (
            <TextInput
              label="Name"
              mb="md"
              size="xl"
              {...form.getInputProps("name")}
            />
          )}
          <TextInput
            label="Game code"
            size="xl"
            {...form.getInputProps("gameCode")}
          />
          <Divider my="sm" />
          <Button disabled={fetching} fullWidth size="xl" type="submit">
            Join
          </Button>
        </form>
      </Stack>
    </CenterPaper>
  );
};

export default JoinGamePage;
