import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { Center, Loader, LoadingOverlay, Stack } from "@mantine/core";
import AuthWrapper from "../components/AuthWrapper/AuthWrapper";
import AppLayout from "../components/AppLayout";
import { clientRoutes } from "../routes";
import { AuthWrapperApiKey } from "../components/AuthWrapperApiKey";
import { useOrganizations } from "../hooks/useOrganizations/useOrganizations";
import ErrorAlert from "../components/ErrorAlert";
import { useAuthContext } from "../contexts/AuthContext";
import Signup from "./auth/Signup/Signup";
import LoginPage from "./auth/LoginPage/LoginPage";
import ProfilePage from "./auth/ProfilePage/ProfilePage";
import OldLobbyPage from "./games/OldLobbyPage/OldLobbyPage";
import X01ConfigPage from "./games/config/X01ConfigPage/X01ConfigPage";
import GamePage from "./games/GamePage";
import JoinGamePage from "./games/JoinGamePage/JoinGamePage";
import BoardsPage from "./organization/BoardsPage";
import BoardFollowPage from "./board/BoardFollowPage";
import NotFoundPage from "./NotFoundPage";
import CricketConfigPage from "./games/config/CricketConfigPage";
import BoardIFramePage from "./board/BoardIFramePage";
import LobbyPage from "./games/LobbyPage";
import GameSelectionPage from "./games/GameSelectionPage";
import { BoardStartPage } from "./board/BoardStartPage";
import DashboardPage from "./organization/DashboardPage";
import { NewOrganizationPage } from "./organization/NewOrganizationPage";
import { BoardPage } from "./organization/BoardPage";
import { CalibrateBoardPage } from "./organization/CalibrateBoardPage";
import { LobbyIdlePage } from "./lobby/LobbyIdlePage";
import { CalendarPage } from "./organization/CalendarPage";

const OrgRedirect: React.FC = () => {
  const [{ data, fetching, error }] = useOrganizations();
  const { logout } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.organizations.length > 0) {
      const org = data.organizations[0];
      navigate(clientRoutes.organizationHome(org.slug), {
        replace: true,
      });
    }
  }, [data, navigate]);

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-console -- we need to log this
      console.error(error);
      setTimeout(() => {
        logout();
      }, 3000);
    }
  }, [error, logout]);

  return (
    <LoadingOverlay
      visible
      loaderProps={{
        children: (
          <Stack>
            <ErrorAlert error={error} />
            {!fetching && (
              <Center>
                <Loader />
              </Center>
            )}
          </Stack>
        ),
      }}
    >
      Hello
    </LoadingOverlay>
  );
};

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<JoinGamePage />} path="/game/join/:gameCode?" />
        <Route element={<GamePage />} path={clientRoutes.game()} />
        <Route element={<LoginPage />} path={clientRoutes.login()} />
        <Route element={<Signup />} path={clientRoutes.signUp} />
        <Route
          element={
            <AuthWrapperApiKey>
              <BoardFollowPage />
            </AuthWrapperApiKey>
          }
          path={clientRoutes.boardFollowPage()}
        />
        <Route
          element={
            <AuthWrapper>
              <GameSelectionPage />
            </AuthWrapper>
          }
          path={clientRoutes.lobbySelectPage()}
        />
        <Route
          element={
            <AuthWrapper>
              <LobbyPage />
            </AuthWrapper>
          }
          path={clientRoutes.boardLobbyPage()}
        />
        <Route
          index
          element={
            <AuthWrapper>
              <BoardStartPage />
            </AuthWrapper>
          }
          path={clientRoutes.boardStartPage()}
        />
        {/* Lobby Routes */}
        <Route
          element={
            <AuthWrapper>
              <LobbyIdlePage />
            </AuthWrapper>
          }
          path={clientRoutes.lobbyIdlePage()}
        />
        <Route element={<AppLayout />} path={clientRoutes.root}>
          <Route
            element={
              <AuthWrapper>
                <OrgRedirect />
              </AuthWrapper>
            }
            index
          />

          {/* ORGANIZATIONS ROUTES */}
          <Route
            element={
              <AuthWrapper>
                <NewOrganizationPage />
              </AuthWrapper>
            }
            path={clientRoutes.newOrganization}
          />
          <Route
            element={
              <AuthWrapper>
                <DashboardPage />
              </AuthWrapper>
            }
            path={clientRoutes.organizationHome()}
          />
          <Route
            element={
              <AuthWrapper>
                <CalendarPage />
              </AuthWrapper>
            }
            path={clientRoutes.organizationCalendar()}
          />
          <Route
            element={
              <AuthWrapper>
                <BoardsPage />
              </AuthWrapper>
            }
            path={clientRoutes.organizationBoards()}
          />

          <Route
            element={
              <AuthWrapper>
                <BoardPage />
              </AuthWrapper>
            }
            path={clientRoutes.organizationBoard()}
          />

          <Route
            element={
              <AuthWrapper>
                <CalibrateBoardPage />
              </AuthWrapper>
            }
            path={clientRoutes.calibrateBoard()}
          />

          <Route element={<OldLobbyPage />} path={clientRoutes.gameLobby()} />

          <Route
            element={
              <AuthWrapper>
                <X01ConfigPage />
              </AuthWrapper>
            }
            path="/game/x01/:boardId?"
          />
          <Route
            element={
              <AuthWrapper>
                <CricketConfigPage />
              </AuthWrapper>
            }
            path="/game/cricket/:boardId?"
          />
          <Route
            element={
              <AuthWrapper>
                <ProfilePage />
              </AuthWrapper>
            }
            path={clientRoutes.profile}
          />

          <Route
            element={<BoardIFramePage />}
            path={clientRoutes.boardLogsPage()}
          />
        </Route>
        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
