import React, { useState } from "react";
import { Button, Center, Modal, PinInput, Text } from "@mantine/core";
import type { GenerateBoardSignInCodeMutation } from "@pilplay/graphql";
import useBoardMutations from "../../../../../hooks/useBoardMutations/useBoardMutations";
import classes from "./GenerateSignInCodeModal.module.css";

export interface GenerateSignInCodeModalProps {
  boardId: string;
  open: boolean;
  onClose: () => void;
}

export const GenerateSignInCodeModal: React.FC<
  GenerateSignInCodeModalProps
> = ({ boardId, open, onClose }) => {
  const [result, setResult] = useState<
    GenerateBoardSignInCodeMutation["generateBoardSignInCode"] | null
  >(null);
  const { genereateSignInCode, fetching, error } = useBoardMutations();

  const generateCode = async () => {
    const res = await genereateSignInCode({
      boardId,
    });
    if (res.data) {
      setResult(res.data.generateBoardSignInCode);
    }
  };

  const errorMessage =
    error?.graphQLErrors.map((e) => e.message).join(",") ||
    error?.message ||
    (error && "Internal error, please try again later.");

  return (
    <Modal
      title="Generate sign in code"
      opened={open}
      onClose={onClose}
      closeOnClickOutside={false}
      className={classes.root}
    >
      <Text c="dimmed" fz="xs">
        This code will allow you to sign in to the board from the web app. You
        can generate a new code at any time. The code will expire after 10
        minutes.
      </Text>
      <Center my="xl">
        <PinInput
          style={{ color: "#FFF !important" }}
          length={6}
          type="number"
          value={result?.code || ""}
        />
        {errorMessage ? (
          <Text c="red.7" fz="xs">
            {errorMessage}
          </Text>
        ) : null}
      </Center>
      <Button fullWidth loading={fetching} onClick={generateCode}>
        Generate
      </Button>
    </Modal>
  );
};
