import React, { useEffect, useState } from "react";
import {
  TOKEN_KEY,
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../../utils/storage";
import type { AuthAttributes, AuthContextData } from "./context";
import AuthContext from "./context";

interface AuthContextProviderProps {
  children: React.ReactNode;
}

const AuthContextProvider: React.FC<AuthContextProviderProps> = ({
  children,
}) => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasAttemptedVerification, setHasAttemptedVerification] =
    useState<boolean>(false);

  useEffect(() => {
    const attributes = getLocalStorage<AuthAttributes | null>(TOKEN_KEY, null);
    if (attributes) {
      setIsSignedIn(
        attributes.accessToken !== undefined && attributes.refreshToken !== null
      );
    }
    setHasAttemptedVerification(true);
    setIsLoading(false);
  }, []);

  const setLogin = (attributes: AuthAttributes) => {
    setLocalStorage(TOKEN_KEY, attributes);
    setIsSignedIn(true);
    setHasAttemptedVerification(true);
  };

  const logout = () => {
    removeLocalStorage(TOKEN_KEY);
    setIsSignedIn(false);
  };

  const value: AuthContextData = {
    isSignedIn,
    isLoading,
    hasAttemptedVerification,
    logout,
    setLogin,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
