import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Box,
  Chip,
  Flex,
  Group,
  NumberInput,
  Text,
  Tooltip,
} from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import { Button } from "@pilplay/ui";
import { IconAdjustments } from "@tabler/icons-react";
import type { CreateLobbyCodeMutation } from "@pilplay/graphql";
import { useCreateLobbyCodeMutation } from "@pilplay/graphql";
import ErrorAlert from "../../../../../components/ErrorAlert";
import classes from "./CreateCode.module.css";

export interface CreateCodeProps {
  orgSlug: string;
  onCreate?: (data: CreateLobbyCodeMutation["createLobbyCode"]) => void;
}

const DEFAULT_TIMES = [25, 30, 55, 60, 115, 120];
// eslint-disable-next-line @typescript-eslint/no-empty-function -- used as default prop
const noop = () => {};

export const CreateCode: React.FC<CreateCodeProps> = ({
  orgSlug,
  onCreate = noop,
}) => {
  const [time, setTime] = useInputState<number>(55);
  const [showCustom, setShowCustom] = useState(false);

  const [{ data, error, fetching }, createCode] = useCreateLobbyCodeMutation();

  useEffect(() => {
    if (data?.createLobbyCode) {
      onCreate(data.createLobbyCode);
    }
  }, [data, onCreate]);

  return (
    <Box>
      <Text size="sm" c="dimmed">
        Select session duration
      </Text>
      <Flex py="md" justify="space-between" align="center">
        <Group className={classes.root}>
          {DEFAULT_TIMES.map((t) => (
            <Chip
              key={t}
              value={t}
              onChange={(c) => {
                if (c) {
                  setTime(Number(t));
                }
              }}
              checked={t === time}
              className={classes.chip}
            >
              {t} minutes
            </Chip>
          ))}
          <Tooltip
            label="Custom duration"
            position="right"
            bg="dark.9"
            c="white"
          >
            <ActionIcon
              onClick={() => {
                setShowCustom(!showCustom);
              }}
              variant="subtle"
            >
              <IconAdjustments size={16} />
            </ActionIcon>
          </Tooltip>
        </Group>
        <Box>
          <Button
            size="sm"
            onClick={() => {
              createCode({
                input: {
                  duration: time,
                  orgSlug,
                },
              });
            }}
            loading={fetching}
          >
            Create
          </Button>
        </Box>
      </Flex>
      {showCustom ? (
        <NumberInput
          pb="md"
          value={time}
          onChange={(v) => {
            setTime(Number(v));
          }}
          label="Custom duration"
          c="dimmed"
          placeholder="23 minutes"
          suffix=" minutes"
          maw={320}
          min={1}
          max={999}
        />
      ) : null}
      <ErrorAlert mb="md" error={error} />
    </Box>
  );
};
