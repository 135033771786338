import { Alert, Button, Modal, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { IconPlus } from "@tabler/icons-react";
import CodeInput from "../../../../../components/CodeInput";
import useBoardMutations from "../../../../../hooks/useBoardMutations/useBoardMutations";

interface AddBoardModalProps {
  onClosed?: () => void;
}

const AddBoardModal: React.FC<AddBoardModalProps> = ({ onClosed }) => {
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState("");

  const handleCodeChange = (value: string) => {
    setCode(value);
  };

  useEffect(() => {
    if (open || !onClosed) return;
    onClosed();
  }, [onClosed, open]);

  const { fetching, error, connectBoard, data } = useBoardMutations();

  useEffect(() => {
    if (data?.connectBoard) {
      setOpen(false);
    }
  }, [data]);

  const isDisabled = code.length !== 6;
  return (
    <>
      <Button
        leftSection={<IconPlus size={18} />}
        ml="auto"
        onClick={() => {
          setOpen(true);
        }}
      >
        Add Board
      </Button>

      <Modal
        centered
        onClose={() => {
          setOpen(false);
        }}
        opened={open}
        title="Register new board"
      >
        <Modal.Body>
          <Text c="dimmed" fz="sm">
            Enter the code of the board you want to register. You can find the
            code on the board when you have connected it.
          </Text>
          <CodeInput
            // eslint-disable-next-line jsx-a11y/no-autofocus -- autofocus is better for this
            autoFocus
            onChange={handleCodeChange}
            onComplete={async (v) => {
              await connectBoard({
                code: v,
              });
            }}
            toUppercase
          />

          <Button
            disabled={isDisabled}
            fullWidth
            loading={fetching}
            mt="md"
            onClick={async () => {
              await connectBoard({
                code,
              });
            }}
            variant="filled"
          >
            Register board
          </Button>
          {error ? (
            <Alert color="red" my="md">
              <Text fz="sm">
                Could not register board. Please check the code and try again.
              </Text>
            </Alert>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddBoardModal;
