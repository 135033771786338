import React from "react";
import { BoardStatus } from "@pilplay/graphql";
import type { BadgeProps } from "@mantine/core";
import { Badge } from "@mantine/core";

export interface ConnectionStatusBadgeProps extends BadgeProps {
  status: BoardStatus;
}

const statusToColor: Record<BoardStatus, string> = {
  [BoardStatus.Connected]: "green",
  [BoardStatus.Disconnected]: "red",
};

export const ConnectionStatusBadge: React.FC<ConnectionStatusBadgeProps> = ({
  status,
  ...otherProps
}) => {
  const color = statusToColor[status];
  return (
    <Badge color={color} {...otherProps}>
      {status}
    </Badge>
  );
};
