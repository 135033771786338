import React, { useState } from "react";
import { PlayerSelect } from "./components/PlayerSelect";
import type { TableColumn } from "./components/RoundTable";
import { RoundTable } from "./components/RoundTable";

export interface PlayerRounds<Round> {
  id: string;
  player: string;
  rounds: Round[];
}

export interface GameHistoryProps<Round> {
  playerRoundsData: PlayerRounds<Round>[];
  columns: TableColumn<Round>[];
  loading?: boolean;
  initialPlayerId?: string;
}

export const GameHistory = <Round,>({
  playerRoundsData,
  columns,
  loading,
  initialPlayerId,
}: GameHistoryProps<Round>): React.ReactElement => {
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerRounds<Round>>(
    playerRoundsData.find((data) => data.id === initialPlayerId) ||
      playerRoundsData[0]
  );

  const handlePlayerSelect = (palyerId: string): void => {
    const player = playerRoundsData.find((data) => data.id === palyerId);
    if (player) {
      setSelectedPlayer(player);
    }
  };

  return (
    <div>
      <PlayerSelect
        players={playerRoundsData.map((data) => ({
          value: data.id,
          label: data.player,
        }))}
        selectedPlayer={selectedPlayer.id}
        onPlayerSelect={handlePlayerSelect}
      />
      <RoundTable
        data={selectedPlayer.rounds}
        columns={columns}
        loading={loading}
      />
    </div>
  );
};
