import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Affix, Alert, Collapse, LoadingOverlay } from "@mantine/core";
import { GameState } from "@pilplay/graphql";
import useBoardFollow from "../../../hooks/useBoardFollow";
import GamePage from "../../games/GamePage";
import LobbyPage from "../../games/OldLobbyPage";
import BoardConnectivity from "../../../components/BoardConnectivity";
import { useSignInWithApiKey } from "../../../hooks/useSignInWithApiToken/useSignInWithApiToken";
import { useAuthContext } from "../../../contexts/AuthContext";
import { TOKEN_KEY, setLocalStorage } from "../../../utils/storage";
import classes from "./BoardFollowPage.module.css";
import IdleBoard from "./components/IdleBoard";

enum BoardFollowState {
  Idle = "IDLE",
  GameLobby = "GAME_LOBBY",
  GameActive = "GAME_ACTIVE",
}

const getStateComponent = (
  state: BoardFollowState,
  boardId: string,
  gameId?: string
) => {
  switch (state) {
    case BoardFollowState.Idle:
      return <IdleBoard boardId={boardId} />;
    case BoardFollowState.GameLobby:
      return <LobbyPage id={gameId} redirect={false} showControls={false} />;
    case BoardFollowState.GameActive:
      return <GamePage id={gameId} showControls={false} />;
    default:
      return <div>Unknown state</div>;
  }
};

const BoardFollowPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { isSignedIn, setLogin } = useAuthContext();

  const [query, setQueryParams] = useSearchParams();
  const [state, setState] = useState<BoardFollowState>(BoardFollowState.Idle);
  const [gameId, setGameId] = useState<string | undefined>(undefined);
  const [{ data: signInData, fetching, error: errorSignIn }, signIn] =
    useSignInWithApiKey();
  const [{ data, error }] = useBoardFollow(id!);

  const key = query.get("key");

  useEffect(() => {
    if (key && !isSignedIn) {
      /*void signIn({
        apiToken: key,
      });*/
    } else if (isSignedIn) {
      if (signInData) {
        setLocalStorage(TOKEN_KEY, signInData.signInWithApiToken);
        setLogin(signInData.signInWithApiToken);
      }
      setQueryParams({ key: "" });
    }
  }, [isSignedIn, key, setQueryParams, setLogin, signIn, signInData]);

  // Hard relead if nothing been done for 2 hours
  const [lastAction, setLastAction] = useState<number>(Date.now());
  useEffect(() => {
    const interval = setInterval(() => {
      if (Date.now() - lastAction > 2 * 60 * 60 * 1000) {
        window.location.reload();
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [lastAction]);

  useEffect(() => {
    if (data && isSignedIn) {
      setLastAction(Date.now());
      switch (data.boardEvents.__typename) {
        case "BoardEventBoardRegistered":
          break;
        case "BoardEventCanceledGame":
          setState(BoardFollowState.Idle);
          break;
        case "BoardEventNewGame":
          setGameId(data.boardEvents.game.id);
          switch (data.boardEvents.game.state) {
            case GameState.Created:
              setState(BoardFollowState.GameLobby);
              break;
            case GameState.Started:
              setState(BoardFollowState.GameActive);
              break;
            case GameState.Aborted:
              setState(BoardFollowState.Idle);
              break;
            case GameState.Finished:
              setState(BoardFollowState.GameActive);
              break;
          }
          break;
      }
    }
  }, [data, isSignedIn]);

  if (fetching) {
    return <LoadingOverlay visible />;
  }
  if (errorSignIn) {
    return (
      <div className={classes.root}>
        <Alert color="red" my="xl" title="Something went wrong">
          {errorSignIn.graphQLErrors.map((e) => e.message).join(", ")}
        </Alert>
      </div>
    );
  }
  if (!isSignedIn) {
    return <div>Not authenticated</div>;
  }

  const comp = getStateComponent(state, id!, gameId);
  return (
    <div className={classes.root}>
      <Affix
        position={{
          bottom: 32,
          left: 32,
        }}
      >
        <BoardConnectivity id={id!} />
      </Affix>
      <Collapse in={Boolean(error)}>
        {error ? (
          <Alert color="red" my="xl" title="Something went wrong">
            {error.graphQLErrors.map((e) => e.message).join(", ")}
          </Alert>
        ) : null}
      </Collapse>
      {comp}
    </div>
  );
};

export default BoardFollowPage;
