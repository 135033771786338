import { useMediaQuery } from "@mantine/hooks";
import { useMemo } from "react";

export interface UseDeviceReturn {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

/* 
Default breakpoints:
xs	576px
sm	768px
md	992px
lg	1200px
xl	1408px 
*/
export const MOBILE_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 992;
export const DESKTOP_BREAKPOINT = 1200;
// Use this constant for tables and that we need to adjust to mobile
export const Device = {
  MOBILE: MOBILE_BREAKPOINT,
  TABLET: TABLET_BREAKPOINT,
  DESKTOP: DESKTOP_BREAKPOINT,
};

export const useIsMobile = (): boolean => {
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`);
  return isMobile || false;
};

export const useIsTablet = (): boolean => {
  const isTablet = useMediaQuery(`(max-width: ${TABLET_BREAKPOINT}px)`);
  return isTablet || false;
};

export const useIsTabletStanding = (): boolean => {
  const isTabletStanding = useMediaQuery("(max-width: 64em)");
  return isTabletStanding || false;
};

export const useDevice = (): UseDeviceReturn => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useMemo(() => !isTablet, [isTablet]);

  return { isMobile, isTablet, isDesktop };
};
