import type { CricketGameConfig } from "@pilplay/graphql";
import React from "react";
import { Box } from "@mantine/core";
import CricketContextProvider from "./context/CricketContextProvider";
import CricketGame from "./components/CricketGame";

interface CricketGamePageProps {
  gameId: string;
  lobbyId?: string;
  config: CricketGameConfig;
  showControls?: boolean;
  players: {
    id: string;
    index: number;
    playerType: string;
    name: string;
    userId?: string;
    avatarUrl?: string;
  }[];
}

const CricketGamePage: React.FC<CricketGamePageProps> = ({
  gameId,
  lobbyId,
  players,
  showControls,
}) => {
  return (
    <CricketContextProvider
      gameId={gameId}
      lobbyId={lobbyId}
      players={players}
      showControls={showControls}
    >
      <Box
        style={{
          backgroundColor: "var(--mantine-color-dark-9)",
        }}
      >
        <CricketGame />
      </Box>
    </CricketContextProvider>
  );
};

export default CricketGamePage;
