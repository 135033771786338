import { Immutable, produce } from "immer";
import seedrandom from "seedrandom";
import { DartHit, DartPoint, parseMultiplier, scoreToPoints } from "../dart";
import Game, { GameAnimationEvent } from "../game/Game";

import {
  LegOrderType,
  X01ExitMode,
  X01GameConfig,
  X01GameSnapshot,
  X01Round,
} from "@pilplay/graphql";
import {
  GameEventEdit,
  GameEventEmptyBoard,
  GameEventManualNext,
  GameEventStart,
  GameEventThrow,
  GameEventUndo,
} from "../game/types";

const DefaultState: X01GameSnapshot = {
  finished: false,
  activeRound: 0,
  leg: 1,
  activePlayerId: "",
  players: [],
  rounds: [],
  legOrder: [],
  middlingPhase: false,
  middlingThrows: [],
};

const EmptyRound = {
  hit1: undefined,
  hit2: undefined,
  hit3: undefined,
};

const isHitSet = (hit: DartHit | undefined | null): hit is DartHit => {
  return hit !== undefined && hit !== null;
};

const emptyHit = (id: string, index: number): DartHit & { bust: boolean } => ({
  id: id + String(index),
  point: DartPoint.MISS,
  bust: false,
});

export class X01Game extends Game<X01GameSnapshot> {
  public config: X01GameConfig;
  protected snapshot: Immutable<X01GameSnapshot> = DefaultState;

  private players: { id: string; index: number; legs: number }[];

  constructor(
    gameId: string,
    config: X01GameConfig,
    players: { id: string; index: number }[]
  ) {
    super(gameId);
    this.config = config;
    this.players = players.map((p) => ({ ...p, legs: 0 }));
  }

  static fromSnapshot(
    gameId: string,
    config: X01GameConfig,
    players: { id: string; index: number }[],
    snapshot: X01GameSnapshot
  ) {
    const game = new X01Game(gameId, config, players);
    game.snapshot = snapshot;
    return game;
  }

  // Initialize the leg order based on the game configuration
  private initializeLegOrder() {
    switch (this.config.legOrderType) {
      case LegOrderType.Random:
        // We shuffle the players and set the first player as active player
        // We seed the rng with the gameId and the current leg to get randomness between legs

        const rng = seedrandom(`${this.gameId}-${this.snapshot.leg}`);
        const shuffledPlayers = this.players
          .map((p) => p.id)
          .sort(() => rng() - 0.5);
        this.snapshot = produce(this.snapshot, (draft) => {
          draft.legOrder = [...(draft.legOrder || []), shuffledPlayers];
          draft.activePlayerId = shuffledPlayers[0];
        });
        break;

      case LegOrderType.Middle:
        let tempMiddleOrder = this.players.map((p) => p.id);
        if (this.snapshot.legOrder.length > 0) {
          tempMiddleOrder = [...this.getLegOrder(this.snapshot.leg - 1)];
        }
        const firstMiddler = tempMiddleOrder[0];
        this.snapshot = produce(this.snapshot, (draft) => {
          draft.legOrder = [...(draft.legOrder || []), tempMiddleOrder];
          draft.activePlayerId = firstMiddler;
          draft.middlingPhase = true;
        });

        break;
      default:
        // As a default we use a round robin
        let nextLegOrder = this.players.map((p) => p.id);
        if (this.snapshot.legOrder && this.snapshot.legOrder.length > 0) {
          // We get the last leg order and move start after the last active player
          const lastLegOrder = this.getLegOrder(this.snapshot.leg - 1);
          const lastActivePlayerIndex = lastLegOrder.findIndex(
            (p) => p === this.snapshot.activePlayerId
          );
          const firstPlayerIndex =
            (lastActivePlayerIndex + 1) % lastLegOrder.length;
          nextLegOrder = [
            ...lastLegOrder.slice(firstPlayerIndex),
            ...lastLegOrder.slice(0, firstPlayerIndex),
          ];
        }
        const firstPlayer = nextLegOrder[0];
        this.snapshot = produce(this.snapshot, (draft) => {
          draft.legOrder = [...(draft.legOrder || []), nextLegOrder];
          draft.activePlayerId = firstPlayer;
        });
    }
  }

  onUndoEvent(_event: GameEventUndo) {
    // If we are in middling phase and we have middling throws
    if (
      this.snapshot.middlingPhase &&
      this.snapshot.middlingThrows.some((mt) => mt.leg === this.snapshot.leg)
    ) {
      // If the active player have a middle throw we remove it if not we move back to previous player
      const activePlayerId = this.getActivePlayer().id;
      const activeMiddleThrow = this.snapshot.middlingThrows.find(
        (mt) => mt.playerId === activePlayerId && mt.leg === this.snapshot.leg
      );
      if (activeMiddleThrow) {
        this.snapshot = produce(this.snapshot, (draft) => {
          draft.middlingThrows = [...draft.middlingThrows].filter(
            (mt) => mt.throw.id !== activeMiddleThrow.throw.id
          );
        });
        return;
      }
      this.snapshot = produce(this.snapshot, (draft) => {
        const previousPlayer = this.getPreviousPlayer();
        if (draft.activePlayerId !== previousPlayer.id) {
          this.emitter.emit(
            GameAnimationEvent.PreviousPlayer,
            previousPlayer.id
          );
        }
        draft.activePlayerId = previousPlayer.id;
      });
      return;
    }

    const roundIndex = this.getActiveRoundIndex();

    const { hit1, hit2, hit3 } = this.snapshot.rounds[roundIndex];

    // If we are undoing a finish event we need to decrement winning player legs
    // and set game to not finished
    if (this.snapshot.finished || this.getActivePlayer().score === 0) {
      this.snapshot = produce(this.snapshot, (draft) => {
        // Reduce the number of legs for the winning player
        draft.players.find(
          (p) => p.id === this.snapshot.activePlayerId
        )!.legs -= 1;
        draft.finished = false;
      });
    }

    if (hit3) {
      this.snapshot = produce(this.snapshot, (draft) => {
        draft.rounds[roundIndex].hit3 = undefined;
        draft.players.find((p) => p.id === this.snapshot.activePlayerId)!.score;
      });
      this.snapshot = produce(this.snapshot, (draft) => {
        draft.players.find(
          (p) => p.id === this.snapshot.activePlayerId
        )!.score = this.getLegScore(
          this.snapshot.activePlayerId,
          this.snapshot.leg
        );
      });
      return;
    }
    if (hit2) {
      this.snapshot = produce(this.snapshot, (draft) => {
        draft.rounds[roundIndex].hit2 = undefined;
      });
      this.snapshot = produce(this.snapshot, (draft) => {
        draft.players.find(
          (p) => p.id === this.snapshot.activePlayerId
        )!.score = this.getLegScore(
          this.snapshot.activePlayerId,
          this.snapshot.leg
        );
      });
      return;
    }
    if (hit1) {
      this.snapshot = produce(this.snapshot, (draft) => {
        draft.rounds[roundIndex].hit1 = undefined;
      });
      this.snapshot = produce(this.snapshot, (draft) => {
        draft.players.find(
          (p) => p.id === this.snapshot.activePlayerId
        )!.score = this.getLegScore(
          this.snapshot.activePlayerId,
          this.snapshot.leg
        );
      });
      return;
    }
    if (roundIndex === 0 && this.snapshot.middlingThrows.length === 0) {
      throw new Error("Cannot undo first round");
    }

    // We have not hits we need to move back to previous player
    this.snapshot = produce(this.snapshot, (draft) => {
      const currentLegOrder = this.getCurrentLegOrder();
      const currentPlayerIndex = currentLegOrder.indexOf(
        this.snapshot.activePlayerId
      );

      const isFirstPlayerInLeg = currentPlayerIndex === 0;
      const isFirstRoundInLeg = this.snapshot.activeRound === 1;

      // Set active player to previous player
      let previousPlayer = this.getPreviousPlayer();
      if (draft.activePlayerId !== previousPlayer.id) {
        this.emitter.emit(GameAnimationEvent.PreviousPlayer, previousPlayer.id);
      }
      draft.activePlayerId = previousPlayer.id;
      // If not first player in leg we just move back to previous player
      if (!isFirstPlayerInLeg) {
        return;
      }

      // If not first round in leg we move back to previous round
      if (!isFirstRoundInLeg) {
        draft.rounds = draft.rounds.filter(
          (r) =>
            r.roundNumber !== this.currentRound() || r.leg !== this.snapshot.leg
        );
        // Decrement active round
        draft.activeRound -= 1;
        return;
      }

      // If the config is set to middle we need to move back to middling phase
      if (this.config.legOrderType === LegOrderType.Middle) {
        const legMiddleThrows = [...this.snapshot.middlingThrows].filter(
          (mt) => mt.leg === this.snapshot.leg
        );

        // If we have middling throws we move back to middling phase
        if (legMiddleThrows.length > 0) {
          const lastMiddler =
            this.snapshot.middlingThrows[
              this.snapshot.middlingThrows.length - 1
            ].playerId;
          draft.activePlayerId = lastMiddler;
          // Reset leg order to previous leg order
          draft.legOrder[this.snapshot.leg - 1] = this.snapshot.middlingThrows
            .filter((mt) => mt.leg === this.snapshot.leg)
            .map((mt) => mt.playerId);
          draft.middlingPhase = true;
          return;
        }
      }

      // We need to move back to previous leg

      // Find the last player in previous leg
      const previousLeg = this.snapshot.leg - 1;
      // Find last round in previous leg with a hit to find the last player
      const lastLegRounds = [...draft.rounds].filter(
        (r) => r.leg === previousLeg
      );
      const lastPlayerInLeg = lastLegRounds
        .reverse()
        .find(
          (r) =>
            r.leg === previousLeg &&
            (r.hit1?.point || r.hit2?.point || r.hit3?.point)
        );
      if (!lastPlayerInLeg) {
        throw new Error("Last player in leg not found");
      }

      draft.activePlayerId = lastPlayerInLeg.playerId;
      draft.activeRound = lastPlayerInLeg.roundNumber;
      // Remove all rounds in current leg
      draft.rounds = draft.rounds.filter((r) => r.leg !== this.snapshot.leg);

      // Remove last leg order
      draft.legOrder = draft.legOrder!.filter(
        (l) => l !== draft.legOrder![draft.legOrder!.length - 1]
      );

      // Decrement leg
      draft.leg -= 1;

      // Reset players to the score they had before the leg
      draft.players = draft.players.map((p) => {
        // We need to sum the score of the previous leg rounds for the player
        const score = this.getLegScore(p.id, previousLeg);

        return { ...p, score };
      });

      // Set middlingPhase to false if we move back to a previous leg
      draft.middlingPhase = false;
    });
  }

  getLegScore(playerId: string, leg: number): number {
    const rounds = this.snapshot.rounds.filter(
      (r) => r.playerId === playerId && r.leg === leg
    );
    return rounds.reduce((a, round) => {
      // If a hit is bust we skip the whole round
      if (round.hit1?.bust || round.hit2?.bust || round.hit3?.bust) {
        return a;
      }
      const hit1 = round.hit1 ? scoreToPoints(round.hit1.point) : 0;
      const hit2 = round.hit2 ? scoreToPoints(round.hit2.point) : 0;
      const hit3 = round.hit3 ? scoreToPoints(round.hit3.point) : 0;
      return a - hit1 - hit2 - hit3;
    }, this.config.startScore);
  }

  // TO DISCUSS: We should probably rename this to not get cunfused with the active round in snapshot
  // My suggestion is to rename activeRound in snapshot to something else
  getActiveRoundIndex() {
    const activePlayerId = this.getActivePlayer().id;
    const currentRound = this.currentRound();
    return this.snapshot.rounds.findIndex(
      (r) =>
        r.playerId === activePlayerId &&
        r.roundNumber === currentRound &&
        r.leg === this.snapshot.leg
    );
  }

  onEditEvent(event: GameEventEdit) {
    if (this.snapshot.middlingPhase) {
      // Find the index of the throw to edit
      const editIndex = this.snapshot.middlingThrows.findIndex(
        (mt) => mt.throw.id === event.payload.eventId
      );
      if (editIndex < 0) {
        throw new Error("Throw not found");
      }
      // Replce the throw with the new throw
      this.snapshot = produce(this.snapshot, (draft) => {
        draft.middlingThrows[editIndex].throw = {
          id: event.payload.eventId,
          point: event.payload.point,
          position: event.payload.position,
          bust: false,
        };
      });
      return;
    }

    const editIndex = this.snapshot.rounds.findIndex(
      (r) =>
        r.hit1?.id === event.payload.eventId ||
        r.hit2?.id === event.payload.eventId ||
        r.hit3?.id === event.payload.eventId
    );
    if (editIndex < 0) {
      throw new Error("Hit not found");
    }
    const editRound = this.snapshot.rounds[editIndex];
    if (
      isHitSet(editRound.hit1) &&
      editRound.hit1?.id === event.payload.eventId
    ) {
      this.editHit(editIndex, "hit1", event);
    } else if (
      isHitSet(editRound.hit2) &&
      editRound.hit2?.id === event.payload.eventId
    ) {
      this.editHit(editIndex, "hit2", event);
    } else if (
      isHitSet(editRound.hit3) &&
      editRound.hit3?.id === event.payload.eventId
    ) {
      this.editHit(editIndex, "hit3", event);
    }
  }

  private editHit(
    roundIndex: number,
    key: "hit1" | "hit2" | "hit3",
    event: GameEventEdit
  ) {
    const editRound = this.snapshot.rounds[roundIndex];
    this.snapshot = produce(this.snapshot, (draft) => {
      draft.rounds[roundIndex][key] = {
        ...editRound[key],
        id: event.payload.eventId,
        point: event.payload.point,
        bust: false,
        position: event.payload.position,
      };

      const diff =
        scoreToPoints(event.payload.point) -
        scoreToPoints(editRound[key]!.point);

      const newScore = this.getActivePlayer().score - diff;

      // Check if we gonna bust
      if (this.isNewScoreBusted(newScore, event.payload.point)) {
        draft.rounds[roundIndex][key]!.bust = true;
        if (key === "hit1") {
          if (!editRound.hit2) {
            draft.rounds[roundIndex].hit2 = emptyHit(event.id, 2);
          } else {
            draft.rounds[roundIndex].hit2!.bust = true;
          }
        }
        if (key === "hit1" || key === "hit2") {
          if (!editRound.hit3) {
            draft.rounds[roundIndex].hit3 = emptyHit(event.id, 3);
          } else {
            draft.rounds[roundIndex].hit3!.bust = true;
          }
        }
      } else if (newScore === 0) {
        draft.players.find(
          (p) => p.id === this.snapshot.activePlayerId
        )!.legs += 1;
        if (this.config.legs === this.getActivePlayer().legs) {
          this.emitter.emit(
            GameAnimationEvent.Winner,
            this.snapshot.activePlayerId
          );
          draft.finished = true;
        }
      }
    });

    // Recalculate new score for player
    this.snapshot = produce(this.snapshot, (draft) => {
      const playerIndex = draft.players.findIndex(
        (p) => p.id === editRound.playerId
      );
      // Change score to new score with edit
      draft.players[playerIndex].score = this.getLegScore(
        editRound.playerId,
        editRound.leg
      );
    });
  }

  private onBusted(
    draft: X01GameSnapshot,
    roundIndex: number,
    resetBust = false
  ) {
    // If we bust we reset whole round
    const hit1 = draft.rounds[roundIndex].hit1;
    const hit2 = draft.rounds[roundIndex].hit2;
    const hit3 = draft.rounds[roundIndex].hit3;
    let hits = [hit1, hit2, hit3].filter(
      (h) => h !== undefined && h !== null
    ) as (DartHit & { bust: true })[];

    if (!resetBust) {
      hits = hits.filter((h) => !h.bust);
    }
    const resetPoints = hits.map((h) => h!.point);

    const playerIndex = draft.players.findIndex(
      (p) => p.id === draft.rounds[roundIndex].playerId
    );

    draft.players[playerIndex].score += resetPoints.reduce(
      (a, b) => a + scoreToPoints(b),
      0
    );
    this.emitter.emit(
      GameAnimationEvent.Bust,
      draft.rounds[roundIndex].playerId
    );
  }

  endMiddlePhase() {
    const middlingThrows = [...this.snapshot.middlingThrows].filter(
      (mt) => mt.leg === this.snapshot.leg
    );
    // We sort the middling throws by closest to bull at 0,0
    const sortedMiddlingThrows = middlingThrows.sort((a, b) => {
      // If position is undefined we set it to 1,1
      const aPos = a.throw.position || { x: 1, y: 1 };
      const bPos = b.throw.position || { x: 1, y: 1 };
      const aDistance = Math.sqrt(
        Math.pow(aPos.x - 0, 2) + Math.pow(aPos.y - 0, 2)
      );
      const bDistance = Math.sqrt(
        Math.pow(bPos.x - 0, 2) + Math.pow(bPos.y - 0, 2)
      );
      return aDistance - bDistance;
    });

    const middlingLegOrder = sortedMiddlingThrows.map((mt) => mt.playerId);
    // Replace the current leg order with the middling leg order
    this.snapshot = produce(this.snapshot, (draft) => {
      draft.legOrder[this.snapshot.leg - 1] = middlingLegOrder;
      draft.middlingPhase = false;
      draft.activePlayerId = middlingLegOrder[0];
    });
  }

  startNextLeg() {
    this.snapshot = produce(this.snapshot, (draft) => {
      draft.leg += 1;
    });
    this.initializeLegOrder();
    this.resetState();
  }

  onManualNextEvent(event: GameEventManualNext) {
    if (this.snapshot.middlingPhase) {
      // If all players have thrown we can establish the leg order and end the middling phase
      const middlingThrows = [...this.snapshot.middlingThrows].filter(
        (mt) => mt.leg === this.snapshot.leg
      );
      if (middlingThrows.length === this.players.length) {
        this.endMiddlePhase();
        return;
      }

      this.snapshot = produce(this.snapshot, (draft) => {
        // The active player have not thrown we register a throw for the player
        if (
          !middlingThrows.some(
            (mt) => mt.playerId === this.getActivePlayer().id
          )
        ) {
          draft.middlingThrows.push({
            playerId: this.getActivePlayer().id,
            leg: draft.leg,
            throw: {
              id: event.id,
              point: DartPoint.MISS,
              position: {
                x: 1,
                y: 1,
              },
              bust: false,
            },
          });
        }
        // Set next player as active player
        const nextPlayer = this.getNextPlayer();
        draft.activePlayerId = nextPlayer.id;
      });
      return;
    }

    if (this.getActivePlayer().score === 0) {
      this.startNextLeg();
      return;
    }

    const hits = { ...this.getActiveRoundHits(this.getActivePlayer().id) };
    const roundIndex = this.snapshot.rounds.findIndex(
      (r) =>
        r.playerId === this.getActivePlayer()?.id &&
        r.roundNumber === this.currentRound() &&
        r.leg === this.snapshot.leg
    );
    // If we have no bust hit we add miss hits to the missing hits
    if (!hits.hit1?.bust && !hits.hit2?.bust && !hits.hit3?.bust) {
      if (!hits.hit1) {
        this.snapshot = produce(this.snapshot, (draft) => {
          draft.rounds[roundIndex].hit1 = emptyHit(event.id, 1);
        });
      }
      if (!hits.hit2) {
        this.snapshot = produce(this.snapshot, (draft) => {
          draft.rounds[roundIndex].hit2 = emptyHit(event.id, 2);
        });
      }
      if (!hits.hit3) {
        this.snapshot = produce(this.snapshot, (draft) => {
          draft.rounds[roundIndex].hit3 = emptyHit(event.id, 3);
        });
      }
    }

    this.nextPlayer();
  }

  onEmptyBoardEvent(event: GameEventEmptyBoard) {
    if (this.snapshot.middlingPhase) {
      // In middling phase we set next player after throw
      // Since players might want to leave darts on the board
      const middlingThrows = [...this.snapshot.middlingThrows].filter(
        (mt) => mt.leg === this.snapshot.leg
      );
      if (middlingThrows.length === this.players.length) {
        this.endMiddlePhase();
      }
      return;
    }

    if (this.getActivePlayer().score === 0) {
      this.startNextLeg();
      return;
    }

    const hits = { ...this.getActiveRoundHits(this.getActivePlayer().id) };
    if (!hits.hit1) {
      throw new Error("No hit registered, cannot empty board");
    }
    if (!hits.hit2) {
      hits.hit2 = emptyHit(event.id, 2);
    }
    if (!hits.hit3) {
      hits.hit3 = emptyHit(event.id, 3);
    }
    this.snapshot = produce(this.snapshot, (draft) => {
      const roundIndex = draft.rounds.findIndex(
        (r) =>
          r.playerId === this.getActivePlayer().id &&
          r.roundNumber === this.currentRound()
      );
      draft.rounds[roundIndex] = hits;
    });

    this.nextPlayer();
  }

  private isNewScoreBusted(newScore: number, point: DartPoint): boolean {
    const isBustDouble =
      this.config.exitMode === X01ExitMode.Double &&
      newScore <= 1 &&
      !(parseMultiplier(point) === 2 && newScore == 0);

    return newScore < 0 || isBustDouble;
  }

  /**
   * Handles the throw event. It will add the hit to the current round
   * and subtract the score from the active player
   * @throws Error if the player has already thrown three times
   * @param event The throw event
   */
  onThrowEvent(event: GameEventThrow) {
    if (this.isRoundBusted(this.getActiveRoundIndex())) {
      throw new Error("Round is busted, cannot add throw");
    }
    if (this.snapshot.middlingPhase) {
      // Throw error if all players have thrown
      const middlingThrows = [...this.snapshot.middlingThrows].filter(
        (mt) => mt.leg === this.snapshot.leg
      );
      if (middlingThrows.length === this.players.length) {
        throw new Error("All players have thrown");
      }
      const activePlayerId = this.getActivePlayer().id;
      // Throw error if player has already thrown
      if (middlingThrows.some((mt) => mt.playerId === activePlayerId)) {
        throw new Error("Player has already thrown");
      }
      // Add throw to middling throws
      this.snapshot = produce(this.snapshot, (draft) => {
        draft.middlingThrows.push({
          playerId: activePlayerId,
          leg: draft.leg,
          throw: {
            id: event.id,
            point: event.payload.point,
            position: event.payload.position,
            bust: false,
          },
        });
        // Set next player as active player
        // Only set next player if not all players have thrown
        if (middlingThrows.length < this.players.length - 1) {
          const nextPlayer = this.getNextPlayer();
          this.emitter.emit(GameAnimationEvent.NextPlayer, nextPlayer.id);
          draft.activePlayerId = nextPlayer.id;
        }
      });
      return;
    }

    const hits = this.getActiveRoundHits(this.getActivePlayer().id);
    // find first undefined hit
    const newHit = {
      id: event.id,
      point: event.payload.point,
      position: event.payload.position,
      bust: false,
    };
    // Check new score
    const newScore =
      this.getActivePlayer().score - scoreToPoints(event.payload.point);

    if (this.isNewScoreBusted(newScore, event.payload.point)) {
      newHit.bust = true;
    } else if (newScore === 0) {
      this.snapshot = produce(this.snapshot, (draft) => {
        const playerIndex = draft.players.findIndex(
          (p) => p.id === this.snapshot.activePlayerId
        );
        draft.players[playerIndex].legs += 1;
      });
      if (this.config.legs === this.getActivePlayer().legs) {
        this.snapshot = produce(this.snapshot, (draft) => {
          this.emitter.emit(
            GameAnimationEvent.Winner,
            this.snapshot.activePlayerId
          );
          draft.finished = true;
        });
      }
    }
    const roundIndex = this.snapshot.rounds.findIndex(
      (r) =>
        r.playerId === this.getActivePlayer()?.id &&
        r.roundNumber === this.currentRound() &&
        r.leg === this.snapshot.leg
    );
    if (!hits.hit1) {
      this.snapshot = produce(this.snapshot, (draft) => {
        draft.rounds[roundIndex].hit1 = newHit;
      });
    } else if (!hits.hit2) {
      this.snapshot = produce(this.snapshot, (draft) => {
        draft.rounds[roundIndex].hit2 = newHit;
      });
    } else if (!hits.hit3) {
      this.snapshot = produce(this.snapshot, (draft) => {
        draft.rounds[roundIndex].hit3 = newHit;
      });
    } else {
      throw new Error("No more hits allowed");
    }
    if (!newHit.bust) {
      this.snapshot = produce(this.snapshot, (draft) => {
        const playerIndex = draft.players.findIndex(
          (p) => p.id === this.snapshot.activePlayerId
        );
        draft.players[playerIndex].score -= scoreToPoints(event.payload.point);
      });
    } else {
      this.snapshot = produce(this.snapshot, (draft) => {
        this.onBusted(draft, roundIndex);
      });
    }
  }
  isRoundBusted(roundIndex: number): boolean {
    const round = this.snapshot.rounds[roundIndex];
    return !!round.hit1?.bust || !!round.hit2?.bust || !!round.hit3?.bust;
  }

  /**
   * Handles the start event
   * @param event The start event
   */
  onStartEvent(_event: GameEventStart) {
    if (this.players.length < 1) {
      throw new Error("Not enough players");
    }
    // set player order
    this.initializeLegOrder();
    this.resetState();

    const currentLegOrder = this.getCurrentLegOrder();
    this.snapshot = produce(this.snapshot, (draft) => {
      draft.activePlayerId = currentLegOrder[0];
    });
  }

  private resetState() {
    // reset active round
    this.snapshot = produce(this.snapshot, (draft) => {
      draft.activeRound = 1;
    });
    // reset player scores
    this.snapshot = produce(this.snapshot, (draft) => {
      const players = draft.players.length > 0 ? draft.players : this.players;
      draft.players = players.map((p) => ({
        legs: p.legs,
        id: p.id,
        score: this.config.startScore,
      }));
    });
    this.snapshot = produce(this.snapshot, (draft) => {
      const currentLegOrder = this.getCurrentLegOrder();
      const newRounds: X01Round[] = currentLegOrder.map((p) => {
        return {
          ...EmptyRound,
          playerId: p,
          roundNumber: 1,
          leg: draft.leg,
        };
      });
      draft.rounds = [...draft.rounds, ...newRounds];
    });
  }

  /**
   * Sets the next player as active player
   * if the last player has thrown, the round is incremented
   * @throws Error if the active player is not found in the player order
   */
  private nextPlayer() {
    const currentLegOrder = this.getCurrentLegOrder();
    const index = currentLegOrder.indexOf(this.snapshot.activePlayerId);
    if (index === -1) {
      throw new Error("Active player not found");
    }
    const nextIndex = (index + 1) % currentLegOrder.length;
    this.snapshot = produce(this.snapshot, (draft) => {
      draft.activePlayerId = currentLegOrder[nextIndex];
    });
    if (nextIndex === 0) {
      this.snapshot = produce(this.snapshot, (draft) => {
        const newRoundNumber = draft.activeRound + 1;
        draft.activeRound = newRoundNumber;
        const newRounds: X01Round[] = currentLegOrder.map((p) => {
          return {
            ...EmptyRound,
            leg: draft.leg,
            playerId: p,
            roundNumber: newRoundNumber,
          };
        });
        draft.rounds = [...draft.rounds, ...newRounds];
      });
    }
    this.emitter.emit(
      GameAnimationEvent.NextPlayer,
      this.snapshot.activePlayerId
    );
  }

  /**
   * Returns the current round number
   * @returns The current round number
   */
  public currentRound(): number {
    return this.snapshot.activeRound;
  }

  /**
   * Returns the max rounds for the game
   */
  public maxRounds(): number {
    return this.config.numRounds;
  }

  public getCurrentLegOrder() {
    return this.getLegOrder(this.snapshot.leg);
  }

  public getLegOrder(leg: number) {
    const legIndex = leg - 1;
    if (this.snapshot.legOrder && legIndex < this.snapshot.legOrder.length) {
      const legOrder = this.snapshot.legOrder[legIndex];
      if (legOrder) return legOrder;
    }
    throw new Error("Leg order not defined for leg");
  }

  /**
   *  Returns the active player
   * @returns The active player
   */
  public getActivePlayer() {
    const player = this.snapshot.players.find(
      (p) => p.id === this.snapshot.activePlayerId
    );
    if (!player) {
      throw new Error("Active player not found");
    }
    return player;
  }

  public getNextPlayer() {
    const currentLegOrder = this.getCurrentLegOrder();
    const index = currentLegOrder.indexOf(this.snapshot.activePlayerId);
    const nextIndex = (index + 1) % currentLegOrder.length;
    const nextPlayerId = currentLegOrder[nextIndex];
    const player = this.snapshot.players.find((p) => p.id === nextPlayerId);
    if (!player) {
      throw new Error("Next player not found");
    }
    return player;
  }

  public getPreviousPlayer() {
    // Retrieve the current order of players in the leg.
    const currentLegOrder = this.getCurrentLegOrder();

    // Find the index of the currently active player.
    const activePlayerIndex = currentLegOrder.indexOf(
      this.snapshot.activePlayerId
    );

    // Calculate the index of the previous player.
    // If the active player is the first in the array, set to the last player's index.
    const previousPlayerIndex =
      activePlayerIndex === 0
        ? currentLegOrder.length - 1
        : activePlayerIndex - 1;

    // Get the ID of the previous player.
    const previousPlayerId = currentLegOrder[previousPlayerIndex];

    // Find and return the previous player's data.
    const previousPlayer = this.snapshot.players.find(
      (p) => p.id === previousPlayerId
    );
    if (!previousPlayer) {
      throw new Error("Previous player not found");
    }

    return previousPlayer;
  }

  public getPlayer(playerId: string) {
    return this.snapshot.players.find((p) => p.id === playerId);
  }

  public getHits(playerId: string) {
    return this.snapshot.rounds
      .filter((r) => r.playerId === playerId)
      .sort((a, b) => a.roundNumber - b.roundNumber);
  }

  public getRoundHits(playerId: string, round: number) {
    return this.snapshot.rounds.find(
      (r) => r.playerId === playerId && r.roundNumber === round
    );
  }

  public getActiveRoundHits(playerId: string) {
    const round = this.snapshot.rounds.find(
      (r) =>
        r.playerId === playerId &&
        r.roundNumber === this.currentRound() &&
        r.leg === this.snapshot.leg
    );
    if (!round) {
      throw new Error("Round not found");
    }
    return round;
  }
}

export const getActiveRoundHits = (
  snapshot: X01GameSnapshot | Immutable<X01GameSnapshot>,
  playerId?: string
) => {
  if (!playerId) {
    playerId = snapshot.activePlayerId;
  }
  const round = snapshot.rounds.find(
    (r) =>
      r.playerId === playerId &&
      r.roundNumber === snapshot.activeRound &&
      r.leg === snapshot.leg
  );
  if (!round) {
    // throw new Error("Round not found");
    return undefined;
  }
  return round;
};
