import React, { useEffect } from "react";
import {
  Box,
  Center,
  Paper,
  PinInput,
  Skeleton,
  Stack,
  Text,
} from "@mantine/core";
import { Button, Logo } from "@pilplay/ui";
import { useNavigate } from "react-router-dom";
import { useInputState } from "@mantine/hooks";
import type { CombinedError } from "urql";
import { useLobbyMutations } from "../../../../hooks/useLobbyMutations/useLobbyMutations";
import { clientRoutes } from "../../../../routes";
import ErrorAlert from "../../../../components/ErrorAlert";
import useBoard from "../../../../hooks/useBoard";
import classes from "./CreateLobbyActions.module.css";

export interface CreateLobbyActionsProps {
  boardId: string;
}

const extractGraphqlError = (error?: CombinedError) => {
  if (error?.graphQLErrors.length) {
    return error.graphQLErrors[0].message;
  }
  return error?.networkError?.message;
};

export const CreateLobbyActions: React.FC<CreateLobbyActionsProps> = ({
  boardId,
}) => {
  const {
    createLobby,
    create: { error },
  } = useLobbyMutations();
  const navigate = useNavigate();

  const [codeInput, setCodeInput] = useInputState("");

  const [{ data, fetching, error: boardError }] = useBoard(boardId, {
    requestPolicy: "network-only",
  });

  const requireCode = data?.board.requireLobbySession;

  const onCreateLobby = async () => {
    const res = await createLobby({
      input: {
        boardId,
        players: [],
      },
    });
    if (res.data?.createLobby) {
      navigate(clientRoutes.boardLobbyPage(boardId), {
        replace: true,
      });
    }
  };

  const onCreateLobbyWithCode = async () => {
    const res = await createLobby({
      input: {
        boardId,
        players: [],
        codeInput,
      },
    });
    if (res.data?.createLobby) {
      navigate(clientRoutes.boardLobbyPage(boardId), {
        replace: true,
      });
    }
  };

  useEffect(() => {
    if (data?.board.activeLobby?.id) {
      navigate(clientRoutes.boardLobbyPage(boardId), { replace: true });
    }
  }, [boardId, data?.board, navigate]);

  return (
    <Center>
      <Paper maw="320" bg="dark.8" p="xl" className={classes.root}>
        <Logo />
        <Text fw="600" ta="center" py="md">
          Start a new game lobby and compete with your friends!
        </Text>
        <Stack>
          {!requireCode && (
            <Button onClick={onCreateLobby} fullWidth>
              Create Lobby
            </Button>
          )}
          {requireCode ? (
            <Box>
              <Skeleton visible={fetching}>
                <PinInput
                  value={codeInput}
                  onChange={setCodeInput}
                  type="number"
                  size="lg"
                  length={6}
                  error={Boolean(error)}
                />
                {Boolean(error) && (
                  <Text mt={5} c="red" fz="xs">
                    {extractGraphqlError(error)}
                  </Text>
                )}
              </Skeleton>
              <Skeleton my="xs" visible={fetching}>
                <Text pb="sm" size="xs" c="dimmed">
                  Talk to your venue host to get the code to join the lobby
                </Text>
              </Skeleton>
              <Button
                onClick={onCreateLobbyWithCode}
                loading={fetching}
                fullWidth
              >
                Start Lobby
              </Button>
            </Box>
          ) : null}
          <ErrorAlert error={boardError} />
        </Stack>
      </Paper>
    </Center>
  );
};
