import { Box } from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { Animations, DesktopLayout } from "@pilplay/ui";
import React, { useState } from "react";
import ScaleToFit from "../../../../../../../components/ScaleToFit";
import { useIsTabletStanding } from "../../../../../../../hooks/layout/useDevice";
import { useGameContext } from "../../../../context/useGameContext";
import { EditHitModal } from "../../../EditHit/EditHit";
import CricketControlsModal from "../CricketControlsModal/CricketControlsModal";
import CricketFloatingActions from "../CricketFloatingActions";
import CricketHeader from "../CricketHeader";
import CricketHistory from "../CricketHistory";
import CricketScoreTable from "../CricketScoreTable";

const CricketGame: React.FC = () => {
  const { showControls, setEditId, editId } = useGameContext();
  const [showHistory, setShowHistory] = useState(false);
  const onHistoryClick = () => {
    setShowHistory(true);
  };
  const [expanded, toggleExpanded] = useToggle([false, true]);

  const isTabletStanding = useIsTabletStanding();

  return (
    <>
      <DesktopLayout
        style={{
          background: "var(--mantine-color-dark-8)",
        }}
        header={<CricketHeader />}
        cols={[
          {
            span: 12,
            render: ({ height, width }) => (
              <Box
                style={{
                  height,
                }}
              >
                <ScaleToFit margin={50}>
                  {showControls ? (
                    <CricketFloatingActions
                      onControlsClick={toggleExpanded}
                      onHistoryClick={onHistoryClick}
                      minWidth={350} // Prevent control overlap if one player
                    />
                  ) : null}
                  <Box
                    style={{
                      overflow: "auto",
                      maxWidth: width,
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    py={75}
                  >
                    <CricketScoreTable
                      width={width - 100}
                      onEmptyHitClick={toggleExpanded}
                    />
                  </Box>
                </ScaleToFit>
              </Box>
            ),
          },
        ]}
      />

      {/* Overlays and modals */}
      <Animations />
      <EditHitModal
        editId={editId}
        onClose={() => {
          setEditId(undefined);
        }}
        show={showControls}
      />
      <CricketControlsModal opened={expanded} onClose={toggleExpanded} />
      <CricketHistory
        isMobile={isTabletStanding || false}
        onClose={() => {
          setShowHistory(false);
        }}
        onHitClick={(hit) => {
          if (hit) {
            setShowHistory(false);
            setEditId(hit.id);
          }
        }}
        open={showHistory}
      />
    </>
  );
};

export default CricketGame;
