import { Card, Container, Grid, Stack } from "@mantine/core";
import React from "react";
import { AreaChart } from "@mantine/charts";
import { CallToActionButton, SectionHeader } from "@pilplay/ui";
import { useParams } from "react-router-dom";
import { IconBrandFlightradar24, IconUser } from "@tabler/icons-react";
import { useGames } from "../../../hooks/useGames";
import { useMe } from "../../../hooks/useMe";
import { OrganizationTabs } from "../OrganizationTabs";
import { clientRoutes } from "../../../routes";
import { isUser } from "../../../hooks/useMe/useMe";
import NotFoundPage from "../../NotFoundPage";
import { countItemsByDate } from "../../../utils/charts";

const DashboardPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [{ data: me }] = useMe();
  const { games } = useGames({
    take: 1000,
  });

  const gamesPlayedByDate = countItemsByDate(games ?? []);

  if (isUser(me) && !me.me.organizations.some((org) => org.slug === slug)) {
    return <NotFoundPage />;
  }

  return (
    <Container size="xl">
      <SectionHeader
        breadcrumbs={[
          { label: "Home" },
          { label: "Organization", link: `/organization/${slug}` },
          {
            label: "Dashboard",
            link: "/",
            active: true,
          },
        ]}
        mt="xl"
        title={`Hey there, ${me?.me.displayName}`}
        subTitle={`Welcome back to Pilplay, we're glad to see you again!`}
      />

      <OrganizationTabs value="dashboard" />

      <Grid>
        <Grid.Col span={{ base: 12, md: 3 }}>
          <Stack>
            <CallToActionButton
              title="Boards"
              description="Register a new board"
              to={clientRoutes.organizationBoards(slug)}
              icon={<IconBrandFlightradar24 size={32} />}
            />
            <CallToActionButton
              title="Profile"
              description="Update your profile"
              to={clientRoutes.profile}
              icon={<IconUser size={32} />}
            />
          </Stack>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 9 }}>
          <Card bg="dark.9" withBorder shadow="sm" radius="md">
            <Card.Section fw="600" withBorder inheritPadding py="xs">
              Games played last 30 days
            </Card.Section>
            <Card.Section inheritPadding py="md">
              <AreaChart
                h={230}
                data={gamesPlayedByDate}
                dataKey="date"
                series={[
                  { name: "count", label: "Games played", color: "indigo.6" },
                ]}
                curveType="monotone"
                withDots={false}
              />
            </Card.Section>
          </Card>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default DashboardPage;
