/* eslint-disable @typescript-eslint/no-explicit-any -- store can story anything */
import { useSyncExternalStore } from "react";

export type PilplayStoreSubscriber<Value> = (value: Value) => void;
type SetStateCallback<Value> = (value: Value) => Value;

export interface PilplayStore<Value> {
  getState: () => Value;
  setState: (value: Value | SetStateCallback<Value>) => void;
  updateState: (value: Value | SetStateCallback<Value>) => void;
  initialize: (value: Value) => void;
  subscribe: (callback: PilplayStoreSubscriber<Value>) => () => void;
}

export type PilplayStoreValue<Store extends PilplayStore<any>> = ReturnType<
  Store["getState"]
>;

export function createStore<Value extends Record<string, any>>(
  initialState: Value
): PilplayStore<Value> {
  let state = initialState;
  let initialized = false;
  const listeners = new Set<PilplayStoreSubscriber<Value>>();

  return {
    getState() {
      return state;
    },

    updateState(value) {
      state = typeof value === "function" ? value(state) : value;
    },

    setState(value) {
      this.updateState(value);
      listeners.forEach((listener) => {
        listener(state);
      });
    },

    initialize(value) {
      if (!initialized) {
        state = value;
        initialized = true;
      }
    },

    subscribe(callback) {
      listeners.add(callback);
      return () => listeners.delete(callback);
    },
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- store can store anything
export function useStore<Store extends PilplayStore<any>>(store: Store) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- store can store anything
  return useSyncExternalStore<PilplayStoreValue<Store>>(
    store.subscribe,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- store can store anything
    () => store.getState(),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- store can store anything
    () => store.getState()
  );
}
