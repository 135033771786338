import React, { useState } from "react";
import { Box, Center } from "@mantine/core";
import { useTimeout } from "@mantine/hooks";
import { useParams } from "react-router-dom";
import classes from "./LobbyIdlePage.module.css";
import { IdleScreen } from "./IdleScreen";
import { CreateLobbyActions } from "./CreateLobbyActions";

export const LobbyIdlePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [isIdle, setIsIdle] = useState(false);
  const { start, clear } = useTimeout(() => {
    setIsIdle(true);
  }, 30000);

  const onSplashClick = () => {
    clear();
    setIsIdle(false);
    start();
  };

  return (
    <Box onClick={onSplashClick} className={classes.root}>
      <IdleScreen show={isIdle} />
      {isIdle ? (
        <Center pos="absolute" w="100%">
          <div className={classes.gradient} />
        </Center>
      ) : null}
      {!isIdle && <CreateLobbyActions boardId={id!} />}
    </Box>
  );
};
