import type { ReactElement } from "react";
import {
  FullScreenSwipe,
  type FullScreenSwipeProps,
} from "./components/FullScreenSwipe";
import type { CircleFadeProps } from "./components/CircleFade";
import { CircleFade as CircleFadeComp } from "./components/CircleFade";

export type PlayableAnimation =
  | AnimationSwipeRight
  | Animation
  | AnimationCircleFade;

export enum PlayableAnimationType {
  SwipeRight = "SWIPE_RIGHT",
  Animation = "ANIMATION",
  CircleFade = "CIRCLE_FADE",
}

export interface BaseAnimation<T extends object> {
  id?: string;
  type: PlayableAnimationType;
  duration: number;
  payload: T;
  component: ReactElement;
}

export interface AnimationSwipeRight
  extends BaseAnimation<FullScreenSwipeProps> {
  type: PlayableAnimationType.SwipeRight;
  duration: 2100;
}

export interface AnimationCircleFade extends BaseAnimation<CircleFadeProps> {
  type: PlayableAnimationType.CircleFade;
  duration: 2000;
}

export interface Animation
  extends BaseAnimation<{
    id?: string;
  }> {
  type: PlayableAnimationType.Animation;
  duration: number;
}

export const animation = (
  component: ReactElement,
  duration?: number
): Animation => ({
  type: PlayableAnimationType.Animation,
  duration: duration ? duration : 2100,
  payload: {},
  component,
});

export const animationSwipeRight = (
  payload: FullScreenSwipeProps & { id?: string }
): AnimationSwipeRight => ({
  type: PlayableAnimationType.SwipeRight,
  duration: 2100,
  payload,
  component: <FullScreenSwipe key={payload.id} {...payload} />,
});

export const animationCircleFade = (
  payload: CircleFadeProps & { id?: string }
): AnimationCircleFade => ({
  type: PlayableAnimationType.CircleFade,
  duration: 2000,
  payload,
  component: <CircleFadeComp key={payload.id} {...payload} />,
});
