import { Flex, NumberFormatter, Text } from "@mantine/core";
import classes from "./ListStat.module.css";

export interface ListStatProps {
  title: string;
  value: string | number;
}

export const ListStat: React.FC<ListStatProps> = ({ title, value }) => {
  return (
    <Flex className={classes.stat}>
      <Text c="dimmed" className={classes.statTitle}>
        {title}
      </Text>
      <div className={classes.statDivider} />
      <Text>
        {typeof value === "number" ? (
          <NumberFormatter value={value} decimalScale={1} />
        ) : (
          value
        )}
      </Text>
    </Flex>
  );
};
