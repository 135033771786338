import { useContext, useEffect } from "react";
import { WsClientContext } from "./WsClientProvider";

// Returns the current status of the WebSocket client
// If onReconnected is provided, it will be called when the WebSocket client reconnects
export function useWsClient(onReconnected?: () => void) {
  const wsClient = useContext(WsClientContext);

  useEffect(() => {
    if (wsClient && onReconnected) {
      const unlisten = wsClient.client.onReconnected(onReconnected);
      // Clean up the listener when the component unmounts
      return unlisten;
    }
    return undefined;
  }, [wsClient, onReconnected]);

  return wsClient?.status;
}
