import { LoadingOverlay } from "@mantine/core";
import type { DartHit } from "@pilplay/games";
import type Game from "@pilplay/games/src/game/Game";
import type { GameSnapshot, GameSnapshotQuery } from "@pilplay/graphql";
import {
  DetectionState,
  useBoardUpdatesSubscription,
  useCreateGameEventMutation,
  useGameEventsSubscription,
  useGameSnapshotQuery,
} from "@pilplay/graphql";
import { ConfettiOverlay, showAnimation } from "@pilplay/ui";
import {
  animation,
  animationCircleFade,
  animationSwipeRight,
} from "@pilplay/ui/src/Animations/animationConfig";
import type { Immutable } from "immer";
import React, { createContext, useEffect, useState } from "react";
import type { CombinedError } from "urql";
import { useWsClient } from "../../../../contexts/GraphQLProvider/WsClientProvider";
import { useGameMutations } from "../../../../hooks/useGameMutations/useGameMutations";
import { TakeoutInProgress } from "../components/TakeoutInProgress";

interface GamePlayer {
  id: string;
  index: number;
  playerType: string;
  name: string;
  userId?: string;
  avatarUrl?: string;
}

export interface GameContextProviderValue {
  gameId: string;
  lobbyId?: string;
  players: GamePlayer[];

  fetching: boolean;
  error?: CombinedError;

  throwDart: (hit: DartHit) => Promise<void>;
  nextPlayer: () => Promise<void>;
  undo: () => Promise<void>;
  editDart: (eventId: string, hit: DartHit) => Promise<void>;
  editId?: string;
  setEditId: (id: string | undefined) => void;
  showControls: boolean;
  abortGame: () => Promise<void>;
  getHitByEventId: (eventId: string) => DartHit | undefined;
  editMode: boolean;
  setEditMode: (editMode: boolean) => void;
}

export const GameContext = createContext<GameContextProviderValue>({
  gameId: "",
  showControls: true,
  players: [],
  fetching: false,
  error: undefined,
  editMode: false,
  setEditMode: (_: boolean) => {
    throw new Error("GameContext not initialized");
  },
  throwDart: (_: DartHit) => {
    throw new Error("GameContext not initialized");
  },
  nextPlayer: () => {
    throw new Error("GameContext not initialized");
  },
  editDart: (_id: string, _: DartHit) => {
    throw new Error("GameContext not initialized");
  },
  setEditId: (_: string | undefined) => {
    throw new Error("GameContext not initialized");
  },
  undo: () => {
    throw new Error("GameContext not initialized");
  },
  editId: undefined,
  abortGame: () => {
    throw new Error("GameContext not initialized");
  },

  getHitByEventId: (_: string) => {
    throw new Error("GameContext not initialized");
  },
});

const onNextPlayer = (
  playerId: string,
  players: { id: string; name: string; avatarUrl?: string }[]
) => {
  const player = players.find((p) => p.id === playerId);
  if (!player) {
    return;
  }
  showAnimation(
    animationSwipeRight({
      title: "Next player",
      description: player.name,
      image: player.avatarUrl,
      direction: "reverse",
    })
  );
};

const onPreviousPlayer = (
  playerId: string,
  players: { id: string; name: string; avatarUrl?: string }[]
) => {
  const player = players.find((p) => p.id === playerId);
  if (!player) {
    return;
  }
  showAnimation(
    animationSwipeRight({
      title: "Previous player",
      description: player.name,
      image: player.avatarUrl,
    })
  );
};

const onWinner = (
  playerId: string,
  players: { id: string; name: string; avatarUrl?: string }[]
) => {
  const player = players.find((p) => p.id === playerId);
  if (!player) {
    return;
  }
  showAnimation(
    animationSwipeRight({
      title: "Winner",
      description: player.name,
      image: player.avatarUrl,
    })
  );
  showAnimation(animation(<ConfettiOverlay />));
};

const onBust = () => {
  showAnimation(
    animationCircleFade({
      text: "Busted",
    })
  );
};

interface GameContextProviderProps<
  TGame extends Game<TSnapshot>,
  TSnapshot extends GameSnapshot,
> {
  gameId: string;
  lobbyId?: string;
  players: GamePlayer[];
  showControls?: boolean;
  children: React.ReactNode;
  gameRef: React.MutableRefObject<TGame>;
  setGameState: React.Dispatch<React.SetStateAction<Immutable<TSnapshot>>>;
  fromSnapshot: (game: GameSnapshotQuery) => TGame;
  getHitByEventId: (eventId: string) => DartHit | undefined;
  // Triggered when the gameRef changes
  onGameRefChange?: () => void;
}

const GameContextProvider = <
  TGame extends Game<TSnapshot>,
  TSnapshot extends GameSnapshot,
>({
  gameId,
  lobbyId,
  players,
  children,
  showControls = true,
  gameRef,
  setGameState,
  fromSnapshot,
  getHitByEventId,
  onGameRefChange,
}: GameContextProviderProps<TGame, TSnapshot>) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editId, setEditId] = useState<string | undefined>(undefined);
  const [_, createGameEvent] = useCreateGameEventMutation();
  const { abortGame } = useGameMutations();

  const [detectionState, setDetectionState] = useState<DetectionState>(
    DetectionState.Stable
  );

  const [listenerUpdates, setListenerUpdates] = useState(0);

  const [{ data: gameEvents }] = useGameEventsSubscription({
    variables: {
      gameId,
    },
  });

  const [{ data, fetching, error }, refetch] = useGameSnapshotQuery({
    variables: { gameId },
    requestPolicy: "network-only",
  });

  const [{ data: boardEvent }] = useBoardUpdatesSubscription({
    variables: {
      id: data?.game.board?.id || "",
    },
    pause: !data?.game.board?.id,
  });

  useEffect(() => {
    if (
      boardEvent?.boardEvents.__typename === "BoardEventDetectionStateUpdate"
    ) {
      setDetectionState(boardEvent.boardEvents.detectionState);
    }
  }, [boardEvent]);

  const wsStatus = useWsClient(() => {
    // Refetch game data when the WebSocket client reconnects
    refetch();
  });

  useEffect(() => {
    if (gameEvents?.gameEvents) {
      try {
        gameRef.current.onGameEvent(gameEvents.gameEvents);
      } catch (e) {
        refetch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to run this effect when gameEvents changes
  }, [gameEvents?.gameEvents]);

  useEffect(() => {
    if (data && gameRef.current && data.game.snapshot) {
      gameRef.current = fromSnapshot(data);

      const onEvent = (event: Immutable<TSnapshot>) => {
        setGameState(event);
      };
      gameRef.current.onGameStateChange(onEvent);
      setGameState(gameRef.current.getGameState());
      setListenerUpdates(listenerUpdates + 1);
      if (onGameRefChange) {
        onGameRefChange();
      }
      return () => {
        gameRef.current.offGameStateChange(onEvent);
      };
    }

    return () => {
      // Cleanup code, not needed
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to run this effect when data has been loaded
  }, [data]);

  useEffect(() => {
    const oNP = (p: string) => {
      onNextPlayer(p, players);
    };
    const oPP = (p: string) => {
      onPreviousPlayer(p, players);
    };
    const oW = (p: string) => {
      onWinner(p, players);
    };
    const oB = () => {
      onBust();
    };

    gameRef.current.onNextPlayer(oNP);
    gameRef.current.onPreviousPlayer(oPP);
    gameRef.current.onWinner(oW);
    gameRef.current.onBust(oB);

    return () => {
      gameRef.current.offNextPlayer(oNP);
      gameRef.current.offPreviousPlayer(oPP);
      gameRef.current.offWinner(oW);
      gameRef.current.offBust(oB);
    };
  }, [gameRef, listenerUpdates, players]);

  const throwDart = async (hit: DartHit) => {
    await createGameEvent({
      input: {
        throw: {
          gameId,
          point: hit.point,
          position: hit.position,
        },
      },
    });
  };

  const nextPlayer = async () => {
    await createGameEvent({
      input: {
        manualNext: {
          gameId,
        },
      },
    });
  };

  const undo = async () => {
    await createGameEvent({
      input: {
        undo: {
          gameId,
        },
      },
    });
  };

  const editDart = async (eventId: string, hit: DartHit) => {
    await createGameEvent({
      input: {
        edit: {
          gameId,
          eventId,
          point: hit.point,
          position: hit.position,
        },
      },
    });
  };

  return (
    <GameContext.Provider
      value={{
        gameId,
        lobbyId,
        players,
        throwDart,
        nextPlayer,
        editDart,
        editId,
        setEditId,
        undo,
        showControls,
        abortGame: async () => {
          await abortGame({
            gameId,
          });
        },
        fetching,
        error,
        getHitByEventId,
        editMode,
        setEditMode,
      }}
    >
      <LoadingOverlay
        // Temporary loading overlay
        // We need to discuss how to handle this
        style={{
          backgroundColor: "#252525",
        }}
        visible={wsStatus !== "connected"}
      />
      <TakeoutInProgress
        show={detectionState === DetectionState.PartialTakeout && showControls}
        boardId={data?.game.board?.id}
        onClose={() => {
          setDetectionState(DetectionState.Stable);
        }}
      />
      {children}
    </GameContext.Provider>
  );
};

export default GameContextProvider;
