import { Box, Center, Group, Space, Stack, Text } from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import { PlayerAvatar } from "@pilplay/ui";
import cx from "clsx";
import React, { useEffect, useRef } from "react";
import AnimatedNumber from "../../../../../../../components/AnimatedNumber";
import PlacementIcon from "../../../PlacementIcon";
import { useCricketGame } from "../../context/useCricketGame";
import classes from "./CricketScoreTable.module.css";
import CricketPlayerHits from "./componets/CricketPlayerHits";

interface NumberStackProps {
  hitNumbers: { label: string }[];
}

const NumberStack: React.FC<NumberStackProps> = ({ hitNumbers }) => {
  return (
    <Stack className={classes.numberStack}>
      {hitNumbers.map((hitNumber, i) => {
        return (
          <Text fw={900} key={`number-${i}`}>
            {hitNumber.label}
          </Text>
        );
      })}
    </Stack>
  );
};

interface CricketHitMarkersProps {
  count: number;
  hitsToOpenClose: number;
}

export const CricketHitMarkers: React.FC<CricketHitMarkersProps> = ({
  count,
  hitsToOpenClose,
}) => {
  return (
    <Group className={classes.hitMarkerBox}>
      {Array.from({ length: hitsToOpenClose }).map((_, i) => {
        const closed = i < count;
        return (
          <Box
            className={cx(classes.hitMarker, {
              [classes.hitMarkerClosed]: closed,
            })}
            key={`cricket-hit-marker-${i}`}
          />
        );
      })}
    </Group>
  );
};

interface CricketPlayerProps {
  playerId: string;
  width: number;
  maxWidth: number;
  minWidth: number;
  onEmptyClick?: () => void;
}

export const CricketPlayer: React.FC<CricketPlayerProps> = ({
  playerId,
  width,
  maxWidth,
  minWidth,
  onEmptyClick,
}) => {
  const { gamePlayers, activePlayerId, getPlayerMatrix, finished } =
    useCricketGame();
  const ref = useRef<HTMLDivElement>(null);

  const player = gamePlayers.find((p) => p.id === playerId);
  const playerMatrix = getPlayerMatrix(playerId);

  const active = playerId === activePlayerId;

  // If active player scroll into view
  useEffect(() => {
    if (active) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [active]);

  // Lets set the width here so we can use it for the other components
  const w = Math.max(Math.min(width, maxWidth), minWidth);

  const imageWidth = w / 5;
  const nameSize = w / 12;
  const numberSize = w / 8;

  return (
    <Box
      className={cx(classes.playerHeaderBox, {
        [classes.activePlayerHeader]: active && !finished, // The active player might not be the winner
      })}
      ref={ref}
      style={{
        width: w,
      }}
    >
      <Group pb="sm" wrap="nowrap">
        <PlayerAvatar url={player?.avatarUrl} size={imageWidth} />
        <Stack
          gap={0}
          align="center"
          style={{
            width: "100%",
          }}
        >
          <Center>
            <Text ta={"center"} fz={nameSize} fw={900}>
              {player?.name}
            </Text>
          </Center>
          <Center>
            <Text ta={"center"} fz={numberSize} fw={900}>
              <AnimatedNumber value={player?.score || 0} />
            </Text>
          </Center>
        </Stack>
        {finished && playerMatrix?.position ? (
          <Box>
            <PlacementIcon
              legWinnerOnly={!finished}
              placementIndex={playerMatrix.position - 1}
              size={imageWidth}
            />
          </Box>
        ) : null}
      </Group>
      <CricketPlayerHits playerId={playerId} onEmptyClick={onEmptyClick} />
    </Box>
  );
};

interface CricketPlayerHitMarkersProps {
  playerId: string;
  height?: number;
}
export const CricketPlayerHitMarkers: React.FC<
  CricketPlayerHitMarkersProps
> = ({ playerId, height }) => {
  const { config, getPlayerMatrix, activePlayerId, finished } =
    useCricketGame();
  const playerMatrix = getPlayerMatrix(playerId);
  const active = playerId === activePlayerId;
  return (
    <Stack
      className={cx(classes.playerHitMarkerBox, {
        [classes.activePlayerHitMarkerBox]: active && !finished, // The active player might not be the winner
      })}
      h={height}
      justify="space-between"
      w="100%"
    >
      {playerMatrix?.hits.map((hitNumber, i) => (
        <CricketHitMarkers
          count={hitNumber.count}
          hitsToOpenClose={config?.hitsToOpenClose || 3}
          key={`cricket-hit-marker-${i}`}
        />
      ))}
    </Stack>
  );
};

const CricketScoreTable: React.FC<{
  width?: number;
  onEmptyHitClick?: () => void;
}> = ({ width, onEmptyHitClick }) => {
  const { hitNumbers, scoringMatrix } = useCricketGame();

  const { ref: playerHeadeRef, height: playersHeaderHeight } = useElementSize();
  const { ref: numberStackRef, height: numberStackHeight } = useElementSize();

  const playerWidth = width ? width / Object.keys(scoringMatrix).length : 200;
  return (
    <Box className={classes.cricketTable}>
      <Box
        style={{
          position: "sticky",
          left: 0,
          zIndex: 2,
        }}
      >
        <Space h={Math.ceil(playersHeaderHeight) + 0.5} />
        <Box ref={numberStackRef}>
          <NumberStack hitNumbers={hitNumbers} />
        </Box>
      </Box>
      <Box>
        <Box className={classes.playersHeaderBox} ref={playerHeadeRef}>
          {Object.keys(scoringMatrix).map((playerId, i) => (
            <CricketPlayer
              key={`cricket-player-${i}`}
              playerId={playerId}
              width={playerWidth}
              maxWidth={220}
              minWidth={100}
              onEmptyClick={onEmptyHitClick}
            />
          ))}
        </Box>
        <Box
          className={classes.playerHitMarkersBox}
          h={Math.floor(numberStackHeight) - 0.5}
        >
          {Object.keys(scoringMatrix).map((playerId, i) => (
            <CricketPlayerHitMarkers
              key={`cricket-player-${i}`}
              playerId={playerId}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CricketScoreTable;
