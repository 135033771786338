import type { MeQuery, MeQueryVariables, UserType } from "@pilplay/graphql";
import { useMeQuery } from "@pilplay/graphql";
import type { UseQueryResponse } from "urql";
import { useEffect } from "react";
import { useAuthContext } from "../../contexts/AuthContext";

export function useMe(): UseQueryResponse<MeQuery, MeQueryVariables> {
  const { isSignedIn, logout } = useAuthContext();
  const [{ data, ...rest }, other] = useMeQuery({
    pause: !isSignedIn,
  });

  useEffect(() => {
    // If Me query fails due to unauthorized, the token is broken
    if (rest.error && rest.error.message.includes("Unauthorized")) {
      logout();
    }
  }, [logout, rest.error]);

  return [{ data: isSignedIn ? data : undefined, ...rest }, other];
}

export interface BoardUser {
  me: {
    __typename: "BoardUser";
    id: string;
    displayName: string;
    type: UserType.Board;
    board: { __typename?: "Board"; id: string };
  };
}

export interface User {
  me: {
    __typename: "User";
    id: string;
    displayName: string;
    email: string;
    avatarUrl?: string | null;
    type: UserType;
    activeOrganization: {
      __typename?: "Organization";
      id: string;
      name: string;
      slug: string;
    };
    organizations: {
      __typename?: "Organization";
      id: string;
      name: string;
      slug: string;
    }[];
  };
}

export const isUser = (data: MeQuery | undefined): data is User => {
  return data?.me.__typename === "User";
};

export const isBoardUser = (data: MeQuery | undefined): data is BoardUser => {
  return data?.me.__typename === "BoardUser";
};
