import React, { useEffect } from "react";
import type { CricketGameConfigInput } from "@pilplay/graphql";
import { CricketScoringRule, CricketWinCondition } from "@pilplay/graphql";
import { useForm } from "@mantine/form";
import {
  SCORING_RULE_CONFIG,
  HITS_TO_OPEN_CLOSE_CONFIG,
  START_NUMBER_CONFIG,
  MAX_ROUNDS_CONFIG,
  WIN_CONDITION_CONFIG,
} from "@pilplay/games";
import { Divider, ScrollArea, Text } from "@mantine/core";
import SegmentConfig from "../../../SegmentConfig";
import classes from "./cricket-game-config.module.css";

interface CricketGameConfigProps {
  onConfigChange: (config: { cricket: CricketGameConfigInput }) => void;
}

const CricketGameConfig: React.FC<CricketGameConfigProps> = ({
  onConfigChange,
}) => {
  const form = useForm({
    initialValues: {
      scoringRule: CricketScoringRule.Cutthroat,
      hitsToOpenClose: "3",
      startNumber: "15",
      winCondition: CricketWinCondition.Allclosed,
      numRounds: "20",
      includeBull: "true",
    },
  });

  useEffect(() => {
    onConfigChange({
      cricket: {
        ...form.values,
        hitsToOpenClose: parseInt(form.values.hitsToOpenClose.toString()),
        startNumber: parseInt(form.values.startNumber.toString()),
        numRounds: parseInt(form.values.numRounds.toString()),
        includeBull: form.values.includeBull === "true",
      },
    });
  }, [form.values, onConfigChange]);

  return (
    <div className={classes.root}>
      <ScrollArea>
        <SegmentConfig
          data={SCORING_RULE_CONFIG}
          description="How the score is applied"
          title="Scoring rule"
          {...form.getInputProps("scoringRule")}
          tooltip={
            <Text>
              <Text component="span" fw="bold">
                Standard
              </Text>{" "}
              The score is applied to the player who hit an opened number
              <br />
              <br />
              <Text component="span" fw="bold">
                Cut Throath
              </Text>{" "}
              All players that have not closed a number gets the score
            </Text>
          }
          value={form.values.scoringRule.toString()}
        />
        <SegmentConfig
          data={HITS_TO_OPEN_CLOSE_CONFIG}
          description="How many hits to open/close a number"
          title="Hits to open/close"
          {...form.getInputProps("hitsToOpenClose")}
          value={form.values.hitsToOpenClose.toString()}
        />
        <SegmentConfig
          data={START_NUMBER_CONFIG}
          description="From start number to 20"
          title="Start number"
          {...form.getInputProps("startNumber")}
        />
        <SegmentConfig
          data={[
            {
              label: "Yes",
              value: "true",
            },
            {
              label: "No",
              value: "false",
            },
          ]}
          description="Should the bull be included in the game"
          title="Include bull"
          {...form.getInputProps("includeBull")}
        />
        <SegmentConfig
          data={MAX_ROUNDS_CONFIG}
          description="The maximum number of rounds to play"
          title="Max rounds"
          {...form.getInputProps("numRounds")}
          value={form.values.numRounds.toString()}
        />
        <SegmentConfig
          data={WIN_CONDITION_CONFIG}
          description="How the game is finished"
          title="Win condition"
          {...form.getInputProps("winCondition")}
          tooltip={
            <Text>
              <Text component="span" fw="bold">
                All Numbers Closed
              </Text>{" "}
              When any player closes all numbers and bull the game ends
              <br />
              <br />
              <Text component="span" fw="bold">
                All Closed with Highest Score
              </Text>{" "}
              When the player with the highest/lowest score closes all numbers
              and bull the game ends.
            </Text>
          }
          value={form.values.winCondition.toString()}
        />
        <Divider my="md" />
        <Text c="dimmed" fz="sm">
          Cricket in darts is a strategy game where players aim to
          &quot;close&quot; numbers 15 through 20 and the bullseye by hitting
          each three times. Players score points by hitting these numbers after
          closing them, while preventing opponents from doing so. The game ends
          when one player closes all numbers and leads in points.
        </Text>
      </ScrollArea>
    </div>
  );
};

export default CricketGameConfig;
