export const clientRoutes = {
  root: "/",
  login: (redirect = ":redirect?") =>
    `/login/${
      redirect === ":redirect?"
        ? redirect
        : new URLSearchParams(redirect).toString().replace("=", "")
    }`,
  signUp: "/signup",
  profile: "/profile",
  gameX01: (boardId?: string) => `/game/x01/${boardId ? boardId : ""}`,
  gameCricket: (boardId?: string) => `/game/cricket/${boardId ? boardId : ""}`,
  joinLobby: (gameCode?: string | number) =>
    `/game/join/${gameCode ? gameCode : ""}`,
  game: (gameId = ":id") => `/game/${gameId}/play`,
  gameLobby: (gameId = ":id") => `/game/${gameId}/lobby`,
  boardFollowPage: (boardId = ":id") => `/board/${boardId}/follow`,
  boardLogsPage: (boardId = ":id") => `/board/${boardId}/logs`,
  boardStartPage: (boardId = ":id") => `/board/${boardId}/`,
  boardLobbyPage: (boardId = ":id") => `/board/${boardId}/lobby`,
  lobbyIdlePage: (boardId = ":id") => `/lobby/${boardId}/idle`,
  lobbySelectPage: (lobbyId = ":id") => `/lobby/${lobbyId}/select`,

  // Orgnaization
  newOrganization: "/organization/new",
  organizationHome: (orgSlug = ":slug") => `/organization/${orgSlug}`,
  organizationBoards: (orgSlug = ":slug") => `/organization/${orgSlug}/boards`,
  organizationCalendar: (orgSlug = ":slug") =>
    `/organization/${orgSlug}/calendar`,
  organizationBoard: (orgSlug = ":slug", boardId = ":id") =>
    `/organization/${orgSlug}/board/${boardId}`,
  calibrateBoard: (orgSlug = ":slug", boardId = ":id") =>
    `/organization/${orgSlug}/board/${boardId}/calibrate`,
};
