import {
  CricketDartHit,
  CricketGameConfig,
  CricketGameSnapshot,
  CricketRound,
  CricketScoringRule,
  CricketWinCondition,
  HitCount,
} from "@pilplay/graphql";
import { Immutable, produce } from "immer";

import { DartPoint, scoreToPointsWithMultiplier } from "../dart";
import Game, { GameAnimationEvent } from "../game/Game";
import {
  GameEventEdit,
  GameEventEmptyBoard,
  GameEventManualNext,
  GameEventStart,
  GameEventThrow,
  GameEventUndo,
} from "../game/types";

const THROWS_PER_ROUND = 3;

// Placeholder for the default state of the game
const DefaultCricketState: CricketGameSnapshot = {
  finished: false,
  activePlayerId: "",
  players: [],
  roundThrows: [],
  activeRound: 1,
};

export type AppliedScore = {
  roundNumber: number;
  hitIndex: number;
  score: number;
  block: boolean;
  from: string;
};

export type ScoreMatrix = Record<
  string,
  {
    hits: HitCount[];
    totalScore: number;
    scores: AppliedScore[];
    position: number;
  }
>;

export class CricketGame extends Game<CricketGameSnapshot> {
  public config: CricketGameConfig;
  protected snapshot: Immutable<CricketGameSnapshot> = DefaultCricketState;

  constructor(
    gameId: string,
    config: CricketGameConfig,
    players: { id: string }[]
  ) {
    super(gameId);
    this.config = config;

    // TODO shuffling players similar to X01Game

    // Initialize the game state with provided players
    this.snapshot = produce(this.snapshot, (draft) => {
      draft.players = players.map((player) => ({
        ...player,
        score: 0, // Score initialization based on game rules
        hits: this.getHitNumbers(
          this.config.startNumber,
          this.config.includeBull
        ),
      }));
    });
  }

  static fromSnapshot(
    gameId: string,
    config: CricketGameConfig,
    players: { id: string }[],
    snapshot: CricketGameSnapshot
  ) {
    const game = new CricketGame(gameId, config, players);
    game.snapshot = snapshot;
    return game;
  }

  public getHitNumbers(startNumber: number, includeBull: boolean): HitCount[] {
    const hits: HitCount[] = [];
    for (let number = startNumber; number <= 20; number++) {
      hits.push({ number: number, count: 0 });
    }
    if (includeBull) {
      hits.push({ number: 25, count: 0 }); // Bullseye
    }
    return hits;
  }

  onStartEvent(_event: GameEventStart) {
    if (this.snapshot.players.length < 1) {
      throw new Error("Not enough players");
    }
    this.snapshot = produce(this.snapshot, (draft) => {
      draft.activePlayerId = this.snapshot.players[0].id;
    });
  }

  onThrowEvent(event: GameEventThrow) {
    this.snapshot = produce(this.snapshot, (draft) => {
      const activePlayer = this.getActivePlayer();
      const roundNumber = draft.activeRound;

      let playerRound = this.getPlayerRound(activePlayer.id, roundNumber);
      if (!playerRound) {
        // Create a new round if it doesn't exist
        const newRound: CricketRound = {
          playerId: activePlayer.id,
          roundNumber,
          hits: [],
        };
        draft.roundThrows.push(newRound);
        playerRound = newRound;
      }

      if (playerRound.hits.length >= THROWS_PER_ROUND) {
        throw new Error(`The round already has ${THROWS_PER_ROUND} throws`);
      }

      // We need to write directly to the draft object
      draft.roundThrows[draft.roundThrows.length - 1].hits.push({
        id: event.id,
        point: event.payload.point,
        position: event.payload.position,
      });

      // Call the function to recalculate all players' hit matrices
    });
    this.recalculatePlayerScores();
  }

  private recalculatePlayerScores() {
    const { scoreMatrix, finished } = this.getScoringMatrix();

    this.snapshot = produce(this.snapshot, (draft) => {
      draft.players.forEach((player) => {
        const playerMatrix = scoreMatrix[player.id];
        if (playerMatrix) {
          player.hits = playerMatrix.hits;
          player.score = playerMatrix.totalScore;
        }
      });

      if (finished) {
        // Emit a finish event
        const winnerId = Object.keys(scoreMatrix).find(
          (playerId) => scoreMatrix[playerId].position === 1
        );
        this.emitter.emit(GameAnimationEvent.Winner, winnerId);
      }
      draft.finished = finished;
    });
  }

  public getScoringMatrix() {
    const scoreMatrix = this.initializeTempMatrix();
    const throws = this.flattenThrows();
    let finished = false;
    // Loop through all throws
    throws.forEach(({ playerId, dartPoint, hitIndex, roundNumber }) => {
      if (finished) {
        return;
      }
      const { points, multiplier } = scoreToPointsWithMultiplier(dartPoint);
      // Get the index of the hit in the hits array
      const numberIndex = scoreMatrix[playerId].hits.findIndex(
        (h) => h.number === points
      );
      if (numberIndex === -1) {
        // The hit is not in the hits array skip
        return;
      }
      const playerMatrix = scoreMatrix[playerId];
      // We need to loop over the multiplier since if we close we should change score
      Array.from({ length: multiplier }).forEach(() => {
        const hitCount = playerMatrix.hits[numberIndex].count;
        const isClosed = this.isClosed(hitCount);

        if (!isClosed) {
          // Increment the hit by 1
          playerMatrix.hits[numberIndex].count++;
        } else {
          if (this.config.scoringRule === CricketScoringRule.Standard) {
            playerMatrix.totalScore += points;
            this.addScoreToScores(
              playerMatrix.scores,
              roundNumber,
              hitIndex,
              points,
              false,
              playerId
            );
          }
          if (this.config.scoringRule === CricketScoringRule.Cutthroat) {
            Object.values(scoreMatrix).forEach((matrix) => {
              const hitCount = matrix.hits[numberIndex].count;
              const block = this.isClosed(hitCount);
              if (!block) {
                matrix.totalScore += points;
              }
              // We want to be able to tell if the score was blocked
              this.addScoreToScores(
                matrix.scores,
                roundNumber,
                hitIndex,
                block ? 0 : points,
                block,
                playerId
              );
            });
          }
        }
      });

      // Update the position of each player and check win condition after each throw
      this.updatePlayerPositions(scoreMatrix);
      finished = this.checkWinCondition(scoreMatrix);
    });
    return { scoreMatrix: scoreMatrix, finished };
  }

  private addScoreToScores(
    scores: AppliedScore[],
    roundNumber: number,
    hitIndex: number,
    score: number,
    block: boolean,
    from: string
  ) {
    const scoreIndex = scores.findIndex(
      (s) =>
        s.roundNumber === roundNumber &&
        s.hitIndex === hitIndex &&
        s.from === from
    );
    if (scoreIndex === -1) {
      scores.push({
        roundNumber,
        hitIndex,
        score,
        block,
        from,
      });
    } else {
      scores[scoreIndex].score += score;
    }
  }

  private isClosed(hitCount: number): boolean {
    return hitCount >= this.config.hitsToOpenClose;
  }

  private initializeTempMatrix() {
    return this.snapshot.players.reduce((acc, player) => {
      acc[player.id] = {
        hits: this.getHitNumbers(
          this.config.startNumber,
          this.config.includeBull
        ),
        totalScore: 0,
        position: 1,
        scores: [],
      };
      return acc;
    }, {} as ScoreMatrix);
  }

  private flattenThrows() {
    return [...this.snapshot.roundThrows].reduce(
      (acc, round) => {
        return [
          ...acc,
          ...[...round.hits].map((hit, hitIndex) => ({
            dartPoint: hit.point,
            playerId: round.playerId,
            hitIndex: hitIndex,
            roundNumber: round.roundNumber,
          })),
        ];
      },
      [] as {
        dartPoint: DartPoint;
        playerId: string;
        roundNumber: number;
        hitIndex: number;
      }[]
    );
  }

  private updatePlayerPositions(scoreMatrix: ScoreMatrix) {
    // Sort the players based on the scoring rule
    const players = Object.keys(scoreMatrix).map((playerId) => ({
      id: playerId,
      score: scoreMatrix[playerId].totalScore,
    }));
    if (this.config.scoringRule === CricketScoringRule.Standard) {
      // Sort by score descending
      players.sort((a, b) => b.score - a.score);
    }
    if (this.config.scoringRule === CricketScoringRule.Cutthroat) {
      // Sort by score ascending
      players.sort((a, b) => a.score - b.score);
    }
    // Set position for each player in the scoreMatrix
    players.forEach((player, index) => {
      scoreMatrix[player.id].position = index + 1;
    });
  }

  private checkWinCondition(scoreMatrix: ScoreMatrix): boolean {
    const winCondition = this.config.winCondition;

    const completedPlayers = Object.values(scoreMatrix).filter((matrix) =>
      matrix.hits.every((hit) => hit.count >= this.config.hitsToOpenClose)
    );
    if (completedPlayers.length === 0) {
      return false;
    }
    // If any player has all hits closed, the game is finished
    if (winCondition === CricketWinCondition.Allclosed) {
      return true;
    }
    // The player in position 1 has all hits closed, the game is finished
    if (winCondition === CricketWinCondition.Allclosedhighestscore) {
      const playerInPosition1 = completedPlayers.find(
        (matrix) => matrix.position === 1
      );
      if (playerInPosition1) {
        return true;
      }
    }
    return false;
  }

  onManualNextEvent(event: GameEventManualNext) {
    this.onNext(event.id);
  }

  onEmptyBoardEvent(event: GameEventEmptyBoard) {
    this.onNext(event.id);
  }

  private onNext(eventId: string) {
    this.snapshot = produce(this.snapshot, (draft) => {
      const activePlayerId = this.getActivePlayer().id;
      const roundIndex = draft.roundThrows.findIndex(
        (r) =>
          r.playerId === activePlayerId && r.roundNumber === draft.activeRound
      );

      if (roundIndex === -1) {
        // If no round exists for the active player in this round, create one
        const newRound = {
          playerId: activePlayerId,
          roundNumber: draft.activeRound,
          hits: [this.createEmptyThrow(eventId)],
        };
        draft.roundThrows.push(newRound);
      } else {
        // Add empty throws to the existing round until there are 3 throws
        while (draft.roundThrows[roundIndex].hits.length < 3) {
          draft.roundThrows[roundIndex].hits.push(
            this.createEmptyThrow(eventId)
          );
        }
      }

      // Determine if it's the last player's turn
      const isLastPlayer =
        draft.players[draft.players.length - 1].id === activePlayerId;
      const isLastRound = draft.activeRound === this.config.numRounds;

      if (isLastPlayer) {
        if (isLastRound) {
          // Last player and last round, finish the game
          const { scoreMatrix } = this.getScoringMatrix();
          const winnerId = Object.keys(scoreMatrix).find(
            (playerId) => scoreMatrix[playerId].position === 1
          );
          this.emitter.emit(GameAnimationEvent.Winner, winnerId);
          draft.finished = true;
          return;
        } else {
          // Last player but not the last round, increment round number
          draft.activeRound += 1;
        }
      }
      // Change tp the next player
      const nextPlayerId = this.getNextPlayer(activePlayerId).id;
      this.emitter.emit(GameAnimationEvent.NextPlayer, nextPlayerId);
      draft.activePlayerId = nextPlayerId;
      // Add a empty round for the next player
      draft.roundThrows.push({
        playerId: nextPlayerId,
        roundNumber: draft.activeRound,
        hits: [],
      });
    });
  }

  private createEmptyThrow(id: string): CricketDartHit {
    return {
      id,
      point: DartPoint.MISS,
    };
  }

  private getNextPlayer(currentPlayerId: string) {
    const currentPlayerIndex = this.snapshot.players.findIndex(
      (p) => p.id === currentPlayerId
    );
    const nextPlayerIndex =
      (currentPlayerIndex + 1) % this.snapshot.players.length;
    return this.snapshot.players[nextPlayerIndex];
  }

  onUndoEvent(_event: GameEventUndo) {
    const activePlayerId = this.getActivePlayer().id;
    const roundIndex = this.snapshot.roundThrows.findIndex(
      (r) =>
        r.playerId === activePlayerId &&
        r.roundNumber === this.snapshot.activeRound
    );

    // If no active round for player, just return
    if (roundIndex === -1) {
      // Change back to the previous player
      this.snapshot = produce(this.snapshot, (draft) => {
        this.emitter.emit(
          GameAnimationEvent.PreviousPlayer,
          draft.roundThrows[draft.roundThrows.length - 1].playerId
        );
        draft.activePlayerId =
          draft.roundThrows[draft.roundThrows.length - 1].playerId;
      });
      return;
    }

    // If the round has hits, remove the last hit
    if (this.snapshot.roundThrows[roundIndex].hits.length > 0) {
      this.snapshot = produce(this.snapshot, (draft) => {
        draft.roundThrows[roundIndex].hits.pop();
      });
      // We need to reclaculate the score
      this.recalculatePlayerScores();
      return;
    }

    this.snapshot = produce(this.snapshot, (draft) => {
      // If only one round, we cant undo
      if (draft.roundThrows.length === 1) {
        // Cant undo first round
        return;
      }

      // No hits in the round, remove the round
      draft.roundThrows.pop();

      // Decrement round number if necessary
      if (!draft.roundThrows.some((r) => r.roundNumber === draft.activeRound)) {
        draft.activeRound--;
      }

      // Change active player to the player in the last round
      const lastRound = draft.roundThrows[draft.roundThrows.length - 1];
      this.emitter.emit(GameAnimationEvent.PreviousPlayer, lastRound.playerId);
      draft.activePlayerId = lastRound.playerId;
    });
  }

  onEditEvent(event: GameEventEdit) {
    this.snapshot = produce(this.snapshot, (draft) => {
      // Find the hit based on the event id and change the point & position
      const hit = draft.roundThrows
        .flatMap((r) => r.hits)
        .find((h) => h.id === event.payload.eventId);
      if (!hit) {
        throw new Error("Hit not found");
      }
      hit.point = event.payload.point;
      hit.position = event.payload.position;
    });

    // Recalculate the score
    this.recalculatePlayerScores();
  }

  /**
   *  Returns the active player
   * @returns The active player
   */
  public getActivePlayer() {
    const player = this.snapshot.players.find(
      (p) => p.id === this.snapshot.activePlayerId
    );
    if (!player) {
      throw new Error("Active player not found");
    }
    return player;
  }

  public getPlayerRound(playerId: string, roundNumber: number) {
    const round = this.snapshot.roundThrows.find(
      (r) => r.playerId === playerId && r.roundNumber === roundNumber
    );
    return round;
  }

  public getActivePlayerRound() {
    const activePlayer = this.getActivePlayer();
    const round = this.getPlayerRound(
      activePlayer.id,
      this.snapshot.activeRound
    );
    return round;
  }

  public getPlayerRoundIndex(playerId: string, roundNumber: number) {
    const roundIndex = this.snapshot.roundThrows.findIndex(
      (r) => r.playerId === playerId && r.roundNumber === roundNumber
    );
    return roundIndex;
  }
}

// Additional utility functions like calculateScoreMatrix, getHitIndex, applyScoringRule, etc.

export default CricketGame;
