import { Flex, Stack, Text } from "@mantine/core";
import { IconFileCheck, IconWifi } from "@tabler/icons-react";
import { BigButton } from "@pilplay/ui";
import {
  PILPLAY_BOARD_ID_UUID,
  PILPLAY_NETWORK_STATUS_UUID,
} from "../../../../../../hooks/useBluetooth/useBluetooth";
import useBluetoothCharacteristic, {
  parseBoardInfo,
  parseNetworkStatus,
} from "../../../../../../hooks/useBluetooth/useBluetoothCharacteristic";
import ErrorAlert from "../../../../../../components/ErrorAlert";
import { useBoards } from "../../../../../../hooks/useBoards/useBoards";
import useBoardMutations from "../../../../../../hooks/useBoardMutations/useBoardMutations";

export interface BluetoothMenuScreenProps {
  device: BluetoothDevice;
  onWifiConfigure: () => void;
}

export const BluetoothMenuScreen: React.FC<BluetoothMenuScreenProps> = ({
  device,
  onWifiConfigure,
}) => {
  const { val: boardInfo, loading: loadingBoardInfo } =
    useBluetoothCharacteristic(device, PILPLAY_BOARD_ID_UUID, parseBoardInfo);

  const { val: networkStatus, loading: loadingNetworkStatus } =
    useBluetoothCharacteristic(
      device,
      PILPLAY_NETWORK_STATUS_UUID,
      parseNetworkStatus
    );

  const [{ data, fetching, error }, refetch] = useBoards();

  const isRegistredToMe = data?.boards.some((b) => b.id === boardInfo?.boardId);

  const {
    connectBoard,
    fetching: fetchingBoardConnect,
    error: boardConnectError,
  } = useBoardMutations();

  const onRegisterBoard = async () => {
    if (!boardInfo?.registrationCode) {
      return;
    }
    await connectBoard({
      code: boardInfo.registrationCode,
    });
    refetch();
  };

  const networkConnected =
    networkStatus?.wiFiConnected || networkStatus?.ethernetConnected;
  const networkSSID = networkStatus?.wiFiSsid || networkStatus?.ethernetSsid;

  return (
    <Flex pos="relative" h="100%" direction="column">
      <Stack>
        <Text fw="bold" ta="left" fz="xl" mt="md">
          Select option
        </Text>
        <ErrorAlert error={error || boardConnectError} />
        <BigButton
          bg="teal.8"
          title="Configure Network"
          icon={<IconWifi size={32} />}
          loading={loadingNetworkStatus}
          disabled={loadingNetworkStatus}
          onClick={onWifiConfigure}
          description="Configure your network settings"
          subtext={
            networkConnected ? (
              <>
                Connected to{" "}
                <Text component="span" fz="bold">
                  {networkSSID}
                </Text>
              </>
            ) : (
              "Not connected to any network"
            )
          }
        />
        <BigButton
          title="Register board"
          icon={<IconFileCheck size={32} />}
          bg="orange.8"
          loading={loadingBoardInfo || fetching}
          disabled={isRegistredToMe || fetchingBoardConnect}
          onClick={onRegisterBoard}
          description={
            isRegistredToMe
              ? "Board is already registerd"
              : "Board is not registerd yet to your account"
          }
          subtext="Register your board to your account"
        />
      </Stack>
    </Flex>
  );
};
