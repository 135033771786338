import { Affix, Flex, Stack, Text, Title } from "@mantine/core";
import { PlayerAvatar } from "@pilplay/ui";
import React from "react";
import { useX01Game } from "../../context/useX01Game";

const BullOffActivePlayer: React.FC = () => {
  const { activePlayer, middlingPhase, middlingCompleted } = useX01Game();
  if (!middlingPhase || middlingCompleted) {
    return null;
  }
  return (
    <Affix
      position={{
        top: 80,
        left: 80,
      }}
      style={{
        position: "absolute",
        zIndex: 1,
      }}
      withinPortal={false}
    >
      <Stack gap={4}>
        <Title>Bull off</Title>
        <Text c="dimmed">
          Closest player to{" "}
          <Text c="orange" component="span" fw="bold">
            Bull
          </Text>{" "}
          start
        </Text>
        <Text>Next player:</Text>
        <Flex align="center" mt="xl">
          <PlayerAvatar size={64} url={activePlayer?.avatarUrl} />
          <Text fw="bold" fz="4cqi" ml="md">
            {activePlayer?.name}
          </Text>
        </Flex>
      </Stack>
    </Affix>
  );
};
export default BullOffActivePlayer;
