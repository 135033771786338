import { Box, Grid, SimpleGrid, Stack, Text } from "@mantine/core";
import type { DartHit, DartPoint} from "@pilplay/games";
import { scoreToPoints } from "@pilplay/games";
import type { Maybe } from "@pilplay/graphql";
import cx from "clsx";
import React, { useMemo } from "react";
import { useIsTabletStanding } from "../../../../../../../hooks/layout/useDevice";
import classes from "./X01DartRound.module.css";

interface X01DartRoundProps {
  className?: string;
  style?: React.CSSProperties;
  round?: {
    hit1?: Maybe<DartHit & { bust: boolean }>;
    hit2?: Maybe<DartHit & { bust: boolean }>;
    hit3?: Maybe<DartHit & { bust: boolean }>;
  } | null;
  onHitClick: (hit?: DartHit | null) => void;
  disabled?: boolean;
}

export const ActiveDart: React.FC<{
  num?: number;
  bust?: boolean;
  point?: DartPoint;
  disabled?: boolean;
  onClick?: () => void;
  label?: string;
}> = ({ num, point, onClick, bust = false, disabled = false, label }) => {
  return (
    <Stack>
      <Box
        className={cx(classes.activeDart, {
          [classes.editable]: Boolean(point) && !disabled,
          [classes.bust]: bust,
        })}
        onClick={!disabled ? onClick : undefined}
      >
        <Text
          fw="bold"
          size="xl"
          ta="center"
          td={bust ? "line-trough" : "inherit"}
        >
          {point || "-"}
        </Text>
      </Box>
      <Text c="dimmed" className={classes.dartLabel} size="sm" ta="center">
        {label ? label : bust ? "Bust" : `Dart ${num}`}
      </Text>
    </Stack>
  );
};

const X01DartRound: React.FC<X01DartRoundProps> = ({
  className,
  style,
  round,
  onHitClick,
  disabled,
}) => {
  const sum = useMemo(() => {
    const points = [
      round?.hit1?.point,
      round?.hit2?.point,
      round?.hit3?.point,
    ].filter((p) => p !== null && p !== undefined) as DartPoint[];
    return points.reduce((sum, p) => {
      return sum + scoreToPoints(p) || 0;
    }, 0);
  }, [round]);

  const isTabletStanding = useIsTabletStanding();
  const span = isTabletStanding ? 4 : 3;
  return (
    <Grid className={className} grow mt="xl" style={style}>
      <Grid.Col span={span}>
        <ActiveDart
          bust={round?.hit1?.bust}
          disabled={disabled}
          num={1}
          onClick={() => { onHitClick(round?.hit1); }}
          point={round?.hit1?.point}
        />
      </Grid.Col>
      <Grid.Col span={span}>
        <ActiveDart
          bust={round?.hit2?.bust}
          disabled={disabled}
          num={2}
          onClick={() => { onHitClick(round?.hit2); }}
          point={round?.hit2?.point}
        />
      </Grid.Col>
      <Grid.Col span={span}>
        <ActiveDart
          bust={round?.hit3?.bust}
          disabled={disabled}
          num={3}
          onClick={() => { onHitClick(round?.hit3); }}
          point={round?.hit3?.point}
        />
      </Grid.Col>
      <Grid.Col span={span}>
        <Stack>
          <Box className={classes.sumBox}>
            <Text fw="bold" size="xl" ta="center">
              {sum}
            </Text>
          </Box>
        </Stack>
      </Grid.Col>
    </Grid>
  );
};

export default X01DartRound;
