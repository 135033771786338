import { Stack } from "@mantine/core";
import React from "react";
import { useX01Game } from "../../../../context/useX01Game";
import X01PlayerScore from "../X01PlayerScore";
import {} from "./player-stack.module.css";

const PlayerStack: React.FC<{
  height?: number;
  width?: number;
}> = ({ height, width }) => {
  const { players } = useX01Game();

  const playerHeight = height ? height / players.length : 200;
  return (
    <Stack
      style={{
        justifyContent: "center",
        height: "100%",
      }}
    >
      {players.map((player) => {
        return (
          <X01PlayerScore
            key={player.id}
            player={player}
            height={playerHeight}
            width={width}
          />
        );
      })}
    </Stack>
  );
};

export default PlayerStack;
