import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { cssVariablesResolver, theme } from "@pilplay/ui";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Notifications } from "@mantine/notifications";
import Router from "../routes/Router";
import AuthContextProvider from "../contexts/AuthContext/provider";
import "@mantine/core/styles.layer.css";
import "@mantine/charts/styles.css";
import "@mantine/notifications/styles.css";
import GraphQLProvider from "../contexts/GraphQLProvider/GraphQlProvider";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  return (
    <MantineProvider
      cssVariablesResolver={cssVariablesResolver}
      defaultColorScheme="dark"
      theme={theme}
    >
      <Notifications />
      <AuthContextProvider>
        <QueryClientProvider client={queryClient}>
          <GraphQLProvider>
            <ModalsProvider>
              <Router />
            </ModalsProvider>
          </GraphQLProvider>
        </QueryClientProvider>
      </AuthContextProvider>
    </MantineProvider>
  );
}

export default App;
