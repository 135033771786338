import React, { useEffect, useMemo, useRef, useState } from "react";
import cx from "clsx";
import { useElementSize } from "@mantine/hooks";
import { LoadingOverlay } from "@mantine/core";
import classes from "./ImageMagnifier.module.css";

export interface MagnifiedPosition {
  x: number;
  y: number;
}

export interface ImageMagnifierProps {
  zoom: number;
  src: string;
  magnifierSize?: number;
  crossHair?: boolean;
  showMagnifier?: boolean;
  alwaysSendDragEvents?: boolean;
  showLoadingOverlay?: boolean;
  onImageResize?: (data: {
    width: number;
    height: number;
    naturalWidth: number;
    naturalHeight: number;
  }) => void;
  onClick?: (
    e: React.TouchEvent | React.MouseEvent,
    pos: MagnifiedPosition
  ) => void;
  onDrag?: (
    e: React.TouchEvent | React.MouseEvent,
    pos: MagnifiedPosition
  ) => void;
  onDragStart?: (
    e: React.TouchEvent | React.MouseEvent,
    pos: MagnifiedPosition
  ) => void;
  onDragEnd?: (
    e: React.TouchEvent | React.MouseEvent,
    pos: MagnifiedPosition
  ) => void;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const isTouchEvent = (
  e: React.MouseEvent | React.TouchEvent
): e is React.TouchEvent => {
  return "touches" in e;
};

const isMouseEvent = (
  e: React.MouseEvent | React.TouchEvent
): e is React.MouseEvent => {
  return !("touches" in e);
};

const BW = 3;

// eslint-disable-next-line @typescript-eslint/no-empty-function -- noop
const noop = (): void => {};

export const ImageMagnifier: React.FC<ImageMagnifierProps> = ({
  src,
  zoom,
  magnifierSize = 100,
  onClick = noop,
  onDrag = noop,
  onDragEnd = noop,
  onDragStart = noop,
  onImageResize = noop,
  showMagnifier = true,
  crossHair = true,
  alwaysSendDragEvents = false,
  showLoadingOverlay = true,
  children,
  className,
  style,
}) => {
  const { width, height, ref: imgRef } = useElementSize<HTMLImageElement>();
  const [show, setShow] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(showLoadingOverlay);
  const glassRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setIsLoading(false);
      onImageResize({
        width,
        height,
        naturalWidth: imgRef.current.naturalWidth || 0,
        naturalHeight: imgRef.current.naturalHeight || 0,
      });
    } else {
      setIsLoading(true);
    }
  }, [src]);

  const moveMagnifier = (e: React.MouseEvent | React.TouchEvent): void => {
    if (imgRef.current && glassRef.current) {
      const w = glassRef.current.offsetWidth / 2;
      const h = glassRef.current.offsetHeight / 2;
      const pos = getCursorPos(e);
      let x = pos.x;
      let y = pos.y;
      if (x > imgRef.current.width - w / zoom) {
        x = imgRef.current.width - w / zoom;
      }
      if (x < w / zoom) {
        x = w / zoom;
      }
      if (y > imgRef.current.height - h / zoom) {
        y = imgRef.current.height - h / zoom;
      }
      if (y < h / zoom) {
        y = h / zoom;
      }
      y = Math.max(y, glassRef.current.offsetHeight / zoom);

      glassRef.current.style.left = `${x - w}px`;
      glassRef.current.style.top = `${y - h}px`;
      glassRef.current.style.backgroundPosition = `-${x * zoom - w + BW}px -${
        y * zoom - h + BW
      }px`;
    }
  };

  const getCursorPos = (
    e: React.MouseEvent | React.TouchEvent
  ): { x: number; y: number } => {
    if (imgRef.current) {
      const a = imgRef.current.getBoundingClientRect();
      if (isTouchEvent(e)) {
        const x = e.touches[0].pageX - a.left - window.scrollX;
        const y = e.touches[0].pageY - a.top - window.scrollY;
        return { x, y };
      } else if (isMouseEvent(e)) {
        let x = e.pageX - a.left;
        let y = e.pageY - a.top;
        /* Consider any page scrolling: */
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;

        return { x, y };
      }
    }
    return { x: 0, y: 0 };
  };

  const backgroundSize = useMemo(() => {
    return `${width * zoom}px ${height * zoom}px`;
  }, [width, height]);

  useEffect(() => {
    onImageResize({
      width,
      height,
      naturalWidth: imgRef.current?.naturalWidth || 0,
      naturalHeight: imgRef.current?.naturalHeight || 0,
    });
  }, [width, height]);

  const magnifierStyle = useMemo(() => {
    return {
      backgroundSize,
      backgroundImage: `url(${src})`, // Replace with your image source
      width: magnifierSize,
      height: magnifierSize,
      display: show && showMagnifier ? "block" : "none",
    };
  }, [src, backgroundSize, show, showMagnifier]);

  const _onClick = (e: React.MouseEvent | React.TouchEvent): void => {
    const pos = getCursorPos(e);
    onClick(e, pos);
  };

  const onHover = (): void => {
    setShow(true);
  };

  const onLeave = (): void => {
    setShow(false);
  };

  const _onDrag = (e: React.MouseEvent | React.TouchEvent): void => {
    if (isDragging || alwaysSendDragEvents) {
      if (alwaysSendDragEvents) {
        setShow(true);
      }
      const pos = getCursorPos(e);
      onDrag(e, pos);
    }
  };

  const _onDragStart = (e: React.MouseEvent | React.TouchEvent): void => {
    setShow(true);
    setIsDragging(true);
    const pos = getCursorPos(e);
    onDragStart(e, pos);
  };

  const _onDragEnd = (e: React.MouseEvent | React.TouchEvent): void => {
    setShow(false);
    setIsDragging(false);
    const pos = getCursorPos(e);
    onDragEnd(e, pos);
  };

  // Prevents scrolling on mobile when touching the dartboard
  useEffect(() => {
    const preventDefault = (e: HTMLElementEventMap["touchmove"]): void => {
      e.preventDefault();
    };
    if (imgRef.current) {
      imgRef.current.addEventListener("touchmove", preventDefault, {
        passive: false,
      });
    }
    if (glassRef.current) {
      glassRef.current.addEventListener("touchmove", preventDefault, {
        passive: false,
      });
    }
    return () => {
      if (imgRef.current) {
        imgRef.current.removeEventListener("touchmove", preventDefault);
      }
      if (glassRef.current) {
        glassRef.current.removeEventListener("touchmove", preventDefault);
      }
    };
  }, []);

  return (
    <div style={style} className={cx(classes.root, className)}>
      <LoadingOverlay visible={isLoading} />
      <img
        ref={imgRef}
        src={src}
        alt="Magnify"
        onLoad={() => {
          setIsLoading(false);
          onImageResize({
            width,
            height,
            naturalWidth: imgRef.current?.naturalWidth || 0,
            naturalHeight: imgRef.current?.naturalHeight || 0,
          });
        }}
        className={classes.image}
        onClick={_onClick}
        onMouseMove={(e) => {
          moveMagnifier(e);
          _onDrag(e);
        }}
        onTouchMove={(e) => {
          moveMagnifier(e);
          _onDrag(e);
        }}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        onTouchStart={_onDragStart}
        onTouchEnd={_onDragEnd}
        onMouseDown={_onDragStart}
        onMouseUp={_onDragEnd}
      />
      {children}
      <div
        ref={glassRef}
        className={cx(classes.magnifier)}
        style={magnifierStyle}
        onClick={_onClick}
        onMouseMove={(e) => {
          moveMagnifier(e);
          _onDrag(e);
        }}
        onTouchMove={(e) => {
          moveMagnifier(e);
          _onDrag(e);
        }}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        onTouchStart={_onDragStart}
        onTouchEnd={_onDragEnd}
        onMouseDown={_onDragStart}
        onMouseUp={_onDragEnd}
      >
        {crossHair && (
          <div
            style={{
              width: magnifierSize,
              height: magnifierSize,
            }}
            className={classes.crosshair}
          />
        )}
      </div>
    </div>
  );
};
