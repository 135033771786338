import { useGameQuery } from "@pilplay/graphql";
// import { useAuthContext } from "../../contexts/AuthContext";

export function useGame(id?: string) {
  // const { isSignedIn } = useAuthContext();
  return useGameQuery({
    requestPolicy: "cache-and-network",
    variables: {
      id: id || "",
    },
    pause: !id, // !isSignedIn, TODO renable after spotify demo and new auth flow
  });
}
