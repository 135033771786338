import { Box, Divider, Flex, Group, Text } from "@mantine/core";
import { useMemo } from "react";
import AnimatedNumber from "../../../../../../../../../components/AnimatedNumber/AnimatedNumber";
import PlacementIcon from "../../../../../PlacementIcon";
import { useX01Game } from "../../../../context/useX01Game";

interface PlayerProps {
  player: { id: string; score: number; name: string; placement?: number };
  active: boolean;
  finished: boolean;
  legFinished: boolean;
  placementIndex: number;
}

const Player: React.FC<PlayerProps> = ({
  player,
  active,
  finished,
  legFinished,
  placementIndex,
}) => {
  return (
    <Flex justify="space-between" key={player.id}>
      <Group wrap="nowrap">
        {finished || legFinished ? (
          <PlacementIcon
            legWinnerOnly={!finished}
            placementIndex={placementIndex}
            size={19}
          />
        ) : null}
        <Text
          component="div"
          style={{}}
          fw={active ? "bold" : "normal"}
          lineClamp={1}
          size="md"
        >
          <Text
            c={active ? "orange" : "dimmed"}
            component="span"
            truncate="end"
          >
            &#x2022;{" "}
          </Text>
          {player.name}
        </Text>
      </Group>

      <Text component="div" fw="bold" size="md">
        <AnimatedNumber value={player.score} />
      </Text>
    </Flex>
  );
};

interface MobilePlayerListProps {
  playerLimit?: number;
}

const MobilePlayerList: React.FC<MobilePlayerListProps> = ({ playerLimit }) => {
  const { players, activePlayer, scoreOrder, legFinished, finished } =
    useX01Game();

  // TODO can overflow on mobile or longer names
  const { orderedPlayers, truncatedPlayers } = useMemo(() => {
    if (!playerLimit || players.length <= playerLimit) {
      return { orderedPlayers: players, truncatedPlayers: [] };
    }
    const playersCopy = players.slice();
    const activePlayerIndex = playersCopy.findIndex(
      (player) => player.id === activePlayer?.id
    );

    const playersAfterActive = playersCopy.splice(activePlayerIndex);
    const newPlayerOrder = playersAfterActive.concat(playersCopy);
    return {
      orderedPlayers: newPlayerOrder.slice(0, playerLimit - 1),
      truncatedPlayers: newPlayerOrder.slice(playerLimit - 1),
    };
  }, [activePlayer?.id, playerLimit, players]);
  return (
    <Box p={"sm"} h={"100%"}>
      <Flex
        direction="column"
        justify="space-between"
        style={{ height: "100%" }}
      >
        {orderedPlayers.map((player) => {
          const active = activePlayer?.id === player.id;
          return (
            <Player
              key={player.id}
              player={player}
              active={active}
              placementIndex={scoreOrder.findIndex((p) => p.id === player.id)}
              finished={finished}
              legFinished={legFinished}
            />
          );
        })}
        <Group wrap="nowrap" grow>
          {truncatedPlayers.map((player) => {
            const active = activePlayer?.id === player.id;
            return (
              <Player
                key={player.id}
                player={player}
                active={active}
                placementIndex={scoreOrder.findIndex((p) => p.id === player.id)}
                finished={finished}
                legFinished={legFinished}
              />
            );
          })}
        </Group>
      </Flex>
      <Divider
        size="xl"
        style={{
          borderRadius: "5px",
        }}
      />
    </Box>
  );
};

export default MobilePlayerList;
