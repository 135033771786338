import React from "react";
import {
  Container,
  Grid,
  Text,
  Paper,
  Divider,
  Flex,
  Tabs,
} from "@mantine/core";
import { Button, SectionHeader } from "@pilplay/ui";
import { useAuthContext } from "../../../contexts/AuthContext";
import ProfileInformation from "./components/ProfileInformation/ProfileInformation";
import AccountInformation from "./components/AccountInformation/AccountInformation";

const ProfilePage: React.FC = () => {
  const { logout } = useAuthContext();
  return (
    <Container size="xl">
      <SectionHeader
        mt="xl"
        breadcrumbs={[
          { label: "Home", link: "/" },
          { label: "Account", link: "/profile" },
          { label: "General", active: true },
        ]}
        title="Account"
        subTitle="Manage your personal information and account settings"
      />
      <Tabs radius={0} py="xl" defaultValue="/profile">
        <Tabs.List fw="600">
          <Tabs.Tab fz="md" p="md" value="/profile">
            General
          </Tabs.Tab>
        </Tabs.List>
      </Tabs>
      <Paper bg="dark.9" pt="lg" radius="lg" px="xl" pb="xl">
        <ProfileInformation />
        <Divider mt="xl" />
        <AccountInformation />
        <Divider mt="xl" />

        <Grid mt="xl">
          <Grid.Col span={{ base: 12, xs: 5 }}>
            <Text c="red" fw="bold">
              Danger Zone
            </Text>
            <Text c="dimmed" size="xs">
              Handle with care, this section will allow you to delete your
              account and all your data.
            </Text>
          </Grid.Col>
          <Grid.Col span={{ base: 12, xs: 7 }}>
            <Flex justify="flex-end" mt="md">
              <Button
                color="red"
                size="sm"
                onClick={() => {
                  logout();
                }}
                variant="filled"
              >
                Sign out
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ProfilePage;
