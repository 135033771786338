import React from "react";
import { useTrail, a } from "@react-spring/web";
import { useX01Game } from "../../../../context/useX01Game";
import { ScoreboardPlayer } from "./ScoreboardPlayer";
import classes from "./ScoreboardList.module.css";

const SCORE_BOARD_WIDTH: Record<number, string> = {
  0: "100%",
  1: "90%",
  2: "80%",
  3: "70%",
  4: "70%",
  5: "70%",
  6: "70%",
};

export interface ScoreboardListProps {
  selectedPlayerIndex: number;
  onClick?: (index: number) => void;
}

export const ScoreboardList: React.FC<ScoreboardListProps> = ({
  selectedPlayerIndex,
  onClick,
}) => {
  const { players } = useX01Game();

  const sortedPlayers = players.sort((i, b) => i.score - b.score);

  const playerComponents = sortedPlayers.map((player, index) => {
    const selected = player.id === players[selectedPlayerIndex].id;
    const originalIndex = players.indexOf(player);
    return (
      <ScoreboardPlayer
        player={player}
        key={player.id}
        onClick={() => onClick?.(originalIndex)}
        selected={selected}
        placement={index}
        style={{
          height: `100%`,
          width: SCORE_BOARD_WIDTH[index],
        }}
      />
    );
  });

  const trail = useTrail(playerComponents.length, {
    config: { mass: 5, tension: 1000, friction: 200 },
    opacity: 1,
    x: 0,
    delay: 600,
    from: { opacity: 0, x: -300 },
  });

  return (
    <div className={classes.root}>
      {trail.map(({ ...rest }, index) => (
        <a.div key={index} className={classes.animationRoot} style={rest}>
          {playerComponents[index]}
        </a.div>
      ))}
    </div>
  );
};
