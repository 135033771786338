import {
  useConnectBoardMutation,
  useGenerateBoardSignInCodeMutation,
  useRestartDetectionMutation,
} from "@pilplay/graphql";

export default function useBoardMutations() {
  const [{ error, fetching, data }, connectBoard] = useConnectBoardMutation();
  const [
    { error: errorCode, fetching: fetchingCode, data: dataCode },
    genereateSignInCode,
  ] = useGenerateBoardSignInCodeMutation();

  const [
    { error: errorDetection, fetching: fetchingRestart, data: dataRestart },
    restartDetection,
  ] = useRestartDetectionMutation();

  return {
    error: error || errorCode || errorDetection,
    data: data || dataCode || dataRestart,
    fetching: fetching || fetchingCode || fetchingRestart,
    connectBoard,
    restartDetection,
    genereateSignInCode,
  };
}
