import React, { useEffect, useState } from "react";
import {
  Grid,
  Text,
  Paper,
  Stack,
  Title,
  Flex,
  Button,
  Box,
  ActionIcon,
} from "@mantine/core";
import { Dartboard, LobbySlot } from "@pilplay/ui";
import { useForm } from "@mantine/form";
import type { CreateLobbyPlayerInput } from "@pilplay/graphql";
import { useNavigate, useParams } from "react-router-dom";
import { IconTrashX } from "@tabler/icons-react";
import { useLobbyMutations } from "../../../hooks/useLobbyMutations/useLobbyMutations";
import ErrorAlert from "../../../components/ErrorAlert";
import { clientRoutes } from "../../../routes";
import useBoard from "../../../hooks/useBoard";
import { useLobby } from "../../../hooks/useLobby/useLobby";
import GameLobbyHeader from "../../../components/GameLobbyHeader";
import classes from "./LobbyPage.module.css";
import AddPlayerModal from "./components/AddPlayerModal";
import QuickAddChip from "./components/QuickAddChip";

const MAX_PLAYERS = 6;
const QUICK_ADD_PLAYERS = ["Ante", "Dan", "Daniel", "KF", "Matilda"];

interface LobbyFormValues {
  boardId?: string;
  lobbyId?: string;
  players: ((CreateLobbyPlayerInput & { avatarUrl?: string }) | undefined)[];
}

const fillPlayers = (players: (CreateLobbyPlayerInput | undefined)[]) => {
  return [
    ...players,
    ...new Array(MAX_PLAYERS - players.length).fill(undefined),
  ].slice(0, MAX_PLAYERS) as (
    | (CreateLobbyPlayerInput & { avatarUrl?: string })
    | undefined
  )[];
};

const LobbyPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [{ data: boardData, fetching: fetchingBoard }] = useBoard(id, {
    requestPolicy: "network-only",
  });

  const [{ data: lobbyData, fetching: fetchingLobby }] = useLobby(
    boardData?.board.activeLobby?.id
  );

  const {
    create: { fetching, error },
    addPlayer,
    removePlayer,
  } = useLobbyMutations();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const { setFieldValue, values } = useForm<LobbyFormValues>({
    initialValues: {
      lobbyId: undefined,
      boardId: id || "",
      players: fillPlayers([]),
    },
    transformValues: (v) => {
      return {
        ...v,
        players: v.players.filter((p) => Boolean(p) && Boolean(p?.name)),
      };
    },
  });

  useEffect(() => {
    if (boardData?.board && !boardData.board.activeLobby) {
      navigate(clientRoutes.lobbyIdlePage(boardData.board.id));
    }
  }, [boardData, navigate]);

  useEffect(() => {
    if (lobbyData?.lobby) {
      setFieldValue("lobbyId", lobbyData.lobby.id);
      const mappedPlayers = lobbyData.lobby.players.map((p) => ({
        name: p.displayName,
        avatarUrl: p.avatarUrl,
      }));
      setFieldValue("players", fillPlayers(mappedPlayers));
    }
  }, [lobbyData, setFieldValue]);

  const onAddGuest = (name: string): void => {
    if (lobbyData?.lobby?.id) {
      void addPlayer({
        input: {
          lobbyId: lobbyData.lobby.id,
          name,
        },
      });
    } else {
      const playerIndex = values.players.findIndex((p) => !p?.name);
      setFieldValue("players", [
        ...values.players.slice(0, playerIndex),
        {
          name,
        },
        ...values.players.slice(playerIndex + 1),
      ]);
    }
  };

  const removeGuest = (name: string): void => {
    if (lobbyData?.lobby?.id) {
      const player = lobbyData.lobby.players.find(
        (p) => p.displayName === name
      );
      if (!player) {
        return;
      }
      void removePlayer({
        input: {
          lobbyId: lobbyData.lobby.id,
          playerId: player.id,
        },
      });
    } else {
      const playerIndex = values.players.findIndex((p) => p?.name === name);
      setFieldValue("players", [
        ...values.players.slice(0, playerIndex),
        undefined,
        ...values.players.slice(playerIndex + 1),
      ]);
    }
  };

  const onClose = (): void => {
    setShowModal(false);
  };

  const openGuestModal = (): void => {
    setShowModal(true);
  };

  const startLobby = (): void => {
    navigate(clientRoutes.lobbySelectPage(values.lobbyId));
  };

  const isDisabled =
    values.players.filter((p) => Boolean(p) && Boolean(p?.name)).length < 1;
  return (
    <div className={classes.root}>
      <GameLobbyHeader
        title="Create lobby"
        lobbyId={boardData?.board.activeLobby?.id || ""}
        players={values.players
          .map((p) => {
            return {
              displayName: p?.name || "",
              avatarUrl: p?.avatarUrl,
            };
          })
          .filter((p) => p.displayName)}
      />
      <AddPlayerModal onAdd={onAddGuest} onClose={onClose} open={showModal} />

      <Grid mt="xl">
        <Grid.Col
          className={classes.lobbySlots}
          pos="relative"
          span={{ base: 8 }}
        >
          <Stack>
            {values.players.map((player, index) => (
              <LobbySlot
                loading={fetchingBoard || fetchingLobby}
                leftSection={
                  player?.name ? (
                    <ActionIcon
                      onClick={() => {
                        removeGuest(player.name);
                      }}
                      variant="subtle"
                      c="dimmed"
                    >
                      <IconTrashX size={18} />
                    </ActionIcon>
                  ) : undefined
                }
                key={`player-slot-${index}`}
                onClick={player ? undefined : openGuestModal}
                player={
                  player
                    ? {
                        displayName: player.name,
                        avatarUrl: player.avatarUrl,
                      }
                    : undefined
                }
              />
            ))}
          </Stack>
          <div className={classes.dartboard}>
            <Dartboard />
          </div>
        </Grid.Col>
        <Grid.Col span={{ base: 4 }}>
          <Paper h="100%" p="md" radius="md">
            <Flex direction="column" h="100%" justify="space-between">
              <Box>
                <Title order={3}>Lobby creation</Title>
                <Text c="dimmed" size="sm">
                  Add players to your lobby by clicking on the slots on the
                  left. Once you are ready, click on the button below to start
                  the game.
                </Text>
              </Box>
              <Flex wrap="wrap">
                <Box w="100%">
                  <Title mt="xl" order={3}>
                    Quick add players
                  </Title>
                  <Text c="dimmed" size="sm">
                    Click on the chips to quickly add recent players to your
                    lobby.
                  </Text>
                </Box>
                {QUICK_ADD_PLAYERS.map((name) => (
                  <QuickAddChip
                    checked={values.players.some((p) => p?.name === name)}
                    key={name}
                    mr="xs"
                    mt="sm"
                    onClick={() => {
                      if (!values.players.some((p) => p?.name === name)) {
                        onAddGuest(name);
                      } else {
                        removeGuest(name);
                      }
                    }}
                    value={name}
                  />
                ))}
              </Flex>
              <ErrorAlert error={error} />
              <Flex justify="center" mt="xl">
                <Button
                  disabled={isDisabled}
                  fullWidth
                  loading={fetching}
                  onClick={startLobby}
                  size="xl"
                >
                  Create game lobby
                </Button>
              </Flex>
            </Flex>
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default LobbyPage;
