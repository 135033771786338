import React from "react";
import type { X01GameConfig } from "@pilplay/graphql";
import X01ContextProvider from "./context/X01ContextProvider";
import X01Game from "./components/X01Game";

interface X01GameProps {
  gameId: string;
  lobbyId?: string;
  config: X01GameConfig;
  showControls?: boolean;
  players: {
    id: string;
    index: number;
    playerType: string;
    name: string;
    userId?: string;
    avatarUrl?: string;
  }[];
}

// This component is the entry point for the X01 game since we need to wrap it in a context provider
// Actual game logic is in X01Game.tsx
const X01GamePage: React.FC<X01GameProps> = ({
  gameId,
  lobbyId,
  players,
  showControls,
}) => {
  return (
    <X01ContextProvider
      gameId={gameId}
      lobbyId={lobbyId}
      players={players}
      showControls={showControls}
    >
      <X01Game />
    </X01ContextProvider>
  );
};

export default X01GamePage;
